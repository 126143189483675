import { Injectable } from '@angular/core';
import { User } from '../models/users/user.model';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private currentUserIdSubject = new BehaviorSubject<string>(null);
  private currentUserSubject = new BehaviorSubject<User>(null);
  private hasSubscriptionSubject = new BehaviorSubject<boolean>(null);

  get currentUserId$(): Observable<string> {
    return this.currentUserIdSubject.asObservable();
  }

  get currentUserId(): string {
    return this.currentUserIdSubject.value;
  }

  get currentUser$(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  get currentUser(): User {
    return this.currentUserSubject.value;
  }

  get hasSubscription$(): Observable<boolean> {
    return this.hasSubscriptionSubject.asObservable();
  }

  get hasSubscription(): boolean {
    return this.hasSubscriptionSubject.value;
  }

  public setCurrentUserId(userId: string) {
    this.currentUserIdSubject.next(userId);
  }

  public setCurrentUser(user: User) {
    this.currentUserSubject.next(user);
  }

  public setSubscription(exists: boolean) {
    this.hasSubscriptionSubject.next(exists);
  }
}
