import { Company } from '../companies/company.model';
import { ContractStatus } from '../../enums/contract-status.enum';

/**
 * View model for a contract.
 */
export class Contract {
  id: number;
  primeId: number;
  primeContractor: Company;
  specifierId: number;
  specifierContractor: Company;
  contractNumber: string;
  endItemName: string;
  status: ContractStatus;
  supplierContractors: Company[];
  supplierContractorIds: number[];
  requestCount: number;
  openRequestCount: number;
}
