<kendo-daterange>
  <kendo-dateinput class="range-filter"
                   kendoDateRangeStartInput
                   [value]="start"
                   (valueChange)="filterRange($event, end)"
                   placeholder="Start date...">
  </kendo-dateinput>
  <span class="date-separator">-</span>
  <kendo-dateinput class="range-filter"
                   kendoDateRangeEndInput
                   [value]="end"
                   (valueChange)="filterRange(start, $event)"
                   placeholder="End date...">
  </kendo-dateinput>
</kendo-daterange>
<span class="clear-filter-button">
  <button *ngIf="hasFilter"
          class="k-button k-button-icon"
          title="Clear"
          (click)="clearFilter()">
    <span class="k-icon k-i-filter-clear"></span>
  </button>
</span>
