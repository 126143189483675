import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[nGridCellTemplate]'
})
export class NGridCellTemplateDirective {  

  @Input() forField: string;

  constructor(public templateRef: TemplateRef<any>) {
  }
}
