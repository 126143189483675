import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, combineLatest} from 'rxjs';
import { SessionService } from '../shared/services/session.service';
import { Router, ActivatedRoute} from '@angular/router';
import { AppInsightsService } from "../core/app-insights/app-insights-service";
import { environment } from '../../environments/environment';
import { AuthorizationService } from '../shared/services/authorization.service';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  //private readonly customerIdKey: string = '_customerId';
  //private userSettings: UserSettings;
  private userId: string;
  isAuthenticated: boolean;
  isCollapsed = true;  
  public isSystemOrPortalAdmin = false;
  portalUrl = environment.portalUrl;

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private authorizationService: AuthorizationService) {

//    this.logger.trace(`CLT-LOG-NavMenuComponent constructor START - ${(new Date).getMilliseconds()}`);   
  }

  ngOnInit() {
    this.subscriptions.add(combineLatest(this.authService.isAuthenticated$, this.sessionService.currentUser$).subscribe(([{ isAuthenticated }, user]) => {
      this.isAuthenticated = isAuthenticated;
      if (isAuthenticated && user) {
          this.userId = user.externalId;
          this.isSystemOrPortalAdmin = this.authorizationService.isSystemOrPortalAdmin(user);
        }
    }));
  }

  collapse() {
    this.isCollapsed = true;
  }

  toggle() {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
