import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { TestRequirement } from '../../../../shared/models/test-requirements/test-requirement.model';
import { Sample } from '../../../../shared/models/requests/sample.model';
import { OperatorType } from '../../../../shared/enums/operator-type.enum';
import { FillOutDirection } from '../../../../shared/enums/fill-out-direction.enum';
import { TestResult } from '../../../../shared/models/requests/test-result.model';
import { AggregateFunction } from '../../../../shared/enums/aggregate-function.enum';
import { I18nService } from '../../../../shared/services/i18n.service';
import { SortingService } from '../../../../shared/services/sorting.service';
import { TestEvaluationService } from '../../../../shared/services/test-evaluation.service';
import { TestType } from '../../../../shared/enums/test-type.enum';
import { TestAttribute } from '../../../../shared/models/test-methods/test-attribute.model';
import { DataSourceService } from '../../../../shared/services/datasource.service';
import { Globals } from '../../../../shared/globals';
import { AggregateTestResult } from '../../../../shared/models/requests/aggregate-test-result.model';
import { EvaluationMode } from '../../../../shared/enums/evaluation-mode.enum';
import { formProvider } from '../../../../shared/providers/form.provider';
import { FormGroupNames } from '../../../../shared/enums/form-group-names.enum';
import { AggregateConditionType } from '../../../../shared/enums/aggregate-condition-type.enum';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { TestAttributeOverride } from '../../../../shared/models/test-requirements/test-attribute-override.model';
import { ReasonForSubmittal } from '../../../../shared/enums/reason-for-submittal.enum';
import { TestResultComment } from '../../../../shared/models/requests/test-result-comment.model';

@Component({
  selector: 'pdp-test-results',
  templateUrl: './test-results.component.html',
  styleUrls: ['./test-results.component.css'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [formProvider]
})
export class TestResultsComponent implements OnInit {

  @Input() testRequirement: TestRequirement;
  @Input() samples: Sample[];
  @Input() fillOutDirection: FillOutDirection = FillOutDirection.Across;
  @Input() lastTabIndex = 0;
  @Input() testRequirementIndex = 0;
  @Input() reasonForSubmittal = ReasonForSubmittal.SourceSampling;
  @Input() readonly = false;
  @Input() controlsRequired = false;

  @ViewChild('resultsTable') resultsTable: ElementRef;
  @ViewChild('sampleColumn') sampleColumn: ElementRef;
  @ViewChild('memberColumn') memberColumn: ElementRef;
  @ViewChild('aggregateColumn') aggregateColumn: ElementRef;
  @ViewChild('specificationRequirementColumn') specificationRequirementColumn: ElementRef;
  @ViewChild('scoreColumn') scoreColumn: ElementRef;

  OperatorType = OperatorType;
  TestType = TestType;
  EvaluationMode = EvaluationMode;
  FormGroupNames = FormGroupNames;
  AggregateFunction = AggregateFunction;
  AggregateConditionType = AggregateConditionType;
  ReasonForSubmittal = ReasonForSubmittal;

  logicalValues: { text: string, value: number }[] = [
    { text: '', value: null },
    { text: this.i18nService.transform('true'), value: 1 },
    { text: this.i18nService.transform('false'), value: 0 }
  ];

  commentLenghtLimit = 1000;

  spectralReflectanceNote = "";
  passingText: string;
  failingText: string;
  evaluationMode: EvaluationMode;

  grayscaleValues: { text: string, value: number }[];
  isGrayScale = false;
  isLogical = false;
  precisionLevel = 0;
  testType: TestType;  

  numberFormat: NumberFormatOptions = {};

  constructor(
    private i18nService: I18nService,
    private testEvaluationService: TestEvaluationService,
    private dataSourceService: DataSourceService,
    public sortingService: SortingService
  ) { }

  ngOnInit() {
    this.grayscaleValues = this.dataSourceService.getGrayScaleDataSource();
    this.isGrayScale = this.testRequirement.testMethodVariant.testMethod.unitOfMeasure === Globals.GrayScaleUnitOfMeasure;
    this.testType = this.testRequirement.testMethodVariant.testMethod.testType;
    this.isLogical = this.testType === TestType.Logical;
    this.evaluationMode = this.testRequirement.testMethodVariant.testMethod.evaluationMode;
    this.precisionLevel = this.testRequirement.precisionLevel;
    this.numberFormat = this.setNumberFormat(this.precisionLevel);

    this.passingText = this.i18nService.transform('pass').toUpperCase();
    this.failingText = this.i18nService.transform('fail').toUpperCase();

    if (this.testRequirement && (!this.testRequirement.testResults || this.testRequirement.testResults.length === 0)) {
      this.generateTestResults();
    }

    if (this.reasonForSubmittal === ReasonForSubmittal.ShadeEvaluation && this.testRequirement && (!this.testRequirement.testResultComments || this.testRequirement.testResultComments.length === 0)) {
      this.generateTestResultComments();
    }

    if (this.reasonForSubmittal === ReasonForSubmittal.ShadeEvaluation && this.testRequirement && this.testRequirement.testResultComments) {
      this.testRequirement.testResultComments.map(trc => {
        trc.originalComment = trc.comment;
        return trc;
      });
    }

    if (this.testRequirement
      && this.evaluationMode === EvaluationMode.SpectralReflectance) {
      this.spectralReflectanceNote = this.i18nService.transform('specificationRequirementNotes_spectralReflectance', { '@numberOfAllowedFailures': this.testRequirement.numberOfAllowedFailures });
    }

  }

  public getTestResultValueName(sampleId: number, memberId: number, determinationIndex: number): string {
    return `value_${sampleId}_${memberId}_${determinationIndex}`;
  }

  public getTestResultCommentName(sampleId: number): string {
    return `comment_${sampleId}`;
  }

  public getTestResult(sampleId: number, memberId: number, determinationIndex: number): TestResult {
    return this.testRequirement && this.testRequirement.testResults ? this.testRequirement.testResults.find(r => r.testMethodMemberId === memberId
      && r.sampleId === sampleId
      && r.determinationIndex === determinationIndex) : null;
  }

  public getTestResultComment(sampleId: number): TestResultComment {
    return this.testRequirement && this.testRequirement.testResultComments ? this.testRequirement.testResultComments.find(r => r.sampleId === sampleId) : null;
  }

  public getAggregateResultOfDeterminations(sampleId: number, memberId: number): AggregateTestResult {
    return this.testRequirement.aggregatedTestResults.length > 0 ? this.testRequirement.aggregatedTestResults.find(r => r.testMethodMemberId === memberId
      && r.sampleId === sampleId) : undefined;
  }

  public getLotAverageResult(memberId: number): AggregateTestResult {
    return this.testRequirement.lotAverageTestResults.length > 0 ? this.testRequirement.lotAverageTestResults.find(r => r.testMethodMemberId === memberId) : undefined;
  }

  public testResultValueChanged(newValue: number, testResult: TestResult) {
    if (testResult) {
      testResult.isValueChanged = newValue !== testResult.value;      
      testResult.value = newValue;
      testResult.isTestResultModified = testResult.value !== testResult.originalValue;
    }
  }

  public testResultCommentChanged(newValue: string, testResultComment: TestResultComment) {
    if (testResultComment) {
      testResultComment.comment = newValue;
      testResultComment.isCommentModified = testResultComment.comment !== testResultComment.originalComment;
    }
  }

  public evaluateResult(result: TestResult) {
    if (result && result.isValueChanged) {
      result.hasPassed = null;
      if (this.testRequirement.determination > 1 && this.testRequirement.testMethodVariant.testMethod.evaluationMode === EvaluationMode.Regular) {
        this.calculateAggregateResultValue(result.sampleId, result.testMethodMemberId);
        this.evaluateAggregateResult(result.sampleId, result.testMethodMemberId);
      }

      const formattedValue = this.testEvaluationService.getDisplayedValue(result.value, this.precisionLevel);
      const value = parseFloat(formattedValue);

      this.testEvaluationService.getEvaluationResult(result.testRequirementId, result.testMethodMemberId, value).subscribe(hasPassed => {
        result.hasPassed = hasPassed;
      });
    }
  }

  private evaluateAggregateResult(sampleId: number, memberId: number) {
    const result = this.getAggregateResultOfDeterminations(sampleId, memberId);

    if (result) {
      result.hasPassed = null;
      const formattedValue = this.testEvaluationService.getDisplayedValue(result.value, this.precisionLevel);
      const value = parseFloat(formattedValue);

      if (this.testRequirement.aggregatedTestResults.length > 0 && this.testRequirement.aggregateFunction === AggregateFunction.LotAverage) {
        this.evaluateLotAverageResult(memberId, this.testRequirement.testMethodVariant.lotAverageConditionMember.id);
      }

      this.testEvaluationService.getEvaluationResult(result.testRequirementId, result.testMethodMemberId, value).subscribe(hasPassed => {
        result.hasPassed = hasPassed;
      });
    }
  }

  private evaluateLotAverageResult(memberId: number, lotAverageConditionMemberId: number) {
    const result = this.getLotAverageResult(memberId);

    if (result) {
      result.hasPassed = null;
      const formattedValue = this.testEvaluationService.getDisplayedValue(result.value, this.precisionLevel);

      if (formattedValue !== '') {
        const value = parseFloat(formattedValue);
        this.testEvaluationService.getEvaluationResult(result.testRequirementId, lotAverageConditionMemberId, value).subscribe(hasPassed => {
          result.hasPassed = hasPassed;
        });
      }
    }
  }

  public getFailedResultCountForDetermination(sampleId: number, determinationIndex: number) {
    if (this.testRequirement.testResults.some(r => r.sampleId === sampleId
      && r.determinationIndex === determinationIndex
      && r.value !== null
      && r.hasPassed !== null)) {
      return this.testRequirement.testResults.filter(r => r.sampleId === sampleId && r.determinationIndex === determinationIndex && r.hasPassed === false).length;
    }

    return -1;
  }

  public getFailedResultCountForMember(sampleId: number, memberId: number) {
    if (this.testRequirement.determination > 1) {
      if (this.testRequirement.aggregatedTestResults.some(r => r.sampleId === sampleId
        && r.testMethodMemberId === memberId
        && r.value !== null
        && r.hasPassed !== null)) {
        return this.testRequirement.aggregatedTestResults.filter(r => r.sampleId === sampleId && r.testMethodMemberId === memberId && r.hasPassed === false).length;
      }
    } else {
      if (this.testRequirement.testResults.some(r => r.sampleId === sampleId
        && r.testMethodMemberId === memberId
        && r.value !== null
        && r.hasPassed !== null)) {
        return this.testRequirement.testResults.filter(r => r.sampleId === sampleId && r.testMethodMemberId === memberId && r.hasPassed === false).length;
      }
    }

    return -1;
  }

  public getFailedResultCountForLotAverage(memberId: number) {
    let lotAverageResult = this.getLotAverageResult(memberId);

    if (lotAverageResult && lotAverageResult.value !== null && (lotAverageResult.hasPassed !== undefined && lotAverageResult.hasPassed !== null)) {
      return lotAverageResult.hasPassed === false ? 1 : 0;
    }

    return -1;
  }

  public getScoreForSamples(memberId: number) {
    if (this.testRequirement.determination > 1) {
      if (this.testRequirement.aggregatedTestResults.some(r => r.testMethodMemberId === memberId
        && r.value !== null
        && r.hasPassed !== null)) {
        const count = this.testRequirement.aggregatedTestResults.filter(r => r.testMethodMemberId === memberId).length;
        const score = this.testRequirement.aggregatedTestResults.filter(r => r.testMethodMemberId === memberId && r.hasPassed === (this.testRequirement.aggregateConditionType === AggregateConditionType.Percentage)).length;
        return this.testRequirement.aggregateConditionType === AggregateConditionType.Amount ? score : score / count * 100;
      }
    } else {
      if (this.testRequirement.testResults.some(r => r.testMethodMemberId === memberId
        && r.value !== null
        && r.hasPassed !== null)) {
        const count = this.testRequirement.testResults.filter(r => r.testMethodMemberId === memberId).length;
        const score = this.testRequirement.testResults.filter(r => r.testMethodMemberId === memberId && r.hasPassed === (this.testRequirement.aggregateConditionType === AggregateConditionType.Percentage)).length;
        return this.testRequirement.aggregateConditionType === AggregateConditionType.Amount ? score : score / count * 100;
      }
    }

    return -1;
  }

  public getFailedResultCountForSpectralReflectance(sampleId: number) {
    let result = -1;

    for (let determinationIndex = 0; determinationIndex < this.testRequirement.determination; determinationIndex++) {
      let subResult = -1;
      if (this.testRequirement.testResults.some(r => r.sampleId === sampleId
        && r.determinationIndex === determinationIndex
        && r.value !== null
        && r.hasPassed !== null)) {
        subResult = this.testRequirement.testResults.filter(r => r.sampleId === sampleId && r.determinationIndex === determinationIndex && r.hasPassed === false).length;
      }

      if (subResult > result) {
        result = subResult;
      }
    }
    return result;
  }

  public getDeterminationIndices(): number[] {
    const determinationIndices = []
    for (let i = 0; i < this.testRequirement.determination; i++) {
      determinationIndices.push(i);
    }
    return determinationIndices;
  }

  public resolveAggregateFunctionName(aggregateFunction: string) {
    var prefix = this.testType === TestType.Logical ? 'aggregateFunction_simple_logical' : 'aggregateFunction_full';
    var translationKey = `${prefix}_${aggregateFunction === AggregateFunction.LotAverage ? AggregateFunction.Average : aggregateFunction}`;
    var aggregateFunctionName = this.i18nService.transform(translationKey);
    if (this.testType === TestType.Logical) {
      return this.i18nService.transform('resultOf', { '@argument': aggregateFunctionName });
    } else {
      return aggregateFunctionName;
    }
  }

  private calculateAggregateResultValue(sampleId: number, memberId: number) {
    const results = this.testRequirement.testResults.filter(r => r.testMethodMemberId === memberId && r.sampleId === sampleId && r.value !== null);
    const determination = this.testRequirement.determination;
    const aggregateFunction = this.testRequirement.aggregateFunction;
    let aggregateResult = this.getAggregateResultOfDeterminations(sampleId, memberId);
    let aggregateValue: number;

    if (results.length > 0) {
      switch (aggregateFunction) {
        case AggregateFunction.Minimum: aggregateValue = Math.min(...results.map(r => +r.value || 0)); break;
        case AggregateFunction.Maximum: aggregateValue = Math.max(...results.map(r => +r.value || 0)); break;
        case AggregateFunction.LotAverage:
        case AggregateFunction.Average: aggregateValue = this.calculateAverage(results, determination); break;
        case AggregateFunction.Sum:
        default: aggregateValue = this.calculateSum(results);
      }
    } else {
      aggregateValue = null;
    }

    if (aggregateValue && this.isGrayScale) {
      aggregateValue = this.roundToNearestHalf(aggregateValue);
    }

    if (!aggregateResult) {
      aggregateResult = {
        testRequirementId: this.testRequirement.id,
        testMethodMemberId: memberId,
        sampleId: sampleId,
        value: aggregateValue
      }

      this.testRequirement.aggregatedTestResults.push(aggregateResult);
    } else {
      aggregateResult.value = aggregateValue;
    }

    if (this.testRequirement.aggregatedTestResults.length > 0 && this.testRequirement.aggregateFunction === AggregateFunction.LotAverage) {
      this.calculateLotAverageTestResult(memberId);
    }
  }

  private calculateLotAverageTestResult(testMethodMemberId: number) {
    const results = this.testRequirement.aggregatedTestResults.filter(ar => ar.testMethodMemberId === testMethodMemberId);
    let aggregateResult = this.getLotAverageResult(testMethodMemberId);
    var aggregateValue = null;

    if (results.length > 0 && results.some(r => r.value != null)) {
      aggregateValue = results.map(a => +a.value).reduce((a, b) => (a || 0) + (b || 0)) / results.length;
    }

    if (!aggregateResult) {
      aggregateResult = {
        testRequirementId: this.testRequirement.id,
        testMethodMemberId: testMethodMemberId,
        sampleId: null,
        value: aggregateValue
      }

      this.testRequirement.lotAverageTestResults.push(aggregateResult);
    } else {
      aggregateResult.value = aggregateValue;
    }
  }

  public translateAggregateValue(aggregateValue: number) {
    if (!this.isLogical && !this.isGrayScale) {
      return this.testEvaluationService.getDisplayedValue(aggregateValue, this.precisionLevel);
    } else {
      return this.translateValue(this.isGrayScale ? this.roundToNearestHalf(aggregateValue) : aggregateValue, this.isLogical, this.isGrayScale);
    }
  }

  public getTabIndex(sampleIndex: number, memberIndex: number, determinationIndex: number): number {
    let tabIndex: number = this.lastTabIndex;
    const memberCount = this.testRequirement.testMethodVariant.testMethodMembers.length;
    const determination = this.testRequirement.determination;

    tabIndex += sampleIndex * memberCount * determination;

    if (this.fillOutDirection === FillOutDirection.Across) {
      tabIndex += (memberIndex * determination) + determinationIndex;
    } else {
      tabIndex += (determinationIndex * memberCount) + memberIndex;
    }

    return tabIndex;

  }

  public scrollIntoView(sender: any, determinationIndex: number) {
    const testResultCell = sender.hostElement.nativeElement.parentElement.parentElement as HTMLElement;

    const sampleColumnWidth = this.getColumnWidth(this.sampleColumn);
    const memberColumnWidth = this.getColumnWidth(this.memberColumn);
    const aggregateColumnWidth = this.getColumnWidth(this.aggregateColumn);
    const specificationRequirementColumnWidth = this.getColumnWidth(this.specificationRequirementColumn);
    const scoreColumnWidth = this.getColumnWidth(this.scoreColumn);

    let tableScrollLeft = this.resultsTable.nativeElement.scrollLeft;
    const tableWidth = this.resultsTable.nativeElement.clientWidth;
    const boundaryLeft = sampleColumnWidth + memberColumnWidth;
    const boundaryRight = tableWidth - (aggregateColumnWidth + specificationRequirementColumnWidth + scoreColumnWidth);
    const cellLeftOffset = (determinationIndex * testResultCell.clientWidth) - tableScrollLeft;
    const cellRightOffset = boundaryLeft + ((determinationIndex + 1) * testResultCell.clientWidth) - tableScrollLeft;

    if (cellLeftOffset < 0) {
      tableScrollLeft = (determinationIndex * testResultCell.clientWidth);
    } else if (boundaryRight - cellRightOffset < 0) {
      tableScrollLeft = ((determinationIndex + 1) * testResultCell.clientWidth) - (boundaryRight - boundaryLeft);
    }

    if ((cellLeftOffset < 0) || (boundaryRight - cellRightOffset < 0)) {
      this.resultsTable.nativeElement.scrollTo({
        left: tableScrollLeft,
        behavior: 'smooth'
      });
    }
  }

  private getColumnWidth(element: ElementRef): number {
    return element ? element.nativeElement.clientWidth : 0;
  }

  public getOperatorType(testAttribute: TestAttribute, testAttributeOverrides: TestAttributeOverride[]) {
    const testAttributeOverride = testAttributeOverrides.find(t => t.testAttributeId === testAttribute.id);
    const operatorType = testAttributeOverride ? testAttributeOverride.operatorType : testAttribute.operatorType;
    return OperatorType[operatorType];
  }

  public translateExpectedValue(testAttribute: TestAttribute, testAttributeOverrides: TestAttributeOverride[]) {
    const testAttributeOverride = testAttributeOverrides.find(t => t.testAttributeId === testAttribute.id);
    const expectedValue = testAttributeOverride ? testAttributeOverride.expectedValue : testAttribute.expectedValue;
    if (!this.isLogical && !this.isGrayScale) {
      return this.testEvaluationService.getDisplayedValue(expectedValue, this.precisionLevel);
    } else {
      return this.translateValue(expectedValue, this.isLogical, this.isGrayScale);
    }
  }

  private translateValue(value: number, isLogical: boolean, isGrayScale: boolean) {
    if (isLogical) {
      const logicalValue = this.logicalValues.find(lv => lv.value === value);
      return logicalValue ? logicalValue.text : '';
    } else {
      if (isGrayScale) {
        const grayscaleValue = this.grayscaleValues.find(lv => lv.value === value);
        return grayscaleValue ? grayscaleValue.text : '';
      } else {
        return +value.toFixed(Globals.DecimalPlaces);
      }
    }
  }

  private calculateSum(results: TestResult[]): number {
    return results.map(a => +a.value).reduce((a, b) => (a || 0) + (b || 0));
  }

  private calculateAverage(results: TestResult[], determination: number): number {
    return this.calculateSum(results) / determination;
  }

  private roundToNearestHalf(value: number) {
    return parseFloat((Math.round(value * 2) / 2).toFixed(1));
  }

  private generateTestResults() {
    this.testRequirement.testResults = [];

    const determination = this.testRequirement.determination;

    if (this.testRequirement.testMethodVariant) {
      for (const member of this.testRequirement.testMethodVariant.testMethodMembers) {
        for (let i = 0; i < determination; i++) {
          for (const sample of this.samples) {
            const testResult = new TestResult();
            testResult.testRequirementId = this.testRequirement.id;
            testResult.testMethodMemberId = member.id;
            testResult.determinationIndex = i;
            testResult.sampleId = sample.id;
            testResult.value = null;
            testResult.originalValue = null;
            testResult.hasPassed = null;
            this.testRequirement.testResults.push(testResult);
          };
        }
      }
    }
  }

  private generateTestResultComments() {
    this.testRequirement.testResultComments = [];

    for (const sample of this.samples) {
      const testResultComment = new TestResultComment();
      testResultComment.testRequirementId = this.testRequirement.id;
      testResultComment.sampleId = sample.id;
      testResultComment.comment = '';
      testResultComment.originalComment = '';
      this.testRequirement.testResultComments.push(testResultComment);
    };
  }

  public isNumber(value: any): boolean {
    return typeof value === 'number';
  }

  getAggregateCondition(testRequirement: TestRequirement) {
    switch (testRequirement.aggregateConditionType) {
      case AggregateConditionType.Percentage:
        return `${testRequirement.percentageOfRequiredPasses}${this.i18nService.transform('aggregateCondition_samplesNeedToPass')}`;
      case AggregateConditionType.Amount:
      default:
        return `${testRequirement.numberOfAllowedFailures} ${this.i18nService.transform('aggregateCondition_samplesCanFail')}`;
    }
  }

  private setNumberFormat(precisionLevel: number): NumberFormatOptions {
    return {
      minimumFractionDigits: precisionLevel,
      maximumFractionDigits: 8
    };
  }
}
