import { Injectable } from '@angular/core';
import { ListItem } from '../models/list-item.model';
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class DataSourceService {

  constructor(
    private i18nService: I18nService
  ) { }

  getPrecisionLevelDataSource(): ListItem<number>[] {
    const wholeNumbersText = this.i18nService.transform('wholeNumbers');
    const decimalText = this.i18nService.transform('decimal');
    const decimalsText = this.i18nService.transform('decimals');
    return [
      { text: `# (${wholeNumbersText})`, value: 0 },
      { text: `.# (1 ${decimalText})`, value: 1 },
      { text: `.## (2 ${decimalsText})`, value: 2 },
      { text: `.### (3 ${decimalsText})`, value: 3 },
      { text: `.#### (4 ${decimalsText})`, value: 4 }
    ]
  }

  getGrayScaleDataSource(): ListItem<number>[] {
    return [
      { text: '', value: null },
      { text: '1', value: 1.0 },
      { text: '1-2', value: 1.5 },
      { text: '2',   value: 2.0 },
      { text: '2-3', value: 2.5 },
      { text: '3',   value: 3.0 },
      { text: '3-4', value: 3.5 },
      { text: '4',   value: 4.0 },
      { text: '4-5', value: 4.5 },
      { text: '5',   value: 5.0 }
    ];
  }
}
