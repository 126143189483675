import { Component } from '@angular/core';
import { Location } from '@angular/common';

/**
 * A Component which is used to display the forbidden (HTTP 403) message.
 */
@Component({
  selector: 'pdp-forbidden',
  templateUrl: 'forbidden.component.html'
})
export class ForbiddenComponent {
  _location;
  /**
   * Initializes a new instance of the `ForbiddenComponent` class.
   * @param location Service to interact with the browser URL.
   */
  constructor(private location: Location) {
    this._location = location;
  }

  /**
   * Goes back to the previous page.
   */
  goBack() {
    this._location.back();
  }
}
