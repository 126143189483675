import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './shared/services/auth.guard';
import { ContractsComponent } from './contracts/contracts.component';

import { LoginComponent } from './auth/login/login.component';
import { UnauthorizedComponent } from './status/unauthorized/unauthorized.component';
import { ForbiddenComponent } from './status/forbidden/forbidden.component';
import { NotFoundComponent } from './status/not-found/not-found.component';
import { NoSubscriptionsComponent } from './status/no-subscriptions/no-subscriptions.component';
import { UserRole } from './shared/models/users/user-role.model';
import { RequestsComponent } from './requests/requests.component';
import { SettingsComponent } from './settings/settings.component';
import { RequestDetailsComponent } from './requests/request-details/request-details.component';
import { TestMethodsComponent } from './test-methods/test-methods.component';
import { CanDeactivateGuard } from './shared/services/can-deactivate.guard';
import { TestMethodGroupsComponent } from './test-method-groups/test-method-groups.component';
import { AutoLoginAllRoutesGuard } from 'angular-auth-oidc-client';

const appRoutes: Routes = [
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'login', component: LoginComponent },
  { path: 'forbidden', component: ForbiddenComponent, canActivate: [AutoLoginAllRoutesGuard, AuthGuard] },
  { path: 'notfound', component: NotFoundComponent, canActivate: [AutoLoginAllRoutesGuard, AuthGuard] },
  { path: 'nosubscription', component: NoSubscriptionsComponent, canActivate: [AuthGuard] },
  { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AutoLoginAllRoutesGuard, AuthGuard] },  
  {
    path: 'testMethods',
    component: TestMethodsComponent,
    data: { roles: [UserRole.PortalAdmin, UserRole.SystemAdmin] },
    canActivate: [AutoLoginAllRoutesGuard, AuthGuard]
  },
  {
    path: 'testMethodGroups',
    component: TestMethodGroupsComponent,
    canActivate: [AutoLoginAllRoutesGuard, AuthGuard]
  },
  {
    path: 'contracts',
    component: ContractsComponent,
    canActivate: [AutoLoginAllRoutesGuard, AuthGuard]
  },
  {
    path: 'requests',
    component: RequestsComponent,
    canActivate: [AutoLoginAllRoutesGuard, AuthGuard]
  },
  {
    path: 'request/create-for/:contractIds',
    component: RequestDetailsComponent,
    canActivate: [AutoLoginAllRoutesGuard, AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'request/:id',
    component: RequestDetailsComponent,
    canActivate: [AutoLoginAllRoutesGuard, AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AutoLoginAllRoutesGuard, AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
