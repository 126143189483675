<kendo-dialog-titlebar (close)="onClose($event)">
  <span>{{title}}</span>
</kendo-dialog-titlebar>

<div class="container">
  <form #contractForm="ngForm">
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="contractNumber">{{'contractNumber'|i18n}}</label>

      <div class="col-sm-9">
        <input type="text"
               class="form-control"
               id="contractNumber"
               required
               name="contractNumber"
               placeholder="{{'contractNumber_placeholder'|i18n}}"
               [(ngModel)]="contract.contractNumber"
               [readonly]="isReadonly() || isArchived()" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="prime">{{'primeContractor'|i18n}}</label>
      <div class="col-sm-9">
        <kendo-dropdownlist [data]="primeContractors"
                            textField="name"
                            valueField="id"
                            [(ngModel)]="contract.primeId"
                            [valuePrimitive]="true"
                            name="prime"
                            class="w-100"
                            (valueChange)="onPrimeChange($event)"
                            [disabled]="!isPortalAdmin || isReadonly() || isArchived()">
        </kendo-dropdownlist>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="endItemName">{{'endItemName'|i18n}}</label>
      <div class="col-sm-9">
        <input type="text"
               class="form-control"
               id="endItemName"
               required
               name="endItemName"
               placeholder="{{'endItem_placeholder'|i18n}}"
               [(ngModel)]="contract.endItemName"
               [readonly]="isReadonly() || isArchived()" />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label" for="suppliers">{{'suppliers'|i18n}}</label>
      <div class="col-sm-9">
        <table class="suppliers-list">
          <tbody>
            <tr>
              <td>
                <span *ngIf="defaultSupplier" class="info-card nowrap mb-1 me-1">{{defaultSupplier.name}}</span>
                <span *ngFor="let supplier of supplierContractorsInvolved" class="info-card nowrap mb-1 me-1">
                  {{supplier.name}}
                </span>
                <ng-container *ngIf="isArchived()">
                  <span *ngFor="let supplier of supplierContractorsNotInvolved" class="info-card nowrap mb-1 me-1">
                    {{supplier.name}}
                  </span>
                </ng-container>
              </td>
              </tr>
            <tr>
              <td *ngIf="!isArchived() && availableSuppliers.length > 0">
                <kendo-multiselect [data]="availableSuppliers"
                                   textField="name"
                                   valueField="id"
                                   [(ngModel)]="contract.supplierContractorIds"
                                   [valuePrimitive]="true"
                                   [readonly]="isReadonly()"
                                   placeholder="{{'supplier_selection_placeholder'|i18n}}"
                                   name="suppliers">
                </kendo-multiselect>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div *ngIf="isReadonly() && !isArchived()" class="alert alert-info fst-italic">{{'contractReadonlyNote'|i18n}}</div>
  </form>
</div>
<kendo-dialog-actions layout="normal">
  <button *ngIf="canArchiveContract()" kendoButton (click)="archiveContract()">{{'archiveContract' |i18n}}</button>
  <button *ngIf="canReactivateContract()" kendoButton themeColor="primary" (click)="reactivateContract()">{{'reactivateContract' |i18n}}</button>
  <button *ngIf="!isArchived()" kendoButton themeColor="primary" (click)="saveContract()">{{actionName}}</button>
</kendo-dialog-actions>
