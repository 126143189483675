import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor() { }

  dateDiff(firstDate: Date, secondDate: Date): number {
    return Math.round((secondDate.valueOf() - firstDate.valueOf()) / (1000 * 60 * 60 * 24));
  }

  datePart(date: Date): Date {
    return new Date(Math.round(date.valueOf() / (1000 * 60 * 60 * 24)));
  }
}
