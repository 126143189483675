import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { I18nService } from "../shared/services/i18n.service";
import { SessionService } from '../shared/services/session.service';
import { MessageBoxService } from '../shared/components/message-box/message-box.service';
import { PendingChangesService } from '../shared/services/pending-changes.service';
import { AppSettingsService } from '../shared/services/app-settings.service';
import { AuthService } from '../auth/auth.service';
import { PopupSettings } from '@progress/kendo-angular-buttons';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  public loginMenu: Array<any> = [];
  public popupSettings: PopupSettings = { align: 'right' };

  isAuthenticated: boolean;
  isUserLoading: boolean;
  userName: string;
  companyName: string;
  roles: string;
  customerId: string;
  customerProfileText: string;
  logoutText: string;
  myProfileText: string;
  buildNumber: string;

  constructor(
    private appSettingsService: AppSettingsService,
    private sessionService: SessionService,
    private i18nService: I18nService,
    private messageBoxService: MessageBoxService,
    private pendingChangesService: PendingChangesService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.initializeMenu();
    this.isUserLoading = true;
    this.authService.isAuthenticated$.subscribe(({ isAuthenticated }) => {
      this.isAuthenticated = isAuthenticated;
    });
    this.sessionService.currentUser$.subscribe(user => {
      if (user) {
        this.isUserLoading = false;
        this.userName = user.fullName;
        this.companyName = user.companyName;
        this.roles = user.roles.map(r => this.i18nService.transform(r)).join('|');
      }
    });

    this.buildNumber = this.appSettingsService.getSettings().buildNumber;
  }

  //Initialize Login-Logout control menu
  private initializeMenu(): void {
    this.loginMenu = [];    

    this.loginMenu.push({
      text: this.i18nService.transform("navbar_login_menu_item_logout_label_text"),
      iconClass: 'fas fa-sign-in-alt pe-025',
      click: () => {
        this.onLogout();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onSelect({ item }): void {
    if (item.icon === 'logout') {
      this.onLogout();
    }
  }

  onLogout(): void {
    const component = this.pendingChangesService.getComponentCanDeactivate();

    if (component && !component.canDeactivate()) {
      component.clearDeactivationBypass();
      this.pendingChangesService.confirmRejectionOfPendingChanges(component.deactivationWarningMessage).subscribe(canLogout => {
        if (canLogout) {
          component.bypassDeactivation();
          this.authService.logoff();
        }
      })
    } else {
      this.messageBoxService.open({
        title: this.i18nService.transform('message_box_title_confirm'),
        message: this.i18nService.transform("navbar_logout_confirm_message"),
        confirmButtonText: this.i18nService.transform('yes'),
        denyButtonText: this.i18nService.transform('no'),
        onConfirm: () => {
          this.authService.logoff();
        }
      });
    }
  }
}
