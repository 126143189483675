import { Injectable } from '@angular/core';
import { NGridService } from '../components/n-grid/n-grid.service';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { NGridState } from '../components/n-grid/models/n-grid-state.model';
import { Observable } from 'rxjs';
import { NGridPagedData } from '../components/n-grid/models/n-grid-paged-data.model';
import { RequestGridViewModel } from '../models/requests/request-gridview.model';
import { StatusCountViewModel } from '../models/status-count.model';
import { Request } from '../models/requests/request.model';
import { AgendaGridViewModel } from '../models/agenda-gridview.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  constructor(private http: HttpClient,
    private nGridService: NGridService) { }

  public requestEndpointUrl = environment.apiBaseUrl + 'api/v1/Request'; // URL to web api

  getRequests(state: NGridState): Observable<NGridPagedData<RequestGridViewModel>> {
    const url = `${this.requestEndpointUrl}`;
    let httpParams = new HttpParams();

    httpParams = this.nGridService.stateToHttpParams(state, httpParams);

    return this.http.get<NGridPagedData<RequestGridViewModel>>(url, { observe: 'body', params: httpParams, headers: httpOptions.headers });
  }

  getRequest(requestId: number): Observable<HttpResponse<Request>> {
    const url = `${this.requestEndpointUrl}/${requestId}`;
    
    return this.http.get<Request>(url, { observe: 'response', headers: httpOptions.headers });
  }

  getStatusCounts(excludeArchived: boolean = false): Observable<HttpResponse<StatusCountViewModel[]>> {
    const url = `${this.requestEndpointUrl}/StatusCounts`;
    let httpParams = new HttpParams();
    if (excludeArchived) {
      httpParams = httpParams.set('excludeArchived', 'true');
    }

    return this.http.get<StatusCountViewModel[]>(url, { observe: 'response', params: httpParams, headers: httpOptions.headers });
  }

  getAgenda(state: NGridState): Observable<NGridPagedData<AgendaGridViewModel>> {
    const url = `${this.requestEndpointUrl}/Agenda`;
    let httpParams = new HttpParams();

    httpParams = this.nGridService.stateToHttpParams(state, httpParams);
    httpParams = httpParams.set('excludeArchived', 'true');

    return this.http.get<NGridPagedData<AgendaGridViewModel>>(url, { observe: 'body', params: httpParams, headers: httpOptions.headers });
  }

  /** POST: create a new request */
  addRequest(request: Request): Observable<number> {
    return this.http.post<number>(this.requestEndpointUrl, request, httpOptions);
  }

  /** PUT: updates the request on the server */
  updateRequest(request: Request): Observable<number> {
    return this.http.put<number>(this.requestEndpointUrl, request, httpOptions);
  }

  /** DELETE: deletes the request from the server */
  deleteRequest(requestId: number): Observable<any> {
    const url = `${this.requestEndpointUrl}/${requestId}`;
    return this.http.delete(url, { observe: 'response' });
  }

  generateWorkOrderNumber(contractId: number): Observable<string> {
    const url = `${this.requestEndpointUrl}/GenerateWorkOrderNumber/${contractId}`;

    return this.http.get(url, { observe: 'body', responseType: 'text' });
  }

  downloadDD1222(requestId: number, isSectionAOnly: boolean = false): Observable<Blob> {
    const url = `${this.requestEndpointUrl}/${requestId}/DD1222${isSectionAOnly?'/SectionA':''}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadLabReport(requestId: number, reportId: number): Observable<Blob> {
    const url = `${this.requestEndpointUrl}/${requestId}/LabReport/${reportId}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getUploadLabReportUrl(requestId: number): string {
    return `${this.requestEndpointUrl}/${requestId}/LabReport`;
  }

  deleteLabReport(requestId: number, reportId: number): Observable<any> {
    const url = `${this.requestEndpointUrl}/${requestId}/LabReport/${reportId}`;
    return this.http.delete(url, { observe: 'response' });
  }
}
