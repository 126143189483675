import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'pdp-contract-selector-dialog',
  templateUrl: './contract-selector-dialog.component.html'
})
export class ContractSelectorDialogComponent {

  constructor(
    public dialog: DialogRef,
    private router: Router
  ) { }

  public selectedContractIds: number[] = [];

  selectedContractIdsChanged(selectedContractIds: number[]) {
    this.selectedContractIds = selectedContractIds;
  }

  onCancelAction() {
    this.dialog.close();
  }

  onCreateRequestAction() {
    this.dialog.close();
    this.router.navigateByUrl(`request/create-for/${this.selectedContractIds.join('&')}`);
  }

}
