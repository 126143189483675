import { SortDescriptor, GroupDescriptor, CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { NGridFilterMethod } from "../enums/n-grid-filter-method.enum";

export class NGridState {
  skip?: number;
  take?: number;
  sort?: SortDescriptor[];
  group?: GroupDescriptor[];
  readonly filterMethod?: NGridFilterMethod;
  filter?: CompositeFilterDescriptor;
  freeTextSearch?: string;
  isExport?: boolean;
}

