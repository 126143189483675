import { Component, Input } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { Observable, Subject } from 'rxjs';
import { MessageBoxType } from './enums/message-box-type.enum';

@Component({
  selector: 'pdp-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent extends DialogContentBase {
  MessageBoxType = MessageBoxType;

  @Input()
  type: MessageBoxType = MessageBoxType.Info;

  @Input()
  showIcon: boolean = false;

  @Input()
  message: string;

  private confirmationSubject = new Subject<boolean>();

  constructor(dialog: DialogRef) {
    super(dialog);
  }

  public setConfirmationResult(value: boolean) {
    this.confirmationSubject.next(value);
  }

  public getConfirmationObservable(): Observable<boolean> {
    return this.confirmationSubject
      .asObservable();
  }
}
