import { User } from "../users/user.model";
import { RequestStatus } from "../../enums/request-status.enum";
import { Sample } from "./sample.model";
import { TestRequirement } from "../test-requirements/test-requirement.model";
import { Company } from "../companies/company.model";
import { Attachment } from "./attachment.model";
import { Stamp } from "../../enums/stamp.enum";
import { RequestContract } from "./request-contract.model";
import { SpecifierObservationType } from "../../enums/specifier-observation-type.enum";

export class Request {
  id: number;
  linkedRequestId?: number;
  requestContracts: RequestContract[];
  submitDate?: Date;
  workOrderNumber: string;
  sspNumber: string;
  ssrUser?: User;
  ssrSignedDate?: Date;
  plannedTestingDate?: Date;
  willSpecifierObserve?: SpecifierObservationType;
  correlationRequired?: boolean;
  labManagerUser: User;
  labManagerSignedDate?: Date;
  specifierAttention: string;
  specifierComment: string;
  specifierUser?: User;
  assignedSpecifierUser?: User;
  assignedSpecifierUserExternalId: string;
  specifierSignedDate?: Date;
  status: RequestStatus;
  lastStatusChangedDate?: Date;
  lotNumber: string;
  testConductedBy: string;
  samples: Sample[];
  testRequirements: TestRequirement[];
  manufacturer: Company;
  laboratory: Company;
  reasonForSubmittal: string;
  materialToBeTested: string;
  quantitySubmitted: string;
  quantityRepresented: number;
  governmentSpecificationNumber: string;
  purchaseOrderNumber: string;
  purchasedFromOrSource: string;
  shipmentMethod: string;
  attachments: Attachment[];
  stamps: Stamp;
  specialInstructions: string;
  additionalRecipients: string;
  nationalStockNumber: string;
  partNumber: string;
  dateOfManufacture?: Date;
  engineeringAuthority: string;
  mqcssQslReview: boolean;
  shade: string;
  rollNumber: string;
  sampleReceivedDate?: Date;
}
