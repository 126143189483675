import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[isInteger][formControlName],[isInteger][formControl],[isInteger][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: IsIntegerValidationDirective, multi: true }]
})
export class IsIntegerValidationDirective implements Validator {
  validate(c: UntypedFormControl): { [key: string]: any } {
    const v = c.value;
    const regex = /^(?:[-]\d+|\d*)$/;
    return (regex.test(v)) ? null : { "isInteger": true };
  }
}




