import { Injectable } from '@angular/core';
import { AuthenticatedResult, OidcSecurityService,  UserDataResult } from 'angular-auth-oidc-client';
import { User } from '../shared/models/users/user.model';
import { SessionService } from '../shared/services/session.service';
import { UsersService } from '../shared/services/users.service';
import { Observable, Subscription } from 'rxjs';
import { LoginResponse } from 'angular-auth-oidc-client/lib/login/login-response';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private configId = 'pdp';

  private currentUserSubscription: Subscription;

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private usersService: UsersService,
    private sessionService: SessionService
  ) {
    this.oidcSecurityService.checkSessionChanged$.pipe(
      switchMap((sessionChanged: boolean) => {
        if (sessionChanged) {
          this.authenticate();
        }
        return this.oidcSecurityService.isAuthenticated$.pipe(
          switchMap(() => {
            return this.oidcSecurityService.userData$;
          }));
      })).subscribe((userDataResult: UserDataResult) => {
        if (userDataResult.userData && this.sessionService.currentUserId != userDataResult.userData.sub) {
          this.sessionService.setCurrentUserId(userDataResult.userData.sub);
        }
      });

    if (!this.currentUserSubscription || this.currentUserSubscription && this.currentUserSubscription.closed) {
      this.currentUserSubscription = this.sessionService.currentUserId$.subscribe((userId: string) => {
        if (userId && userId !== '') {
          this.usersService.getUser(userId).subscribe((user: User) => {
            this.usersService.createIfNew(user.externalId, user.companyId).subscribe();
            this.sessionService.setCurrentUser(user);
          });
        }
      });
    }
    
  }

  public get isAuthenticated$(): Observable<AuthenticatedResult> {
    return this.oidcSecurityService.isAuthenticated$;
  }

  public get userData$(): Observable<UserDataResult> {
    return this.oidcSecurityService.userData$;
  }

  public authenticate() {
    this.oidcSecurityService.authorize(this.configId);
  }

  public logoff() {
    this.oidcSecurityService.logoff(this.configId);
  }

  public refreshSession(): Observable<LoginResponse> {
    return this.oidcSecurityService.forceRefreshSession();
  }

  public getAccessToken(): Observable<string> {
    return this.oidcSecurityService.getAccessToken(this.configId);
  }
}
