<fieldset [disabled]="readonly">
  <div class="row">
    <!-- Left column-->
    <div class="col-sm-6">
      <div class="form-group row" [ngClass]="{ 'hide-control': !showSampleReceivedDate }">
        <label class="col-sm-4 col-form-label" for="sampleReceivedDate" [ngClass]="{ 'required-for-upgrade': isSampleReceivedDateRequired }">{{'dateSampleReceived'|i18n}}</label>
        <div class="col-sm-8">
          <kendo-datepicker #sampleReceivedDate
                            id="sampleReceivedDate"
                            name="sampleReceivedDate"
                            [ngModel]="sampleReceivedDateValue"
                            [format]="dateFormatString"
                            [min]="getMinimumSampleReceivedDateValue()"
                            [disabled]="readonly"
                            [required]="isSampleReceivedDateRequired"
                            class="w-100">
          </kendo-datepicker>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label" for="lotNumber" [ngClass]="{'required-for-upgrade': isLotNumberRequired }">{{'lotNumber'|i18n}}</label>
        <div class="col-sm-8">
          <input type="text"
                 class="form-control"
                 id="lotNumber"
                 name="lotNumber"
                 [(ngModel)]="request.lotNumber"
                 [disabled]="isLotNumberReadonly"
                 [required]="isLotNumberRequired" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label" for="quantitySubmitted" [ngClass]="{'required-for-upgrade': indicatorsRequired }">{{'quantitySubmitted'|i18n}}</label>
        <div class="col-sm-8">
          <input type="text"
                 class="form-control"
                 id="quantitySubmitted"
                 name="quantitySubmitted"
                 [(ngModel)]="request.quantitySubmitted"
                 [required]="controlsRequired" />
        </div>
      </div>
    </div>
    <!-- Right column-->
    <div class="col-sm-6">
      <div class="form-group row">
        <label class="col-sm-4 col-form-label" for="shade">{{'shade'|i18n}}</label>
        <div class="col-sm-8">
          <input type="text"
                 class="form-control"
                 id="shade"
                 name="shade"
                 [(ngModel)]="request.shade" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-4 col-form-label" for="rollNumber">{{'rollNumber'|i18n}}</label>
        <div class="col-sm-8">
          <input type="text"
                 class="form-control"
                 id="rollNumber"
                 name="rollNumber"
                 [(ngModel)]="request.rollNumber" />
        </div>
      </div>
    </div>
  </div>
  <hr class="mt-2" />
  <div class="row mb-3">
    <div class="col">
      <button kendoButton themeColor="secondary" *ngIf="!readonly" (click)="openAddSamplesDialog()">
        <i class="fas fa-plus me-2"></i>{{'addSamples'|i18n}}...
      </button>
    </div>
  </div>
  <div class="row mb-3">
    <label class="col">
      {{'supplierRollNumbers'|i18n}}
    </label>    
  </div>
  <div class="row">
    <input type="hidden" [(ngModel)]="samplesCheckValue" name="samplesCheck" [required]="controlsRequired" />
    <div class="col ignore-ng-invalid" [ngModelGroup]="FormGroupNames.Samples">
      <ng-container *ngIf="request.samples && request.samples.length > 0; else no_samples">
        <div class="sample" *ngFor="let sample of request.samples; let i = index; trackBy: trackBy">
          <div class="sample-contents">
            <label class="col-form-label pe-3" [ngClass]="{'required-for-save': !readonly }" for="{{'sample_'+i}}">{{i+1}}.</label>
            <input type="text" class="form-control ms-1" required id="sample_{{i}}" name="sample_{{i}}" [(ngModel)]="sample.name" />
            <span class="remove-button k-icon k-i-x"
                  *ngIf="!readonly"
                  (click)="deleteSample(sample)">
            </span>
          </div>
          <span class="duplicates-warning">
            <ng-container *ngTemplateOutlet="duplicatesWarningTemplate; context: { indices: getIndicesOfRollNumberDuplicates(sample.name, i) }"></ng-container>
            <ng-template #duplicatesWarningTemplate let-indices="indices">
            <i *ngIf="indices.length > 0" class="fas fa-exclamation-triangle" [title]="getDuplicateIndicesMessage(indices)"></i>
            </ng-template>
          </span>
        </div>
      </ng-container>
      <ng-template #no_samples>
        <div class="p-2 mb-2 alert alert-info text-center" [ngClass]="{ 'ng-invalid ng-touched': isSamplesInvalid() }">
          <span class="pe-3" [ngClass]="{ 'required-for-upgrade': indicatorsRequired }">{{ isSamplesInvalid() ? noSamplesErrorMessage : 'noSamplesMessage' | i18n }}</span>
        </div>
      </ng-template>
    </div>
  </div>
</fieldset>
