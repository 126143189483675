import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidationService {
  ValidateMinMax(minimum: string, maximum: string) {
    return (formGroup: UntypedFormGroup) => {
      const minimumControl = formGroup.controls[minimum];
      const maximumControl = formGroup.controls[maximum];

      if (minimumControl && maximumControl && minimumControl.value >= maximumControl.value) {
        maximumControl.setErrors({ compareError: true });
      } else if (minimumControl && maximumControl && minimumControl.value < maximumControl.value) {
        maximumControl.setErrors(null);
      } else if (maximumControl && maximumControl.value == null) {
        maximumControl.setErrors({ required: true});
      } else {
        return null;
      }
    }
  }
}  
