import { Component } from '@angular/core';
import { I18nService } from "../../shared/services/i18n.service";
import { environment } from "../../../environments/environment";

@Component({
    selector: 'pdp-no-subscriptions',
    templateUrl: './no-subscriptions.component.html',
    styleUrls: ['./no-subscriptions.component.css']
})
/** no-subscriptions component*/
export class NoSubscriptionsComponent {
  public readonly portalUrl: string = environment.portalUrl;
    /** no-subscriptions ctor */
  constructor(private i18nService: I18nService) {

  }
   
}
