import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[nGridFilterCellTemplate]'
})
export class NGridFilterCellTemplateDirective {  

  @Input() forField: string;

  constructor(public templateRef: TemplateRef<any>) {
  }
}
