import { Company } from "../companies/company.model";

/**
 * View model of an application user.
 */
export class User {
  id: number;
  externalId: string;
  companyId: string;
  company: Company;
  roles: string[];
  email: string;
  firstName: string;
  lastName: string;
  companyName: string;  
  isCompanyAdmin: boolean;
  fullName: string;

  constructor(
    externalId: string,
    companyId: string,
    roles: string[],
    email: string,
    firstName: string,
    lastName: string,
    companyName: string,
    isCompanyAdmin: boolean,
    fullName: string  ) {
    this.externalId = externalId;
    this.companyId = companyId;
    this.roles = roles;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.companyName = companyName;
    this.isCompanyAdmin = isCompanyAdmin;
    this.fullName = fullName;
  }
}
