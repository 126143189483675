import { Directive, TemplateRef, Input } from '@angular/core';

@Directive()
export class NGridCommandCellTemplateDirectiveBase {

  @Input() forName: string;

  constructor(public templateRef: TemplateRef<any>) {
  }
}

@Directive({
  selector: '[nGridRemoveCommandCellTemplate]'
})
export class NGridRemoveCommandCellTemplateDirective extends NGridCommandCellTemplateDirectiveBase {
  constructor(public templateRef: TemplateRef<any>) {
    super(templateRef);
  }
}
