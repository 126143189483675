import { AfterContentInit, Directive, EventEmitter, Output } from '@angular/core';

//
// Directive to trigger event when view or part of a view is rendered. E.g.: using *ngIf to show/hide something.
//
// Example:
//
// <div *ngIf="expression" (show)='onShow()'></div>
//
// when "expression" is true and <div> element is rendered onShow() will be called
//

@Directive({
  selector: '[show]'
})
export class ShowDirective implements AfterContentInit {

  @Output('show')
  public after: EventEmitter<void> = new EventEmitter<void>();

  public ngAfterContentInit(): void {
    // timeout helps prevent unexpected change errors
    setTimeout(() => this.after.next());
  }

}
