import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { TestMethod } from '../../shared/models/test-methods/test-method.model';
import { TestMethodsService } from '../../shared/services/test-methods.service';
import { TestType } from '../../shared/enums/test-type.enum';
import { I18nService } from '../../shared/services/i18n.service';
import { TestMethodMember } from '../../shared/models/test-methods/test-method-member.model';
import { TestAttribute } from '../../shared/models/test-methods/test-attribute.model';
import { OperatorType } from '../../shared/enums/operator-type.enum';
import { NgControl, NgForm } from '@angular/forms';
import '../../shared/extensions/string.extension';
import { Globals } from '../../shared/globals';
import { TestMethodVariant } from '../../shared/models/test-methods/test-method-variant.model';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { EvaluationMode } from '../../shared/enums/evaluation-mode.enum';
import { DialogComponentCanDeactivate } from '../../shared/services/dialog-component-can-deactivate';
import { PendingChangesService } from '../../shared/services/pending-changes.service';
import { LocalizationService } from '../../shared/services/localization.service';
import { ListItem } from '../../shared/models/list-item.model';
import { SortingService } from '../../shared/services/sorting.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { TestMethodVariantStatusChangeEventArgs } from '../../shared/models/test-methods/test-method-variant-status-change-event-args.model';
import { NumberEvaluatingFn } from '../../shared/interfaces/number-evaluating-function.interface';
import { TestMethodType } from '../../shared/enums/test-method-type.enum';
import { MessageBoxService } from '../../shared/components/message-box/message-box.service';
import { MessageBoxType } from '../../shared/components/message-box/enums/message-box-type.enum';
import { AggregateFunction } from '../../shared/enums/aggregate-function.enum';

@Component({
  selector: 'pdp-test-method-details',
  templateUrl: './test-method-details.component.html',
  styleUrls: ['./test-method-details.component.css']
})
export class TestMethodDetailsComponent extends DialogComponentCanDeactivate implements OnInit {
  @Input() public testMethod: TestMethod;
  @Input() public actionName: string;
  @Input() public title: string;
  public selectedTab = 0;
  public dateFormatString: string;

  private readonly defaultNumberOfAllowedFailures = 4;

  @ViewChild('testMethodForm') testMethodForm: NgForm;
  @ViewChild('variantsTabStrip') variantsTabStrip: TabStripComponent;

  public operatorTypeEnum = OperatorType;
  public isSubmitted = false;
  public isGrayScale = false;

  public currentTestType: TestType;
  public testTypeEnum = TestType;
  public testTypes: ListItem<string>[] = [];
  public variantWithError: NumberEvaluatingFn[] = [];
  public EvaluationMode = EvaluationMode;
  public isArchived = false;
  public variantsSubject: BehaviorSubject<TestMethodVariant[]>;
  public variants$: Observable<TestMethodVariant[]>;

  constructor(
    public dialog: DialogRef,
    public dialogService: DialogService,
    private messageBoxService: MessageBoxService,
    private testMethodsService: TestMethodsService,
    private localizationService: LocalizationService,
    public i18nService: I18nService,
    private sortingService: SortingService,
    private pendingChangesService: PendingChangesService) {
    super(dialog, i18nService);
  }

  getTestTypes() {
    for (const testType in TestType) {
      if (typeof TestType[testType] === 'string') {
        this.testTypes.push({ value: <string>TestType[testType], text: this.i18nService.transform(testType.toLowerFirstLetter()) });
      }
    }
  }

  ngOnInit(): void {
    this.getTestTypes();

    this.dateFormatString = this.localizationService.getDateFormatString();

    this.isGrayScale = this.isUnitOfMeasureGrayScale(this.testMethod.unitOfMeasure);

    this.variantsSubject = new BehaviorSubject(this.testMethod.testMethodVariants);
    this.variants$ = this.variantsSubject.asObservable();

    if (this.testMethod.archiveDate) {
      this.isArchived = true;
      this.testMethod.archiveDate = new Date(this.testMethod.archiveDate);
    }


    if (!this.testMethod.testType) {
      this.testMethod.testType = TestType.InclusiveRange;
    }
    if (!this.testMethod.evaluationMode) {
      this.testMethod.evaluationMode = EvaluationMode.Regular;
    }

    this.currentTestType = this.testMethod.testType;

    if (this.testMethod.testMethodVariants) {
      this.testMethod.testMethodVariants.forEach(specification => {
        specification.evaluationMode = this.testMethod.evaluationMode;
        specification.testType = this.currentTestType;
      });
    }
  }

  public getTitle() {
    return this.title;
  }

  isShadeEvaluationSet() {
    return (this.testMethod && this.testMethod.testMethodType === TestMethodType.ShadeEvaluation);
  }

  onTestMethodTypeChange(testMethodType: TestMethodType) {
    this.testMethod.testMethodType = testMethodType;
  }

  public onEvaluationModeChange(evaluationMode: EvaluationMode) {
    this.testMethod.evaluationMode = evaluationMode;

    if (this.testMethod.testMethodVariants && this.testMethod.testMethodVariants.length > 0) {
      this.testMethod.testMethodVariants.forEach(variant => {
        variant.evaluationMode = evaluationMode;

        if (evaluationMode === EvaluationMode.SpectralReflectance && variant && (variant.numberOfAllowedFailures === null || variant.numberOfAllowedFailures === undefined)) {
          variant.numberOfAllowedFailures = this.defaultNumberOfAllowedFailures;
          variant.aggregateConditionType = null;
          variant.percentageOfRequiredPasses = null;
        }
      });
    }
  }

  public onSave(): void {
    this.isSubmitted = true;

    if (this.testMethodForm.form.status === 'VALID') {
      if (this.testMethod.id != null) {
        this.testMethodsService.updateTestMethod(this.testMethod).subscribe(result => {
          this.dialog.close('saved');
        });
      } else {
        this.testMethodsService.addTestMethod(this.testMethod).subscribe(result => {
          this.dialog.close('saved');
        });
      }
    } else {
      const firstInvalidTabIndex = this.getFirstInvalidTabIndex();

      if (firstInvalidTabIndex > -1 && this.variantsTabStrip) {
        this.variantsTabStrip.selectTab(firstInvalidTabIndex);
      }
    }
  }

  private getFirstInvalidTabIndex() {
    let minimumIndex = -1;
    this.variantWithError.forEach(getVariantIndex => {
      const currentIndex = getVariantIndex();
      if (minimumIndex < 0 || currentIndex < minimumIndex) {
        minimumIndex = currentIndex;
      }
    });

    return minimumIndex;
  }

  public hasTabError(variantIndex): boolean {
    return this.variantWithError.some(getVariantIndex => getVariantIndex() === variantIndex);
  }

  public onVariantStatusChange(eventArgs: TestMethodVariantStatusChangeEventArgs) {
    if (eventArgs.status === 'INVALID') {
      if (!this.variantWithError.includes(eventArgs.getVariantIndex)) {
        this.variantWithError.push(eventArgs.getVariantIndex);
      }
    } else {
      if (this.variantWithError.includes(eventArgs.getVariantIndex)) {
        var index = this.variantWithError.indexOf(eventArgs.getVariantIndex);
        if (index > -1) {
          this.variantWithError.splice(index, 1);
        }
      }
    }
  }

  public onChangeUnitOfMeasure(unitOfMeasure): void {
    const wasGrayScale = this.isGrayScale;
    this.isGrayScale = this.isUnitOfMeasureGrayScale(unitOfMeasure);

    if (this.isGrayScale) {
      this.testMethod.unitOfMeasure = Globals.GrayScaleUnitOfMeasure;
    } else {
      this.testMethod.unitOfMeasure = unitOfMeasure;
    }

    if (this.testMethod.testMethodVariants && this.isGrayScale !== wasGrayScale) {
      this.testMethod.testMethodVariants.forEach(variant => {
        variant.precisionLevel = this.isGrayScale ? 1 : Globals.DecimalPlaces;
      });
    }
  }

  public onChangeTestType(): void {
    this.messageBoxService.open({
      title: this.i18nService.transform('message_box_title_confirm'),
      message: this.i18nService.transform('test_method_delete_confirm_message'),
	  type: MessageBoxType.Warning,
      showIcon: true,
      confirmButtonText: this.i18nService.transform('yes'),
      denyButtonText: this.i18nService.transform('no'),
      onConfirm: () => {
        this.testMethod.testType = this.currentTestType;

        if (this.testMethod.testMethodVariants) {
          this.testMethod.testMethodVariants.forEach(variant => {
            variant.testType = this.currentTestType;
            
          });
        }

        this.resetTestMethod();
      },
      onDeny: () => {
        this.currentTestType = this.testMethod.testType;
      }
    });    
  }

  public addTestMethodVariant(clone): void {
    this.isSubmitted = false;
    const currentIndex = this.testMethod.testMethodVariants.length + 1;
    const description = `${this.i18nService.transform('variant')} #${currentIndex}`;
    const testMethodVariant = clone != null ? clone : this.initializeTestMethodVariant(description, this.testMethod.evaluationMode);
    this.testMethod.testMethodVariants.push(testMethodVariant);
    this.testMethodForm.form.markAsDirty();
    this.selectedTab = this.testMethod.testMethodVariants.length - 1;
    this.variantsSubject.next(this.testMethod.testMethodVariants);    
  }

  public removeTestMethodVariant(testMethodVariant): void {
    let index: number;

    if (this.testMethod.testMethodVariants && this.testMethod.testMethodVariants.length > 1) {
      index = this.testMethod.testMethodVariants.indexOf(testMethodVariant);
      if (index >= 0) {
        if (this.hasTabError(index)) {
          const errorIndex = this.variantWithError.findIndex(getVariantIndex => getVariantIndex() === index);
          if (errorIndex > -1) {
            this.variantWithError.splice(errorIndex, 1);
          }
        }
        this.testMethod.testMethodVariants.splice(index, 1);
        this.testMethodForm.form.markAsDirty();
        if (this.selectedTab > 0 && index < this.selectedTab) {
          this.selectedTab--;
        } else if (this.selectedTab > this.testMethod.testMethodVariants.length - 1) {
          this.selectedTab = this.testMethod.testMethodVariants.length - 1;
        }

        this.variantsSubject.next(this.testMethod.testMethodVariants);
      }

      this.sortingService.calculateSortIndex(this.testMethod.testMethodVariants, index);
    }
  }

  public onTabSelect(e) {
    if (e.index === this.testMethod.testMethodVariants.length) {
      e.preventDefault();
    } else {
      this.selectedTab = e.index;
    }
  }

  isUnitOfMeasureGrayScale(unitOfMeasure: string): boolean {
    if (unitOfMeasure !== undefined && unitOfMeasure !== null) {
      const regexPattern = /gr[a|e]y[ ]*scale/gi;
      const matchArray = unitOfMeasure.match(regexPattern);
      return matchArray !== null && matchArray.length > 0;
    }

    return false;
  }

  private resetTestMethod(): void {
    this.isSubmitted = false;

    this.testMethod.testMethodVariants = [];
    const testMethodVariant = this.initializeTestMethodVariant(this.i18nService.transform('default'), this.testMethod.evaluationMode);
    this.testMethod.testMethodVariants = [testMethodVariant];
    this.selectedTab = 0;
    this.variantsSubject.next(this.testMethod.testMethodVariants);
  }

  private initializeTestMethodVariant(description: string, evaluationMode: EvaluationMode): TestMethodVariant {
    const testMethodVariant = new TestMethodVariant();

    testMethodVariant.description = description;
    testMethodVariant.determination = 1;
    testMethodVariant.evaluationMode = evaluationMode;
    testMethodVariant.testType = this.currentTestType;
    testMethodVariant.precisionLevel = this.isGrayScale ? 1 : Globals.DecimalPlaces;
    testMethodVariant.sortIndex = this.testMethod.testMethodVariants.length;
    testMethodVariant.numberOfAllowedFailures = (evaluationMode === EvaluationMode.SpectralReflectance) ? this.defaultNumberOfAllowedFailures : null;
    testMethodVariant.testMethodMembers = [new TestMethodMember()];
    testMethodVariant.testMethodMembers[0].testAttributes = [new TestAttribute()];
    testMethodVariant.testMethodMembers[0].testAttributes[0].index = 0;

    if (this.currentTestType === TestType.InclusiveRange || this.currentTestType === TestType.ExclusiveRange) {
      testMethodVariant.testMethodMembers[0].testAttributes.push(new TestAttribute());
      testMethodVariant.testMethodMembers[0].testAttributes[1].index = 1;
    }

    return testMethodVariant;
  }

  canDeactivate(): boolean {
    this.deactivationWarningMessage = this.i18nService.transform('generic_unsavedChanges_message');

    return !(this.testMethodForm.dirty);
  }

  onClose(ev): void {
    if (this.testMethodForm.dirty) {
      ev.preventDefault();
      const message = this.i18nService.transform('generic_unsavedChanges_message');
      this.pendingChangesService.confirmRejectionOfPendingChanges(message).subscribe(shouldBeClosed => {
        if (shouldBeClosed) {
          this.dialog.close();
        }
      });
    }
  }

  onChangeArchive(value) {
    this.isArchived = value;
    this.testMethod.archiveDate = value ? new Date() : null;
  }

  public getIsArchived() {
    return this.testMethod.archiveDate && this.testMethod.archiveDate <= new Date();
  }

  public cloneTestMethod() {
    this.dialog.close('clone');
  }

  public onCloneVariant(clone) {
    this.addTestMethodVariant(clone);
  }
}
