import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Contract } from '../models/contracts/contract.model';
import { environment } from '../../../environments/environment';
import { NGridState } from '../components/n-grid/models/n-grid-state.model';
import { NGridService } from '../components/n-grid/n-grid.service';
import { ContractListItem } from '../models/contracts/contract-list-item.model';
import { RequestContract } from '../models/requests/request-contract.model';
import { NGridPagedData } from '../components/n-grid/models/n-grid-paged-data.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ContractsService {

  private contractsUrl = environment.apiBaseUrl + 'api/v1/Contract';

  constructor(
    private http: HttpClient,
    private nGridService: NGridService) { }

  /** GET contracts from the server */
  getContractsByGridState(state: NGridState): Observable<NGridPagedData<Contract>> {
    let httpParams = new HttpParams();
    httpParams = this.nGridService.stateToHttpParams(state, httpParams);
    return this.http.get<NGridPagedData<Contract>>(this.contractsUrl, { observe: 'body', params: httpParams });
  }

  getContractListItems(state: NGridState, primeId: number = null): Observable<ContractListItem[]> {
    let httpParams = new HttpParams();
    httpParams = this.nGridService.stateToHttpParams(state, httpParams);
    if (primeId) {
      httpParams = httpParams.set('primeId', `${primeId}`);
    }
    const url = `${this.contractsUrl}/ContractListItems`;
    return this.http.get<ContractListItem[]>(url, { observe: 'body', params: httpParams });
  }

  /** GET contract by id. Will 404 if id not found */
  getContract(id: number): Observable<Contract> {
    const url = `${this.contractsUrl}/${id}`;
    return this.http.get<Contract>(url);
  }

  getContracts(contractIds: number[]): Observable<Contract[]> {
    const queryParams = `contractIds=${contractIds.join('&contractIds=')}`;
    const url = `${this.contractsUrl}/List?${queryParams}`;
    return this.http.get<Contract[]>(url);
  }

  getRequestContracts(contractIds: number[]): Observable<RequestContract[]> {
    const queryParams = `contractIds=${contractIds.join('&contractIds=')}`;
    const url = `${this.contractsUrl}/RequestContracts?${queryParams}`;
    return this.http.get<RequestContract[]>(url);
  }

  /** GET id list of involved suppliers by contract id. */
  getInvolvedSupplierIds(id: number): Observable<number[]> {
    const url = `${this.contractsUrl}/${id}/InvolvedSupplierIds`;
    return this.http.get<number[]>(url);
  }

  /** POST: create a new contract */
  addContract(contract: Contract): Observable<Contract> {
    return this.http.post<Contract>(this.contractsUrl, contract, httpOptions);
  }

  /** PUT: updates the contract on the server */
  updateContract(contract: Contract): Observable<Contract> {
    return this.http.put<Contract>(this.contractsUrl, contract, httpOptions);
  }

  /** DELETE: deletes the contract from the server */
  deleteContract(contract: Contract | string): Observable<any> {
    const id = typeof contract === 'string' ? contract : contract.id;
    const url = `${this.contractsUrl}/${id}`;
    return this.http.delete(url, httpOptions);
  }
}
