import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { XsbRequirementsDocument } from '../models/swiss/xsb-requirements-document.model';

@Injectable({
  providedIn: 'root'
})
export class SwissDocumentService {

  private swissUrl = environment.apiBaseUrl + 'api/v1/Swiss';

  constructor(private http: HttpClient) { }

  getDocument(uri: string): Observable<string> {
    const url = `${this.swissUrl}/Document/${encodeURIComponent(uri)}`;
    return this.http.get(url, { responseType: 'text' });
  }

  getDocumentById(documentLabel: string, revision: string): Observable<string> {
    let url = `${this.swissUrl}/DocumentById/${documentLabel}`;
    if (revision) {
      url = `${url}/${revision}`;
    }
    return this.http.get(url, { responseType: 'text' });
  }

  getRequirements(documentLabel: string, revision: string, purgeUnknown: boolean = false): Observable<XsbRequirementsDocument> {
    let url = `${this.swissUrl}/Requirements/${documentLabel}`;
    if (revision) {
      url = `${url}/${revision}`;
    }
    let httpParams = new HttpParams();

    if (purgeUnknown) {
      httpParams = httpParams.set('purgeUnknown', 'true');
    }
    return this.http.get<XsbRequirementsDocument>(url, { params: httpParams });
  }
}
