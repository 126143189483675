import { Injectable } from '@angular/core';
import { FeatureFlagDataService } from './feature-flag-data.service';

@Injectable()
export class FeatureFlagService {
  private _featureFlags: Array<string> = []; // A list of all features turned OFF
  private _initialized = false;

  constructor(private featureFlagDataService: FeatureFlagDataService) { }

  isFeatureOff(featureName: string): boolean {
    if (!featureName) {
      return false;
    }

    return this._featureFlags && this._featureFlags.includes(featureName);
  }

  get initialized() {
    return this._initialized;
  }

  initialize() {
    this._featureFlags = [];
    this.featureFlagDataService.getFeatureFlags().subscribe();

    return new Promise<void>((resolve, reject) => {
      this.featureFlagDataService.getFeatureFlags().toPromise()
        .then(featureFlags => {
          this._featureFlags = featureFlags;
          this._initialized = true;
          resolve();
        })
        .catch((e) => {
          reject();
        });
    });
  }
}
