export class TestResult {
  id: number;
  testRequirementId: number;
  testMethodMemberId: number;
  determinationIndex: number;
  sampleId: number;
  originalValue?: number;
  value?: number;
  hasPassed?: boolean;
  isValueChanged: boolean = false;
  isTestResultModified: boolean = false;
}
