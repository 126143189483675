import { TemplateRef } from "@angular/core";
import { INGridColumnOptionsBase } from "../interfaces/i-n-grid.column-options.interface";
import { INGridPropertyFn } from "../interfaces/i-n-grid-property-function.interface";

export class NGridCommandColumnOptions implements INGridColumnOptionsBase {
  name: string;
  title?: string;
  width?: number;
  class?: string | string[] | Set<string> | { [key: string]: any };
  headerClass?: string | string[] | Set<string> | { [key: string]: any };
  private _hidden?: boolean | INGridPropertyFn<boolean>;
  removeCommandCellTemplate?: TemplateRef<any>;

  get hidden(): boolean {
    return typeof this._hidden === 'function' ? this._hidden() : this._hidden;
  }

  constructor(columnOptions: INGridCommandColumnOptions) {
    this.name = columnOptions.name;    
    this.title = 'title' in columnOptions ? columnOptions.title : columnOptions.name;
    if ('width' in columnOptions) {
      this.width = columnOptions.width;
    }
    if ('headerClass' in columnOptions) {
      this.headerClass = columnOptions.headerClass;
    }
    if ('class' in columnOptions) {
      this.class = columnOptions.class;
    }
    this._hidden = 'hidden' in columnOptions ? columnOptions.hidden : false;
    if ('removeCommandCellTemplate' in columnOptions) {
      this.removeCommandCellTemplate = columnOptions.removeCommandCellTemplate;
    }
  }
}

export interface INGridCommandColumnOptions extends INGridColumnOptionsBase{
  name: string;
  title ?: string;
  width ?: number;
  class?: string | string[] | Set<string> | { [key: string]: any };
  headerClass?: string | string[] | Set<string> | { [key: string]: any };
  hidden ?: boolean;
  removeCommandCellTemplate ?: TemplateRef<any>;
}
