<div *ngIf="!readonly || labReports.length > 0" class="row mb-3">
  <div class="col">
    <label class="me-3">{{'labReports'|i18n}}</label>
    <kendo-upload *ngIf="!readonly"
                  class="upload-lab-report"
                  [saveUrl]="uploadSaveUrl"
                  [withCredentials]="false"
                  [showFileList]="false"
                  [multiple]="false"
                  (complete)="onUploadComplete()">
    </kendo-upload>
    <div *ngIf="labReports.length > 0" class="px-2 mt-2 info-card-light">
      <kendo-chip *ngFor="let labReport of labReports"
                  [look]="'filled'"
                  [label]="labReport.name"
                  [iconClass]="'file-download'"
                  [removable]="true"
                  (remove)="deleteLabReport(labReport)"
                  class="me-1"
                  (click)="onClickLabReport(labReport)">
      </kendo-chip>
    </div>
  </div>
</div>
<div class="row mb-1 fill-out-direction-panel">
  <div class="col-sm-6">
    <ng-container *ngIf="!readonly">
      <label class="me-2">{{'fillOutDirection'|i18n}}</label>
      <kendo-buttongroup selection="single">
        <button kendoButton
                [toggleable]="true"
                class="fill-out-direction-button"
                [selected]="fillOutDirection===FillOutDirection.Across"
                (click)="setFillOutDirection(FillOutDirection.Across)">
          <span class="me-2">{{'across'|i18n}}</span><i class="fas fa-angle-double-right"></i>
        </button>
        <button kendoButton
                [toggleable]="true"
                class="fill-out-direction-button"
                [selected]="fillOutDirection===FillOutDirection.Down"
                (click)="setFillOutDirection(FillOutDirection.Down)">
          <span class="me-2">{{'down'|i18n}}</span><i class="fas fa-angle-double-down"></i>
        </button>
      </kendo-buttongroup>
    </ng-container>
  </div>
  <div class="col-sm-6 text-end">
    <button kendoButton
            themeColor="secondary"
            (click)="expandAll()"
            class="me-1">
      <i class="far fa-caret-square-down me-2"></i>{{'expandAll'|i18n}}
    </button>
    <button kendoButton
            themeColor="secondary"
            (click)="collapseAll()">
      <i class="far fa-caret-square-up me-2"></i>{{'collapseAll'|i18n}}
    </button>
  </div>
</div>
<div class="alert alert-info">
  {{'evaluationNote'|i18n}}
</div>
<div [ngModelGroup]="FormGroupNames.TestResults" class="ignore-ng-invalid">
  <kendo-panelbar class="my-3 results-panelbar"
                  [keepItemContent]="true"
                  (stateChange)="stateChange($event)">
    <kendo-panelbar-item #testResultsPanelBarItem
                         title=""
                         *ngFor="let testRequirement of request.testRequirements; let i = index; trackBy: trackBy"
                         [id]="testRequirement.id"
                         [expanded]="isExpanded(testRequirement.id)"
                         [ngClass]="{'invalid': isTestResultsInvalid(i)}">
      <ng-template kendoPanelBarItemTitle>
        <div class="d-flex flex-row w-100">
          <div class="test-description header-cell">
            <ng-container *ngIf="!isUnfinishedFillout(testRequirement)">
              <i class="pe-2 fas fa-clipboard-check" title="{{'fillOutCompleted'|i18n}}"></i>
            </ng-container>
            <ng-template *ngTemplateOutlet="panelTitle; context: {tmv: testRequirement.testMethodVariant, hasClassification: testRequirement?.testMethodVariant?.specificationType || testRequirement?.testMethodVariant?.specificationClass || testRequirement?.testMethodVariant?.specificationSubclass, indicatorsRequired: indicatorsRequired}">
            </ng-template>
          </div>
          <div class="unit-of-measure header-cell border-left d-flex flex-row">
            <label class="col-sm-7 text-truncate"
                   title="{{'testResults_columnName_unitOfMeasure'|i18n}}">
              {{'testResults_columnName_unitOfMeasure'|i18n}}
            </label>
            <span class="col-sm-5 ps-0 text-center text-bold">{{testRequirement?.testMethodVariant?.testMethod?.unitOfMeasure}}</span>
          </div>
          <div class="determinations header-cell border-left d-flex flex-row">
            <label class="col-sm-8 text-truncate"
                   title="{{'testResults_columnName_determinationNumber'|i18n}}">
              {{'testResults_columnName_determinationNumber'|i18n}}
            </label>
            <span class="col-sm-4 text-center text-bold">{{testRequirement?.determination}}</span>
          </div>
          <div class="aggregate-function header-cell border-left d-flex flex-row">
            <label class="col-sm-7 text-truncate"
                   title="{{'testResults_columnName_aggregateFunction'|i18n}}">
              {{'testResults_columnName_aggregateFunction'|i18n}}
            </label>
            <span class="col-sm-5 text-center text-bold text-truncate"
                  title="{{testRequirement?.aggregateFunction && testRequirement.determination > 1 && testRequirement?.testMethodVariant?.testMethod?.evaluationMode === EvaluationMode.Regular ? resolveAggregateFunctionName(testRequirement?.aggregateFunction, testRequirement?.testMethodVariant?.testMethod?.testType, true) : '' }}">
              {{testRequirement?.aggregateFunction && testRequirement.determination > 1 && testRequirement?.testMethodVariant?.testMethod?.evaluationMode === EvaluationMode.Regular ? resolveAggregateFunctionName(testRequirement?.aggregateFunction, testRequirement?.testMethodVariant?.testMethod?.testType) : '' }}
            </span>
          </div>
          <div class="actions header-cell border-left text-end" *ngIf="!readonly && request.testRequirements.length > 1">
            <button kendoButton
                    class="m-0125"
                    [disabled]="i <= 0"
                    (click)="moveSortableItemUp($event, request.testRequirements, testRequirement)"
                    title="{{'testResults_moveTestUp' | i18n}}">
              <i class="fas fa-angle-double-up"></i>
            </button>
            <button kendoButton class="m-0125"
                    [disabled]="i >= request.testRequirements.length-1"
                    (click)="moveSortableItemDown($event, request.testRequirements, testRequirement)"
                    title="{{'testResults_moveTestDown' | i18n}}">
              <i class="fas fa-angle-double-down"></i>
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <div class="test-results-content px-3 py-1">
          <pdp-test-results [testRequirement]="testRequirement"
                            [reasonForSubmittal]="request.reasonForSubmittal"
                            [samples]="request.samples"
                            [fillOutDirection]="fillOutDirection"
                            [lastTabIndex]="calculateLastTabIndexFor(i)"
                            [testRequirementIndex]="i"
                            [readonly]="readonly"
                            [controlsRequired]="controlsRequired"></pdp-test-results>
        </div>
      </ng-template>
    </kendo-panelbar-item>

  </kendo-panelbar>
</div>

<ng-template #panelTitle let-tmv="tmv" let-hasClassification="hasClassification" let-indicatorsRequired="indicatorsRequired">
  <span [ngClass]="{'required-for-upgrade': indicatorsRequired }" class="pe-3">
    <span class="text-bold pe-2">{{tmv.testMethod.name}} - {{tmv.testMethod.title}} [{{tmv.description}}]</span>
    <ng-container *ngIf="hasClassification">
      <ng-template *ngTemplateOutlet="classificationSummary; context: {tmv: tmv}"></ng-template>
      </ng-container>
  </span>
</ng-template>

<ng-template #classificationSummary let-tmv="tmv">
  (<span *ngIf="tmv.specificationType">{{tmv.specificationType}}</span>
  <span *ngIf="tmv.specificationClass"> - {{tmv.specificationClass}}</span>
  <span *ngIf="tmv.specificationSubClass"> - {{tmv.specificationSubClass}}</span>)
</ng-template>
