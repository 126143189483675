import { GridNavigableSettings, ScrollMode, SelectableSettings } from "@progress/kendo-angular-grid";

export class NGridOptions {
  isFreeTextSearchEnabled?: boolean;
  isExcelExportEnabled?: boolean;
  isDataExportEnabled?: boolean;
  isFilterable?: boolean;
  isPageable?: boolean;
  scrollMode?: ScrollMode;
  isSortable?: boolean;
  selectable?: boolean | SelectableSettings;
  isNavigable?: GridNavigableSettings;
  showCheckboxColumn?: boolean;
  showSelectAll?: boolean;
  height?: number;
  rowHeight?: number;
  initialLoading?: boolean; 

  constructor(options: NGridOptions = {}) {
    this.isFreeTextSearchEnabled = 'isFreeTextSearchEnabled' in options ? options.isFreeTextSearchEnabled : false;
    this.isExcelExportEnabled = 'isExcelExportEnabled' in options ? options.isExcelExportEnabled : false;
    this.isDataExportEnabled = 'isDataExportEnabled' in options ? options.isDataExportEnabled : false;
    this.isFilterable = 'isFilterable' in options ? options.isFilterable : false;
    this.isPageable = 'isPageable' in options ? options.isPageable : false;
    this.scrollMode = 'scrollMode' in options ? options.scrollMode : 'scrollable';
    this.isSortable = 'isSortable' in options ? options.isSortable : false;
    this.selectable = 'selectable' in options ? options.selectable : true;
    this.isNavigable = 'isNavigable' in options ? options.isNavigable : false;
    this.showCheckboxColumn = 'showCheckboxColumn' in options ? options.showCheckboxColumn : false;
    this.showSelectAll = 'showSelectAll' in options ? options.showSelectAll : true;

    if ('height' in options) {
      this.height = options.height;
    }

    if ('rowHeight' in options) {
      this.rowHeight = options.rowHeight;
    }
    if ('initialLoading' in options) {
      this.initialLoading = options.initialLoading;
    }
  }
}
