import { Component, Input } from '@angular/core';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'n-grid-drop-down-filter',
  templateUrl: './n-grid-drop-down-filter.component.html'
})
export class NGridDropDownFilterComponent extends BaseFilterCellComponent {
  @Input() public filter: CompositeFilterDescriptor;
  @Input() public column: any;
  @Input() public data: any[];
  @Input() public textField: string;
  @Input() public valueField: string;
  @Input() public defaultTextField: string;
  public get defaultItem(): any {
    return {
      [this.textField]: this.defaultTextField,
      [this.valueField]: null
    };
  }

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public get hasFilter(): boolean {
    let filtersFound = this.filtersByField(this.column.field);

    return filtersFound.length > 0;
  }

  public clearFilter(): void {
    this.filterService.filter(
      this.removeFilter(this.column.field)
    );
  }

  public onChange(filter: any): void {
    this.applyFilter(
      filter === null || filter.value === null ? // if value of the default item
        this.removeFilter(this.column.field) : // remove the filter
        this.updateFilter({ // otherwise add/modify the filter for the field with the value
          field: this.column.field,
          operator: "eq",
          value: filter.value
        })
    ); // and update the root filter
  }
}
