<div class="card">
  <div *ngIf="testMethodVariant.isReadOnly || isArchived" class="alert alert-info fst-italic mb-1">
    {{'variantReadonlyNote'|i18n}}
  </div>
  <fieldset [disabled]="testMethodVariant.isReadOnly || isArchived" ngModelGroup="testMethodVariant_{{variantIndex}}" #variantModelGroup="ngModelGroup">
    <div class="mb-05">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label required" for="description_{{variantIndex}}_0_0">{{'description'|i18n}}</label>
        <div class="col-sm-9 ps-0">
          <input type="text" id="description_{{variantIndex}}_0_0" class="form-control" name="description_{{variantIndex}}_0_0" required
                 [(ngModel)]="testMethodVariant.description" placeholder="{{'descriptionPlaceholder'|i18n}}" #description="ngModel"
                 (blur)="onVariantChange()" />
          <div *ngIf="isSubmitted && !description.valid" class="alert alert-danger">
            {{'description'|i18n}} {{'is_required'|i18n}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="type_{{variantIndex}}_0_0">{{'type'|i18n}}</label>
        <div class="col-sm-9 ps-0">
          <input type="text" id="type_{{variantIndex}}_0_0" class="form-control" name="type_{{variantIndex}}_0_0"
                 [(ngModel)]="testMethodVariant.specificationType" placeholder="{{'type'|i18n}}" #type="ngModel"
                 (ngModelChange)="onChangeSpecType($event)" (blur)="onVariantChange()" />
          <div *ngIf="isSubmitted && !type.valid" class="alert alert-danger">
            {{'type'|i18n}} {{'is_required'|i18n}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="specClass_{{variantIndex}}_0_0">{{'class'|i18n}}</label>
        <div class="col-sm-9 ps-0">
          <input type="text" id="specClass_{{variantIndex}}_0_0" class="form-control" name="specClass_{{variantIndex}}_0_0"
                 [(ngModel)]="testMethodVariant.specificationClass" placeholder="{{'class'|i18n}}"
                 (ngModelChange)="onChangeSpecClass($event)" (blur)="onVariantChange()"
                 #specClass="ngModel" />
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="specSubClass_{{variantIndex}}_0_0">{{'sub_class'|i18n}}</label>
        <div class="col-sm-9 ps-0">
          <input type="text" id="specSubClass_{{variantIndex}}_0_0" class="form-control" name="specSubClass_{{variantIndex}}_0_0"
                 [(ngModel)]="testMethodVariant.specificationSubClass" (blur)="onVariantChange()" placeholder="{{'sub_class'|i18n}}"
                 #specSubClass="ngModel" />
        </div>
      </div>
      <div class="form-group row" *ngIf="testType != testTypeEnum['Logical'] && !isGrayScale">
        <label class="col-sm-3 col-form-label required" for="precision_{{variantIndex}}_0_0">{{'precision'|i18n}}</label>
        <div class="col-sm-9 ps-0">
          <kendo-dropdownlist [data]="precisionListItems"
                              id="precision_{{variantIndex}}_0_0"
                              textField="text"
                              valueField="value"
                              [disabled]="testMethodVariant.isReadOnly || isArchived"
                              [ngModel]="testMethodVariant.precisionLevel"
                              (ngModelChange)="onPrecisionLevelChange($event)"
                              (blur)="onVariantChange()"
                              [valuePrimitive]="true"
                              name="precision_{{variantIndex}}_0_0"
                              class="w-100"
                              [required]="testType != testTypeEnum['Logical'] && !isGrayScale"
                              #precision="ngModel">
          </kendo-dropdownlist>
          <div *ngIf="isSubmitted && !precision.valid" class="alert alert-danger">
            {{'precision'|i18n}} {{'is_required'|i18n}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label required" for="determination_{{variantIndex}}_0_0">{{'determination'|i18n}}</label>
        <div class="col-sm-9 ps-0">
          <input id="determination_{{variantIndex}}_0_0" type="number" class="form-control" min="1" [customMin]="1" required
                 name="determination_{{variantIndex}}_0_0" [ngModel]="testMethodVariant.determination" (ngModelChange)="onChangeDetermination($event)" (blur)="onVariantChange()"
                 placeholder="{{'determination'|i18n}}" #determination="ngModel" />
          <div *ngIf="isSubmitted && !determination.valid" class="alert alert-danger">
            <ng-container *ngIf="determination.errors?.required; else determinationCustomMin">{{'determination'|i18n}} {{'is_required'|i18n}}</ng-container>
            <ng-template #determinationCustomMin>
              <ng-container *ngIf="determination.errors?.customMin">{{'determination_validation_message'|i18n}}</ng-container>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="form-group row" *ngIf="aggregateFunctionShouldShow()">
        <label class="col-sm-3 col-form-label required" for="aggregateFunction_{{variantIndex}}_0_0">{{'aggregate_function'|i18n}}</label>
        <div class="col-sm-9 ps-0">
          <kendo-dropdownlist [data]="aggregateFunctions"
                              id="aggregateFunction_{{variantIndex}}_0_0"
                              textField="text"
                              valueField="value"
                              [disabled]="testMethodVariant.isReadOnly || isArchived"
                              [(ngModel)]="testMethodVariant.aggregateFunction"
                              [valuePrimitive]="true"
                              (valueChange)="onAggregateFunctionChange($event)"
                              (blur)="onVariantChange()"
                              name="aggregateFunction_{{variantIndex}}_0_0"
                              class="w-100"
                              [required]="testMethodVariant.determination > 1">
          </kendo-dropdownlist>
          <div *ngIf="isSubmitted && !testMethodVariant.aggregateFunction" class="alert alert-danger">
            {{'aggregate_function'|i18n}} {{'is_required'|i18n}}
          </div>
        </div>
      </div>
      <div class="row" *ngIf="lotAverageConditionShouldShow()">
        <label class="col-sm-3 col-form-label required" for="aggregateConditionPanelBar">{{'lotAverageCondition'|i18n}}</label>
        <div class="border-left col-sm-9 ps-0">
          <div class="form-group mx-0 px-0 row">
            <label class="col-sm-3 col-form-label" for="type">{{'type'|i18n}}</label>
            <div class="col-sm-9 px-0">
              <kendo-dropdownlist [data]="testTypes"
                                  textField="text"
                                  valueField="value"
                                  [(value)]="testMethodVariant.lotAverageConditionType"
                                  [valuePrimitive]="true"
                                  name="lotAverageConditionType"
                                  class="w-100"
                                  (valueChange)="onChangeLotAverageConditionType()"
                                  [disabled]="testMethodVariant.isReadOnly || isArchived">
              </kendo-dropdownlist>
            </div>
          </div>
          <div class="mx-0 px-0 row">
            <div class="col pe-0">
              <ng-container *ngIf="testMethodVariant.lotAverageConditionMember">
                <pdp-test-method-member [testType]="testMethodVariant.lotAverageConditionType" [testMethodMember]="testMethodVariant.lotAverageConditionMember" [index]="0" class="w-100"
                                        [isGrayScale]="isGrayScale" [isOnlyMember]="true" [numberFormat]="numberFormat$ | async"
                                        [isSubmitted]="isSubmitted" (attributeChange)="onAttributeChange()" (memberChange)="onMemberChange()"
                                        [readonly]="testMethodVariant.isReadOnly || isArchived">
                </pdp-test-method-member>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group row" *ngIf="evaluationMode === EvaluationMode.SpectralReflectance">
        <label class="col-sm-3 col-form-label required" for="numberOfAllowedFailures_{{variantIndex}}_0_0">{{'numberOfAllowedFailures'|i18n}}</label>
        <div class="col-sm-9 ps-0">
          <input type="number" class="form-control" min="0" [customMin]="0" required
                 id="numberOfAllowedFailures_{{variantIndex}}_0_0"
                 name="numberOfAllowedFailures_{{variantIndex}}_0_0"
                 [(ngModel)]="testMethodVariant.numberOfAllowedFailures"
                 (blur)="onVariantChange()"
                 placeholder="{{'numberOfAllowedFailures'|i18n}}" #numberOfAllowedFailures="ngModel" />
          <div *ngIf="isSubmitted && !numberOfAllowedFailures.valid" class="alert alert-danger">
            <ng-container *ngIf="numberOfAllowedFailures.errors?.required; else numberOfAllowedFailuresCustomMin">{{'numberOfAllowedFailures'|i18n}} {{'is_required'|i18n}}</ng-container>
            <ng-template #numberOfAllowedFailuresCustomMin>
              <ng-container *ngIf="numberOfAllowedFailures.errors?.customMin">{{'numberOfAllowedFailures_min_validation_message'|i18n:{ "@minValue": 0 } }}</ng-container>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="form-group row" *ngIf="evaluationMode === EvaluationMode.Regular">
        <label class="col-sm-3 col-form-label" for="aggregateConditionEnabled">
          {{'aggregateCondition'|i18n}}
          <input type="checkbox"
                 id="aggregateConditionEnabled"
                 kendoCheckBox
                 [(ngModel)]="isAggregateConditionEnabled"
                 [ngModelOptions]="{standalone: true}"
                 (change)="aggregateConditionEnabledChange()"
                 (blur)="onVariantChange()" />
        </label>
        <ng-container *ngIf="isAggregateConditionEnabled">
          <div class="col-sm-2 ps-0">
            <kendo-dropdownlist [data]="aggregateConditionTypes"
                                id="aggregateConditionType_{{variantIndex}}_0_0"
                                textField="text"
                                valueField="value"
                                [disabled]="testMethodVariant.isReadOnly || isArchived"
                                [ngModel]="testMethodVariant.aggregateConditionType"
                                (ngModelChange)="onChangeAggregateConditionType($event)"
                                (blur)="onVariantChange()"
                                [valuePrimitive]="true"
                                name="aggregateConditionType_{{variantIndex}}_0_0"
                                class="w-100">
            </kendo-dropdownlist>
          </div>
          <div class="col-sm-2 pe-1">
            <ng-container *ngIf="testMethodVariant.aggregateConditionType === AggregateConditionType.Amount">
              <input name="numberOfAllowedFailures_{{variantIndex}}_0_0"
                     type="number"
                     class="form-control"
                     [(ngModel)]="testMethodVariant.numberOfAllowedFailures"
                     (blur)="onVariantChange()"
                     min="0"
                     [max]="10000"
                     [customMin]="0"
                     [customMax]="10000"
                     step="1"
                     isInteger
                     required
                     #numberOfAllowedFailuresRef="ngModel" />
              <ng-container *ngIf="isSubmitted && numberOfAllowedFailuresRef.errors?.required; else numberOfAllowedFailuresRangeError">
                <div class="alert alert-danger">{{'numberOfAllowedFailures'|i18n}} {{'is_required'|i18n}}</div>
              </ng-container>
              <ng-template #numberOfAllowedFailuresRangeError>
                <div class="alert alert-danger" *ngIf="isSubmitted && numberOfAllowedFailuresRef.errors?.customMin">{{'numberOfAllowedFailures_min_validation_message'|i18n:{ "@minValue": 0 } }}</div>
                <div class="alert alert-danger" *ngIf="isSubmitted && numberOfAllowedFailuresRef.errors?.customMax">{{'numberOfAllowedFailures_max_validation_message'|i18n:{ "@maxValue": 10000 } }}</div>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="testMethodVariant.aggregateConditionType === AggregateConditionType.Percentage">
              <input name="percentageOfRequiredPasses_{{variantIndex}}_0_0"
                     type="number"
                     class="form-control"
                     [(ngModel)]="testMethodVariant.percentageOfRequiredPasses"
                     (blur)="onVariantChange()"
                     min="0"
                     [max]="100"
                     [customMin]="0"
                     [customMax]="100"
                     required
                     #percentageOfRequiredPassesRef="ngModel" />
              <ng-container *ngIf="isSubmitted && percentageOfRequiredPassesRef.errors?.required; else percentageOfRequiredPassesRangeError">
                <div class="alert alert-danger">{{'percentageOfRequiredPasses'|i18n}} {{'is_required'|i18n}}</div>
              </ng-container>
              <ng-template #percentageOfRequiredPassesRangeError>
                <div class="alert alert-danger" *ngIf="isSubmitted && percentageOfRequiredPassesRef.errors?.customMin || percentageOfRequiredPassesRef.errors?.customMax">{{'percentageOfRequiredPasses_validation_message'|i18n}}</div>
              </ng-template>
            </ng-container>
          </div>
          <div class="col-sm-5 ps-0 pt-2">
            {{(testMethodVariant.aggregateConditionType === AggregateConditionType.Percentage ? 'aggregateCondition_samplesNeedToPass' : 'aggregateCondition_samplesCanFail')|i18n}}
          </div>
        </ng-container>
      </div>
    </div>
    <hr />
    <ng-container *ngIf="testMethodVariant.testMethodMembers && testMethodVariant.testMethodMembers.length > 0">
      <div class="overflow-auto">
        <div class="d-flex" *ngFor="let testMethodMember of testMethodVariant.testMethodMembers; index as memberIndex">
          <pdp-test-method-member [testType]="testType" [testMethodMember]="testMethodMember" [index]="variantIndex + '_' + memberIndex" class="w-100"
                                  [isGrayScale]="isGrayScale" [isOnlyMember]="testMethodVariant.testMethodMembers?.length === 1" [numberFormat]="numberFormat$ | async"
                                  [isSubmitted]="isSubmitted" (attributeChange)="onAttributeChange()" (memberChange)="onMemberChange()"
                                  [readonly]="testMethodVariant.isReadOnly || isArchived">
          </pdp-test-method-member>
          <button kendoButton themeColor="error" type="button" class="btn-sm ms-3 h-38"
                  *ngIf="testMethodVariant.testMethodMembers?.length > 1"
                  (click)="removeTestMethodMember(testMethodMember)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </div>
    </ng-container>
  </fieldset>
  <div class="d-inline-flex justify-content-end">
    <button kendoButton themeColor="secondary" (click)="cloneTestMethodVariant()" class="me-2" [disabled]="isArchived">
      <i class="far fa-clone me-2"></i>
      {{'clone_test_variant'|i18n}}
    </button>
    <button kendoButton themeColor="primary" (click)="addTestMethodMember()" [disabled]="testMethodVariant.isReadOnly || isArchived">
      <i class="fas fa-plus">&nbsp;</i>
      {{'test_method_member_button_add'|i18n}}
    </button>
  </div>
</div>
