import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagDataService {
  private cache$: Observable<string[]>;
  private featureUrl = environment.apiBaseUrl + 'api/v1/Features';  // URL to web api

  constructor(
    private http: HttpClient) { }

  getFeatureFlags(): Observable<string[]> {
    if (!this.cache$) {
      this.cache$ = this.requestFeatureFlags().pipe(        
        shareReplay(1)
      );
    }

    return this.cache$;
  }

  private requestFeatureFlags() {
    return this.http.get<string[]>(this.featureUrl);
  }
}
