import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, UntypedFormGroup } from '@angular/forms';
import { CustomValidationService } from '../services/custom-validation.service';
import { MinMaxValidation } from '../models/validation/min-max-validation.model';

@Directive({
  selector: '[minMaxNames]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MinMaxValidationDirective, multi: true }]
})
export class MinMaxValidationDirective implements Validator {
  @Input('minMaxNames') minMaxControllerNames: MinMaxValidation[] = [];

  constructor(private customValidator: CustomValidationService) { }

  validate(formGroup: UntypedFormGroup): ValidationErrors {
    let validationErrors: ValidationErrors[] = [];
    if (formGroup && formGroup.controls) {
      this.minMaxControllerNames.forEach(c => {
        const error = this.customValidator.ValidateMinMax(c.minimum, c.maximum)(formGroup);
        if (error) {
          validationErrors.push(error);
        }
      });
    }
    return validationErrors;
  }
}
