import { Component, OnInit, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
//import { AppInsightsService } from './core/app-insights/app-insights-service';
import { DatePipe, Location } from '@angular/common';
import { AppSettingsService } from './shared/services/app-settings.service';
import { SessionService } from "./shared/services/session.service";
import { environment } from '../environments/environment';
import { AuthService } from './auth/auth.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  title = 'app';

  public isAuthenticated$: Observable<boolean>;

  constructor(
    //appInsightsService: AppInsightsService,
    settingsService: AppSettingsService,
    private authService: AuthService,
    private sessionService: SessionService,
    private location: Location) {

    const settings = settingsService.getSettings();

    const datePipe = new DatePipe('en-US');
    //appInsightsService.logEvent('appStart', { startedAt: datePipe.transform(Date.now(), settings.dateFormat) });
  }

  ngOnInit() {
    this.isAuthenticated$ = combineLatest([this.authService.isAuthenticated$, this.sessionService.hasSubscription$]).pipe(
      map(([{ isAuthenticated }, hasSubscription]) => {
        return isAuthenticated && (!environment.requiresSubscription || hasSubscription);
      }));
  }

  goBack(): void {
    this.location.back();
  }
}
