import { TestAttribute } from "./test-attribute.model";

/**
 * View model for a test method member.
 */
export class TestMethodMember {
  id: number;
  testAttributes: TestAttribute[];
  name: string;  
  testMethodVariantId: number;
  index: number;
  isLotAverageConditionComponent: boolean;
}
