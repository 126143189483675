<fieldset [disabled]="readonly">
  <div class="table-scroll ignore-ng-invalid" ngModelGroup="{{FormGroupNames.TestRequirement}}_{{testRequirement.id}}">
    <div *ngIf="testRequirement.testMethodVariant.testMethod.specificationRequirementNotes || spectralReflectanceNote"
         class="alert requirement-note">
      {{testRequirement.testMethodVariant.testMethod.specificationRequirementNotes}}
      <div *ngIf="spectralReflectanceNote" [innerHTML]="spectralReflectanceNote"></div>
    </div>
    <table *ngIf="testRequirement.determination > 1" class="results text-bold">
      <thead>
        <tr>
          <th class="column-sample"></th>
          <th class="column-member">
          </th>
          <th class="column-determination" [attr.colspan]="testRequirement.determination">
            <span>{{'testNr' | i18n}}</span>
          </th>
          <th></th>
          <th *ngIf="testRequirement.determination > 1 && evaluationMode === EvaluationMode.Regular" class="column-aggregate"></th>
          <th [attr.colspan]="testType===TestType.InclusiveRange || testType===TestType.ExclusiveRange ? 2 : 1"
              class="column-spec-req"></th>
          <th class="column-score"></th>
        </tr>
        <tr>
      </thead>
    </table>
    <div class="table-wrapper ignore-ng-invalid" #resultsTable ngModelGroup="testResults">
      <table [ngClass]="{ 'shade-evaluation': reasonForSubmittal === ReasonForSubmittal.ShadeEvaluation }" class="results text-bold">
        <colgroup>
          <col>
          <col class="column-member">
          <col [attr.span]="testRequirement.determination">
          <col />
          <col *ngIf="testRequirement.determination > 1 && evaluationMode === EvaluationMode.Regular" class="column-aggregate">
          <col [attr.span]="testType===TestType.InclusiveRange || testType===TestType.ExclusiveRange ? 2 : 1">
          <col class="column-score">
        </colgroup>
        <thead>
          <tr>
            <th #sampleColumn
                class="frozen column-sample">
              {{'label_samples' | i18n}}
            </th>
            <th #memberColumn
                class="frozen column-member">
            </th>
            <th *ngFor="let determinationIndex of getDeterminationIndices()"
                class="determination-cell"
                [ngClass]="{'cell-border-left': determinationIndex > 0 }">
              <span *ngIf="testRequirement.determination > 1">{{determinationIndex + 1}}</span>
            </th>
            <th><!--PLACEHOLDER--></th>
            <th #aggregateColumn
                *ngIf="testRequirement.determination > 1 && evaluationMode === EvaluationMode.Regular"
                class="frozen column-aggregate">
              {{resolveAggregateFunctionName(testRequirement.aggregateFunction)}}
            </th>
            <th #specificationRequirementColumn
                [attr.colspan]="testType===TestType.InclusiveRange || testType===TestType.ExclusiveRange ? 2 : 1"
                class="cell-border-left frozen column-spec-req">
              {{'label_specificationRequirement' | i18n}}
            </th>
            <th #scoreColumn class="frozen column-score"></th>
            <th class="frozen column-comment" *ngIf="reasonForSubmittal === ReasonForSubmittal.ShadeEvaluation">{{'comments' | i18n}}</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let sample of samples; let sampleIndex = index">
            <tr *ngFor="let member of testRequirement.testMethodVariant.testMethodMembers; let memberIndex = index" [ngClass]="{ 'sample-first-row': memberIndex === 0 }">
              <td *ngIf="memberIndex === 0" [attr.rowspan]="testRequirement.testMethodVariant.testMethodMembers.length" class="frozen sample-cell">
                {{sample.name}}
                <span class="order-buttons" *ngIf="!readonly">
                  <button kendoButton class="px-1 py-0 me-1"
                          [disabled]="sampleIndex <= 0"
                          (click)="sortingService.moveSortableItemUp(samples, sample)"
                          title="{{'testResults_moveSampleUp' | i18n}}">
                    <i class="fas fa-angle-double-up"></i>
                  </button>
                  <button kendoButton class="px-1 py-0"
                          [disabled]="sampleIndex >= samples.length-1"
                          (click)="sortingService.moveSortableItemDown(samples, sample)"
                          title="{{'testResults_moveSampleDown' | i18n}}">
                    <i class="fas fa-angle-double-down"></i>
                  </button>
                </span>
              </td>
              <td class="text-end px-2 frozen member-cell">
                <span *ngIf="testRequirement.testMethodVariant.testMethodMembers.length > 1 || testRequirement.testMethodVariant.testMethod.testType === TestType.Logical">{{member.name}}</span>
              </td>
              <td #testResultCell class="input-cell" *ngFor="let determinationIndex of getDeterminationIndices()">
                <div class="input-wrapper">
                  <ng-container *ngTemplateOutlet="resultInputTemplate; context: { result: getTestResult(sample.id, member.id, determinationIndex), testResultValueName: getTestResultValueName(sample.id, member.id, determinationIndex), tabIndex: 1000+getTabIndex(sampleIndex, memberIndex, determinationIndex) }"></ng-container>
                  <ng-template #resultInputTemplate
                               let-result="result"
                               let-testResultValueName="testResultValueName"
                               let-tabIndex="tabIndex">
                    <span *ngIf="result && isNumber(result.value)" class="tooltip-wrapper">
                      <i *ngIf="result.hasPassed===false"
                         class="ps-1 fas fa-asterisk"
                         title="{{'testResults_isFailing' | i18n}}"></i>
                      <span *ngIf="result.previousValue!==null && result.hasPassed===null"
                            class="ms-1 k-icon k-i-loading"></span>
                    </span>
                    <kendo-numerictextbox *ngIf="testRequirement.testMethodVariant.testMethod.testType !== TestType.Logical && !isGrayScale"
                                          class="numeric-textbox"
                                          [name]="testResultValueName"
                                          [spinners]="false"
                                          [ngModel]="result.value"
                                          (ngModelChange)="testResultValueChanged($event, result)"
                                          [format]="numberFormat"
                                          (focus)="scrollIntoView(resultTextBox, determinationIndex)"
                                          (blur)="evaluateResult(result)"
                                          [tabindex]="tabIndex"
                                          isNumber
                                          [required]="controlsRequired"
                                          #resultTextBox>
                    </kendo-numerictextbox>
                    <kendo-dropdownlist *ngIf="testRequirement.testMethodVariant.testMethod.testType === TestType.Logical || isGrayScale"
                                        [data]="isGrayScale ? grayscaleValues : logicalValues"
                                        [name]="testResultValueName"
                                        [ngModel]="result.value"
                                        (ngModelChange)="testResultValueChanged($event, result)"
                                        [tabindex]="tabIndex"
                                        textField="text"
                                        valueField="value"
                                        [valuePrimitive]="true"
                                        (focus)="scrollIntoView(resultDropdown, determinationIndex)"
                                        (blur)="evaluateResult(result)"
                                        class="flex-fill"
                                        [required]="controlsRequired"
                                        [disabled]="readonly"
                                        #resultDropdown>
                    </kendo-dropdownlist>
                  </ng-template>
                </div>
              </td>
              <td></td>
              <td *ngIf="testRequirement.determination > 1 && evaluationMode === EvaluationMode.Regular" class="frozen aggregate-cell text-end cell-border-left">
                <ng-container *ngTemplateOutlet="aggregateResultTemplate; context: { $implicit: getAggregateResultOfDeterminations(sample.id, member.id)}"></ng-container>
              </td>
              <td *ngFor="let testAttribute of member.testAttributes; let attributeIndex = index"
                  class="nowrap text-center frozen"
                  [ngClass]="{ 'cell-border-left': attributeIndex === 0, 'spec-req-cell': member?.testAttributes?.length === 1, 'spec-req-cell-left': member?.testAttributes?.length > 1 && attributeIndex === 0, 'spec-req-cell-right': member?.testAttributes?.length > 1 && attributeIndex === 1 }">
                <ng-container *ngTemplateOutlet="specificationRequirementAttributeTemplate; context { $implicit: testAttribute, attributeIndex: attributeIndex, testType: testRequirement.testMethodVariant.testMethod.testType, testAttributeOverrides: testRequirement.testAttributeOverrides }"></ng-container>
              </td>
              <td *ngIf="evaluationMode === EvaluationMode.Regular"
                  class="text-center pe-3 frozen score-cell">
                <ng-container *ngTemplateOutlet="scoringTemplate; context:{ $implicit: getFailedResultCountForMember(sample.id, member.id), failLimit: 1, showCount: false, overallScore: true }"></ng-container>
              </td>
              <td *ngIf="evaluationMode === EvaluationMode.SpectralReflectance && memberIndex === 0"
                  class="text-center pe-3 frozen score-cell"
                  [attr.rowspan]="testRequirement.testMethodVariant.testMethodMembers.length">
                <ng-container *ngTemplateOutlet="scoringTemplate; context:{ $implicit: getFailedResultCountForSpectralReflectance(sample.id), failLimit: testRequirement.numberOfAllowedFailures, showCount: false, overallScore: true }"></ng-container>
              </td>
              <td *ngIf="memberIndex === 0 && reasonForSubmittal === ReasonForSubmittal.ShadeEvaluation" [attr.rowspan]="testRequirement.testMethodVariant.testMethodMembers.length" class="frozen comment-cell">
                <ng-container *ngTemplateOutlet="testResultCommentTemplate; context: { testResultComment: getTestResultComment(sample.id), testResultCommentName: getTestResultCommentName(sample.id) }"><</ng-container>
                <ng-template #testResultCommentTemplate
                             let-testResultCommentName="testResultCommentName"
                             let-testResultComment="testResultComment">
                  <kendo-textarea [name]="testResultCommentName"
                                  resizable="none"
                                  [rows]="4"
                                  [maxlength]="commentLenghtLimit"
                                  [ngModel]="testResultComment.comment"
                                  (ngModelChange)="testResultCommentChanged($event, testResultComment)"
                                  [readonly]="readonly">
                    <kendo-textarea-suffix class="test-result-comment-counter">
                      <span class="counter">{{ testResultComment.comment.length }} / {{commentLenghtLimit}}</span>
                    </kendo-textarea-suffix>
                  </kendo-textarea>
                </ng-template>
              </td>
            </tr>
            <tr *ngIf="evaluationMode === EvaluationMode.SpectralReflectance">
              <td class="frozen sample-cell"></td>
              <td class="frozen member-cell"></td>
              <td *ngFor="let determinationIndex of getDeterminationIndices()">
                <ng-container *ngTemplateOutlet="scoringTemplate; context:{ $implicit: getFailedResultCountForDetermination(sample.id, determinationIndex), failLimit: 1, showCount: true, overallScore: false }"></ng-container>
              </td>
              <td></td>
              <td *ngIf="testRequirement.determination > 1 && evaluationMode === EvaluationMode.Regular"
                  class="frozen aggregate-cell"
                  [attr.colspan]="testType===TestType.InclusiveRange || testType===TestType.ExclusiveRange ? 2 : 1"></td>
              <td class="frozen spec-req-cell cell-border-left" colspan="2"></td>
              <td class="frozen score-cell"></td>
            </tr>
          </ng-container>
          <ng-container *ngIf="testRequirement.determination > 1 && evaluationMode === EvaluationMode.Regular && testRequirement.aggregateFunction === AggregateFunction.LotAverage">
            <tr *ngFor="let member of testRequirement.testMethodVariant.testMethodMembers">
              <td class="frozen cell-border-top"></td>
              <td class="frozen cell-border-top" [attr.colspan]="testRequirement.determination"></td>
              <th colspan="2" class="frozen lot-average-header-cell text-end cell-border-top">
                {{'aggregateFunction_full_LotAvg'|i18n}}<ng-container *ngIf="testRequirement.testMethodVariant.testMethodMembers.length > 1"> ({{member.name}})</ng-container>
              </th>
              <td class="frozen lot-average-cell text-end justify-content-end cell-border-top cell-border-left">
                <ng-container *ngTemplateOutlet="aggregateResultTemplate; context: { $implicit: getLotAverageResult(member.id)}"></ng-container>
              </td>
              <ng-container *ngIf="{ member: testRequirement.testMethodVariant.lotAverageConditionMember } as context">
                <td *ngFor="let testAttribute of context.member.testAttributes; let attributeIndex = index"
                    class="frozen text-center nowrap cell-border-top"
                    [ngClass]="{ 'cell-border-left': attributeIndex === 0, 'spec-req-cell': context.member?.testAttributes?.length === 1, 'spec-req-cell-left': context.member?.testAttributes?.length > 1 && attributeIndex === 0, 'spec-req-cell-right': context.member?.testAttributes?.length > 1 && attributeIndex === 1 }">
                  <ng-container *ngTemplateOutlet="specificationRequirementAttributeTemplate; context { $implicit: testAttribute, attributeIndex: attributeIndex, testType: testRequirement.testMethodVariant.lotAverageConditionType, testAttributeOverrides: testRequirement.lotAverageConditionAttributeOverrides }"></ng-container>
                </td>
                <td class="frozen pe-3 column-score cell-border-top">
                  <ng-container *ngTemplateOutlet="scoringTemplate; context:{ $implicit: getFailedResultCountForLotAverage(member.id), failLimit: 1, showCount: false, overallScore: true }"></ng-container>
                </td>
              </ng-container>
            </tr>
          </ng-container>
          <ng-container *ngIf="testRequirement.aggregateConditionType && evaluationMode === EvaluationMode.Regular">
            <tr *ngFor="let member of testRequirement.testMethodVariant.testMethodMembers">
              <td class="frozen cell-border-top"></td>
              <th [attr.colspan]="testRequirement.determination + (testRequirement.determination > 1 && evaluationMode === EvaluationMode.Regular ? 3 : 2)" class="frozen aggregate-condition-header-cell text-end cell-border-top">
                {{'aggregateCondition'|i18n}}<ng-container *ngIf="testRequirement.testMethodVariant.testMethodMembers.length > 1"> ({{member.name}})</ng-container>
              </th>
              <td [attr.colspan]="testType===TestType.InclusiveRange || testType===TestType.ExclusiveRange ? 2 : 1" class="frozen aggregate-condition-req-cell cell-border-left text-center cell-border-top">
                {{getAggregateCondition(testRequirement)}}
              </td>
              <td class="text-center pe-3 frozen aggregate-condition-score-cell cell-border-top">
                <ng-container *ngTemplateOutlet="aggregatedScoringTemplate; context:{ $implicit: getScoreForSamples(member.id), conditionType: testRequirement.aggregateConditionType, failLimit: testRequirement.numberOfAllowedFailures, passLimit: testRequirement.percentageOfRequiredPasses, overallScore: true }"></ng-container>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</fieldset>

<ng-template #aggregateResultTemplate let-aggregateResult>
  <ng-container *ngIf="aggregateResult && isNumber(aggregateResult.value); else noAggregateValue;">
    <span *ngIf="aggregateResult.hasPassed===false">
      <i class="ps-1 fas fa-asterisk"
         title="{{'testResults_isFailing' | i18n}}"></i>
    </span>
    <span *ngIf="aggregateResult.hasPassed===null"
          class="ms-1 k-icon k-i-loading"></span>
    <span class="me-1">{{translateAggregateValue(aggregateResult.value)}}</span>
  </ng-container>
</ng-template>

<ng-template #noAggregateValue>
  &nbsp;
</ng-template>

<ng-template #scoringTemplate let-failCount let-failLimit="failLimit" let-showCount="showCount" let-overallScore="overallScore">
  <div class="score-cell" [ngClass]="{
       'justify-content-end': overallScore,
       'justify-content-center': overallScore === false }">
    <span *ngIf="failCount>=failLimit">
      <i class="ps-1 fas fa-asterisk"
         title="{{'testResults_isFailing' | i18n}}"></i>
    </span>
    <span class="score" *ngIf="failCount >= 0 && failCount < failLimit">{{passingText}}</span>
    <span class="score" *ngIf="failCount >= failLimit"><ng-container *ngIf="showCount">{{failCount}} </ng-container>{{failingText}}</span>
  </div>
</ng-template>

<ng-template #aggregatedScoringTemplate let-score let-conditionType="conditionType" let-failLimit="failLimit" let-passLimit="passLimit">
  <div class="score-cell justify-content-end">
    <ng-container *ngIf="score >= 0 && ((conditionType === AggregateConditionType.Amount && score > failLimit) || (conditionType === AggregateConditionType.Percentage && score < passLimit))">
      <span>
        <i class="ps-1 fas fa-asterisk"
           title="{{'testResults_isFailing' | i18n}}"></i>
      </span>
      <span class="score">{{failingText}}</span>
    </ng-container>
    <span class="score" *ngIf="score>=0 && ((conditionType === AggregateConditionType.Amount && score <= failLimit) || (conditionType === AggregateConditionType.Percentage && score >= passLimit))">{{passingText}}</span>
  </div>
</ng-template>

<ng-template #specificationRequirementAttributeTemplate let-testAttribute let-attributeIndex="attributeIndex" let-testType="testType" let-testAttributeOverrides="testAttributeOverrides">
  <span [ngSwitch]="getOperatorType(testAttribute, testAttributeOverrides)">
    <ng-template [ngSwitchCase]="OperatorType.LessThan">
      <i class="fas fa-less-than"></i>
    </ng-template>
    <ng-template [ngSwitchCase]="OperatorType.LessThanOrEqual">
      <i class="fas fa-less-than-equal"></i>
    </ng-template>
    <ng-template [ngSwitchCase]="OperatorType.GreaterThan">
      <i class="fas fa-greater-than"></i>
    </ng-template>
    <ng-template [ngSwitchCase]="OperatorType.GreaterThanOrEqual">
      <i class="fas fa-greater-than-equal"></i>
    </ng-template>
    <ng-template ngSwitchDefault>
      <span *ngIf="testType === TestType.Logical; else equalsType">
        {{'shouldBe'|i18n}}
      </span>
      <ng-template #equalsType>
        <i class="fas fa-equals"></i>
      </ng-template>
    </ng-template>
    {{ translateExpectedValue(testAttribute, testAttributeOverrides)}}
  </span>
</ng-template>
