import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as math from 'mathjs';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalizationService } from './localization.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class TestEvaluationService {

  constructor(
    private http: HttpClient,
    private localizationService: LocalizationService    
  ) { }

  private testEvaluationEndpointUrl = environment.apiBaseUrl + 'api/v1/TestEvaluation'; // URL to web api

  getEvaluationResult(testRequirementId: number, testMethodMemberId: number, value: number): Observable<boolean> {    
    let url = `${this.testEvaluationEndpointUrl}/${testRequirementId}/${testMethodMemberId}`;
    if (value !== null) {      
      url += `/${value}`;
    }
    return this.http.get<boolean>(url, { headers: httpOptions.headers });
  }

  // Important! Any change in this method should be mirrored in the method GetDisplayedValue() in Natific.PDP.BusinessLogic.Services
  // under "TestEvaluatiuonService.cs"
  getDisplayedValue(value: number, precisionLevel: number = 0, zeroPadRightOnly: boolean = false): string {
    if (value !== null && typeof value !== 'undefined') {
      const decimalSeparator = this.localizationService.getDecimalSeparator();

      if (zeroPadRightOnly) {
        const numberGroups = value.toString().split(decimalSeparator);
        const paddedFraction = (numberGroups.length > 1 ? numberGroups[1] : '').padEnd(precisionLevel, '0');
        let formattedValue = numberGroups[0];

        if (paddedFraction !== '') {
          formattedValue += `${decimalSeparator}${paddedFraction}`;
        }

        return formattedValue;
      } else {        
        var shouldRoundToZero = value !== 0 && math.round(value, precisionLevel) == 0;

        if (shouldRoundToZero) {
          var numberGroups = value.toString().split(decimalSeparator);
          if (numberGroups.length > 1) {
            var fractionString = numberGroups[1];
            var match = fractionString.match(/^0+/)
            var leadingZeroes = match ? match[0].length : 0;
            let formattedNumber = math.round(value, leadingZeroes + 1);
            return formattedNumber.toString();
          }
          else {
            return `${numberGroups[0]}`;
          }
        } else {
          return math.round(value, precisionLevel).toFixed(precisionLevel);
        }
      }
    }

    return '';
  }  
}
