<div class="container">
  <div class="alert alert-warning mb-2" *ngIf="!readonly && isShadeEvaluationSet() && (noEvaluationProcedureAvailable$ | async)">
    {{getNoEvaluationProcedureAvailableMessage()}}
  </div>
  <div class="row mb-05" *ngIf="!readonly && !isShadeEvaluationSet()">
    <div class="col-9 pe-0">
      <kendo-combobox #testMethodGroupComboBox
                      [data]="testMethodGroups$ | async"
                      textField="name"
                      valueField="id"
                      [ngModel]="selectedTestMethodGroup"
                      name="testMethodGroupComboBox"
                      class="w-100 test-method-groups"
                      placeholder="{{'searchForTestMethodGroup'|i18n}}"
                      [popupSettings]="{ popupClass: 'test-method-group-popup'}"
                      [listHeight]="300"
                      [filterable]="true"
                      [loading]="isTestMethodGroupsLoading"
                      (filterChange)="filterTestMethodGroups($event)"
                      (valueChange)="testMethodGroupChanged($event)">
        <ng-template kendoComboBoxItemTemplate let-dataItem>
          <div *ngIf="dataItem" class="test-method-item">
            <div class="test-method-group">
              <div class="test-method-group-name">{{dataItem.name}}</div>
              <div class="test-method-group-items ms-3">
                {{getTestMethodVariantSummaryList(dataItem.testMethodGroupItems)}}
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template kendoComboBoxNoDataTemplate>
          {{'noSearchResult' | i18n}}
        </ng-template>
        <ng-template kendoComboBoxFooterTemplate>
          <div *ngIf="resultInfo" class="test-method-selector-footer">{{resultInfo}}</div>
        </ng-template>
      </kendo-combobox>
    </div>
    <div class="col ps-0">
      <button kendoButton themeColor="secondary" class="ms-1 w-100" (click)="addTestRequirementsFromGroup(testMethodGroupComboBox)" [disabled]="selectedTestMethodGroup == null">
        <i class="fas fa-plus me-2"></i>{{'add_test_requirement_group'|i18n}}
      </button>
    </div>
  </div>
  <div class="row" *ngIf="!readonly">
    <div class="col-9 pe-0">
      <kendo-combobox #testMethodComboBox
                      [data]="groupedData$ | async"
                      textField="summary"
                      valueField="id"
                      [ngModel]="selectedTestMethodVariant"
                      name="testMethodComboBox"
                      class="w-100"
                      placeholder="{{'searchForTestMethod'|i18n}}"
                      [listHeight]="300"
                      [filterable]="true"
                      [loading]="isTestMethodVariantsLoading"
                      (filterChange)="filterTestMethodVariants($event)"
                      (valueChange)="testMethodVariantChanged($event)">
        <ng-template kendoComboBoxFixedGroupTemplate let-testMethodId>
          <ng-template *ngTemplateOutlet="groupHeader; context: {tm: getTestMethod(testMethodId)}"></ng-template>
        </ng-template>
        <ng-template kendoComboBoxGroupTemplate let-testMethodId>
          <ng-template *ngTemplateOutlet="groupHeader; context: {tm: getTestMethod(testMethodId)}"></ng-template>
        </ng-template>
        <ng-template kendoComboBoxItemTemplate let-dataItem>
          <div *ngIf="dataItem" class="test-method-item">
            <div class="test-method-selector-name test-method-variant ms-3">
              <span class="pe-2">[{{dataItem.description}}]</span>
              <ng-container *ngIf="dataItem.specificationType || dataItem.specificationClass || dataItem.specificationSubclass">
                <ng-template *ngTemplateOutlet="classificationSummary; context: {tmv: dataItem }"></ng-template>
                </ng-container>
            </div>
          </div>
        </ng-template>
        <ng-template kendoComboBoxNoDataTemplate>
          {{'noSearchResult' | i18n}}
        </ng-template>
        <ng-template kendoComboBoxFooterTemplate>
          <div *ngIf="resultInfo" class="test-method-selector-footer">{{resultInfo}}</div>
        </ng-template>
      </kendo-combobox>
    </div>
    <div class="col ps-0">
      <button kendoButton themeColor="secondary" class="ms-1 w-100" (click)="addTestRequirement(testMethodComboBox)" [disabled]="selectedTestMethodVariant == null">
        <i class="fas fa-plus me-2"></i>{{'add_test_requirement'|i18n}}
      </button>
    </div>
  </div>
</div>
<input type="hidden" [ngModel]="testRequirementsCheckValue" name="testRequirementsCheck" [required]="controlsRequired" />
<div [ngModelGroup]="FormGroupNames.TestRequirements" class="ignore-ng-invalid">
  <ng-template #no_testRequirements>
    <div class="p-2 mt-2 alert alert-info text-center" [ngClass]="{'ng-invalid ng-touched': isTestRequirementsInvalid() }">
      <span class="pe-3" [ngClass]="{ 'required-for-upgrade': indicatorsRequired }">{{isTestRequirementsInvalid() ? noRequirementsErrorMessage : 'noRequirementsMessage' | i18n}}</span>
</div>
  </ng-template>
  <div *ngIf="request.testRequirements && request.testRequirements.length > 0; else no_testRequirements">
    <kendo-panelbar class="my-3 requirements-panelbar"
                    [keepItemContent]="true"
                    (stateChange)="stateChange($event)">
      <kendo-panelbar-item #testRequirementPanelBarItem
                           title=""
                           *ngFor="let testRequirement of request.testRequirements; let i = index; trackBy: trackBy"
                           [expanded]="isExpanded(testRequirement.id)"
                           [ngClass]="{'invalid': isTestRequirementInvalid(i)}">
        <ng-template kendoPanelBarItemTitle>
          <div class="requirement-title-panelbar">
            <ng-template *ngTemplateOutlet="panelTitle; context: {tmv: testRequirement?.testMethodVariant, hasClassification: testRequirement?.testMethodVariant?.specificationType || testRequirement?.testMethodVariant?.specificationClass || testRequirement?.testMethodVariant?.specificationSubclass }"></ng-template>
            <div class="actions" *ngIf="!readonly">
              <div class="header-cell border-left text-end" *ngIf="request.testRequirements.length > 1">
                <button kendoButton themeColor="secondary"
                        class="m-0125"
                        [disabled]="i <= 0"
                        (click)="moveSortableItemUp($event, request.testRequirements, testRequirement)"
                        title="{{'moveRequirementUp' | i18n}}">
                  <i class="fas fa-angle-double-up"></i>
                </button>
                <button kendoButton themeColor="secondary"
                        class="m-0125"
                        [disabled]="i >= request.testRequirements.length-1"
                        (click)="moveSortableItemDown($event, request.testRequirements, testRequirement)"
                        title="{{'moveRequirementDown' | i18n}}">
                  <i class="fas fa-angle-double-down"></i>
                </button>
              </div>
              <button kendoButton themeColor="error"
                      (click)="deleteTestRequirement($event, testRequirement)">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </ng-template>
        <ng-template kendoPanelBarContent>
          <pdp-test-requirement [testRequirement]="testRequirement"
                                [suppliers]="suppliers"
                                [readonly]="readonly"
                                [requirementIndex]="i"></pdp-test-requirement>
        </ng-template>
      </kendo-panelbar-item>
    </kendo-panelbar>
  </div>
</div>

<ng-template #groupHeader let-tm="tm">
  <div *ngIf="tm" class="test-method-item">
    <div class="test-method-selector-name">{{tm.summary}}</div>
    <div class="test-method-details">
      <div class="test-method-selector-comment">{{tm.comment}}</div>
      <div><label class="me-2">{{'unit_of_measure' | i18n}}:</label>{{tm.unitOfMeasure}}</div>
    </div>
  </div>
</ng-template>

<ng-template #panelTitle let-tmv="tmv" let-hasClassification="hasClassification">
  <span [ngClass]="{'required-for-save': !readonly}" class="pe-3">
    <span class="text-bold pe-2">{{tmv.testMethod.name}} - {{tmv.testMethod.title}} [{{tmv.description}}]</span>
    <ng-container *ngIf="hasClassification">
      <ng-template *ngTemplateOutlet="classificationSummary; context: {tmv: tmv}"></ng-template>
    </ng-container>
  </span>
</ng-template>

<ng-template #classificationSummary let-tmv="tmv">
  (<span *ngIf="tmv.specificationType">{{tmv.specificationType}}</span>
  <span *ngIf="tmv.specificationClass"> - {{tmv.specificationClass}}</span>
  <span *ngIf="tmv.specificationSubClass"> - {{tmv.specificationSubClass}}</span>)
</ng-template>
