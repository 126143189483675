import { Injectable } from '@angular/core';
import { ISortable } from '../interfaces/i-sortable.interface';

@Injectable({
  providedIn: 'root'
})
export class SortingService {

  constructor() { }

  compareBySortIndex(a: ISortable, b: ISortable) {
    return (a.sortIndex > b.sortIndex) ? 1 : (b.sortIndex > a.sortIndex) ? -1 : 0;
  }

  calculateSortIndex(list: Array<ISortable>, fromIndex: number = 0) {
    if (fromIndex < list.length) {
      for (let i = fromIndex; i < list.length; i++) {
        list[i].sortIndex = i;        
      }
    }
  }

  moveSortableItemUp(list: Array<ISortable>, item: ISortable) {
    let fromIndex = list.findIndex(i => i === item);
    if (fromIndex > 0) {
      list.splice(fromIndex, 1);
      list.splice(fromIndex - 1, 0, item);

      item.sortIndex = fromIndex - 1;
      list[fromIndex].sortIndex = fromIndex;
    }
  }

  moveSortableItemDown(list: Array<ISortable>, item: ISortable) {
    let fromIndex = list.findIndex(i => i === item);
    if (fromIndex >= 0 && fromIndex < list.length - 1) {
      list.splice(fromIndex, 1);
      list.splice(fromIndex + 1, 0, item);

      item.sortIndex = fromIndex + 1;
      list[fromIndex].sortIndex = fromIndex;      
    }
  }
}
