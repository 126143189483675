<kendo-dialog-titlebar (close)="onClose($event)">
  <span>{{getTitle()}}</span>
  <small *ngIf="testMethod.isReadOnly" class="m-auto alert-message">
    {{'test_method_used' | i18n}}
  </small>
</kendo-dialog-titlebar>

<div class="container">
  <form #testMethodForm="ngForm">
    <fieldset [disabled]="testMethod.isReadOnly || getIsArchived()">
      <div class="form-group row">
        <label class="col-sm-3 col-form-label required" for="name">{{'name'|i18n}}</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="name" required name="name"
                 [(ngModel)]="testMethod.name" placeholder="{{'name'|i18n}}" #name="ngModel" />
          <div *ngIf="isSubmitted && !name.valid" class="alert alert-danger">
            {{'name'|i18n}} {{'is_required'|i18n}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label required" for="title">{{'title'|i18n}}</label>
        <div class="col-sm-9">
          <input type="text" class="form-control" id="title" required name="title"
                 [(ngModel)]="testMethod.title" placeholder="{{'title'|i18n}}" #title="ngModel" />
          <div *ngIf="isSubmitted && !title.valid" class="alert alert-danger">
            {{'title'|i18n}} {{'is_required'|i18n}}
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="type">{{'test_type'|i18n}}</label>
        <div class="col-sm-6">
          <kendo-dropdownlist [data]="testTypes"
                              textField="text"
                              valueField="value"
                              [(ngModel)]="currentTestType"
                              [valuePrimitive]="true"
                              name="type"
                              class="w-100"
                              (valueChange)="onChangeTestType()"
                              [disabled]="testMethod.isReadOnly || getIsArchived()">
          </kendo-dropdownlist>
        </div>
        <div class="col-sm-3 d-flex align-items-center">
          <kendo-switch id="testMethodType"
                        name="testMethodType"
                        class="mt-1"
                        size="small"
                        [ngModel]="isShadeEvaluationSet()"
                        (ngModelChange)="onTestMethodTypeChange($event)"
                        [disabled]="testMethod.isReadOnly || getIsArchived()">
          </kendo-switch>
          <label class="mb-0 ms-2">{{'shade_evaluation_procedure'|i18n}}</label>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">{{'test_evaluation_mode'|i18n}}</label>
        <div class="col-sm-9 d-flex align-items-center">
          <input kendoRadioButton
                 type="radio"
                 name="evaluationMode"
                 id="regularMode"
                 [ngModel]="testMethod.evaluationMode"
                 (ngModelChange)="onEvaluationModeChange($event)"
                 [value]="EvaluationMode.Regular"
                 class="me-2" />
          <label class="first-option ms-0   me-3 my-auto text-nowrap k-radio-label" for="regularMode">
            {{'evaluationMode_regular' | i18n}}
          </label>
          <input kendoRadioButton
                 type="radio"
                 name="evaluationMode"
                 id="spectralMode"
                 [ngModel]="testMethod.evaluationMode"
                 (ngModelChange)="onEvaluationModeChange($event)"
                 [value]="EvaluationMode.SpectralReflectance"
                 class="me-2" />
          <label class="ms-0  me-3 my-auto text-nowrap k-radio-label" for="spectralMode">
            {{'evaluationMode_spectralReflectance' | i18n}}
          </label>
        </div>
      </div>
      <div class="form-group row" *ngIf="testMethod.testType != testTypeEnum['Logical']">
        <label class="col-sm-3 col-form-label required" for="unitOfMeasure">{{'unit_of_measure'|i18n}}</label>
        <div class="col-sm-9 d-flex align-items-center">
          <input kendoRadioButton
                 type="radio"
                 name="isGrayScale"
                 [(ngModel)]="isGrayScale"
                 id="grayScale"
                 (click)="onChangeUnitOfMeasure('Gray Scale')"
                 [value]="true"
                 class="me-2" />
          <label class="first-option ms-0  me-3 my-auto text-nowrap k-radio-label" for="grayScale">
            {{'grayScale' | i18n}}
          </label>
          <input kendoRadioButton
                 type="radio"
                 name="isGrayScale"
                 [(ngModel)]="isGrayScale"
                 [value]="false"
                 (click)="onChangeUnitOfMeasure(null)"
                 class="me-2" />
          <input type="text"
                 [disabled]="isGrayScale"
                 class="form-control k-flex-1"
                 *ngIf="isGrayScale" />
          <ng-container *ngIf="!isGrayScale">
            <input type="text"
                   class="form-control k-flex-1"
                   id="unitOfMeasure"
                   [required]="testMethod.testType != testTypeEnum['Logical']"
                   [disabled]="isGrayScale"
                   (ngModelChange)="onChangeUnitOfMeasure($event)"
                   name="unitOfMeasure"
                   [(ngModel)]="testMethod.unitOfMeasure"
                   placeholder="{{'unit_of_measure'|i18n}}" #unitOfMeasureModel="ngModel" />
            <div *ngIf="isSubmitted && !unitOfMeasureModel.valid" class="alert alert-danger">
              {{'unit_of_measure'|i18n}} {{'is_required'|i18n}}
            </div>
          </ng-container>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="comment">{{'comment'|i18n}}</label>
        <div class="col-sm-9">
          <textarea kendoTextArea [autoSize]="true" class="form-control" id="comment"
                    name="comment" [(ngModel)]="testMethod.comment" placeholder="{{'comment'|i18n}}" #comment="ngModel"></textarea>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-3 col-form-label" for="specificationRequirementNotes">{{'specificationRequirementNotes'|i18n}}</label>
        <div class="col-sm-9">
          <textarea kendoTextArea [autoSize]="true" class="form-control" id="specificationRequirementNotes" name="specificationRequirementNotes" [disabled]="testMethod.isReadOnly"
                    [(ngModel)]="testMethod.specificationRequirementNotes" placeholder="{{'specificationRequirementNotes'|i18n}}" #specificationRequirementNotes="ngModel"></textarea>
        </div>
      </div>
    </fieldset>
    <div class="form-group row">
      <label class="col-sm-3 col-form-label">{{'expiration'|i18n}}</label>
      <div class="col-sm-9 d-flex align-items-center">
        <input kendoRadioButton
               type="radio"
               name="archiveUntil"
               id="availableIndefinietly"
               [ngModel]="isArchived"
               (ngModelChange)="onChangeArchive($event)"
               [value]="false"
               class="me-2" />
        <label class="ms-0 me-5 my-auto text-nowrap k-radio-label" for="availableIndefinietly">
          {{'available_indefinitely' | i18n}}
        </label>
        <input kendoRadioButton
               type="radio"
               name="archiveUntil"
               id="archiveUntil"
               [ngModel]="isArchived"
               (ngModelChange)="onChangeArchive($event)"
               [value]="true"
               class="me-2" />
        <label class="ms-0 me-2 my-auto text-nowrap k-radio-label" for="archiveUntil">
          {{'available_until'|i18n}}
        </label>
        <kendo-datepicker name="archiveDatePicker"
                          [(ngModel)]="testMethod.archiveDate"
                          [format]="dateFormatString"
                          [disabled]="!isArchived"
                          class="flex-grow-1">
        </kendo-datepicker>
      </div>
    </div>
    <div>
      <kendo-tabstrip (tabSelect)="onTabSelect($event)" #variantsTabStrip [animate]="false" keepTabContent="true" ngModelGroup="testMethodVariants">
        <kendo-tabstrip-tab *ngFor="let testMethodVariant of variants$ | async; index as variantIndex"
                            [cssClass]="{'alert-danger': hasTabError(variantIndex) }" [selected]="variantIndex === selectedTab">
          <ng-template kendoTabTitle>
            <div style="height:1.9rem">
              {{testMethodVariant.description}}
              <button *ngIf="testMethod.testMethodVariants.length > 1 && !testMethodVariant.isReadOnly && !getIsArchived()" kendoButton themeColor="secondary"
                      (click)="removeTestMethodVariant(testMethodVariant)" class="ms-2">
                <i class="fas fa-times"></i>
              </button>
            </div>
          </ng-template>
          <ng-template kendoTabContent>
            <pdp-test-method-variant [testMethodVariant]="testMethodVariant" [evaluationMode]="testMethod.evaluationMode"
                                     [isArchived]="getIsArchived()" [testType]="testMethod.testType" class="w-100" [variantIndex]="variantIndex"
                                     (cloneVariant)="onCloneVariant($event)" (statusChange)="onVariantStatusChange($event)"
                                     [isGrayScale]="isGrayScale" [isSubmitted]="isSubmitted"></pdp-test-method-variant>
          </ng-template>
        </kendo-tabstrip-tab>
        <kendo-tabstrip-tab *ngIf="!getIsArchived()">
          <ng-template kendoTabTitle>
            <button kendoButton themeColor="secondary" (click)="addTestMethodVariant()">
              <i class="fas fa-plus"></i>
            </button>
          </ng-template>
        </kendo-tabstrip-tab>
      </kendo-tabstrip>
    </div>
  </form>
</div>

<kendo-dialog-actions layout="normal" class="p-2">
  <button kendoButton themeColor="secondary" (click)="cloneTestMethod()">
    <i class="far fa-clone me-2"></i>
    {{'clone_test_method'|i18n}}
  </button>
  <button kendoButton themeColor="primary" (click)="onSave()">{{actionName}}</button>
</kendo-dialog-actions>
