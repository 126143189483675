<fieldset [disabled]="readonly">
  <div class="row">
    <div class="col">
      <label class="col-form-label pe-3" [ngClass]="{'required-for-upgrade': indicatorsRequired}">{{'stamps'|i18n}}</label>
    </div>
  </div>
  <div class="stamps" [ngClass]="{'ng-invalid': isStampsInvalid() }">
    <input #stampDirective [ngModel]="request.stamps" type="hidden" name="stamps" [isStampSelected]="controlsRequired" />
    <div class="row">
      <div *ngFor="let stamp of stamps" class="stamp-container" [ngClass]="{'stamp-readonly': readonly}">
        <i class="fas fa-check-circle selected-stamp" *ngIf="stamp.selected"></i>
        <div [ngSwitch]="stamp.stamp">
          <div *ngSwitchCase="stampEnum.Passing" class="stamp passing" (click)="toggleSelection(stamp)" [ngClass]="{'dimmed': !stamp.selectable, 'stamp-readonly': readonly}">
            Passing
          </div>
          <div *ngSwitchCase="stampEnum.Failing" class="stamp failing" (click)="toggleSelection(stamp)" [ngClass]="{'dimmed': !stamp.selectable, 'stamp-readonly': readonly}">
            Failing
          </div>
          <div *ngSwitchCase="stampEnum.LabPassedTest" class="stamp text-justify" (click)="toggleSelection(stamp)" [ngClass]="{'dimmed': !stamp.selectable, 'stamp-readonly': readonly}">
            AN APPROVED LABORATORY UNDER THE SOURCE SAMPLING PROGRAM TESTED THIS LOT.
          </div>
          <div *ngSwitchCase="stampEnum.PtcPassedTest" class="stamp text-justify" (click)="toggleSelection(stamp)" [ngClass]="{'dimmed': !stamp.selectable, 'stamp-readonly': readonly}">
            THIS LOT WAS TESTED BY THE DLA-PTC. PASSING TEST RESULTS WERE REPORTED ON THE CONTRACTOR’S TEST REPORT.
          </div>
          <div *ngSwitchCase="stampEnum.LabFailedTest" class="stamp text-justify" (click)="toggleSelection(stamp)" [ngClass]="{'dimmed': !stamp.selectable, 'stamp-readonly': readonly}">
            AN APPROVED LABORATORY UNDER THE SOURCE SAMPLING PROGRAM TESTED THIS LOT. FAILING TEST RESULTS WERE REPORTED ON THE CONTRACTOR’S TEST REPORT.
          </div>
          <div *ngSwitchCase="stampEnum.PtcFailedTest" class="stamp text-justify" (click)="toggleSelection(stamp)" [ngClass]="{'dimmed': !stamp.selectable, 'stamp-readonly': readonly}">
            THIS LOT WAS TESTED BY THE DLA-PTC. FAILING TEST RESULTS WERE REPORTED ON THE CONTRACTOR’S TEST REPORT.
          </div>
          <div *ngSwitchDefault class="stamp text-center" (click)="toggleSelection(stamp)" [ngClass]="{'dimmed': !stamp.selectable, 'stamp-readonly': readonly}">
            UNABLE TO PROCESS <br /> FOR FAILURE TO <br /> COMPLY WITH AQAR
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isStampsInvalid()" class="alert alert-danger">{{noStampsSelectedErrorMessage}}</div>
  <div class="row">
    <div class="col">
      <label class="col-form-label">{{'comment'|i18n}}</label>
      <textarea kendoTextArea class="form-control" id="comments" [rows]="2"
                name="comments" [(ngModel)]="request.specifierComment" placeholder="{{'comment'|i18n}}" #comment="ngModel"></textarea>
    </div>
  </div>
</fieldset>
