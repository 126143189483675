import { TemplateRef } from "@angular/core";
import { NGridColumnFilterType } from "../enums/n-grid-column-filter-type.enum";
import { INGridColumnOptionsBase } from "../interfaces/i-n-grid.column-options.interface";
import { INGridPropertyFn } from "../interfaces/i-n-grid-property-function.interface";

export class NGridColumnOptions implements INGridColumnOptionsBase {
  field: string;
  title?: string;
  width?: number;
  class?: string | string[] | Set<string> | { [key: string]: any };
  headerClass?: string | string[] | Set<string> | { [key: string]: any };
  private _hidden?: boolean | INGridPropertyFn<boolean>;
  sortable?: boolean;  
  filterable?: boolean;
  filterType?: NGridColumnFilterType;
  filterShowOperators?: boolean;
  filterData?: any[];
  filterTextField?: string;
  filterValueField?: string;
  filterDefaultTextField?: string;
  filterCellTemplate?: TemplateRef<any>;
  cellTemplate?: TemplateRef<any>;

  get hidden(): boolean {
    return typeof this._hidden === 'function' ? this._hidden() : this._hidden;
  }

  constructor(columnOptions: INGridColumnOptions) {
    this.field = columnOptions.field;
    this.title = 'title' in columnOptions ? columnOptions.title : columnOptions.field;
    if ('width' in columnOptions) {
      this.width = columnOptions.width;
    }
    if ('headerClass' in columnOptions) {
      this.headerClass = columnOptions.headerClass;
    }
    if ('class' in columnOptions) {
      this.class = columnOptions.class;
    }
    this._hidden = 'hidden' in columnOptions ? columnOptions.hidden : false;
    this.filterable = 'filterable' in columnOptions ? columnOptions.filterable : true;
    this.filterType = 'filterType' in columnOptions ? columnOptions.filterType : NGridColumnFilterType.Text;
    this.filterValueField = 'filterValueField' in columnOptions ? columnOptions.filterValueField : 'value';
    this.filterTextField = 'filterTextField' in columnOptions ? columnOptions.filterTextField : 'label';
    this.filterData = 'filterData' in columnOptions ? columnOptions.filterData : [];
    this.filterDefaultTextField = 'filterDefaultTextField' in columnOptions ? columnOptions.filterDefaultTextField : 'Select item...';
    this.sortable = 'sortable' in columnOptions ? columnOptions.sortable : true;
    this.filterShowOperators = 'filterShowOperators' in columnOptions ? columnOptions.filterShowOperators : false;
    if ('filterCellTemplate' in columnOptions) {
      this.filterCellTemplate = columnOptions.filterCellTemplate;
    }
    if ('cellTemplate' in columnOptions) {
      this.cellTemplate = columnOptions.cellTemplate;
    }
  }
}

export interface INGridColumnOptions extends INGridColumnOptionsBase{
  field: string;
  title ?: string;
  width ?: number;
  class?: string | string[] | Set<string> | { [key: string]: any };
  headerClass?: string | string[] | Set<string> | { [key: string]: any };
  hidden ?: boolean | INGridPropertyFn<boolean>;
  sortable ?: boolean;
  filterable ?: boolean;
  filterType?: NGridColumnFilterType;
  filterData?: any[];
  filterTextField?: string;
  filterValueField?: string;
  filterDefaultTextField?: string;
  filterShowOperators ?: boolean;
  filterCellTemplate ?: TemplateRef<any>;
  cellTemplate ?: TemplateRef<any>;
}
