import { Injectable } from '@angular/core';
import { DialogService, DialogCloseResult, DialogRef } from '@progress/kendo-angular-dialog';
import { MessageBoxSettings } from './models/message-box-settings.model';
import { MessageBoxPrimaryButton } from './enums/message-box-primary-button.enum';
import { MessageBoxType } from './enums/message-box-type.enum';
import { MessageBoxComponent } from './message-box.component';

@Injectable({
  providedIn: 'root'
})
export class MessageBoxService {

  constructor(private dialogService: DialogService) { }

  open(settings: MessageBoxSettings): DialogRef {
    let actions = [];
    if (!settings.primary) {
      settings.primary = MessageBoxPrimaryButton.Confirm;
    }

    if (!settings.type) {
      settings.type = MessageBoxType.Info;
    }

    if (settings.cancelButtonText) {
      actions.push({
        text: settings.cancelButtonText,
        themeColor: settings.primary === MessageBoxPrimaryButton.Cancel ? 'primary' : 'secondary'
      });
    }

    if (settings.denyButtonText) {
      actions.push({
        text: settings.denyButtonText,
        themeColor: settings.primary === MessageBoxPrimaryButton.Deny ? 'primary' : 'secondary'
      });
    }

    if (settings.confirmButtonText) {
      actions.push({
        text: settings.confirmButtonText,
        themeColor: settings.primary === MessageBoxPrimaryButton.Confirm ? 'primary' : 'secondary'
      });
    }

    const dialogRef = this.dialogService.open({
      content: MessageBoxComponent,
      title: settings.title,
      actionsLayout: 'normal',
      actions: actions.length > 0 ? actions : null,
      width: '33%'
    });    

    const componentInstance = <MessageBoxComponent>dialogRef.content.instance;

    dialogRef.result.subscribe(result => {

      if (!(result instanceof DialogCloseResult)) {
        if (settings.confirmButtonText && result.text === settings.confirmButtonText) {
          componentInstance.setConfirmationResult(true);

          if (settings.onConfirm) {
            settings.onConfirm();
          }
        } else
          if (settings.denyButtonText && result.text === settings.denyButtonText) {
            componentInstance.setConfirmationResult(false);

            if (settings.onDeny) {
              settings.onDeny();
            }
          } else
            if (settings.cancelButtonText && result.text === settings.cancelButtonText) {
              componentInstance.setConfirmationResult(false);

              if (settings.onCancel) {
                settings.onCancel();
              }
            }
      } else {
        componentInstance.setConfirmationResult(false);

        if (settings.onCancel) {
          settings.onCancel();
        }
      }
    });

    componentInstance.message = settings.message;
    componentInstance.type = settings.type;
    componentInstance.showIcon = settings.showIcon;

    return dialogRef;
  }
}

