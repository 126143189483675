import { Component, ViewChild } from '@angular/core';
import { AbstractControl, NgForm, NgModel } from '@angular/forms';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { RowClassArgs, RowClassFn } from '@progress/kendo-angular-grid';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MessageBoxType } from '../../shared/components/message-box/enums/message-box-type.enum';
import { MessageBoxService } from '../../shared/components/message-box/message-box.service';
import { NGridFilterMethod } from '../../shared/components/n-grid/enums/n-grid-filter-method.enum';
import { INGridColumnOptionsBase } from '../../shared/components/n-grid/interfaces/i-n-grid.column-options.interface';
import { NGridColumnOptions } from '../../shared/components/n-grid/models/n-grid-column-options.model';
import { NGridOptions } from '../../shared/components/n-grid/models/n-grid-options.model';
import { NGridState } from '../../shared/components/n-grid/models/n-grid-state.model';
import { EvaluationMode } from '../../shared/enums/evaluation-mode.enum';
import { TestMethodGroupAddingOption } from '../../shared/enums/test-method-group-adding-option.enum';
import { TestType } from '../../shared/enums/test-type.enum';
import { SuggestionConflictResult } from '../../shared/models/swiss/suggestions/suggestion-conflict-result.model';
import { Suggestion } from '../../shared/models/swiss/suggestions/suggestion.model';
import { TestMethodSuggestion } from '../../shared/models/swiss/suggestions/test-method-suggestion.model';
import { XsbRequirement } from '../../shared/models/swiss/xsb-requirement.model';
import { XsbRequirementsDocument } from '../../shared/models/swiss/xsb-requirements-document.model';
import { TestMethodGroup } from '../../shared/models/test-method-groups/test-method-group.model';
import { TestMethodListItem } from '../../shared/models/test-methods/test-method-list-item.model';
import { I18nService } from '../../shared/services/i18n.service';
import { SwissDocumentService } from '../../shared/services/swiss-document.service';
import { TestMethodGroupsService } from '../../shared/services/test-method-groups.service';
import { TestMethodsService } from '../../shared/services/test-methods.service';
import { SuggestionConflictResolverComponent } from './suggestion-conflict-resolver/suggestion-conflict-resolver.component';

@Component({
  selector: 'pdp-find-specification',
  templateUrl: './find-specification.component.html',
  styleUrls: ['./find-specification.component.css']
})
export class FindSpecificationComponent {

  private suggestionForm: NgForm;
  private actionResult = '';

  @ViewChild('suggestionForm') set content(content: NgForm) {
    if (content) {
      this.suggestionForm = content;
      this.suggestionForm.form.markAsUntouched();
    }
  }

  public requirementsDocument: XsbRequirementsDocument;
  public selectedRequirementJson: any;
  public selectedRequirementIndex: number;
  public selectedSuggestion: TestMethodSuggestion;
  public selectedKeys: number[] = [];
  public testMethodListItems: TestMethodListItem[];
  public documentLabel = '';
  public revision = '';
  public documentIdentifier = '';
  public findSpecificationErrorMessage = '';
  public isFindInProgress = false;
  public isSubmitted = false;
  public isGrayScale = false;
  public isReadonly = false;
  public isSelectAll = false;

  public isGenerateMethodGroup = false;
  public testMethodGroupAddingOption = TestMethodGroupAddingOption.New;
  private testMethodGroupSearchUpdated: BehaviorSubject<string> = new BehaviorSubject('');
  public testMethodGroups: TestMethodGroup[];
  public selectedTestMethodGroup: TestMethodGroup;
  public isTestMethodGroupsLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isTestMethodGroupsLoading$ = this.isTestMethodGroupsLoadingSubject.asObservable();
  public subscription: Subscription;

  public gridData: { isSelected: boolean; description: string, requirement: XsbRequirement }[];

  public TestMethodGroupAddingOption = TestMethodGroupAddingOption;

  public notificationMessage = '';
  public isImportInProgress = false;
  public isLookingForConflicts = false;

  gridColumns: INGridColumnOptionsBase[] = [
    new NGridColumnOptions({
      field: 'isSelected',
      sortable: false,
      title: '',
      width: 40
    }),
    new NGridColumnOptions({
      field: 'requirement',
      sortable: false,
      title: this.i18nService.transform('description')
    })
  ];

  gridOptions: NGridOptions = new NGridOptions({
    isNavigable: true
  });

  constructor(
    public dialog: DialogRef,
    private testMethodService: TestMethodsService,
    private testMethodGroupsService: TestMethodGroupsService,
    private swissDocumentService: SwissDocumentService,
    private dialogService: DialogService,
    private i18nService: I18nService,
    private messageBoxService: MessageBoxService,
    private toastr: ToastrService
  ) { }

  searchFieldIsValid(control: NgModel) {
    var value = control.value;
    return control.valid && value.match(/^ +$/) === null;
  }

  noRecordsText() {
    return this.i18nService.transform('findSpecification_noRecordMessage');
  }

  private getDocumentIdentifier() {
    const endsWithLetter = /[A-Z]$/.test(this.documentLabel.toUpperCase());
    const separator = endsWithLetter ? '-' : '';
    let suffix = '';

    if (this.revision) {
      suffix = `${separator}${this.revision}`;
    }

    return `${this.documentLabel.toUpperCase()}${suffix}`;
  }

  findSpecification() {
    if (this.documentLabel) {
      this.isFindInProgress = true;
      this.selectedSuggestion = null;
      this.isSubmitted = false;
      this.isSelectAll = false;
      this.findSpecificationErrorMessage = '';
      this.requirementsDocument = null;

      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.swissDocumentService.getRequirements(this.documentLabel, this.revision, false)
        .subscribe(requirementsDocument => {
          this.requirementsDocument = requirementsDocument;
          this.documentIdentifier = this.getDocumentIdentifier();
          this.gridData = [];
          for (let i = 0; i < this.requirementsDocument.requirements.length; i++) {
            const requirement = this.requirementsDocument.requirements[i];
            this.gridData.push({ isSelected: false, description: this.resolveSuggestionDescription(requirement, i + 1), requirement: requirement });
          }

          this.subscription = this.testMethodGroupSearchUpdated.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe(value => {
              this.getTestMethodGroups(value);
            });

          this.selectedTestMethodGroup = new TestMethodGroup();
          this.selectedTestMethodGroup.name = this.getDocumentIdentifier();
          this.isFindInProgress = false;
          if (this.requirementsDocument.requirements.length > 0) {
            this.selectedKeys = [0];
            this.selectedSuggestion = this.requirementsDocument.requirements[0].testMethodSuggestion;
            this.selectedRequirementIndex = 0;
            this.selectedRequirementJson = this.requirementsDocument.requirements[this.selectedRequirementIndex];

            this.setTestMethodListItems(this.selectedSuggestion.matchingTestMethods);
          }
        },
          error => {
            this.isFindInProgress = false;
            if (error.status === 403) {
              this.findSpecificationErrorMessage = this.i18nService.transform('notFound_or_notAuthorized_error')
            } else {
              this.findSpecificationErrorMessage = error.message;
            }

          });
    } else {
      this.toastr.error(this.i18nService.transform('documentLabel_empty_error'));
    }
  }

  private getTestMethodGroups(freetextSearch: string = '') {
    this.isTestMethodGroupsLoadingSubject.next(true);
    const state: NGridState = {
      take: 50,
      filterMethod: NGridFilterMethod.FreeTextSearch,
      freeTextSearch: freetextSearch,
      sort: [{
        field: 'name',
        dir: 'asc'
      }]
    };

    this.testMethodGroupsService.getTestMethodGroups(state).subscribe(
      (data) => {
        this.testMethodGroups = data.data;
        this.isTestMethodGroupsLoadingSubject.next(false);
      },
      catchError((error, caught) => { throw error; })
    );
  }

  filterTestMethodGroups(query: string): void {
    this.testMethodGroupSearchUpdated.next(query);
  }

  testMethodGroupChanged(value: TestMethodGroup) {
    if (value || value === undefined) {
      this.selectedTestMethodGroup = value || null;
    }
  }

  generateTestMethodGroupChanged(value: boolean) {
    this.isGenerateMethodGroup = value;
    if (this.isGenerateMethodGroup && this.testMethodGroupAddingOption === TestMethodGroupAddingOption.Existing) {
      this.tryDetermineTestMethodGroup();
    }
  }

  testMethodGroupAddingOptionChanged(value: TestMethodGroupAddingOption) {
    this.testMethodGroupAddingOption = value;

    if (this.testMethodGroupAddingOption === TestMethodGroupAddingOption.New) {
      this.selectedTestMethodGroup = new TestMethodGroup();
      this.selectedTestMethodGroup.name = this.getDocumentIdentifier();
    } else {
      this.tryDetermineTestMethodGroup();
    }
  }

  private tryDetermineTestMethodGroup() {
    if (this.isTestMethodGroupsLoadingSubject.value === false && this.testMethodGroups && this.testMethodGroups.length > 0) {
      const documentIdentifier = this.getDocumentIdentifier();

      const associatedTestMethodGroup = this.testMethodGroups.find(tmg => tmg.name === documentIdentifier);

      this.selectedTestMethodGroup = associatedTestMethodGroup || null;
    }
  }

  isTestMethodGroupControlInvalid(control: AbstractControl, testMethodGroupAddingOption: TestMethodGroupAddingOption): boolean {
    return this.isGenerateMethodGroup
      && this.testMethodGroupAddingOption === testMethodGroupAddingOption
      && control.touched
      && !control.valid
  }

  updateSelection() {
    this.gridData.forEach(item => {
      if (item.requirement.testMethodSuggestion) {
        item.isSelected = this.isSelectAll;
      }
    });
  }

  rowSelectionChange(e): void {
    if (e.selectedRows && e.selectedRows.length > 0) {
      this.setValidityForSelectedSuggestion();
      this.selectedSuggestion = (e.selectedRows[0].dataItem.requirement as XsbRequirement).testMethodSuggestion;
      this.selectedRequirementIndex = e.selectedRows[0].index;
      this.selectedRequirementJson = this.requirementsDocument.requirements[this.selectedRequirementIndex].json;

      if (this.selectedSuggestion) {
        this.setTestMethodListItems(this.selectedSuggestion.matchingTestMethods);
      }
    }
  }

  suggestionSelectionChange() {
    if (!this.gridData.every(item => item.requirement.testMethodSuggestion && item.isSelected)) {
      this.isSelectAll = false;
    }
  }

  setValidityForSelectedSuggestion() {
    if (this.selectedSuggestion && this.suggestionForm) {
      this.suggestionForm.form.updateValueAndValidity();
      this.selectedSuggestion.valid = this.suggestionForm.form.status === 'VALID';
    }
  }

  stylizeRows: RowClassFn = (context: RowClassArgs) => {
    return {
      'invalid-suggestion': context.dataItem.isSelected && context.dataItem.requirement.testMethodSuggestion.valid === false,
      'unverified-suggestion': context.dataItem.isSelected && (context.dataItem.requirement.testMethodSuggestion.valid === null || context.dataItem.requirement.testMethodSuggestion.valid === undefined),
      'clickable': true
    };
  }

  setTestMethodListItems(items: TestMethodListItem[]) {
    var newTestMethodLabel = this.i18nService.transform('test_method_new');
    this.testMethodListItems = [{ id: null, summary: `[${newTestMethodLabel}]` }, ...items];
  }

  isNewTestMethodSelected() {
    return this.selectedSuggestion && this.selectedSuggestion.testMethodVariant.testMethodId === null;
  }

  resolveSuggestionDescription(requirement: XsbRequirement, index: number = 0): string {
    let suggestionDescription = '';
    const testMethodSuggestion = requirement.testMethodSuggestion;
    if (testMethodSuggestion) {
      const name = testMethodSuggestion.name;
      const title = testMethodSuggestion && testMethodSuggestion.title ? ` (${testMethodSuggestion.title})` : '';
      const description = testMethodSuggestion
        && testMethodSuggestion.testMethodVariant
        && testMethodSuggestion.testMethodVariant.description ? ` [${testMethodSuggestion.testMethodVariant.description}]` : '';
      suggestionDescription = `${name}${title}${description}`;
    } else {
      suggestionDescription = requirement.attributeText || requirement.subjectText || `${this.i18nService.transform('unrecognized_requirement')} #${index}`;
    }
    return suggestionDescription;
  }

  resolveTestType(testType: TestType): string {
    return this.i18nService.transform(testType.toLowerFirstLetter());
  }

  resolveEvaluationMode(evaluationMode: EvaluationMode): string {
    return this.i18nService.transform(`evaluationMode_${evaluationMode.toLowerFirstLetter()}`);
  }

  onClose() {
    this.dialog.close(this.actionResult);
  }

  onSave() {
    this.setValidityForSelectedSuggestion();
    this.isSubmitted = true;
    var testMethodSuggestions = this.gridData.filter(dataItem => dataItem.isSelected).map(dataItem => dataItem.requirement.testMethodSuggestion);
    const isAllSelectedValid = testMethodSuggestions.every(tds => tds.valid);
    if (isAllSelectedValid) {
      this.setLookingForConflicts();
      var suggestion = new Suggestion();
      suggestion.isGenerateTestMethodGroup = this.isGenerateMethodGroup;
      if (this.isGenerateMethodGroup) {
        if (this.testMethodGroupAddingOption === TestMethodGroupAddingOption.Existing) {
          suggestion.testMethodGroupId = this.selectedTestMethodGroup.id;
        } else {
          suggestion.testMethodGroupName = this.selectedTestMethodGroup.name;
        }
      }
      suggestion.testMethodSuggestions = testMethodSuggestions;

      this.testMethodService.checkSuggestionForConflicts(suggestion).subscribe(conflictResults => {

        if (!conflictResults.hasDuplicateTestMethodGroup && conflictResults.testMethodConflictResults.length == 0) {
          this.clearLookingForConflicts();
          this.importSuggestions(suggestion);
        } else {
          this.clearLookingForConflicts();
          const requirementDescriptions = this.gridData.filter(dataItem => dataItem.isSelected).map(dataItem => dataItem.description);
          this.openConflictResolverDialog(suggestion, conflictResults, requirementDescriptions);
        }
      });
    } else {
      this.messageBoxService.open({
        title: this.i18nService.transform('message_box_title_warning'),
        type: MessageBoxType.Warning,
        showIcon: true,
        message: this.i18nService.transform('invalidOrUnverifiedSelectedSuggestions_error'),
        confirmButtonText: this.i18nService.transform('OK'),
      });
    }
  }

  public openConflictResolverDialog(suggestion: Suggestion, suggestionConflictResult: SuggestionConflictResult, requirementDescriptions: string[]) {
    const dialogRef = this.dialogService.open({
      title: this.i18nService.transform('resolve_conflicts'),
      content: SuggestionConflictResolverComponent,      
      width: '70%',
      minWidth: '50%'
    });

    const instance = <SuggestionConflictResolverComponent>dialogRef.content.instance;
    instance.suggestion = suggestion;
    instance.suggestionConflictResult = suggestionConflictResult;
    instance.requirementDescriptions = requirementDescriptions;

    dialogRef.result.subscribe(result => {
      if (result === 'import') {
        this.importSuggestions(instance.suggestion);
      }
    });
  }

  private importSuggestions(suggestion: Suggestion) {
    if (suggestion) {
      this.setImportInProgress();
      this.testMethodService.createSuggestion(suggestion).subscribe(result => {
        this.clearImportInProgress();
        this.toastr.success(this.i18nService.transform('importSuccessful'));
        this.actionResult = 'imported';

        if (this.isGenerateMethodGroup && this.testMethodGroupAddingOption === TestMethodGroupAddingOption.New) {
          this.getTestMethodGroups();
        }

        this.gridData.forEach(dataItem => dataItem.isSelected = false);
        this.isSelectAll = false;
        this.isGenerateMethodGroup = false;
        this.selectedTestMethodGroup = new TestMethodGroup();
        this.selectedTestMethodGroup.name = this.getDocumentIdentifier();
      });
    }
  }

  hasAnyRequirementSelected() {
    return this.gridData && this.gridData.some(r => r.isSelected);
  }

  findSpecificationDocument() {
    if (this.documentLabel) {
      this.swissDocumentService.getDocumentById(this.documentLabel, this.revision).subscribe(documentHtmlContent => {

      });
    } else {
      this.toastr.error(this.i18nService.transform('documentLabel_empty_error'));
    }
  }

  private setLookingForConflicts() {
    this.notificationMessage = `<span class="k-icon k-i-loading"></span><span class="ps-2">${this.i18nService.transform('lookingForConflicts')}...</span>`;
    this.isLookingForConflicts = true;
  }

  private clearLookingForConflicts() {
    this.isLookingForConflicts = false;
  }

  private setImportInProgress() {
    this.notificationMessage = `<span class="k-icon k-i-loading"></span><span class="ps-2">${this.i18nService.transform('importing')}...</span>`;
    this.isImportInProgress = true;
  }

  private clearImportInProgress() {
    this.isImportInProgress = false;
  }
}
