import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { PortalUser } from "../models/users/portal-user.model";
import { environment } from "../../../environments/environment";
import { User } from '../models/users/user.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  get httpOptions(): Object {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
  }
  
  private portalBaseUrl = `${environment.apiPortalUrl}Users`;  // URL to portal web api
  private baseUrl = `${environment.apiBaseUrl}api/v1/User`;  // URL to web api

  private customersPortalUrl = `${environment.apiPortalUrl}Customers`;  // URL to web api
  

  constructor(private http: HttpClient
  )
  { }

  /** GET user by id. Will 404 if id not found */
  getUser(id: string): Observable<User> {
    const url = `${this.portalBaseUrl}/${id}`;
    return this.http.get<PortalUser>(url, this.httpOptions)
      .pipe(
        map((pu: PortalUser) => new User(
          pu.id,
          pu.customerId,
          pu.roles,
          pu.email,
          pu.firstName,
          pu.lastName,
          pu.customerName,
          pu.isCustomerAdmin,
          pu.fullName
    )));
  }

  createIfNew(externalId: string, companyExternalId: string): Observable<any> {
    const url = `${this.baseUrl}/${externalId}/${companyExternalId}`;

    return this.http.post(url, this.httpOptions);
  }

  hasSubscriptions(): Observable<any> {
    const url = `${this.portalBaseUrl}/Current/Applications/${environment.applicationId}`;
    return this.http.get(url, this.httpOptions);
  }

  /** Post: impersonate user */
  impersonate(id: string): Observable<any> {
    const url = `${this.portalBaseUrl}/${id}/Impersonate`;
    return this.http.post(url, this.httpOptions);
  }

  /** Post: stop impersonating */
  stopImpersonating(id: string): Observable<any> {
    const url = `${this.portalBaseUrl}/${id}/StopImpersonating`;
    return this.http.post(url, this.httpOptions);
  }

  getCompanyUsers(companyId: string): Observable<User[]> {
    const url = `${this.customersPortalUrl}/${companyId}/Users`;
    return this.http.get<PortalUser[]>(url, this.httpOptions).pipe(
      map((p: PortalUser[]) => p.map(pu => new User(
        pu.id,
        pu.customerId,
        pu.roles,
        pu.email,
        pu.firstName,
        pu.lastName,
        pu.customerName,
        pu.isCustomerAdmin,
        pu.fullName
      ))));
  }
}
