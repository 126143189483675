import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { AppSettings } from '../models/app-settings/app-settings';

@Injectable({
    providedIn: 'root'
})
export class AppSettingsService {
    private appSettings: any = {};
    constructor(private http: HttpClient) {}

    initSettings(): Promise<AppSettings> {
        return new Promise<AppSettings>((resolve, reject) => {
                let appSettingsUrl = environment.apiBaseUrl + 'api/v1/Configuration';
          this.http.get<AppSettings>(appSettingsUrl).subscribe(settings => {
                    this.appSettings = Object.assign({}, settings || {});
                    resolve(this.appSettings);
                },
                    error => {
                        this.appSettings = {};
                        resolve(this.appSettings);
                    });
                resolve(this.appSettings);
        });
    }

    getSettings(): AppSettings {
        return this.appSettings;
    }
}
