import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AuthModule, LogLevel, OidcSecurityService, StsConfigHttpLoader, StsConfigLoader } from 'angular-auth-oidc-client';
import { AppRoutingModule } from './app-routing.module';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { GridModule, ExcelModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { ButtonsModule, ChipModule } from '@progress/kendo-angular-buttons';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { httpInterceptorProviders } from './core/http-interceptors/index';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { ContractsComponent } from './contracts/contracts.component';
import { RequestsComponent } from './requests/requests.component';
import { ForbiddenComponent } from './status/forbidden/forbidden.component';
import { NoSubscriptionsComponent } from './status/no-subscriptions/no-subscriptions.component';
import { NotFoundComponent } from './status/not-found/not-found.component';
import { UnauthorizedComponent } from './status/unauthorized/unauthorized.component';
import { LoginComponent } from './auth/login/login.component';
import { MessageBoxComponent } from './shared/components/message-box/message-box.component';
import { AuditLogsComponent } from './audit-logs/audit-logs.component';
import { ActionTileComponent } from './shared/components/action-tile/action-tile.component';
import { SettingsComponent } from './settings/settings.component';
import { NGridComponent } from './shared/components/n-grid/n-grid.component';
import { NGridDateRangeFilterComponent } from './shared/components/n-grid/n-grid-date-range-filter/n-grid-date-range-filter.component';
import { SearchTextboxComponent } from './shared/components/search-textbox/search-textbox.component';
import { ContractDetailsComponent } from './contracts/contract-details/contract-details.component';
import { RequestDetailsComponent } from './requests/request-details/request-details.component';
import { TestMethodsComponent } from './test-methods/test-methods.component';
import { TestMethodsService } from './shared/services/test-methods.service';
import { TestMethodDetailsComponent } from './test-methods/test-method-details/test-method-details.component';
import { TestAttributeNumericComponent } from './test-methods/test-attribute-numeric/test-attribute-numeric.component';
import { TestAttributeLogicalComponent } from './test-methods/test-attribute-logical/test-attribute-logical.component';
import { TestMethodMemberComponent } from './test-methods/test-method-member/test-method-member.component';
import { ContractSelectorComponent } from './shared/components/contract-selector/contract-selector.component';
import { SampleInformationComponent } from './requests/request-details/sample-information/sample-information.component';
import { TestRequirementComponent } from './requests/request-details/test-requirements/test-requirement/test-requirement.component';
import { PtcResponseComponent } from './requests/request-details/ptc-response/ptc-response.component';
import { TestDetailsComponent } from './requests/request-details/test-details/test-details.component';
import { TestResultsComponent } from './requests/request-details/test-details/test-results/test-results.component';
import { PtcReviewComponent } from './requests/request-details/ptc-review/ptc-review.component';
import { TestMethodVariantComponent } from './test-methods/test-method-variant/test-method-variant.component';
import { CloneRequestComponent } from './requests/request-details/clone-request/clone-request.component';

import { NGridCellTemplateDirective } from './shared/components/n-grid/directives/n-grid-cell-template.directive';
import { NGridFilterCellTemplateDirective } from './shared/components/n-grid/directives/n-grid-filter-cell-template.directive';
import { NGridRemoveCommandCellTemplateDirective } from './shared/components/n-grid/directives/n-grid-command-cell-template.directive';
import { MinMaxValidationDirective } from './shared/directives/min-max-validation.directive';
import { CustomMinDirective } from './shared/directives/custom-min-validation.directive';
import { CustomMaxDirective } from './shared/directives/custom-max-validation.directive';
import { IsNumberDirective } from './shared/directives/is-number-validation.directive';
import { ShowDirective } from './shared/directives/show.directive';
import { NGridDetailTemplateDirective } from './shared/components/n-grid/directives/n-grid-detail-template.directive';

import { CanDeactivateGuard } from './shared/services/can-deactivate.guard';
import { AuthGuard } from './shared/services/auth.guard';

import { GlobalErrorHandler } from './shared/services/global-error-handler';
import { AppInsightsService } from './core/app-insights/app-insights-service';
import { BlobDownloadService } from './shared/services/blob-download.service';
import { I18nService } from './shared/services/i18n.service';
import { AppSettingsService } from './shared/services/app-settings.service';
import { CustomValidationService } from './shared/services/custom-validation.service';
import { CompaniesService } from './shared/services/companies.service';
import { ContractsService } from './shared/services/contracts.service';
import { AuditLogsService } from './shared/services/audit-logs.service';
import { MessageBoxService } from './shared/components/message-box/message-box.service';
import { PendingChangesService } from './shared/services/pending-changes.service';

import { I18nPipe } from './shared/pipes/i18n.pipe';
import { DatePipe } from '@angular/common';
import { IsStampSelectedDirective } from './shared/directives/is-stamp-selected.directive';
import { TestMethodGroupsComponent } from './test-method-groups/test-method-groups.component';
import { TestMethodGroupDetailsComponent } from './test-method-groups/test-method-group-details/test-method-group-details.component';
import { NotificationOverlayComponent } from './shared/components/notification-overlay/notification-overlay.component';
import { NGridDropDownFilterComponent } from './shared/components/n-grid/n-grid-drop-drown-filter/n-grid-drop-down-filter.component';
import { FindSpecificationComponent } from './test-methods/find-specification/find-specification.component';
import { AutofocusDirective } from './shared/directives/autofocus.directive';
import { IsIntegerValidationDirective } from './shared/directives/is-integer-validation.directive';
import { TestRequirementsComponent } from './requests/request-details/test-requirements/test-requirements.component';
import { FeatureFlagService } from './core/feature-manager/feature-flag.service';
import { FeatureFlagDirective } from './core/feature-manager/feature-flag.directive';
import { FeatureFlagDataService } from './core/feature-manager/feature-flag-data.service';
import { ContractSelectorDialogComponent } from './shared/components/contract-selector-dialog/contract-selector-dialog.component';
import { CloneRequestDialogComponent } from './requests/request-details/clone-request-dialog/clone-request-dialog.component';
import { map } from 'rxjs/operators';
import { AddSamplesComponent } from './requests/request-details/sample-information/add-samples/add-samples.component';
import { SuggestionConflictResolverComponent } from './test-methods/find-specification/suggestion-conflict-resolver/suggestion-conflict-resolver.component';

export function setupI18nServiceFactory(
  service: I18nService): Function {
  return () => service.use('en');
}

export function loadSettings(service: AppSettingsService): Function {
  return () => service.initSettings();
}

export const httpLoadConfig = (httpClient: HttpClient) => {
  const config$ = httpClient
    .get<any>(`${environment.apiBaseUrl}api/v1/Configuration/IdentityConfigurationData`)
    .pipe(
      map((customConfig: any) => {
        return {
          configId: 'pdp',
          authority: customConfig.stsServer,
          clientId: customConfig.client_id,
          redirectUrl: customConfig.redirect_url,
          responseType: customConfig.response_type,
          scope: customConfig.scope,
          postLogoutRedirectUri: customConfig.post_logout_redirect_uri,
          startCheckSession: customConfig.start_checksession,
          silentRenew: customConfig.silent_renew,
          postLoginRoute: customConfig.post_login_route,
          triggerAuthorizationResultEvent: true,
          forbiddenRoute: customConfig.forbidden_route,
          unauthorizedRoute: customConfig.unauthorized_route,
          maxIdTokenIatOffsetAllowedInSeconds: customConfig.max_id_token_iat_offset_allowed_in_seconds,
          historyCleanupOff: false,
          logLevel: customConfig.log_level || LogLevel.None,
          useRefreshToken: true,
          tokenRefreshInSeconds: customConfig.token_refresh_in_seconds || 10,
          refreshTokenRetryInSeconds: customConfig.refresh_token_retry_in_seconds || 10
        };
      })
    );

  return new StsConfigHttpLoader(config$);
}

export function loadFeatureFlags(service: FeatureFlagService) {
  return () => service.initialize();
}
export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    NavMenuComponent,
    MessageBoxComponent,
    SearchTextboxComponent,
    LoginComponent,
    HomeComponent,
    ActionTileComponent,
    ContractsComponent,
    RequestsComponent,
    ForbiddenComponent,
    NoSubscriptionsComponent,
    NotFoundComponent,
    UnauthorizedComponent,
    I18nPipe,
    SettingsComponent,
    NGridComponent,
    NGridDropDownFilterComponent,
    NGridDateRangeFilterComponent,
    NGridCellTemplateDirective,
    NGridFilterCellTemplateDirective,
    NGridRemoveCommandCellTemplateDirective,
    NGridDetailTemplateDirective,
    ContractDetailsComponent,
    RequestDetailsComponent,
    TestMethodsComponent,
    TestMethodDetailsComponent,
    TestAttributeNumericComponent,
    TestAttributeLogicalComponent,
    MinMaxValidationDirective,
    CustomMinDirective,
    CustomMaxDirective,
    IsNumberDirective,
    TestRequirementComponent,
    TestMethodMemberComponent,
    ContractSelectorComponent,
    SampleInformationComponent,
    PtcResponseComponent,
    TestDetailsComponent,
    TestResultsComponent,
    ShowDirective,
    PtcReviewComponent,
    TestMethodVariantComponent,
    CloneRequestComponent,
    AuditLogsComponent,
    IsStampSelectedDirective,
    TestMethodGroupsComponent,
    TestMethodGroupDetailsComponent,
    NotificationOverlayComponent,
    FindSpecificationComponent,
    AutofocusDirective,
    IsIntegerValidationDirective,
    TestRequirementsComponent,
    FeatureFlagDirective,
    ContractSelectorDialogComponent,
    CloneRequestDialogComponent,
    AddSamplesComponent,
    SuggestionConflictResolverComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: httpLoadConfig,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    ButtonsModule,
    BrowserAnimationsModule,
    DialogsModule,
    LayoutModule,
    GridModule,
    ExcelModule,
    InputsModule,
    DateInputsModule,
    DropDownsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
    }),
    TooltipModule,
    ChipModule,
    UploadsModule
  ],
  exports: [
    AuthModule
  ],
  providers: [
     AppSettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: loadSettings,
      deps: [AppSettingsService],
      multi: true
    },
    { provide: 'ORIGIN_URL', useFactory: getBaseUrl },
    I18nService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupI18nServiceFactory,
      deps: [I18nService],
      multi: true
    },
    FeatureFlagDataService,
    FeatureFlagService,
    { provide: APP_INITIALIZER, useFactory: loadFeatureFlags, deps: [FeatureFlagService], multi: true },
    AppInsightsService,
    OidcSecurityService,
    AuthGuard,
    CanDeactivateGuard,
    httpInterceptorProviders,
    DatePipe,
    CompaniesService,
    ContractsService,
    TestMethodsService,
    CustomValidationService,
    BlobDownloadService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    AuditLogsService,
    MessageBoxService,
    PendingChangesService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
