<pdp-notification-overlay *ngIf="isLoading || isValidatingInProgress || isSavingInProgress || isDownloadInProgress" [message]="notificationMessage"></pdp-notification-overlay>
<div class="request-details-wrapper m-05" *ngIf="!isLoading && request" (show)="onDetailsShow()" kendoTooltip tooltipClass="tooltip-wrapper" position="bottom">
  <div class="request-details-header">
    <div class="row m-0 mb-1">
      <div class="col p-0">
        <h3>{{(request?.id?'testingRequest':'newRequest')|i18n}}</h3>
      </div>
      <div class="col-auto row">
        <label class="col col-form-label text-truncate" for="specifierUser" title="{{'assigned_specialist'|i18n}}">
          {{'assigned_specialist'|i18n}}
        </label>
        <div class="col px-2">
          <kendo-dropdownlist [data]="specialists"
                              [defaultItem]="defaultSpecialist"
                              textField="fullName"
                              valueField="externalId"
                              id="specifierUser"
                              name="specifierUser"
                              [(ngModel)]="request.assignedSpecifierUser"
                              (valueChange)="onSpecifierUserChange($event)"
                              [disabled]="!isSystemOrPortalAdmin || isRequestArchived()"
                              class="w-100">
            <ng-template kendoDropDownListItemTemplate let-dataItem>
              <span class="text-nowrap">
                {{ dataItem.fullName }}
                <span class="ps-1 font-weight-light" *ngIf="dataItem.fullName != dataItem.email">({{dataItem.email}})</span>
              </span>
            </ng-template>
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              <span class="text-nowrap">
                {{ dataItem.fullName }}
                <span class="ps-1 font-weight-light" *ngIf="dataItem.fullName != dataItem.email"> ({{dataItem.email}})</span>
              </span>
            </ng-template>
          </kendo-dropdownlist>
        </div>
        <div class="col-auto">
          <button *ngIf="request?.id" class="me-2" kendoButton themeColor="secondary" (click)="openChangeLogDialog()">
            <i class="fas fa-history me-2"></i>
            {{'change_log'|i18n}}
          </button>
          <button *ngIf="request?.id" kendoButton themeColor="secondary" (click)="openCloneRequestDialog()">
            <i class="far fa-clone me-2"></i>
            {{'clone_request'|i18n}}
          </button>
          <button *ngIf="request?.id && request?.linkedRequestId" kendoButton themeColor="secondary" class="ms-2" (click)="navigateToLinkedRequest()">
            <i class="fas fa-link me-1"></i>
            <i class="fas fa-tasks me-2"></i>
            {{'open_base_request'|i18n}}
          </button>
        </div>
      </div>
    </div>
    <div class="general-information row m-0 mb-1">
      <div class="col-sm-3">
        <label>{{'contractNumber' | i18n }}</label>
        <h4 class="text-truncate" [title]="getContractNumbers()">{{getContractNumbers()}}</h4>
      </div>
      <div class="col-sm-3">
        <label>{{'lotNumber'|i18n}}</label>
        <h4 class="text-truncate" [title]="request?.lotNumber">{{request?.lotNumber ? request?.lotNumber : '-'}}</h4>
      </div>
      <div *ngIf="!request?.sspNumber" class="col-sm-3">
        <label>{{'label_workOrderNumber'|i18n}}</label>
        <h4 class="text-truncate" [title]="request?.workOrderNumber">{{request?.workOrderNumber}}</h4>
      </div>
      <div *ngIf="request?.sspNumber" class="col-sm-3">
        <label>{{'label_requestId'|i18n}}</label>
        <h4 class="text-truncate" [title]="request?.sspNumber">{{request?.sspNumber ? request?.sspNumber : '-'}}</h4>
      </div>
      <div class="col-sm-3">
        <label>{{'label_status'|i18n}}</label>
        <h4 class="text-truncate" [title]="resolveStatus(currentStatus)">
          <span>{{resolveStatus(currentStatus)}}</span>
          <span [ngSwitch]="currentStatus" class="ms-1">
            <i *ngSwitchCase="RequestStatus.ArchivedAndCancelled" class="fas fa-ban text-danger"></i>
            <i *ngSwitchCase="RequestStatus.ArchivedAndConfirmed" class="fas fa-check-circle text-success"></i>
            <i *ngSwitchCase="RequestStatus.ArchivedAndRejected" class="fas fa-times-circle text-danger"></i>
          </span>
        </h4>
      </div>
    </div>
    <div *ngIf="invalidControlHandlingPanelShouldShow" class="invalid-control-info row py-2 m-0 mb-1">
      <div class="col">
        <div class="mb-2" [innerHtml]="(isSaveAndUpgradeInProgress ? 'form_update_error_message' : 'form_save_error_message')|i18n"></div>
        <div class="d-flex align-items-center">
          <button (click)="scrollToFirstInvalidControl()" class="btn btn-danger">{{'jump_to_the_next_error' | i18n}}</button>
          <span class="ms-2">{{'error_count'|i18n}}: {{invalidControlCount}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="request-details-content">
    <form #requestForm="ngForm" class="ignore-ng-invalid">
      <kendo-panelbar (stateChange)="onPanelBarStateChange($event)" [keepItemContent]="true" #panelBar>
        <kendo-panelbar-item #requestDetailsPanel
                             title=""
                             [id]="requestDetailsId"
                             class="request-section"
                             [expanded]="isRequestDetailsExpanded"
                             [ngClass]="{'invalid': isRequestDetailsInvalid()}">
          <ng-template kendoPanelBarItemTitle>
            <span class="panelbar-item-icon me-2">
              <i class="fas fa-tasks me-1"></i>
              <i class="fas fa-info-circle"></i>
            </span>
            <span class="panelbar-item-title">{{'requestDetails'|i18n}}</span>
            <i *ngIf="!isRequestDetailsExpanded" class="panelbar-collapsed-info">{{getRequestDetailsCollapseInfo()}}</i>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <fieldset [disabled]="requestDetailsPanelShouldBeReadOnly()">
              <div class="p-3">
                <div class="row form-group">
                  <!-- Left column -->
                  <div class="col-sm-6">
                    <div class="row g-0 h-100">
                      <div class="info-card">
                        <label class="mb-2">{{'request_label_to'|i18n}}</label>
                        <div class="mb-1">{{specifierContractor?.name}}</div>
                        <div class="mb-1">
                          <div class="fst-italic">{{specifierContractor?.addressLine1}}</div>
                          <div class="fst-italic">{{specifierContractor?.addressLine2}}</div>
                        </div>
                        <div class="row">
                          <label class="col-sm-4 col-form-label text-truncate" for="specifierAttention" title="{{'request_label_to_attention'|i18n}}">
                            {{'request_label_to_attention'|i18n}}
                          </label>
                          <div class="col-sm-8">
                            <input type="text"
                                   id="specifierAttention"
                                   class="form-control"
                                   name="specifierAttention"
                                   [ngModel]="request?.specifierAttention" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Right column -->
                  <div class="col-sm-6">
                    <div class="form-group row g-0 h-100">
                      <div class="info-card">
                        <label>{{'request_label_from'|i18n}}</label>
                        <div class="mb-1">
                          {{request?.ssrUser ? request.ssrUser.fullName : ''}}
                          {{ request?.ssrUser?.company ? ', ' + request.ssrUser.company.name : ''}}
                          {{ request?.ssrUser?.email ? ' (' + request.ssrUser.email + ')' : ''}}
                        </div>
                        <div *ngIf="request?.ssrUser?.company">
                          <div class="fst-italic">{{request.ssrUser.company.addressLine1}}</div>
                          <div class="fst-italic">{{request.ssrUser.company.addressLine2}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row form-group">
                  <!-- Left column -->
                  <div class="col-sm-6">
                    <div class="row g-0 h-100">
                      <div class="info-card">
                        <label>{{'request_label_primeContractor'|i18n}}</label>
                        <div class="mb-1">{{primeContractor?.name}}</div>
                        <label>{{'request_label_primeContractorAddress'|i18n}}</label>
                        <div class="fst-italic">{{primeContractor?.addressLine1}}</div>
                        <div class="fst-italic">{{primeContractor?.addressLine2}}</div>
                      </div>
                    </div>
                  </div>
                  <!-- Right column -->
                  <div class="col-sm-6">
                    <div class="row g-0 h-100">
                      <div class="info-card">
                        <label for="manufacturer" [ngClass]="{'required-for-upgrade': isInitiallyRequiredForUpgrade() }" class="pe-3">{{'request_label_manufacturer'|i18n}}</label>
                        <div class="mb-1">
                          <kendo-autocomplete #manufacturerAutocomplete
                                              [data]="filteredManufacturers"
                                              [valueField]="'name'"
                                              [ngModel]="manufacturer.name"
                                              id="manufacturer"
                                              name="manufacturer"
                                              placeholder="{{'selectManufacturer'|i18n}}"
                                              [filterable]="true"
                                              [disabled]="manufacturerShouldBeReadOnly()"
                                              [required]="isInitiallyRequiredForUpgrade(isSaveAndUpgradeInProgress)"
                                              class="w-100"
                                              (filterChange)="filterManufacturers($event)"
                                              (focus)="openPopup(manufacturerAutocomplete)"
                                              (valueChange)="manufacturerChanged($event)">
                            <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                              <span class="company-selector-name">{{ dataItem?.name }}</span>
                              <span class="company-selector-address ps-3">{{ [dataItem?.addressLine1, dataItem?.addressLine2].join(', ') }}</span>
                            </ng-template>
                          </kendo-autocomplete>
                        </div>
                        <div class="mb-1">
                          <label>{{'request_label_manufacturerAddress'|i18n}}</label>
                          <div class="fst-italic">{{manufacturer?.addressLine1}}</div>
                          <div class="fst-italic">{{manufacturer?.addressLine2}}</div>
                        </div>
                        <div class="row">
                          <label class="col-sm-4 col-form-label text-truncate" for="purchaseOrderNumber" title="{{'poNumber'|i18n}}">
                            {{'poNumber' | i18n }}
                          </label>
                          <div class="col-sm-8">
                            <input type="text"
                                   id="purchaseOrderNumber"
                                   class="form-control"
                                   name="purchaseOrderNumber"
                                   [ngModel]="request.purchaseOrderNumber" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <!-- Left column -->
                  <label class="col-sm-2 col-form-label text-truncate" title="{{'request_label_endItemOrProductName'|i18n}}">
                    {{'request_label_endItemOrProductName'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <div class="info-card text-truncate h-38" title="{{endItems}}">
                      {{endItems}}
                    </div>
                  </div>
                  <!-- Right column -->
                  <label class="col-sm-2 col-form-label text-truncate" title="{{'request_label_sampleNo'|i18n}}">
                    {{'request_label_sampleNo'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <div class="info-card h-38 text-truncate" title="{{getSampleNumbers()}}">
                      <span *ngIf="currentStatus > RequestStatus.PendingSsrApproval">{{getSampleNumbers()}}</span>
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <!-- Left column -->
                  <label class="col-sm-2 col-form-label text-truncate" for="reasonForSubmittal" title="{{'request_label_reasonForSubmittal'|i18n}}">
                    {{'request_label_reasonForSubmittal'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <kendo-dropdownlist #reasonForSubmittal
                                        [data]="reasonsForSubmittal"
                                        textField="text"
                                        valueField="value"
                                        id="reasonForSubmittal"
                                        name="reasonForSubmittal"
                                        [ngModel]="request.reasonForSubmittal"
                                        (valueChange)="onReasonForSubmittalChange($event, reasonForSubmittal)"
                                        [valuePrimitive]="true"
                                        [disabled]="!isReasonForSubmittalEditable()"
                                        class="w-100">
                    </kendo-dropdownlist>
                  </div>
                  <!-- Right column -->
                  <label class="col-sm-2 col-form-label text-truncate" for="submitDate" title="{{'request_label_dateSubmitted'|i18n}}">
                    {{'request_label_dateSubmitted'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <div id="submitDate" class="info-card h-38">
                      <ng-container *ngIf="request?.submitDate">{{request?.submitDate | date: dateFormatString}}</ng-container>
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <!-- Left column -->
                  <label class="col-sm-2 col-form-label text-truncate"
                         for="materialToBeTested"
                         title="{{'request_label_materialToBeTested'|i18n}}"
                         [ngClass]="{'required-for-upgrade': requestDetailsPanelControlsShouldBeRequiredForUpgrade() }">
                    {{'request_label_materialToBeTested'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <input type="text"
                           id="materialToBeTested"
                           class="form-control"
                           name="materialToBeTested"
                           [ngModel]="request.materialToBeTested"
                           [required]="requestDetailsPanelControlsShouldBeRequiredForUpgrade(isSaveAndUpgradeInProgress)" />
                  </div>
                  <!-- Right column -->
                  <label class="col-sm-2 col-form-label text-truncate"
                         for="plannedTestingDate"
                         title="{{'request_label_plannedTestingDate'|i18n}}"
                         [ngClass]="{'required-for-upgrade': isInitiallyRequiredForUpgradeForRegularWorkflows() }">
                    {{'request_label_plannedTestingDate'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <kendo-datepicker #plannedTestingDate
                                      id="plannedTestingDate"
                                      name="plannedTestingDate"
                                      [ngModel]="plannedTestingDateValue"
                                      [format]="dateFormatString"
                                      [disabled]="isPlannedTestingDateShouldBeReadonly()"
                                      [min]="getFirstAvailableTestingDate()"
                                      [required]="isInitiallyRequiredForUpgradeForRegularWorkflows(isSaveAndUpgradeInProgress)"
                                      class="w-100">
                    </kendo-datepicker>
                    <div class="alert alert-danger" *ngIf="isPlannedTestingDateMinError()">{{plannedTestingDateMinErrorMessage}}</div>
                  </div>
                </div>
                <div class="row mb-1">
                  <!-- Left column -->
                  <label class="col-sm-2 col-form-label text-truncate" title="{{'quantitySubmitted'|i18n}} ({{'fromSampleInformationPanel'|i18n}})">
                    {{'quantitySubmitted'|i18n}}
                  </label>
                  <div class=" col-sm-4">
                    <div class="info-card h-38">
                      <span *ngIf="request?.quantitySubmitted && currentStatus > RequestStatus.PendingSsrApproval">{{request?.quantitySubmitted}}</span>
                    </div>
                  </div>
                  <!-- Right column -->
                  <label class="col-sm-2 col-form-label text-truncate"
                         for="quantityRepresented"
                         title="{{'request_label_quantityRepresented'|i18n}}"
                         [ngClass]="{'required-for-upgrade': requestDetailsPanelControlsShouldBeRequiredForUpgrade() }">
                    {{'request_label_quantityRepresented'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control"
                           id="quantityRepresented"
                           name="quantityRepresented"
                           [ngModel]="request.quantityRepresented"
                           [required]="requestDetailsPanelControlsShouldBeRequiredForUpgrade(isSaveAndUpgradeInProgress)" />
                  </div>
                </div>
                <div class="row mb-1">
                  <!-- Left column -->
                  <label class="col-sm-2 col-form-label text-truncate"
                         for="governmentSpecificationNumber"
                         [ngClass]="{'required-for-save': requestDetailsPanelControlsShouldBeRequiredForSave() }"
                         title="{{'request_label_specAndAmend'|i18n}}">
                    {{'request_label_specAndAmend'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <input type="text"
                           id="governmentSpecificationNumber"
                           class="form-control"
                           name="governmentSpecificationNumber"
                           [required]="requestDetailsPanelControlsShouldBeRequiredForSave()"
                           [ngModel]="request.governmentSpecificationNumber" />
                  </div>
                  <!-- Right column -->
                  <label class="col-sm-2 col-form-label text-truncate" for="purchasedFromOrSource" title="{{'request_label_purchasedFromOrSource'|i18n}}">
                    {{'request_label_purchasedFromOrSource'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control"
                           id="purchasedFromOrSource"
                           name="purchasedFromOrSource"
                           [ngModel]="request.purchasedFromOrSource" />
                  </div>
                </div>
                <div class="row mb-1">
                  <!-- Left column -->
                  <label class="col-sm-2 col-form-label text-truncate" for="shipmentMethod" title="{{'request_label_shipmentMethod'|i18n}}">
                    {{'request_label_shipmentMethod'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control"
                           id="shipmentMethod"
                           name="shipmentMethod"
                           [ngModel]="request.shipmentMethod" />
                  </div>
                  <!-- Right column -->
                  <label class="col-sm-2 col-form-label text-truncate" for="dateSampledAndSubmittedBy" title="{{'request_label_dateSampledAndSubmittedBy'|i18n}}">
                    {{'request_label_dateSampledAndSubmittedBy'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <div class="info-card h-38 text-truncate"
                         [title]="getDateSampledAndSubmittedBy()">
                      {{getDateSampledAndSubmittedBy()}}
                    </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <!-- Left column -->
                  <label class="col-sm-2 col-form-label text-truncate" for="nationalStockNumber" title="{{'request_label_nationalStockNumber'|i18n}}">
                    {{'request_label_nationalStockNumber'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control"
                           id="nationalStockNumber"
                           name="nationalStockNumber"
                           [ngModel]="request.nationalStockNumber" />
                  </div>
                  <!-- Right column -->
                  <label class="col-sm-2 col-form-label text-truncate" for="partNumber" title="{{'request_label_partNumber'|i18n}}">
                    {{'request_label_partNumber'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control"
                           id="partNumber"
                           name="partNumber"
                           [ngModel]="request.partNumber" />
                  </div>
                </div>
                <div class="row mb-1">
                  <!-- Left column -->
                  <label class="col-sm-2 col-form-label text-truncate"
                         for="dateOfManufacture"
                         title="{{'request_label_dateOfManufacture'|i18n}}">
                    {{'request_label_dateOfManufacture'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <kendo-datepicker id="dateOfManufacture"
                                      name="dateOfManufacture"
                                      [ngModel]="dateOfManufactureValue"
                                      [format]="dateFormatString"
                                      [disabled]="requestDetailsPanelShouldBeReadOnly()"
                                      class="w-100">
                    </kendo-datepicker>
                  </div>
                  <!-- Right column -->

                </div>
                <div class="row mb-1">
                  <!-- Left column -->
                  <label class="col-sm-2 col-form-label text-truncate" for="engineeringAuthority" title="{{'request_label_engineeringAuthority'|i18n}}">
                    {{'request_label_engineeringAuthority'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control"
                           id="engineeringAuthority"
                           name="engineeringAuthority"
                           [ngModel]="request.engineeringAuthority" />
                  </div>
                  <!-- Right column -->
                  <label class="col-sm-2 col-form-label text-truncate" for="mqcssQslReview" title="{{'request_label_mqcssQslReview'|i18n}}">
                    {{'request_label_mqcssQslReview'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <kendo-switch id="mqcssQslReview"
                                  name="mqcssQslReview"
                                  class="mqcssQslReview mt-1"
                                  size="small"
                                  [onLabel]="yesAbbreviationText"
                                  [offLabel]="noAbbreviationText"
                                  [ngModel]="request.mqcssQslReview"
                                  [disabled]="requestDetailsPanelShouldBeReadOnly()">
                    </kendo-switch>
                  </div>
                </div>
                <div class="row mb-1">
                  <!-- Left column -->
                  <label class="col-sm-2 col-form-label text-truncate" for="specialInstructions" title="{{'request_label_specialInstructions'|i18n}}">
                    {{'request_label_specialInstructions'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control"
                           id="specialInstructions"
                           name="specialInstructions"
                           [ngModel]="request.specialInstructions" />
                  </div>
                  <!-- Right column -->
                  <label class="col-sm-2 col-form-label text-truncate" for="additionalRecipients" title="{{'request_label_additionalRecipients'|i18n}}">
                    {{'request_label_additionalRecipients'|i18n}}
                  </label>
                  <div class="col-sm-4">
                    <input type="text"
                           class="form-control"
                           id="additionalRecipients"
                           name="additionalRecipients"
                           [ngModel]="request.additionalRecipients" />
                  </div>
                </div>
                <div class="row mb-1">
                  <!-- Left column -->
                  <div class="col-sm-6">
                    <div class="form-group row g-0">
                      <div class="info-card">
                        <label for="laboratory">{{'request_label_laboratory'|i18n}}</label>
                        <kendo-autocomplete #laboratoryAutocomplete
                                            [data]="filteredLaboratories"
                                            [valueField]="'name'"
                                            [ngModel]="laboratory.name"
                                            id="laboratory"
                                            name="laboratory"
                                            placeholder="{{'select3rdPartyLaboratory'|i18n}}"
                                            [filterable]="true"
                                            [disabled]="requestDetailsPanelShouldBeReadOnly()"
                                            class="w-100"
                                            (filterChange)="filterLaboratories($event)"
                                            (focus)="openPopup(laboratoryAutocomplete)"
                                            (valueChange)="laboratoryChanged($event)">
                          <ng-template kendoAutoCompleteItemTemplate let-dataItem>
                            <span class="company-selector-name">{{ dataItem?.name }}</span>
                            <span class="company-selector-address ps-3">{{ [dataItem?.addressLine1, dataItem?.addressLine2].join(', ') }}</span>
                          </ng-template>
                        </kendo-autocomplete>
                      </div>
                    </div>
                  </div>
                  <!-- Right column -->
                  <div class="col-sm-6">
                    <div class="row mb-1">
                      <label class="col-sm-4 col-form-label text-truncate" title="{{'ptcAttendance'|i18n}}">
                        {{'ptcAttendance' | i18n}}
                      </label>
                      <div class="col-sm-8">
                        <div class="info-card">{{ getSpecifierObservationTypeText(request?.willSpecifierObserve) }}</div>
                      </div>
                    </div>
                    <div class="row">
                      <label class="col-sm-4 col-form-label text-truncate" title="{{'correlationSamplesRequested'|i18n}}">
                        {{'correlationSamplesRequested' | i18n}}
                      </label>
                      <div class="col-sm-8">
                        <div class="info-card">{{ getYesNoUndefinedIndicatorText(request?.correlationRequired) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </ng-template>
        </kendo-panelbar-item>
        <kendo-panelbar-item #testRequirementsPanel
                             *ngIf="testRequirementsPanelShouldShow()"
                             [id]="testRequirementsId"
                             class="request-section"
                             title=""
                             [expanded]="isTestRequirementsExpanded"
                             [ngClass]="{'invalid': isTestRequirementsInvalid()}">
          <ng-template kendoPanelBarItemTitle>
            <span class="panelbar-item-icon me-2">
              <i class="fas fa-flask me-1"></i>
              <i class="fas fa-clipboard-list"></i>
            </span>
            <span class="panelbar-item-title">{{'testRequirements'|i18n}}</span>
            <i class="panelbar-collapsed-info" *ngIf="!isTestRequirementsExpanded && request.testRequirements && request.testRequirements.length > 0">({{request?.testRequirements? request?.testRequirements.length: 0}})</i>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <div class="p-3">
              <pdp-test-requirements #testRequirementsComponent
                                     [request]="request"
                                     [reasonForSubmittalChanged]="reasonForSubmittalChanged$"
                                     [readonly]="testRequirementsPanelShouldBeReadOnly()"
                                     [indicatorsRequired]="testRequirementsPanelControlsShouldBeRequiredForUpgrade()"
                                     [controlsRequired]="testRequirementsPanelControlsShouldBeRequiredForUpgrade(isSaveAndUpgradeInProgress)">
              </pdp-test-requirements>
            </div>
          </ng-template>
        </kendo-panelbar-item>
        <kendo-panelbar-item #sampleInformationPanel
                             [id]="sampleInformationId"
                             class="request-section" title=""
                             [expanded]="isSampleInformationExpanded"
                             [ngClass]="{'invalid': isSampleInformationsInvalid()}">
          <ng-template kendoPanelBarItemTitle>
            <span class="panelbar-item-icon me-2">
              <i class="fas fa-eye-dropper me-1"></i>
              <i class="fas fa-info-circle"></i>
            </span>
            <span class="panelbar-item-title">{{'sampleInformation'|i18n}}</span>
            <i *ngIf="!isSampleInformationExpanded" class="panelbar-collapsed-info">{{getSampleInformationCollapseInfo()}}</i>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <div class="p-3" [ngClass]="{'highlighted-element': isSampleInformationExpanded}">
              <pdp-sample-information [request]="request"
                                      [readonly]="samplesPanelShouldBeReadOnly()"
                                      [indicatorsRequired]="samplesPanelControlsShouldBeRequiredForUpgrade()"
                                      [controlsRequired]="samplesPanelControlsShouldBeRequiredForUpgrade(isSaveAndUpgradeInProgress)"
                                      [showSampleReceivedDate]="sampleReceivedDateShouldShow()"
                                      [isSampleReceivedDateRequired]="sampleReceivedDateShouldBeRequired()"
                                      [isLotNumberReadonly]="isLotNumberShouldBeReadonly()"
                                      [isLotNumberRequired]="isInitiallyRequiredForUpgrade()"
                                      [dateFormatString]="dateFormatString">
              </pdp-sample-information>
            </div>
          </ng-template>
        </kendo-panelbar-item>
        <kendo-panelbar-item #ptcResponsePanel
                             *ngIf="ptcResponsePanelShouldShow()"
                             [id]="ptcResponseId"
                             class="request-section"
                             title=""
                             [expanded]="isPtcResponseExpanded">
          <ng-template kendoPanelBarItemTitle>
            <span class="panelbar-item-icon me-2">
              <i class="fas fa-reply"></i>
            </span>
            <span class="panelbar-item-title">{{'ptcResponse'|i18n}}</span>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <div class="p-3">
              <pdp-ptc-response [request]="request" [readonly]="ptcResponsePanelShouldBeReadOnly()"></pdp-ptc-response>
            </div>
          </ng-template>
        </kendo-panelbar-item>
        <kendo-panelbar-item #testDetailsPanel
                             *ngIf="testDetailsPanelShouldShow()"
                             [id]="testDetailsId"
                             class="request-section"
                             title=""
                             [expanded]="isTestDetailsExpanded"
                             [ngClass]="{'invalid': isTestDetailsInvalid()}">
          <ng-template kendoPanelBarItemTitle>
            <span class="panelbar-item-icon me-2">
              <i class="fas fa-flask me-1"></i>
              <i class="fas fa-clipboard-check"></i>
            </span>
            <span class="panelbar-item-title">{{'testDetails'|i18n}}</span>
            <i class="panelbar-collapsed-info">{{getTestDetailsCollapseInfo()}}</i>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <div class="p-3">
              <pdp-test-details #testDetailsComponent
                                [request]="request"
                                [readonly]="testResultsPanelShouldBeReadOnly()"
                                [indicatorsRequired]="testDetailsPanelControlsShouldBeRequiredForUpgrade()"
                                [controlsRequired]="testDetailsPanelControlsShouldBeRequiredForUpgrade(isSaveAndUpgradeInProgress)">
              </pdp-test-details>
            </div>
          </ng-template>
        </kendo-panelbar-item>
        <kendo-panelbar-item #ptcReviewPanel
                             *ngIf="ptcReviewPanelShouldShow()"
                             [id]="ptcReviewId"
                             class="request-section"
                             title=""
                             [expanded]="isPtcReviewExpanded"
                             [ngClass]="{'invalid': isPtcReviewInvalid()}">
          <ng-template kendoPanelBarItemTitle>
            <span class="panelbar-item-icon me-2">
              <i class="fas fa-stamp me-1"></i>
              <i class="fas fa-check"></i>
            </span>
            <span class="panelbar-item-title">{{'ptcReview'|i18n}}</span>
          </ng-template>
          <ng-template kendoPanelBarContent>
            <div #ptcReviewTarget class="p-3">
              <pdp-ptc-review [request]="request"
                              [readonly]="ptcReviewPanelShouldBeReadOnly()"
                              [indicatorsRequired]="ptcReviewPanelControlsShouldBeRequiredForUpgrade()"
                              [controlsRequired]="ptcReviewPanelControlsShouldBeRequiredForUpgrade(isSaveAndUpgradeInProgress)"></pdp-ptc-review>
            </div>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>
    </form>
  </div>
  <div class="pt-2">
    <div class="request-details-action-bar">
      <button kendoButton
              themeColor="success"
              *ngIf="confirmOrRejectTestResultButtonsShouldShow()"
              [disabled]="isSavingInProgress || isValidatingInProgress"
              class="ms-1"
              (click)="onSave(SaveType.ConfirmResults)">
        <i class="fas fa-check-circle me-2"></i>
        {{'confirmResults' | i18n}}
      </button>
      <button kendoButton
              themeColor="error"
              *ngIf="confirmOrRejectTestResultButtonsShouldShow()"
              [disabled]="isSavingInProgress || isValidatingInProgress"
              class="ms-1"
              (click)="onSave(SaveType.RejectResults)">
        <i class="fas fa-times-circle me-2"></i>
        {{'rejectResults' | i18n}}
      </button>
      <button kendoButton
              themeColor="success"
              *ngIf="confirmOrRejectRequestButtonsShouldShow()"
              [disabled]="isSavingInProgress || isValidatingInProgress"
              class="ms-1"
              (click)="onSave(SaveType.ConfirmRequest)">
        <i class="fas fa-check-circle me-2"></i>
        {{'confirmRequest' | i18n}}
      </button>
      <button kendoButton
              themeColor="error"
              *ngIf="confirmOrRejectRequestButtonsShouldShow()"
              [disabled]="isSavingInProgress || isValidatingInProgress"
              class="ms-1"
              (click)="onSave(SaveType.RejectRequest)">
        <i class="fas fa-times-circle me-2"></i>
        {{'rejectRequest' | i18n}}
      </button>
      <button kendoButton
              themeColor="secondary"
              *ngIf="saveAndUpgradeStatusButtonShouldShow()"
              class="ms-1"
              [disabled]="isSavingInProgress || isValidatingInProgress"
              (click)="onSave(SaveType.SaveAndUpgradeStatus)">
        <i class="fas fa-save me-1"></i>
        <i class="fas fa-forward me-2"></i>
        {{'saveChanges' | i18n}} {{'and' | i18n}} {{resolveActionByStatus()}}
      </button>
      <button kendoButton
              themeColor="secondary"
              *ngIf="saveButtonShouldShow()"
              [disabled]="isSavingInProgress || isValidatingInProgress"
              class="ms-1"
              (click)="onSave(SaveType.SaveChanges)">
        <i class="fas fa-save me-2"></i>
        {{'saveChanges' | i18n}}
      </button>
      <button kendoButton
              themeColor="error"
              *ngIf="cancelRequestButtonShouldShow()"
              [disabled]="isSavingInProgress || isValidatingInProgress"
              class="ms-1"
              (click)="onSave(SaveType.CancelRequest)">
        <i class="fas fa-ban me-2"></i>
        {{'cancelRequest' | i18n}}
      </button>
      <button kendoButton
              themeColor="error"
              *ngIf="request?.id && currentStatus === RequestStatus.ArchivedAndCancelled"
              class="ms-1"
              (click)="deleteRequest()">
        <i class="fas fa-trash-alt me-2"></i>
        {{'delete_request'|i18n}}
      </button>
      <span [title]="getTooltipWhenDownloadDD1222SectionAButtonIsDisabled()">
        <button kendoButton
                themeColor="secondary"
                class="ms-1 h-100"
                *ngIf="downloadDD1222SectionAButtonShouldShow()"
                [disabled]="isSavingInProgress || isValidatingInProgress || downloadDD1222SectionAButtonShouldBeDisabled()"
                (click)="downloadDD1222SectionA()">
          <i class="fas fa-file-download me-2"></i>
          {{ 'downloadDD1222SectionA' | i18n}}
        </button>
      </span>
      <button kendoButton
              themeColor="secondary"
              class="ms-1"
              *ngIf="currentStatus >= RequestStatus.PendingSpecifierApproval"
              [disabled]="isSavingInProgress || isValidatingInProgress"
              (click)="downloadDD1222()">
        <i class="fas fa-file-download me-2"></i>
        {{ (currentStatus === RequestStatus.PendingSpecifierApproval ? 'downloadDD1222Preview' : 'downloadDD1222') | i18n}}
      </button>
      <div class="required-legend alert alert-info col-sm-4">
        <div>
          <label class="me-3">{{'legend' | i18n}}:</label>
          <i class="fas fa-save icon me-1"></i><span class="me-3">{{'required_to_save_changes' | i18n}}</span>
          <i class="fas fa-forward icon me-1"></i><span class="me-2">{{'required_for_status_upgrade' | i18n}}</span>
        </div>
        <div>({{'symbol_in_header_info' | i18n}})</div>
      </div>
    </div>
  </div>
</div>
