import { Optional, Provider } from "@angular/core";
import { ControlContainer, NgForm, NgModelGroup } from "@angular/forms";

export const formProvider: Provider = {
  provide: ControlContainer,
  useFactory: _formProviderFactory,
  deps: [
    [new Optional(), NgForm],
    [new Optional(), NgModelGroup]
  ]
};

export function _formProviderFactory(
  ngForm: NgForm, ngModelGroup: NgModelGroup
) {
  return ngModelGroup || ngForm || null;
}
