import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Company } from '../models/companies/company.model';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  private httpOptions;

  private companyUrl = environment.apiBaseUrl + 'api/v1/Company'; // URL to web api

  constructor(private http: HttpClient,
    private authService: AuthService) {
    this.authService.getAccessToken().subscribe(token => {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': token
        })
      };
    });
  }

  /*
  * Get all Companies.
   * They merged with companies from Portal
  */
  allCompanies(): Observable<Company[]> {
      const pdpUrl = `${this.companyUrl}/GetAllCompanies`;
      // get PDP company
    return this.http.get<Company[]>(pdpUrl, { headers: this.httpOptions.headers, observe: 'body' });
  }

  getCompaniesOfContracts(contractIds: number[]): Observable<Company[]> {
    const queryParams = `contractIds=${contractIds.join('&contractIds=')}`;
    const pdpUrl = `${this.companyUrl}/GetSupplierCompaniesOfContracts?${queryParams}`;
    return this.http.get<Company[]>(pdpUrl, { headers: this.httpOptions.headers, observe: 'body' });
  }
}
