/**
 * View model for a company.
 */
export class Company {
  id: number;
  externalId: string;
  name: string;
  addressLine1: string;
  addressLine2: string;
  logoUrl: string;
}
