import { Component, OnInit } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CellClickEvent, GridDataResult, RowClassArgs, RowClassFn } from '@progress/kendo-angular-grid';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { MessageBoxType } from '../shared/components/message-box/enums/message-box-type.enum';
import { MessageBoxService } from '../shared/components/message-box/message-box.service';
import { NGridFilterMethod } from '../shared/components/n-grid/enums/n-grid-filter-method.enum';
import { INGridColumnOptionsBase } from '../shared/components/n-grid/interfaces/i-n-grid.column-options.interface';
import { NGridColumnOptions } from '../shared/components/n-grid/models/n-grid-column-options.model';
import { NGridOptions } from '../shared/components/n-grid/models/n-grid-options.model';
import { NGridPagedData } from '../shared/components/n-grid/models/n-grid-paged-data.model';
import { NGridState } from '../shared/components/n-grid/models/n-grid-state.model';
import { TestMethodGroupItem } from '../shared/models/test-method-groups/test-method-group-item.model';
import { TestMethodGroup } from '../shared/models/test-method-groups/test-method-group.model';
import { AuthorizationService } from '../shared/services/authorization.service';
import { I18nService } from '../shared/services/i18n.service';
import { SessionService } from '../shared/services/session.service';
import { SortingService } from '../shared/services/sorting.service';
import { TestMethodGroupsService } from '../shared/services/test-method-groups.service';
import { TestMethodGroupDetailsComponent } from './test-method-group-details/test-method-group-details.component';

@Component({
  selector: 'pdp-test-method-groups',
  templateUrl: './test-method-groups.component.html',
  styleUrls: ['./test-method-groups.component.css'],
  host: { class: 'flex-column-layout w-100' }
})
export class TestMethodGroupsComponent implements OnInit {
  isLoading = false;
  isError = false;
  gridData$: Observable<GridDataResult>;
  private gridStateSubject: BehaviorSubject<NGridState>;

  masterGridColumns: INGridColumnOptionsBase[] = [
    new NGridColumnOptions({
      field: 'name',
      headerClass: 'method-group-header',
      title: this.i18nService.transform('test_method_group_name')
    }),
    new NGridColumnOptions({
      field: 'actions',
      title: this.i18nService.transform('label_actions'),
      sortable: false,
      width: 70
    })
  ];

  detailGridColumns: INGridColumnOptionsBase[] = [
    new NGridColumnOptions({
      field: 'testMethodVariant.summary',
      headerClass: 'method-group-header',
      title: `${this.i18nService.transform('name')} - ${this.i18nService.transform('title')} (${this.i18nService.transform('specification_summary')})`,
      sortable: false
    })
  ];

  masterGridOptions: NGridOptions = new NGridOptions({
    isPageable: true,
    isSortable: true,
    initialLoading: true,
    selectable: false
  });

  detailGridOptions: NGridOptions = new NGridOptions({
    isPageable: false,
    isSortable: true,
    initialLoading: false,
    selectable: false
  });

  gridState: NGridState = {
    take: 50,
    filterMethod: NGridFilterMethod.FreeTextSearch,
    freeTextSearch: '',
    sort: [{
      field: 'name',
      dir: 'asc'
    }]
  };

  noRecordsText(): string {
    return !this.isLoading && this.isError ? this.i18nService.transform('message_no_record') : '. . .';
  };

  constructor(
    private testMethodGroupsService: TestMethodGroupsService,
    private sessionService: SessionService,
    private authorizationService: AuthorizationService,
    private toastr: ToastrService,
    private dialogService: DialogService,
    private messageBoxService: MessageBoxService,
    private sortingService: SortingService,
    public i18nService: I18nService) { }

  ngOnInit() {
    this.gridStateSubject = new BehaviorSubject<NGridState>(this.gridState);
    this.gridData$ = this.gridStateSubject.pipe(
      switchMap(state => this.getTestMethodGroups(state))
    );
  }

  private getTestMethodGroups(state: NGridState): Observable<NGridPagedData<TestMethodGroup>> {
    this.isLoading = true;

    return this.testMethodGroupsService.getTestMethodGroups(state).pipe(
      tap(() => {
        this.isLoading = false;
      }),
      catchError((error, caught) => {
        this.toastr.error(error.message);
        this.isLoading = false;
        this.isError = true;

        return EMPTY;
      })
    );
  }

  getDetailGridData(testMethodGroup: TestMethodGroup) {
    if (testMethodGroup && testMethodGroup.testMethodGroupItems) {
      const gridData = new NGridPagedData<TestMethodGroupItem>();
      gridData.data = testMethodGroup.testMethodGroupItems.sort(this.sortingService.compareBySortIndex);
      gridData.total = testMethodGroup.testMethodGroupItems.length;
      return gridData;
    }

      return null;
  }

  freeTextSearchChange(freeTextSearchValue) {
    this.gridState.freeTextSearch = freeTextSearchValue;
    this.gridStateSubject.next(this.gridState);
  }

  gridStateChange(state: NGridState): void {
    var freeTextSearchValue = this.gridState.freeTextSearch;
    this.gridState = state;
    this.gridState.freeTextSearch = freeTextSearchValue;
    this.gridStateSubject.next(this.gridState);
  }

  cellClick(e: CellClickEvent): void {
    if (e.columnIndex < 1) {
      this.openTestMethodGroupDetailsDialog(e.dataItem);
    }
  }

  stylizeRows: RowClassFn = (context: RowClassArgs) => {
    return 'clickable';
  };

  openCreateTestMethodGroupDialog(clone?: TestMethodGroup) {
    const dialogRef = this.dialogService.open({
      content: TestMethodGroupDetailsComponent
    });
    const instance = dialogRef.content.instance;

    const testMethodGroup = this.initializeTestMethodGroup(clone);
    instance.testMethodGroup = testMethodGroup;
    instance.actionName = this.i18nService.transform('create');
    instance.title = clone ? this.i18nService.transform('clone_test_method_group') : this.i18nService.transform('new_test_method_group');
    instance.isReadonly = false;

    dialogRef.result.subscribe(result => {
      if (result === 'saved') {
        this.gridStateSubject.next(this.gridState);
        this.toastr.success(this.i18nService.transform('test_method_group_created'));
      }
      else if (result === 'clone') {
        this.openCreateTestMethodGroupDialog(testMethodGroup);
      }
    });
  }

  openTestMethodGroupDetailsDialog(testMethodGroup: TestMethodGroup) {
    const dialogRef = this.dialogService.open({
      content: TestMethodGroupDetailsComponent,
    });

    const instance = dialogRef.content.instance;
    instance.testMethodGroup = JSON.parse(JSON.stringify(testMethodGroup));
    instance.actionName = this.i18nService.transform('update');
    instance.title = this.i18nService.transform('test_method_group_details');
    instance.readonly = this.isReadonly(testMethodGroup);

    dialogRef.result.subscribe(result => {
      if (result === 'saved') {
        this.gridStateSubject.next(this.gridState);
        this.toastr.success(this.i18nService.transform('test_method_group_updated'));
      }
      else if (result === 'clone') {
        this.openCreateTestMethodGroupDialog(testMethodGroup);
      }
    });
  }

  private initializeTestMethodGroup(clone?: TestMethodGroup): TestMethodGroup {
    let testMethodGroup: TestMethodGroup;
    if (clone) {
      testMethodGroup = Object.assign({}, clone);
      testMethodGroup.id = null;
      testMethodGroup.companyId = null;
      testMethodGroup.name = this.i18nService.transform('clone_of') + testMethodGroup.name;
      testMethodGroup.testMethodGroupItems = [];
    } else {
      testMethodGroup = new TestMethodGroup();
      testMethodGroup.companyId = null;
      testMethodGroup.name = '';
      testMethodGroup.testMethodGroupItems = [];
    }

    return testMethodGroup;
  }

  isReadonly(testMethodGroup: TestMethodGroup) {
    const user = this.sessionService.currentUser;
    return !this.authorizationService.isSystemOrPortalAdmin(user) && testMethodGroup.companyId === null;
  }

  public deleteTestMethodGroup(testMethodGroup: TestMethodGroup): void {
    this.messageBoxService.open({
      title: this.i18nService.transform('message_box_title_confirm'),
      message: this.i18nService.transform('test_method_group_delete_confirm_message'),
      type: MessageBoxType.Warning,
      showIcon: true,
      confirmButtonText: this.i18nService.transform('yes'),
      denyButtonText: this.i18nService.transform('no'),
      onConfirm: () => {
        this.testMethodGroupsService.deleteTestMethodGroup(testMethodGroup).subscribe(result => {
          this.gridStateSubject.next(this.gridState);
          this.toastr.success(this.i18nService.transform('test_method_group_deleted_message'));
        });
      }
    });
  }
}
