import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor() { }

  getDateFormatString() {
    return "MM/dd/yyyy";
  }

  getDateTimeFormatString() {
    return "MM/dd/yyyy h:mm a";
  }

  // Formats the number according to the current locale and gets the decimal separator from the formatted string
  getDecimalSeparator() {
    var n = 1.1;
    return n.toLocaleString().substring(1, 2);
  }
}
