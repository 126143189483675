<nav id="sidebar" [ngClass]="{'collapsed' : isCollapsed}">
  <div class="nav-top">   
    <span id="sidebarCollapse" class="btn-collapse" (click)="toggle()">
      <i class="fas fa-angle-double-left"></i>
    </span>
  </div>
  <ul class="list-unstyled">
    <li [routerLinkActive]="['active']"
        [routerLinkActiveOptions]="{ exact: true }">
      <a [routerLink]="['/']">
        <span class="menu-icon">
          <i class="fas fa-home"></i>
        </span>
        <span class="menu-title">{{'nav_menu_home_label_text'|i18n}}</span>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a [routerLink]="['/contracts']">
        <span class="menu-icon">
          <i class="fas fa-file-contract"></i>
        </span>
        <span class="menu-title">{{'nav_menu_contracts_label_text'|i18n}}</span>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a [routerLink]="['/requests']">
        <span class="menu-icon">
          <i class="fas fa-tasks"></i>
        </span>
        <span class="menu-title">{{'nav_menu_requests_label_text'|i18n}}</span>
      </a>
    </li>
    <li [routerLinkActive]="['active']" *ngIf="isSystemOrPortalAdmin">
      <a [routerLink]="['/testMethods']">
        <span class="menu-icon">
          <i class="fas fa-flask"></i>
        </span>
        <span class="menu-title">{{'nav_menu_test_methods_label_text'|i18n}}</span>
      </a>
    </li>
    <li [routerLinkActive]="['active']">
      <a [routerLink]="['/testMethodGroups']">
        <span class="menu-icon">
          <span class="method-groups-icon-wrapper">
            <i class="fas fa-flask third"></i>
            <i class="fas fa-flask second"></i>
            <i class="fas fa-flask first"></i>
          </span>
        </span>
        <span class="menu-title">{{'nav_menu_test_method_groups_label_text'|i18n}}</span>
      </a>
    </li>
    <li>
      <a href="{{portalUrl}}" target="_blank" rel="noopener">
        <span class="menu-icon">
          <i class="fas fa-user-cog"></i>
        </span>
        <span class="menu-title">{{'nav_menu_userManagement_label_text'|i18n}}</span>
      </a>
    </li>
  </ul>
</nav>
