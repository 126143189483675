<div class="row mb-2">
  <div class="col">
    <kendo-panelbar [keepItemContent]="true">
      <kendo-panelbar-item #requestDetailsPanel
                           title="Instructions">
        <ng-template kendoPanelBarContent>
          <div class="info-card-light instructions">
            <div class="mb-2">
              {{'addSampleInstructions_step_1'|i18n}}
            </div>
            <div class="mb-2">
              {{'addSampleInstructions_step_2'|i18n}}
            </div>
            <div>
              {{'addSampleInstructions_step_3'|i18n}}
            </div>
          </div>
        </ng-template>
      </kendo-panelbar-item>
    </kendo-panelbar>
  </div>
</div>
<div class="row mb-2">
  <div class="col-sm-9 d-flex">
    <div class="info-card flex-column-layout">
      <div>
        <label>{{'supplierRollNumbers'|i18n}}:</label>
      </div>
      <div class="flex-column-layout flex-grow-1">
        <textarea kendoTextArea
                  id="supplierRollNumbersText"
                  [ngModel]="supplierRollNumbersText"
                  (ngModelChange)="supplierRollNumbersTextChange($event)">
        </textarea>
      </div>
    </div>
  </div>
  <div class="col-sm-3 ps-0">
    <div class="info-card">
      <label>{{'selectDelimiter'|i18n}}:</label>
      <div>
        <input kendoRadioButton
               type="radio"
               id="delimiter_lineBreak"
               name="delimiter"
               [value]="Delimiter.LineBreak"
               [(ngModel)]="delimiter"
               (change)="splitValues()"
               class="me-1" />
        <label for="delimiter_lineBreak">Line break</label>
      </div>
      <div>
        <input kendoRadioButton
               type="radio"
               id="delimiter_tabulator"
               name="delimiter"
               [value]="Delimiter.Tabulator"
               [(ngModel)]="delimiter"
               (change)="splitValues()"
               class="me-1" />
        <label for="delimiter_tabulator">Tabulator</label>
      </div>
      <div>
        <input kendoRadioButton
               type="radio"
               id="delimiter_space"
               name="delimiter"
               [value]="Delimiter.Space"
               [(ngModel)]="delimiter"
               (change)="splitValues()"
               class="me-1" />
        <label for="delimiter_space">Space</label>
      </div>
      <div>
        <input kendoRadioButton
               type="radio"
               id="delimiter_comma"
               name="delimiter"
               [value]="Delimiter.Comma"
               [(ngModel)]="delimiter"
               (change)="splitValues()"
               class="me-1" />
        <label for="delimiter_comma">,</label>
      </div>
      <div>
        <input kendoRadioButton
               type="radio"
               id="delimiter_semicolon"
               name="delimiter"
               [value]="Delimiter.Semicolon"
               [(ngModel)]="delimiter"
               (change)="splitValues()"
               class="me-1" />
        <label for="delimiter_semicolon">;</label>
      </div>
      <div class="d-flex align-items-center">
        <input kendoRadioButton
               type="radio"
               id="delimiter_userDefined"
               name="delimiter"
               [value]="Delimiter.UserDefined"
               [(ngModel)]="delimiter"
               (change)="splitValues()"
               class="me-1" />
        <label class="me-1" for="delimiter_userDefined">{{'custom'|i18n}}</label>
        <input kendoTextBox
               type="text"
               [disabled]="delimiter!==Delimiter.UserDefined"
               [ngModel]="userDefinedDelimiterText"
               (ngModelChange)="userDefinedDelimiterTextChange($event)" />
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col">
    <kendo-panelbar [keepItemContent]="true">
      <kendo-panelbar-item #requestDetailsPanel
                           title="{{supplierRollNumbers.length}} {{'matchesFound'|i18n}}"
                           [expanded]="true">
        <ng-template kendoPanelBarContent>
          <div class="found-matches pt-1 ps-1">
            <kendo-chip *ngFor="let supplierRoleNumber of supplierRollNumbers"
                        [label]="supplierRoleNumber"
                        [removable]="false"
                        [selected]="false"
                        class="result me-1 mb-1">
            </kendo-chip>
          </div>
        </ng-template>
      </kendo-panelbar-item>
    </kendo-panelbar>
  </div>
</div>
