<div class="col-md-6 col-lg-8 pt-3 px-4">
  <div class="jumbotron d-flex align-items-center">
    <div class="container">
      <h5>
        <i class="fas fa-hand-paper"></i>&nbsp;
        {{'status_forbidden_message' | i18n}}
      </h5>
      <div class="pt-3">
        <button type="button" class="btn btn-default" (click)="goBack()">
          <i class="fas fa-arrow-left"></i>&nbsp;
          {{'status_go-back_button_text' | i18n}}
        </button>
      </div>
    </div>
  </div>
</div>
