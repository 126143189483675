import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ContentChildren, AfterContentInit, TemplateRef, ContentChild, OnDestroy } from '@angular/core';
import { PagerSettings, CellClickEvent, RemoveEvent, SelectionEvent, PageChangeEvent, RowClassFn, RowClassArgs, RowArgs, DetailCollapseEvent, DetailExpandEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { NGridOptions } from './models/n-grid-options.model';
import { NGridColumnOptions } from './models/n-grid-column-options.model';
import { NGridCommandColumnOptions } from './models/n-grid-command-column-options.model';
import { State } from '@progress/kendo-data-query';
import { NGridState } from './models/n-grid-state.model';
import { NGridCellTemplateDirective } from './directives/n-grid-cell-template.directive';
import { NGridRemoveCommandCellTemplateDirective } from './directives/n-grid-command-cell-template.directive';
import { NGridFilterCellTemplateDirective } from './directives/n-grid-filter-cell-template.directive';
import { ExcelExportData } from '@progress/kendo-angular-excel-export';
import { NGridFilterMethod } from './enums/n-grid-filter-method.enum';
import { NGridColumnFilterType } from './enums/n-grid-column-filter-type.enum';
import { IFetchData } from './interfaces/i-fetch-data.interface';
import { INGridColumnOptionsBase } from './interfaces/i-n-grid.column-options.interface';
import { BehaviorSubject, EMPTY, timer, Observable, Subscription } from 'rxjs';
import { NGridDetailTemplateDirective } from './directives/n-grid-detail-template.directive';
import { debounce, distinctUntilChanged, tap } from 'rxjs/operators';

@Component({
  selector: 'n-grid',
  templateUrl: './n-grid.component.html',
  styleUrls: ['./n-grid.component.css'],
  host: { class: 'n-grid' },
  encapsulation: ViewEncapsulation.None
})
export class NGridComponent implements OnInit, AfterContentInit, OnDestroy {

  @Input() data: any[] | GridDataResult;
  @Input() fetchDataForExport: IFetchData;

  @Input() searchTextboxPlaceholder: string = '';
  @Input() noRecordsText: string;
  @Input() excelExportFilename: string = 'data-export.xlsx';
  @Input() freeTextSearchFilterOptionText = 'Free text search';
  @Input() columnFilterOptionText = 'Column filtering';
  @Input() excelExportButtonText: string = 'Excel Export';
  @Input() exportButtonText: string = 'Export';
  @Input() stateChangeDebounceTime: number = 0;
  @Input() selectBy: string;
  @Input() selectedKeys: any[] = [];
  @Input() isLoading: boolean = false;
  @Input() rowClass: RowClassFn = function (context: RowClassArgs) { return {}; };
  @Input() isDetailExpanded: (args: RowArgs) => boolean = null;
  @Input() options: NGridOptions = new NGridOptions();
  @Input() state: NGridState = {
    freeTextSearch: "",
    filterMethod: NGridFilterMethod.FreeTextSearch
  };
  @Input() columns: INGridColumnOptionsBase[] = [];
  @Input() detailRowTemplate?: TemplateRef<any>;
  @Output() gridStateChange: EventEmitter<NGridState> = new EventEmitter<NGridState>();
  @Output() pageChange: EventEmitter<PageChangeEvent> = new EventEmitter<PageChangeEvent>();
  @Output() onExcelExport: EventEmitter<any> = new EventEmitter<any>();
  @Output() onDataExport: EventEmitter<NGridState> = new EventEmitter<NGridState>();
  @Output() cellClick: EventEmitter<CellClickEvent> = new EventEmitter<CellClickEvent>();
  @Output() selectionChange: EventEmitter<SelectionEvent> = new EventEmitter<SelectionEvent>();
  @Output() remove: EventEmitter<RemoveEvent> = new EventEmitter<RemoveEvent>();
  @Output() detailCollapse: EventEmitter<DetailCollapseEvent> = new EventEmitter<DetailCollapseEvent>();
  @Output() detailExpand: EventEmitter<DetailExpandEvent> = new EventEmitter<DetailExpandEvent>();

  pagerSettings: PagerSettings = {
    buttonCount: 5,
    pageSizes: [10, 25, 50],
    previousNext: true
  }

  activeFilterMethod: NGridFilterMethod;
  isFreeTextSearchActive: boolean;

  private skip: number;
  private debouncedGridStateSubject: BehaviorSubject<NGridState>;
  private gridStateSubscription: Subscription;
  private isDebounceTimeEnabled = true;

  @ContentChildren(NGridCellTemplateDirective) cellTemplates;
  @ContentChild(NGridDetailTemplateDirective) detailTemplate;
  @ContentChildren(NGridRemoveCommandCellTemplateDirective) removeCommandCellTemplates;
  @ContentChildren(NGridFilterCellTemplateDirective) filterCellTemplates;

  NGridColumnFilterType = NGridColumnFilterType;
  NGridFilterMethod = NGridFilterMethod;

  get showCheckboxColumn(): boolean {
    return this.options.showCheckboxColumn && this.options.selectable && (typeof this.options.selectable == "boolean" || this.options.selectable.enabled);
  }

  get showSelectAll(): boolean {
    return this.showCheckboxColumn && this.options.showSelectAll;
  }

  constructor() {
    this.exportToExcel = this.exportToExcel.bind(this);
  }
  ngOnDestroy(): void {
    if (this.gridStateSubscription) {
      this.gridStateSubscription.unsubscribe();
    }
  }

  ngOnInit() {    
    this.debouncedGridStateSubject = new BehaviorSubject(this.state);
    this.gridStateSubscription = new Subscription();
    this.gridStateSubscription.add(this.debouncedGridStateSubject.pipe(
      debounce(() => this.isDebounceTimeEnabled ? timer(this.stateChangeDebounceTime) : EMPTY),
      distinctUntilChanged()
    ).subscribe(state => {
      this.gridStateChange.emit(state);
    }));

    this.isFreeTextSearchActive = 'filterMethod' in this.state ? this.state.filterMethod === NGridFilterMethod.FreeTextSearch : true;

    if (this.options.isPageable || this.isInfiniteScroll()) {
      if (!('skip' in this.state)) {
        this.state.skip = 0;
      }
      this.skip = this.state.skip;
    }

    if ((this.options.isPageable && ((this.pagerSettings.pageSizes instanceof Array && this.pagerSettings.pageSizes.length > 0)))
      || this.isInfiniteScroll()) {

      if (!('take' in this.state)) {
        this.state.take = this.pagerSettings.pageSizes[0];
      }


      if ((this.pagerSettings.pageSizes instanceof Array && !this.pagerSettings.pageSizes.includes(this.state.take))) {
        this.pagerSettings.pageSizes.push(this.state.take);
        this.pagerSettings.pageSizes.sort();
      }
    }

    if (!('filter' in this.state)) {
      this.state.filter = {
        logic: 'and',
        filters: []
      };
    }

    if (!('sort' in this.state)) {
      this.state.sort = [];
    }

    this.isLoading = this.options.initialLoading || false;
  }

  ngAfterContentInit(): void {

    this.columns.forEach(column => {
      if (column instanceof NGridColumnOptions) {
        let cellTemplate = this.cellTemplates.find(ct => ct.forField === column.field);
        let filterCellTemplate = this.filterCellTemplates.find(fct => fct.forField === column.field);

        if (cellTemplate) {
          column.cellTemplate = cellTemplate.templateRef;
        }

        if (filterCellTemplate) {
          column.filterCellTemplate = filterCellTemplate.templateRef;
        }
      } else if (column instanceof NGridCommandColumnOptions) {
        let removeCommandCellTemplate = this.removeCommandCellTemplates.find(cct => cct.forName === column.name);

        if (removeCommandCellTemplate) {
          column.removeCommandCellTemplate = removeCommandCellTemplate.templateRef;
        }
      }
    });

    if (this.detailTemplate) {
      this.detailRowTemplate = this.detailTemplate.templateRef;
    }
  }

  dataStateChangeHandler(state: State): void {
    if (state.skip === this.skip) {
      state.skip = 0;
    }

    this.skip = state.skip;

    this.isDebounceTimeEnabled = this.state.skip === state.skip && this.state.take === state.take;    

    this.state.skip = state.skip;
    this.state.take = state.take;
    this.state.filter = state.filter;
    this.state.group = state.group;
    this.state.sort = state.sort;

    this.nGridStateChanged(this.isDebounceTimeEnabled);
  }

  private nGridStateChanged(isDebounceTimeEnabled: boolean = true) {
    this.isDebounceTimeEnabled = isDebounceTimeEnabled;
    let gridState = this.getState();
    this.state = gridState;
    this.debouncedGridStateSubject.next(gridState);
  }

  exportToExcel(): ExcelExportData | Promise<any> | Observable<any> {
    if (this.fetchDataForExport) {
      let dataState = this.getState(true);

      return this.fetchDataForExport(dataState);
    }

    return { data: this.data instanceof Array ? this.data : this.data.data };
  }

  pageChangeHandler(e: PageChangeEvent) {
    this.setSkip(e.skip);

    this.pageChange.emit(e);
  }

  cellClickHandler(event: CellClickEvent): void {
    this.cellClick.emit(event);
  }

  selectionChangeHandler(event: SelectionEvent): void {
    this.selectionChange.emit(event);
  }

  removeHandler(event: RemoveEvent): void {
    this.remove.emit(event);
  }

  detailCollapseHandler(event: DetailCollapseEvent) {
    this.detailCollapse.emit(event);
  }

  detailExpandHandler(event: DetailExpandEvent) {
    this.detailExpand.emit(event);
  }

  excelExportHandler(data: any) {
    this.onExcelExport.emit(data);
  }

  dataExportHandler() {
    let dataState = this.getState(true);
    this.onDataExport.emit(dataState);
  }

  onToggleSearch = value => {
    this.isFreeTextSearchActive = value;
    this.resetFilters();
    this.nGridStateChanged();
  };

  freeTextSearchChange(freeTextSearchValue) {
    this.state.freeTextSearch = freeTextSearchValue;
    this.state.skip = 0;
    this.skip = 0;
    this.nGridStateChanged();
  }

  anyColumnFilterSet(): boolean {
    return this.columns.some(c => c instanceof NGridColumnOptions ? c.filterable : false);
  }

  isNGridColumn(column: INGridColumnOptionsBase): boolean {
    return column instanceof NGridColumnOptions;
  }

  isNGridCommandColumn(column: INGridColumnOptionsBase): boolean {
    return column instanceof NGridCommandColumnOptions;
  }

  getState(isExport: boolean = false): NGridState {
    var state = {
      skip: this.state.skip,
      take: this.state.take,
      filter: this.state.filter || null,
      group: this.state.group || null,
      sort: this.state.sort || null,
      filterMethod: this.getFilterMethod(this.isFreeTextSearchActive),
      freeTextSearch: this.state.freeTextSearch || "",
      isExport: isExport
    }
    return state;
  }

  setState(state: NGridState): void {
    this.state =
    {
      skip: state.skip || this.state.skip,
      take: state.take || this.state.take,
      filter: state.filter || this.state.filter,
      group: state.group || this.state.group,
      sort: state.sort || this.state.sort,
      freeTextSearch: state.freeTextSearch || this.state.freeTextSearch,
      filterMethod: state.filterMethod || this.state.filterMethod
    }

    this.isFreeTextSearchActive = this.state.filterMethod ? this.state.filterMethod == NGridFilterMethod.FreeTextSearch : true;

    this.nGridStateChanged();
  }

  setSkip(skip: number) {
    if (this.isInfiniteScroll()) {
      this.skip = skip;
      this.state.skip = skip;
    }
  }

  private getFilterMethod(isFreeTextSearchActive: boolean): NGridFilterMethod {
    return isFreeTextSearchActive ? NGridFilterMethod.FreeTextSearch : NGridFilterMethod.ColumnFilter
  }

  private isInfiniteScroll() {
    return this.options.scrollMode === 'virtual';
  }

  private resetFilters() {
    this.state.filter.filters = [];
    this.state.freeTextSearch = "";
  }
}

