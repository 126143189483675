import { Injectable } from '@angular/core';
import { User } from '../models/users/user.model';
import { UserRole } from '../models/users/user-role.model';
import { Request } from '../models/requests/request.model';
import { CompaniesService } from './companies.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(private companiesService: CompaniesService) { }

  isSystemAdmin(user: User): boolean {
    return !user ? false : user.roles.findIndex(r => r === UserRole.SystemAdmin) >= 0;
  }

  isSystemOrPortalAdmin(user: User): boolean {
    return !user ? false : user.roles.findIndex(r => r === UserRole.SystemAdmin || r === UserRole.PortalAdmin) >= 0;
  }

  isCompanyAdmin(user: User): boolean {
    return !user ? false : user.roles.findIndex(r => r === UserRole.CompanyAdmin) >= 0;
  }

  isSourceSamplingRepresentative(user: User): boolean {
    return !user ? false : user.roles.findIndex(r => r === UserRole.SourceSamplingRepresentative) >= 0;
  }

  isLabManager(user: User): boolean {
    return !user ? false : user.roles.findIndex(r => r === UserRole.LabManager) >= 0;
  }

  isSupplierUserOf(user: User, request: Request): boolean {
    if (user && request && request.requestContracts && request.requestContracts.map(rc => rc.contract.supplierContractors)) {
      const supplierContractors = request.requestContracts.
        map(rc => rc.contract.supplierContractors).
        reduce((accumulator, value) => accumulator.concat(...value), []);

      return supplierContractors.findIndex(sc => sc.externalId === user.companyId) >= 0;
    }
 
    return false;
  }

  isPrimeUserOf(user: User, request: Request): boolean {
    return user && request &&
      request.requestContracts &&
      request.requestContracts.length > 0 &&
      request.requestContracts[0].contract &&
      request.requestContracts[0].contract.primeContractor ? request.requestContracts[0].contract.primeContractor.externalId === user.companyId : false;
  }
}
