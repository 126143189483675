/**
 * View model for an application user's role.
 */
export class UserRole {

  public static readonly User = 'User';
  public static readonly CompanyAdmin = 'Customer Administrator';
  public static readonly SystemAdmin = 'System Administrator';
  public static readonly PortalAdmin = 'Portal Administrator';
  public static readonly SourceSamplingRepresentative = 'Source Sampling Representative';
  public static readonly LabManager = 'Lab Manager';  
}
