declare global {
  interface String {
    toLowerFirstLetter(): string;
  }
}
String.prototype.toLowerFirstLetter = function (): string {
  return this.length > 0 ? this.substr(0,1).toLowerCase() + this.substr(1) : this;
}

export {};
