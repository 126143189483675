<div class="col-md-6 col-lg-8 pt-3 px-4">
  <div class="jumbotron d-flex align-items-center">
    <div class="container">
      <h5>
        <i class="fas fa-hand-paper"></i>&nbsp;
        {{'no-subscription_h5_text'|i18n}}&nbsp;<a href="{{portalUrl}}">{{'no-subscription_h5_link_text'|i18n}}</a> 
      </h5>
    </div>
  </div>
</div>
