import { Component, Input } from '@angular/core';
import { FilterService, BaseFilterCellComponent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'n-grid-date-range-filter',
  templateUrl: './n-grid-date-range-filter.component.html',
  styleUrls: ['./n-grid-date-range-filter.component.css']
})
export class NGridDateRangeFilterComponent extends BaseFilterCellComponent {
  @Input()
  public filter: CompositeFilterDescriptor;

  @Input()
  public column: any;

  constructor(filterService: FilterService) {
    super(filterService);
  }

  public get start(): Date {
    const first = this.findByOperator("gte");
    return (first || <FilterDescriptor>{}).value;
  }

  public get end(): Date {
    const end = this.findByOperator("lte");
    return (end || <FilterDescriptor>{}).value;
  }

  public get hasFilter(): boolean {
    let filtersFound = this.filtersByField(this.column.field);

    return filtersFound.length > 0;
  }

  public clearFilter(): void {
    this.filterService.filter(
      this.removeFilter(this.column.field)
    );
  }

  public filterRange(start: Date, end: Date): void {
    this.filter = this.removeFilter(this.column.field);

    let daterangeFilter: CompositeFilterDescriptor = {
      logic: "and",
      filters: []
    };

    if (start) {
      daterangeFilter.filters.push({
        field: this.column.field,
        operator: "gte",
        value: start
      });
    }

    if (end) {
      end = new Date(end.setHours(23, 59, 59, 999));
      daterangeFilter.filters.push({
        field: this.column.field,
        operator: "lte",
        value: end
      });
    }

    const root = this.filter || {
      logic: "and",
      filters: []
    };

    if (daterangeFilter.filters.length) {
      root.filters.push(daterangeFilter);
    }

    this.filterService.filter(root);
  }

  private findByOperator(op: string): FilterDescriptor {
    return this.filtersByField(this.column.field)
      .filter(({ operator }) => operator === op)[0];
  }
}
