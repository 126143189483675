<div class="container pt-3 ignore-ng-invalid" ngModelGroup="testRequirement_{{requirementIndex}}">
  <div class="form-group row mx-0">
    <label class="col-sm-3 col-form-label" for="supplier">{{'assignedSupplier'|i18n}}</label>
    <div class="col-sm-9">
      <kendo-dropdownlist [data]="suppliers"
                          id="supplier_{{requirementIndex}}"
                          textField="name"
                          valueField="id"
                          [(ngModel)]="testRequirement.supplier"
                          [readonly]="readonly"
                          (valueChange)="supplierChanged($event)"
                          name="supplierDropDown">
      </kendo-dropdownlist>
    </div>
  </div>
  <div class="form-group row mx-0" *ngIf="testRequirement.testMethodVariant.testMethod.testType != testTypeEnum['Logical'] && !isGrayScale()">
    <label class="col-sm-3 col-form-label" for="precision_{{requirementIndex}}">{{'precision'|i18n}}</label>
    <div class="col-sm-9">
      <kendo-dropdownlist [data]="precisionListItems"
                          id="precision_{{requirementIndex}}"
                          textField="text"
                          valueField="value"
                          [readonly]="readonly"
                          [ngModel]="testRequirement.precisionLevel"
                          (ngModelChange)="onPrecisionLevelChange($event)"
                          [valuePrimitive]="true"
                          name="precision"
                          class="w-100">
      </kendo-dropdownlist>
      <div *ngIf="isSubmitted && !testMethodVariant.precisionLevel" class="alert alert-danger">
        {{'precision'|i18n}} {{'is_required'|i18n}}
      </div>
    </div>
  </div>
  <div class="form-group row mx-0">
    <label class="col-sm-3 col-form-label" for="determination_{{requirementIndex}}">{{'determination'|i18n}}</label>
    <div class="col-sm-9">
      <input type="number" class="form-control" min="1" [customMin]="1" name="determination" [disabled]="readonly" id="determination_{{requirementIndex}}"
             [(ngModel)]="testRequirement.determination" placeholder="{{'determination'|i18n}}" #determination="ngModel" />
      <div *ngIf="isSubmitted && determination.errors?.customMin" class="alert alert-danger">
        {{'determination_validation_message'|i18n}}
      </div>
    </div>
  </div>
  <div class="form-group row mx-0" *ngIf="testRequirement.determination > 1 && testRequirement.testMethodVariant.testMethod.evaluationMode === EvaluationMode.Regular">
    <label class="col-sm-3 col-form-label" for="aggregateFunction_{{requirementIndex}}">{{'aggregate_function'|i18n}}</label>
    <div class="col-sm-9">
      <kendo-dropdownlist [data]="aggregateFunctions"
                          id="aggregateFunction_{{requirementIndex}}"
                          textField="text"
                          valueField="value"
                          [itemDisabled]="aggregateFunctionsItemDisabled"
                          [(ngModel)]="testRequirement.aggregateFunction"
                          [valuePrimitive]="true"
                          name="aggregateFunction"
                          class="w-100"
                          [required]="testRequirement.determination > 1 && testRequirement.testMethodVariant.testMethod.evaluationMode === EvaluationMode.Regular"
                          [readonly]="readonly">
      </kendo-dropdownlist>
      <div *ngIf="isSubmitted && !testRequirement.aggregateFunction" class="alert alert-danger">
        {{'aggregate_function'|i18n}} {{'is_required'|i18n}}
      </div>
    </div>
  </div>
  <div class="row mx-0 mb-025" *ngIf="testRequirement.aggregateFunction === AggregateFunction.LotAverage && testRequirement.determination > 1">
    <label class="col-sm-3 col-form-label" for="aggregateConditionPanelBar">{{'lotAverageCondition'|i18n}}</label>
    <div class="col-sm-9">
      <div class="border-left">
        <div class="form-group mx-0 px-0 row">
          <label class="col-sm-3 col-form-label" for="type">{{'type'|i18n}}</label>
          <div class="col-sm-9 col-form-label">
            {{getLotAverageConditionType(testRequirement.testMethodVariant.lotAverageConditionType)}}
          </div>
        </div>
        <div class="mx-0 px-0 row">
          <div class="col pe-0">
            <pdp-test-method-member [testType]="testRequirement.testMethodVariant.lotAverageConditionType"
                                    [testMethodMember]="testRequirement.testMethodVariant.lotAverageConditionMember"
                                    [testAttributeOverrides]="testRequirement?.lotAverageConditionAttributeOverrides"
                                    [isLotAverageConditionComponent]="true"
                                    [index]="0" class="w-100"
                                    [isGrayScale]="isGrayScale()" [isOnlyMember]="true" [numberFormat]="numberFormat$ | async"
                                    [isSubmitted]="isSubmitted" [readonly]="readonly">
            </pdp-test-method-member>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-group row mx-0" *ngIf="testRequirement.testMethodVariant.testMethod.evaluationMode === EvaluationMode.SpectralReflectance">
    <label class="col-sm-3 col-form-label" for="numberOfAllowedFailures">{{'numberOfAllowedFailures'|i18n}}</label>
    <div class="col-sm-9">
      <input type="number" class="form-control" min="0" [customMin]="0" name="numberOfAllowedFailures"
             [(ngModel)]="testRequirement.numberOfAllowedFailures"
             [disabled]="readonly"
             required
             placeholder="{{'numberOfAllowedFailures'|i18n}}" #numberOfAllowedFailures="ngModel" />
      <div *ngIf="isSubmitted && numberOfAllowedFailures.errors?.customMin" class="alert alert-danger">
        {{'numberOfAllowedFailures_min_validation_message'|i18n}}
      </div>
    </div>
  </div>
  <div class="form-group row mx-0" *ngIf="isAggregateConditionEnabled()">
    <label class="col-sm-3 col-form-label" for="aggregateConditionType_{{requirementIndex}}">{{'aggregateCondition'|i18n}}</label>
    <div class="col-sm-2">
      <kendo-dropdownlist [data]="aggregateConditionTypes"
                          id="aggregateConditionType_{{requirementIndex}}"
                          textField="text"
                          valueField="value"
                          [disabled]="readonly"
                          [ngModel]="testRequirement.aggregateConditionType"
                          (ngModelChange)="onChangeAggregateConditionType($event)"
                          [ngModelOptions]="{ updateOn: 'change' }"
                          [valuePrimitive]="true"
                          name="aggregateConditionType"
                          class="w-100">
      </kendo-dropdownlist>
    </div>
    <div class="col-sm-2 pe-1">
      <ng-container *ngIf="testRequirement.aggregateConditionType === AggregateConditionType.Amount">
        <input name="aggregateConditionValue"
               type="number"
               class="form-control text-end"
               [disabled]="readonly"
               [(ngModel)]="testRequirement.numberOfAllowedFailures"
               min="0"
               [max]="10000"
               [customMin]="0"
               [customMax]="10000"
               step="1"
               isInteger
               required
               #numberOfAllowedFailuresRef="ngModel" />
        <ng-container *ngIf="isSubmitted && numberOfAllowedFailuresRef.errors?.required; else numberOfAllowedFailuresRangeError">
          <div class="alert alert-danger">{{'numberOfAllowedFailures'|i18n}} {{'is_required'|i18n}}</div>
        </ng-container>
        <ng-template #numberOfAllowedFailuresRangeError>
          <div class="alert alert-danger" *ngIf="isSubmitted && numberOfAllowedFailuresRef.errors?.customMin">{{'numberOfAllowedFailures_min_validation_message'|i18n:{ "@minValue": 0 } }}</div>
          <div class="alert alert-danger" *ngIf="isSubmitted && numberOfAllowedFailuresRef.errors?.customMax">{{'numberOfAllowedFailures_max_validation_message'|i18n:{ "@maxValue": 10000 } }}</div>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="testRequirement.aggregateConditionType === AggregateConditionType.Percentage">
        <input name="aggregateConditionValue"
               type="number"
               class="form-control text-end"
               [disabled]="readonly"
               [(ngModel)]="testRequirement.percentageOfRequiredPasses"
               min="0"
               [max]="100"
               [customMin]="0"
               [customMax]="100"
               required
               #percentageOfRequiredPassesRef="ngModel" />
        <ng-container *ngIf="isSubmitted && percentageOfRequiredPassesRef.errors?.required; else percentageOfRequiredPassesRangeError">
          <div class="alert alert-danger">{{'percentageOfRequiredPasses'|i18n}} {{'is_required'|i18n}}</div>
        </ng-container>
        <ng-template #percentageOfRequiredPassesRangeError>
          <div class="alert alert-danger" *ngIf="isSubmitted && percentageOfRequiredPassesRef.errors?.customMin || percentageOfRequiredPassesRef.errors?.customMax">{{'percentageOfRequiredPasses_validation_message'|i18n}}</div>
        </ng-template>
      </ng-container>
    </div>
    <div class="col-sm-5 ps-0 pt-2">
      {{(testRequirement.aggregateConditionType === AggregateConditionType.Percentage ? 'aggregateCondition_samplesNeedToPass' : 'aggregateCondition_samplesCanFail')|i18n}}
    </div>
  </div>
  <div class="form-group row mx-0" *ngIf="testRequirement.testMethodVariant.testMethod.testType != testTypeEnum['Logical']">
    <label class="col-sm-3 col-form-label" for="unitOfMeasure_{{requirementIndex}}">{{'unit_of_measure'|i18n}}</label>
    <div class="col-sm-9">
      <input type="text" class="form-control" id="unitOfMeasure_{{requirementIndex}}" name="unitOfMeasure" [(ngModel)]="testRequirement.testMethodVariant.testMethod.unitOfMeasure" disabled />
    </div>
  </div>
</div>
<div class="card mb-3 mx-3">
  <div *ngFor="let testMethodMember of testRequirement.testMethodVariant.testMethodMembers; let memberIndex = index">
    <pdp-test-method-member [testType]="testRequirement.testMethodVariant.testMethod.testType"
                            [testMethodMember]="testMethodMember"
                            [testAttributeOverrides]="testRequirement.testAttributeOverrides"
                            [index]="requirementIndex+'_'+memberIndex" class="w-100" [numberFormat]="numberFormat$ | async"
                            [isGrayScale]="isGrayScale()"
                            [isOnlyMember]="testRequirement.testMethodVariant.testMethodMembers?.length === 1"
                            [isSubmitted]="isSubmitted" [readonly]="readonly"></pdp-test-method-member>
  </div>
</div>
