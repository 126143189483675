import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { TestType } from '../../../../shared/enums/test-type.enum';
import { OperatorType } from '../../../../shared/enums/operator-type.enum';
import { I18nService } from '../../../../shared/services/i18n.service';
import { TestRequirement } from '../../../../shared/models/test-requirements/test-requirement.model';
import { Supplier } from '../../../../shared/models/companies/supplier.model';
import { Globals } from '../../../../shared/globals';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { BehaviorSubject, Observable } from 'rxjs';
import { AggregateFunction } from '../../../../shared/enums/aggregate-function.enum';
import { EvaluationMode } from '../../../../shared/enums/evaluation-mode.enum';
import { formProvider } from '../../../../shared/providers/form.provider';
import { NgForm } from '@angular/forms';
import { ErrorMessageService } from '../../../../shared/services/error-message.service';
import { AggregateConditionType } from '../../../../shared/enums/aggregate-condition-type.enum';
import { ListItem } from '../../../../shared/models/list-item.model';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { DataSourceService } from '../../../../shared/services/datasource.service';

@Component({
  selector: 'pdp-test-requirement',
  templateUrl: './test-requirement.component.html',
  styleUrls: ['./test-requirement.component.css'],
  viewProviders: [formProvider],
  encapsulation: ViewEncapsulation.None
})
export class TestRequirementComponent implements OnInit {  
  public testTypeEnum = TestType;
  public operatorTypeEnum = OperatorType;
  public isSubmitted = false;
  public aggregateFunctions: ListItem<string>[] = [];
  public aggregateConditionTypes: ListItem<string>[] = [];
  public lotAverageConditionTypes: ListItem<string>[] = [];
  public numberFormat$: Observable<NumberFormatOptions>;
  public precisionListItems: ListItem<number>[];

  EvaluationMode = EvaluationMode;
  AggregateFunction = AggregateFunction;
  AggregateConditionType = AggregateConditionType;

  private numberFormatSubject: BehaviorSubject<NumberFormatOptions>;
  private isLotAverageConditionDefined = false;

  constructor(
    public i18nService: I18nService,
    public errorMessageService: ErrorMessageService,
    private dataSourceService: DataSourceService,
    private requestForm: NgForm) {
  }

  @Input() testRequirement: TestRequirement;
  @Input() suppliers: Supplier[];
  @Input() requirementIndex: number;
  @Input() readonly = false;
  
  getAggregateFunctions() {
    if (this.testRequirement.testMethodVariant.testMethod.testType != TestType.Logical) {
      for (const aggregateFunction in AggregateFunction) {
        if (typeof AggregateFunction[aggregateFunction] === 'string') {
          let i18nKey = `aggregateFunction_full_${AggregateFunction[aggregateFunction]}`;

          if (AggregateFunction[aggregateFunction] === AggregateFunction.LotAverage && !this.isLotAverageConditionDefined) {
            i18nKey += '_disabled';
          }

          this.aggregateFunctions.push({
            value: AggregateFunction[aggregateFunction], text: this.i18nService.transform(i18nKey)
          });
        }
      }
    } else {
      let i18nKey = `aggregateFunction_full_logical_${AggregateFunction.Minimum}`;
      this.aggregateFunctions.push({
        value: AggregateFunction.Minimum, text: this.i18nService.transform(i18nKey)
      });

      i18nKey = `aggregateFunction_full_logical_${AggregateFunction.Maximum}`;
      this.aggregateFunctions.push({
        value: AggregateFunction.Maximum, text: this.i18nService.transform(i18nKey)
      });
    }
  }

  aggregateFunctionsItemDisabled(itemArgs: { dataItem: { value: string } }) {
    return itemArgs.dataItem.value === AggregateFunction.LotAverage && !this.isLotAverageConditionDefined;
  }

  ngOnInit(): void {
    this.getAggregateFunctions();
    this.getAggregateConditionTypes();
    this.getLotAverageConditionTypes();

    this.isLotAverageConditionDefined = this.testRequirement?.testMethodVariant?.lotAverageConditionMember != null;
    this.precisionListItems = this.dataSourceService.getPrecisionLevelDataSource();
    this.numberFormatSubject = new BehaviorSubject<NumberFormatOptions>(this.setNumberFormat(this.testRequirement.precisionLevel));
    this.numberFormat$ = this.numberFormatSubject.asObservable();

    if (this.testRequirement.aggregateFunction &&
      this.testRequirement.aggregateFunction === AggregateFunction.LotAverage &&
      !this.testRequirement.lotAverageConditionAttributeOverrides) {
      this.testRequirement.lotAverageConditionAttributeOverrides = [];
    }
    if (!this.testRequirement.testAttributeOverrides) {
      this.testRequirement.testAttributeOverrides = [];
    }
  }

  isAggregateConditionEnabled() {
    return this.testRequirement.testMethodVariant.testMethod.evaluationMode === EvaluationMode.Regular && this.testRequirement.testMethodVariant.aggregateConditionType != null;
  }

  isGrayScale(): boolean {
    return this.testRequirement && this.testRequirement.testMethodVariant.testMethod
      && this.testRequirement.testMethodVariant.testMethod.unitOfMeasure === Globals.GrayScaleUnitOfMeasure;
  }

  openPopup(control: ComboBoxComponent) {
    if (!control.isOpen) {
      control.toggle(true);
    }
  }

  public supplierChanged(value: any) {
    this.testRequirement.supplierId = value.id;
  }

  getAggregateConditionTypes() {
    for (const aggregateConditionType in AggregateConditionType) {
      if (typeof AggregateConditionType[aggregateConditionType] === 'string') {
        this.aggregateConditionTypes.push({
          value: AggregateConditionType[aggregateConditionType], text: this.i18nService.transform(`aggregateConditionType_full_${AggregateConditionType[aggregateConditionType]}`)
        });
      }
    }
  }

  private getLotAverageConditionTypes() {
    for (const testType in TestType) {
      if (typeof TestType[testType] === 'string') {
        this.lotAverageConditionTypes.push({ value: <string>TestType[testType], text: this.i18nService.transform(testType.toLowerFirstLetter()) });
      }
    }
  }

  getLotAverageConditionType(lotAverageConditionType: TestType) {
    const item = this.lotAverageConditionTypes.find(t => t.value === lotAverageConditionType);
    if (item) {
      return item.text; 
    }

    return '';
  }

  getConditionMessage(aggregateConditionType: AggregateConditionType) {
    return this.i18nService.transform(aggregateConditionType === AggregateConditionType.Percentage ? 'aggregateCondition_samplesNeedToPass' : 'aggregateCondition_samplesCanFail');
  }

  onChangeAggregateConditionType(aggregateConditionType: AggregateConditionType) {
    this.testRequirement.aggregateConditionType = aggregateConditionType;    
    this.testRequirement.numberOfAllowedFailures = aggregateConditionType === AggregateConditionType.Amount ? 0 : null;
    this.testRequirement.percentageOfRequiredPasses = aggregateConditionType === AggregateConditionType.Percentage ? 100 : null;

    if (this.requestForm) {
      const aggregateConditionValueControl = this.requestForm.form.get(`testRequirements.testRequirement_${this.requirementIndex}.aggregateConditionValue`);
      if (aggregateConditionValueControl) {
        aggregateConditionValueControl.updateValueAndValidity();
      }
    }
  }

  onPrecisionLevelChange(precisionLevel: number) {
    this.testRequirement.precisionLevel = precisionLevel;
    let numberFormat = this.setNumberFormat(precisionLevel);
    this.numberFormatSubject.next(numberFormat);
  }

  private setNumberFormat(precisionLevel: number): NumberFormatOptions {
    return {
      minimumFractionDigits: precisionLevel,
      maximumFractionDigits: 8
    };
  }
}
