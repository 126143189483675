import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { MessageBoxType } from '../components/message-box/enums/message-box-type.enum';
import { MessageBoxComponent } from '../components/message-box/message-box.component';
import { MessageBoxService } from '../components/message-box/message-box.service';
import { I18nService } from './i18n.service';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from './component-can-deactivate';
import { MessageBoxPrimaryButton } from '../components/message-box/enums/message-box-primary-button.enum';

@Injectable({
  providedIn: 'root'
})
export class PendingChangesService {
  constructor(
    private messageBoxService: MessageBoxService,
    private i18nService: I18nService) { }

  private componentCanDeactivate: ComponentCanDeactivate = null;

  public confirmRejectionOfPendingChanges(deactivationWarningMessage: string): Observable<boolean> {
    const dialogRef = this.messageBoxService.open({
      title: this.i18nService.transform('message_box_title_confirm'),
      type: MessageBoxType.Warning,
      message: deactivationWarningMessage,
      confirmButtonText: this.i18nService.transform('yes'),
      denyButtonText: this.i18nService.transform('no'),
      primary: MessageBoxPrimaryButton.Deny
    });

    const messageBoxComponentInstance = <MessageBoxComponent>dialogRef.content.instance;

    return messageBoxComponentInstance
      .getConfirmationObservable()
      .pipe(take(1));
  }

  public setComponentCanDeactivate(component: ComponentCanDeactivate) {
    this.componentCanDeactivate = component;
  }

  public getComponentCanDeactivate(): ComponentCanDeactivate {
    return this.componentCanDeactivate;
  }
}
