<div class="alert alert-info fst-italic">{{ptcResponseNote}}</div>
<div>
  <div class="mb-2 question">{{'isParticipationNeeded'|i18n}}</div>
  <div class="d-flex align-items-center mb-1">
    <input kendoRadioButton
           type="radio"
           id="followUpOffline"
           name="willSpecifierObserve"
           [(ngModel)]="request.willSpecifierObserve"
           [value]="SpecifierObservationType.FollowUpOffline"
           class="me-1" />
    <label [ngClass]="{'selected-answer': request.willSpecifierObserve === SpecifierObservationType.FollowUpOffline}" for="followUpOffline" class="k-radio-label">
      {{'followUpOffline'|i18n}}
    </label>
  </div>
  <div class="d-flex align-items-center mb-1">
    <input kendoRadioButton
           type="radio"
           id="remoteInspection"
           name="willSpecifierObserve"
           [(ngModel)]="request.willSpecifierObserve"
           [value]="SpecifierObservationType.RemoteInspection"
           class="me-1" />
    <label [ngClass]="{'selected-answer': request.willSpecifierObserve === SpecifierObservationType.RemoteInspection}" for="remoteInspection" class="k-radio-label">
      {{'remoteInspection'|i18n}}
    </label>
  </div>
  <div class="d-flex align-items-center">
    <input kendoRadioButton
           type="radio"
           name="willSpecifierObserve"
           id="attendanceNotNeeded"
           [(ngModel)]="request.willSpecifierObserve"
           [value]="SpecifierObservationType.AttendanceNotNeeded"
           class="me-1" />
    <label [ngClass]="{'selected-answer': request.willSpecifierObserve === SpecifierObservationType.AttendanceNotNeeded}" for="attendanceNotNeeded" class="k-radio-label">
      {{'attendanceNotNeeded'|i18n}}
    </label>
  </div>
</div>
<div class="mt-4">
  <div class="mb-2 question">{{'isCorrelationRequired'|i18n}}</div>
  <div class="d-flex align-items-center mb-1">
    <input kendoRadioButton
           type="radio"
           name="correlationRequired"
           id="correlationRequiredTrue"
           [(ngModel)]="request.correlationRequired"
           [value]="true"
           class="me-1" />
    <label [ngClass]="{'selected-answer': request.correlationRequired}" for="correlationRequiredTrue" class="k-radio-label">
      {{'required'|i18n}}
    </label>
  </div>
  <div class="d-flex align-items-center">
    <input kendoRadioButton
           type="radio"
           name="correlationRequired"
           id="correlationRequiredFalse"
           [(ngModel)]="request.correlationRequired"
           [value]="false"
           class="me-1" />
    <label [ngClass]="{'selected-answer': request.correlationRequired === false}" for="correlationRequiredFalse" class="k-radio-label">
      {{'notRequired'|i18n}}
    </label>    
  </div>
</div>
