import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Supplier } from '../models/companies/supplier.model';
import { Observable } from 'rxjs';
import { AuthService } from '../../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {
  private httpOptions;

  private supplierUrl = environment.apiBaseUrl + 'api/v1/Supplier'; // URL to web api

  constructor(private http: HttpClient,
    private authService: AuthService) {
    this.authService.getAccessToken().subscribe(token => {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': token
        })
      };
    });
  }

  getSuppliersOfContracts(contractIds: number[]): Observable<Supplier[]> {
    const queryParams = `contractIds=${contractIds.join('&contractIds=')}`;
    const pdpUrl = `${this.supplierUrl}/GetSuppliersOfContracts?${queryParams}`;
    return this.http.get<Supplier[]>(pdpUrl, { headers: this.httpOptions.headers, observe: 'body' });
  }
}
