import { HostListener, Directive } from '@angular/core';
import { I18nService } from './i18n.service';

@Directive()
export abstract class ComponentCanDeactivate {

  abstract canDeactivate(): boolean;
  /***
   * Override the value of this field to display custom warning message 
   ***/
  public deactivationWarningMessage: string;

  private deactivationBypassed = false;

  public resetDeactivationWarningMessage() {
      this.deactivationWarningMessage = this.i18nService.transform('generic_unsavedChanges_message');
  }

  public bypassDeactivation() {
    this.deactivationBypassed = true;
  }

  public isDeactivationBypassed(): boolean {
    return this.deactivationBypassed;
  }

  public clearDeactivationBypass() {
    this.deactivationBypassed = false;
  }

  constructor(public i18nService: I18nService) {
    this.resetDeactivationWarningMessage();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.bypassDeactivation && !this.canDeactivate()) {
      $event.returnValue = this.deactivationWarningMessage;
    }
  }

}
