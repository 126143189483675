import { Directive, Input, TemplateRef, ViewContainerRef, OnInit } from '@angular/core';
import { FeatureFlagService } from './feature-flag.service';

@Directive({
  selector: '[featureToggle]'
})
export class FeatureFlagDirective implements OnInit {
  @Input() featureToggle: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService
  ) { }

  ngOnInit() {
    if (!this.featureFlagService.initialized) {
      this.featureFlagService.initialize().then(() => {
        this.clearOrCreateView();
      });
    } else {
      this.clearOrCreateView();
    }
  }

  clearOrCreateView() {
    if (this.featureFlagService.isFeatureOff(this.featureToggle)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
