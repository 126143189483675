import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { RequestStatus } from '../../../shared/enums/request-status.enum';
import { SpecifierObservationType } from '../../../shared/enums/specifier-observation-type.enum';
import { Request } from '../../../shared/models/requests/request.model';
import { I18nService } from '../../../shared/services/i18n.service';

@Component({
  selector: 'pdp-ptc-response',
  templateUrl: './ptc-response.component.html',
  styleUrls: ['./ptc-response.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class PtcResponseComponent implements OnInit {

  @Input() request: Request;
  @Input() readonly = false;

  ptcResponseNote: string;

  SpecifierObservationType = SpecifierObservationType;

  constructor(private i18nService: I18nService) { }

  ngOnInit() {
    const noAttendanceText = this.i18nService.transform('attendanceNotNeeded');
    const noParticipationText = this.i18nService.transform('notRequired');
    const fieldName = this.i18nService.transform('request_label_plannedTestingDate');
    this.ptcResponseNote = this.i18nService.transform('ptcResponseNote',
      { '@plannedTestingDate': fieldName, '@noAttendance': noAttendanceText, '@noParticipation': noParticipationText });

    if ((this.request.willSpecifierObserve === null || this.request.willSpecifierObserve === undefined) &&
      (this.request.plannedTestingDate && this.datediff(this.request.plannedTestingDate, new Date())) < 1 || this.request.status > RequestStatus.InProgress) {
      this.request.willSpecifierObserve = SpecifierObservationType.AttendanceNotNeeded;
    }

    if ((this.request.correlationRequired === null || this.request.correlationRequired === undefined) &&
      (this.request.submitDate && this.datediff(this.request.submitDate, new Date()) < 1 || this.request.status > RequestStatus.InProgress)) {
      this.request.correlationRequired = false;
    }
  }

  private datediff(first: Date, second: Date) {
    return Math.round((second.getMilliseconds() - first.getMilliseconds()) / (1000 * 60 * 60 * 24));
  }
}
