<ng-container *ngIf="testAttribute">
  <kendo-dropdownlist [data]="data"
                      name="expectedValue_{{nameIndex}}"
                      [(ngModel)]="testAttribute.expectedValue"
                      [textField]="'text'"
                      [valueField]="'value'"
                      [valuePrimitive]="true"
                      class="w-100 h-38 min-w-5"
                      [disabled]="readonly">
  </kendo-dropdownlist>
</ng-container>
