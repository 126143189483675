import { Injectable } from '@angular/core';
import { CompositeFilterDescriptor, FilterDescriptor } from '@progress/kendo-data-query';
import { HttpParams } from '@angular/common/http';
import { NGridState } from './models/n-grid-state.model';
import { NGridFilterMethod } from './enums/n-grid-filter-method.enum';

@Injectable({
  providedIn: 'root'
})
export class NGridService {

  constructor() { }

  applyFilter(state: NGridState, field: string, value: string, operator: string = 'contains'): NGridState {
    if (!state.filter) {
      state.filter = {
        logic: "and",
        filters: [],
      };
    }

    let statusFilter = state.filter.filters.find(f => {
      if ('field' in f) {
        return f.field === field;
      }
    }) as FilterDescriptor;

    if (statusFilter) {
      state = this.removeFilter(state, field);
    }

    let newFilter = {
      field: field,
      operator: operator,
      value: value
    };

    state.filter.filters.push(newFilter);

    return state;
  }

  removeFilter(state: NGridState, field: string): NGridState {
    let filterIndex = state.filter.filters.findIndex(f => {
      if ('field' in f) {
        return f.field === field;
      }
    });

    if (filterIndex > -1) {
      state.filter.filters.splice(filterIndex, 1);
    }

    return state;
  }

  stateToHttpParams(state: NGridState, httpParams: HttpParams = null): HttpParams {
    if (httpParams == null) {
      httpParams = new HttpParams();
    }

    if (state.filter) {
      let clonedFilter = JSON.parse(JSON.stringify(state.filter));

      clonedFilter.filters.forEach(filter => {
        let compositeFilter = filter as CompositeFilterDescriptor;
        if (this.isDateRangeFilter(compositeFilter)) {
          compositeFilter.filters.forEach(subFilter => {
            subFilter = subFilter as FilterDescriptor;
            if (subFilter.operator === 'gte') {
              subFilter.field = subFilter.field + 'Start';
            } else if (subFilter.operator === 'lte') {
              subFilter.field = subFilter.field + 'End';
            }
          })
        }
      });

      let flattenFilters = this.flatten(clonedFilter);

      flattenFilters.forEach(element => {
        let field = element.field;
        let value = element.value;

        httpParams = httpParams.set(field, value);
      });
    }

    const isFreeTextSearchEnabled = state.filterMethod === NGridFilterMethod.FreeTextSearch;

    httpParams = httpParams.set('isFreeTextSearchEnabled', isFreeTextSearchEnabled ? 'true' : 'false');
    if (isFreeTextSearchEnabled) {
      httpParams = httpParams.set('freeTextSearch', state.freeTextSearch);
    }

    if (state.sort && state.sort.length > 0) {
      state.sort.forEach(sortArgument => {
        if (sortArgument.field !== undefined && sortArgument.dir !== undefined) {
          let sortCondition = `${sortArgument.dir === 'asc' ? '+' : '-'}${sortArgument.field}`;
          httpParams = httpParams.append('sortConditions', encodeURIComponent(sortCondition));
        }
      });
    }

    if (!state.isExport) {
      if (state.skip > 0) {
        httpParams = httpParams.set('skip', state.skip.toString());
      }
      if (state.take > 0) {
        httpParams = httpParams.set('take', state.take.toString());
      }
    }

    return httpParams;
  }

  private isDateRangeFilter(filter: CompositeFilterDescriptor) {
    return (filter.logic === 'and' &&
      filter.filters instanceof Array &&
      filter.filters.length > 0 &&
      ((filter.filters[0] as FilterDescriptor).operator === 'gte' || (filter.filters[0] as FilterDescriptor).operator === 'lte'));
  }

  private flatten = filter => {
    let filters = filter.filters;
    if (filters) {
      return filters.reduce((acc, curr) => acc.concat(curr.filters ? this.flatten(curr) : [curr]), []);
    }
    return [];
  };
}
