import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { NGridFilterMethod } from '../../shared/components/n-grid/enums/n-grid-filter-method.enum';
import { INGridColumnOptionsBase } from '../../shared/components/n-grid/interfaces/i-n-grid.column-options.interface';
import { NGridColumnOptions } from '../../shared/components/n-grid/models/n-grid-column-options.model';
import { NGridOptions } from '../../shared/components/n-grid/models/n-grid-options.model';
import { NGridPagedData } from '../../shared/components/n-grid/models/n-grid-paged-data.model';
import { NGridState } from '../../shared/components/n-grid/models/n-grid-state.model';
import { ISortable } from '../../shared/interfaces/i-sortable.interface';
import { TestMethodGroupItem } from '../../shared/models/test-method-groups/test-method-group-item.model';
import { TestMethodGroup } from '../../shared/models/test-method-groups/test-method-group.model';
import { TestMethodVariant } from '../../shared/models/test-methods/test-method-variant.model';
import { TestMethod } from '../../shared/models/test-methods/test-method.model';
import { DialogComponentCanDeactivate } from '../../shared/services/dialog-component-can-deactivate';
import { I18nService } from '../../shared/services/i18n.service';
import { PendingChangesService } from '../../shared/services/pending-changes.service';
import { SortingService } from '../../shared/services/sorting.service';
import { TestMethodGroupsService } from '../../shared/services/test-method-groups.service';
import { TestMethodsService } from '../../shared/services/test-methods.service';

@Component({
  selector: 'pdp-test-method-group-details',
  templateUrl: './test-method-group-details.component.html',
  styleUrls: ['./test-method-group-details.component.css']
})
export class TestMethodGroupDetailsComponent extends DialogComponentCanDeactivate implements OnInit {

  @Input() public testMethodGroup: TestMethodGroup;
  @Input() public actionName: string;
  @Input() public title: string;
  @Input() public readonly: boolean = false;

  private resultLimit = 50;
  public resultCount: number;
  public resultInfo: string;
  public gridData: NGridPagedData<TestMethodGroupItem>;
  public testMethods: TestMethod[] = [];
  public groupedData$: Observable<GroupResult[]>;
  public selectedTestMethodVariant: TestMethodVariant;
  public testMethodGroupItemsCheckValue: string = null;

  private testMethodSearchUpdated: BehaviorSubject<string> = new BehaviorSubject('');

  @ViewChild('methodGroupForm') methodGroupForm: NgForm;

  constructor(
    public dialog: DialogRef,
    public i18nService: I18nService,
    public pendingChangesService: PendingChangesService,
    private sortingService: SortingService,
    private testMethodsService: TestMethodsService,
    private testMethodGroupsService: TestMethodGroupsService
  ) {
    super(dialog, i18nService)
  }

  gridColumns: INGridColumnOptionsBase[] = [
    new NGridColumnOptions({
      field: 'testMethodVariant.summary',
      title: `${this.i18nService.transform('name')} - ${this.i18nService.transform('title')} (${this.i18nService.transform('specification_summary')})`,
    }),
    new NGridColumnOptions({
      field: 'actions',
      title: this.i18nService.transform('label_actions'),
      sortable: false,
      width: 150
    })
  ];

  gridOptions: NGridOptions = new NGridOptions({
    isPageable: false,
    isSortable: false,
    initialLoading: false
  });

  ngOnInit() {
    this.gridData = new NGridPagedData<TestMethodGroupItem>();
    this.gridData.data = this.testMethodGroup.testMethodGroupItems.sort(this.sortingService.compareBySortIndex);
    this.gridData.total = this.testMethodGroup.testMethodGroupItems.length;
    this.updateCheckValue();

    this.resultCount = this.resultLimit;
    this.resultInfo = this.i18nService.transform('showing_top_n_items_message', { '@count': this.resultCount });

    this.groupedData$ = this.testMethodSearchUpdated.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(value => this.getTestMethodVariants(value)));

    this.getTestMethodVariants();
  }

  canDeactivate(): boolean {
    return !this.methodGroupForm.touched;
  }

  isNameInvalid(control: UntypedFormControl): boolean {
    return control.touched && control.invalid;
  }

  isItemsInvalid(): boolean {
    if (this.methodGroupForm) {
      const testMethodGroupItemsCheckControl = this.methodGroupForm.form.get('testMethodGroupItemsCheck');
      if (testMethodGroupItemsCheckControl) {
        return testMethodGroupItemsCheckControl.invalid && testMethodGroupItemsCheckControl.touched;
      }
    }

    return false;
  }

  isFormInvalid(): boolean {
    return this.methodGroupForm && this.methodGroupForm.form.invalid;
  }

  moveSortableItemUp(event: any, list: Array<ISortable>, item: ISortable) {
    event.stopPropagation();
    this.sortingService.moveSortableItemUp(list, item);
  }

  moveSortableItemDown(event: any, list: Array<ISortable>, item: ISortable) {
    event.stopPropagation();
    this.sortingService.moveSortableItemDown(list, item);
  }

  private getTestMethodVariants(freeTextSearch: string = ''): Observable<GroupResult[]> {
    let gridState: NGridState = {
      take: this.resultLimit,
      sort: [{
        field: 'description',
        dir: 'asc'
      },
        {
          field: 'specificationType',
          dir: 'asc'
        },
        {
          field: 'specificationClass',
          dir: 'asc'
        },
        {
          field: 'specificationSubClass',
          dir: 'asc'
        }],
      filterMethod: NGridFilterMethod.FreeTextSearch,
      freeTextSearch: freeTextSearch,
      filter: { logic: 'and', filters: [{ field: 'isArchived', operator: 'eq', value: 'false' }] }
    };

    return this.testMethodsService.getTestMethodVariants(gridState).pipe(
      map(result => {
        const variants = result.data;
        return groupBy(variants, [{ field: 'testMethodId' }]) as GroupResult[];
      }),
      tap(variants => {
        this.testMethods = variants.map(s => (s.items[0] as TestMethodVariant).testMethod);

        this.resultCount = this.testMethods.length;
        if (freeTextSearch === '' || this.resultCount === 0) {
          this.resultInfo = (this.resultCount < this.resultLimit) ? null : this.i18nService.transform('showing_top_n_items_message', { '@count': this.resultCount });
        } else {
          this.resultInfo = this.i18nService.transform('showing_n_results_message', { '@count': this.resultCount });
        }
      })
    );
  }

  itemDisabled = function (itemArgs: { dataItem: TestMethodVariant; index: number }): boolean {
    return this.isGroupMember(itemArgs.dataItem);
  }.bind(this);

  isGroupMember = function (testMethodVariant: TestMethodVariant): boolean {
    if (this.testMethodGroup && this.testMethodGroup.testMethodGroupItems.length > 0) {
      return this.testMethodGroup.testMethodGroupItems.findIndex(tmgi => tmgi.testMethodVariantId === testMethodVariant.id) !== -1;
    }

    return false;
  }.bind(this);

  filterTestMethodVariants(query: string): void {
    this.testMethodSearchUpdated.next(query);
  }

  public getTestMethod(testMethodId): TestMethod {
    return this.testMethods.find(td => td.id === testMethodId);
  }

  testMethodVariantChanged(value: TestMethodVariant) {
    if (value || value === undefined) {
      this.selectedTestMethodVariant = value || null;
    }
  }

  private updateCheckValue() {
    this.testMethodGroupItemsCheckValue = this.testMethodGroup &&
      this.testMethodGroup.testMethodGroupItems &&
      this.testMethodGroup.testMethodGroupItems.length > 0 ? this.testMethodGroup.testMethodGroupItems.length.toString() : null;    
  }

  public addTestMethodGroupItem(control: ComboBoxComponent): void {
    const testMethodGroupItem = new TestMethodGroupItem();
    const testMethodGroupItemsCheckControl = this.methodGroupForm.form.get('testMethodGroupItemsCheck');
    testMethodGroupItem.id = 0;
    testMethodGroupItem.testMethodGroupId = this.testMethodGroup.id;
    testMethodGroupItem.testMethodVariant = this.selectedTestMethodVariant;
    testMethodGroupItem.testMethodVariantId = this.selectedTestMethodVariant.id;
    testMethodGroupItem.sortIndex = this.testMethodGroup.testMethodGroupItems.length;

    this.testMethodGroup.testMethodGroupItems.push(testMethodGroupItem);
    this.gridData.total = this.testMethodGroup.testMethodGroupItems.length;

    this.selectedTestMethodVariant = null;

    testMethodGroupItemsCheckControl.markAsTouched();
    testMethodGroupItemsCheckControl.markAsDirty();
    this.updateCheckValue();

    if (!control.isFocused) {
      control.focus();
    }

  }

  public deleteTestMethodGroupItem(event: any, testMethodGroupItem: TestMethodGroupItem) {
    event.stopPropagation();
    const testMethodGroupItemsCheckControl = this.methodGroupForm.form.get('testMethodGroupItemsCheck');
    const index: number = this.testMethodGroup.testMethodGroupItems.indexOf(testMethodGroupItem);
    if (index !== -1) {
      this.testMethodGroup.testMethodGroupItems.splice(index, 1);

      this.sortingService.calculateSortIndex(this.testMethodGroup.testMethodGroupItems, index);

      testMethodGroupItemsCheckControl.markAsTouched();
      testMethodGroupItemsCheckControl.markAsDirty();
      this.updateCheckValue();
    }
  }

  onClose(ev): void {
    if (this.methodGroupForm.dirty) {
      ev.preventDefault();
      const message = this.i18nService.transform('generic_unsavedChanges_message');
      this.pendingChangesService.confirmRejectionOfPendingChanges(message).subscribe(shouldBeClosed => {
        if (shouldBeClosed) {
          this.dialog.close();
        }
      });
    }
  }

  public close(): void {
    this.dialog.close();
  }

  public saveMethodGroup(): void {
    if (this.testMethodGroup.id != null) {
      this.testMethodGroupsService.updateTestMethodGroup(this.testMethodGroup).subscribe(result => {
        this.dialog.close('saved');
      });
    } else {
      this.testMethodGroupsService.addTestMethodGroup(this.testMethodGroup).subscribe(result => {
        this.dialog.close('saved');
      });
    }
  }

}
