import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { GridDataResult, RowClassArgs, RowClassFn } from '@progress/kendo-angular-grid';
import { TabHeader } from '../shared/models/tab-header.model';
import { NGridOptions } from '../shared/components/n-grid/models/n-grid-options.model';
import { NGridState } from '../shared/components/n-grid/models/n-grid-state.model';
import { INGridColumnOptionsBase } from '../shared/components/n-grid/interfaces/i-n-grid.column-options.interface';
import { NGridColumnOptions } from '../shared/components/n-grid/models/n-grid-column-options.model';
import { I18nService } from '../shared/services/i18n.service';
import { SessionService } from '../shared/services/session.service';
import { ToastrService } from 'ngx-toastr';
import { NGridService } from '../shared/components/n-grid/n-grid.service';
import { RequestStatus } from '../shared/enums/request-status.enum';
import { RequestService } from '../shared/services/request.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { Router } from '@angular/router';
import { BlobDownloadService } from '../shared/services/blob-download.service';
import { Attachment } from '../shared/models/requests/attachment.model';
import { AttachmentCategory } from '../shared/enums/attachment-category.enum';
import { Request } from '../shared/models/requests/request.model';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { NGridColumnFilterType } from '../shared/components/n-grid/enums/n-grid-column-filter-type.enum';
import { catchError, switchMap, distinctUntilChanged, tap } from 'rxjs/operators';
import { NGridPagedData } from '../shared/components/n-grid/models/n-grid-paged-data.model';
import { RequestGridViewModel } from '../shared/models/requests/request-gridview.model';
import { ReasonForSubmittal } from '../shared/enums/reason-for-submittal.enum';
import { ContractSelectorDialogComponent } from '../shared/components/contract-selector-dialog/contract-selector-dialog.component';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'pdp-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css'],
  host: { class: 'flex-column-layout w-100' },
  encapsulation: ViewEncapsulation.None
})
export class RequestsComponent implements OnInit, OnDestroy {
  isTabsLoading: boolean = false;
  isGridContentLoading: boolean = false;
  isError: boolean = false;

  gridData$: Observable<GridDataResult>;

  private gridStateSubject: BehaviorSubject<NGridState>;
  private tabHeaders: TabHeader[] = [];
  private subscriptions = new Subscription();

  private defaultSortCondition: SortDescriptor[] = [{
    field: 'daysInStatus',
    dir: "desc"
  }];

  gridOptions: NGridOptions = new NGridOptions({
    isPageable: true,
    isSortable: true,
    isFilterable: true,
    isFreeTextSearchEnabled: true,
    initialLoading: true
  });

  gridState: NGridState = {
    take: 25,
    sort: this.defaultSortCondition
  };

  statusWarningDeadline: number = 14;
  statusAlertDeadline: number = 21;
  instance: RequestsComponent = this;

  columnFilterOptionText = this.i18nService.transform('grid_columnFilterOption_text');
  freeTextSearchFilterOptionText = this.i18nService.transform('grid_freeTextSearchFilterOption_text');
  searchTextboxPlaceholder = this.i18nService.transform('grid_searchTextboxPlaceholder_text');

  status: RequestStatus = RequestStatus.All;
  statuses: { value: string, label: string }[] = [];

  gridColumns: INGridColumnOptionsBase[] = [    
    new NGridColumnOptions({
      field: 'sspNumber',
      title: this.i18nService.transform('label_requestId'),
      hidden: this.isRequestIdHidden,
      width: 100
    }),
    new NGridColumnOptions({
      field: 'workOrderNumber',
      title: this.i18nService.transform('label_workOrderNumber'),
      hidden: this.isWorkOrderNumberHidden,
      width: 166
    }),
    new NGridColumnOptions({
      field: 'contractNumbers',
      title: this.i18nService.transform('label_contractNumbers'),
      width: 180
    }),
    new NGridColumnOptions({
      field: 'lotNumber',
      title: this.i18nService.transform('lotNumber'),
      width: 104
    }),
    new NGridColumnOptions({
      field: 'reasonForSubmittal',
      title: this.i18nService.transform('reasonForSubmittal'),
      width: 170,
      filterType: NGridColumnFilterType.DropDown,
      filterData: this.getReasonsForSubmittal(),
      filterTextField: 'label',
      filterValueField: 'value',
      filterDefaultTextField: this.i18nService.transform('select_reasonForSubmittal')
    }),
    new NGridColumnOptions({
      field: 'status',
      title: this.i18nService.transform('label_status'),
      hidden: () => this.status !== RequestStatus.All,
      width: 240,
      filterType: NGridColumnFilterType.DropDown,
      filterData: this.getRequestStatuses(),
      filterTextField: 'label',
      filterValueField: 'value',
      filterDefaultTextField: this.i18nService.transform('select_status')
    }),
    new NGridColumnOptions({
      field: 'daysInStatus',
      title: this.i18nService.transform('label_daysInStatus'),
      width: 150,
      filterType: NGridColumnFilterType.Numeric
    }),    
    new NGridColumnOptions({
      field: 'manufacturerName',
      width: 210,
      title: this.i18nService.transform('request_label_manufacturer')
    }),
    new NGridColumnOptions({
      field: 'endItems',
      width: 170,
      title: this.i18nService.transform('label_endItems')
    }),
    new NGridColumnOptions({
      field: 'materialToBeTested',
      width: 170,
      title: this.i18nService.transform('label_materialToBeTested')
    }),
    new NGridColumnOptions({
      field: 'governmentSpecificationNumber',
      width: 170,
      title: this.i18nService.transform('specification'),
    }),
    new NGridColumnOptions({
      field: 'assignedSpecialistName',
      title: this.i18nService.transform('assigned_specialist'),
      width: 170,
      hidden: this.isAssignedSpecialistHidden
    })
  ];

  constructor(
    public i18nService: I18nService,
    private router: Router,
    private sessionService: SessionService,
    private toastr: ToastrService,
    private nGridService: NGridService,
    private dialogService: DialogService,
    private requestService: RequestService,
    private blobDownloadService: BlobDownloadService
  ) { }

  ngOnInit(): void {
    this.gridStateSubject = new BehaviorSubject<NGridState>(this.gridState);
    this.gridData$ = this.gridStateSubject.pipe(
      distinctUntilChanged(),
      switchMap(state => this.getRequests(state))
    );

    this.subscriptions.add(this.sessionService.currentUser$
      .subscribe(user => {
      if (user) {
        this.buildTabHeaders();
      }
    }));    
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  noRecordsText(): string {
    return !this.isGridContentLoading && this.isError ? this.i18nService.transform('requests_noRecordMessage') : '. . .';
  }

  getVisibleTabHeaders() {
    return this.tabHeaders.filter(h => !h.hidden);
  }

  private isRequestIdHidden(): boolean {
    return this.status < RequestStatus.PendingSpecifierApproval || this.status === RequestStatus.All;
  }

  private isWorkOrderNumberHidden(): boolean {
    return [RequestStatus.PendingSpecifierApproval,
      RequestStatus.ArchivedAndConfirmed,
      RequestStatus.ArchivedAndRejected].includes(this.status);
  }

  private isAssignedSpecialistHidden(): boolean {
    return this.status < RequestStatus.PendingSpecifierApproval || this.status === RequestStatus.All;
  }

  private buildTabHeaders() {
    this.isTabsLoading = true;
    this.requestService.getStatusCounts().subscribe(response => {
      this.tabHeaders = response.body.map(s => {
        return <TabHeader>{
          key: s.status,
          label: this.resolveStatus(s.status),
          itemCount: s.count,
          hidden: s.count == 0 && s.status != RequestStatus.All
        }
      });
      this.isTabsLoading = false;
    },
      error => {
        this.toastr.error(error.message);
      });
  }

  private getReasonsForSubmittal(): any[] {
    let reasons = [];
    for (const value in ReasonForSubmittal) {
      if (typeof ReasonForSubmittal[value] === 'string') {
        const label = this.i18nService.transform('reasonForSubmittal_' + value);
        reasons.push({ value: (<any>ReasonForSubmittal[value]).toString(), label: label });
      }
    }
    return reasons;
  }

  private getRequestStatuses(): any[] {
    let statuses = [];
    for (const n in RequestStatus) {
      if (typeof RequestStatus[n] === 'number') {
        const label = this.i18nService.transform('requestStatus_' + n);
        statuses.push({ value: (<any>RequestStatus[n]).toString(), label: label });
      }
    }
    return statuses;
  }

  getRequests(state: NGridState): Observable<NGridPagedData<RequestGridViewModel>> {
    this.isGridContentLoading = true;

    return this.requestService.getRequests(state).pipe(
      tap(() => {
        this.isGridContentLoading = false;
      }),
      catchError((error, caught) => {
        this.toastr.error(error.message);
        this.isGridContentLoading = false;
        this.isError = true;

        return EMPTY;
      })
    );
  }

  onTabSelect(e) {
    this.status = this.getVisibleTabHeaders()[e.index].key;
    if (this.status === RequestStatus.All) {
      this.gridState = this.nGridService.removeFilter(this.gridState, 'status');
    } else {
      this.gridState = this.nGridService.applyFilter(this.gridState, 'status', this.status.toString());
    }
    this.gridState.skip = 0;
    this.gridState.sort = this.defaultSortCondition;
    this.gridStateChange(this.gridState);
  }

  gridStateChange(state: NGridState): void {
    this.gridState = state;
    this.gridStateSubject.next(state);
  }

  selectionChange(e): void {
    if (e.selectedRows && e.selectedRows.length > 0) {
      const requestId = e.selectedRows[0].dataItem.id;
      this.router.navigateByUrl(`request/${requestId}`);
    }
  }

  resolveStatus(status: RequestStatus): string {
    return this.i18nService.transform(`requestStatus_${RequestStatus[status]}`);
  }

  stylizeRowsByDaysInStatus: RowClassFn = (context: RowClassArgs) => {
    const statusWarningDeadline = this.statusWarningDeadline;
    const statusAlertDeadline = this.statusAlertDeadline;
    return {
      'status-warning': context.dataItem.status < RequestStatus.ArchivedAndConfirmed && context.dataItem.daysInStatus >= statusWarningDeadline,
      'status-alert': context.dataItem.status < RequestStatus.ArchivedAndConfirmed && context.dataItem.daysInStatus >= statusAlertDeadline,
      'clickable': true
    };
  }


  public openContractSelectorDialog() {
    this.dialogService.open({
      content: ContractSelectorDialogComponent,
      title: this.i18nService.transform('createRequest'),
      width: '45%'
    });
  }

  downloadAttachment(request: Request, attachment: Attachment) {
    if (attachment.category == AttachmentCategory.DD1222) {
      this.requestService.downloadDD1222(request.id).subscribe(
        blob => {
          const name = (request.sspNumber != null ? request.sspNumber : request.workOrderNumber) + "_DD1222.pdf";
          this.blobDownloadService.downloadBlob(blob, name);
        });
    } else {
      this.requestService.downloadLabReport(request.id, attachment.id).subscribe(blob => {
        this.blobDownloadService.downloadBlob(blob, attachment.name);
      });
    }
  }
}
