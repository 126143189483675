<div [ngSwitch]="testType">
  <ng-template [ngSwitchCase]="testTypeEnum['Logical']">
    <div class="d-flex mb-05 justify-content-between" ngModelGroup="member_{{index}}">
      <div class="px-0 col">
        <input type="text" class="form-control" required name="memberName_{{index}}_0" #memberName="ngModel" (change)="onMemberChange()"
               [(ngModel)]="testMethodMember.name" placeholder="{{'condition'|i18n}}" [disabled]="testAttributeOverrides != null" />
        <div *ngIf="isSubmitted && !memberName.valid" class="alert alert-danger">
          {{'condition'|i18n}} {{'is_required'|i18n}}
        </div>
      </div>
      <div class="px-3 pt-2 text-nowrap">
        <p class="m-0">{{'the_condition_should_be'|i18n}}</p>
      </div>
      <div class="d-flex">
        <pdp-test-attribute-logical [testAttribute]="getTestAttribute(testMethodMember, 0)" nameIndex="0"
                                    [readonly]="readonly" class="flex-fill"></pdp-test-attribute-logical>
      </div>
    </div>
  </ng-template>
  <ng-template [ngSwitchCase]="testTypeEnum['ExclusiveRange']">
    <div class="d-flex mb-05 justify-content-between" ngModelGroup="member_{{index}}" [minMaxNames]="minMaxNames">
      <div class="pe-3" *ngIf="!isOnlyMember">
        <input type="text" class="form-control min-w-5" required name="memberName_{{index}}_0" #memberName="ngModel" (change)="onMemberChange()"
               [(ngModel)]="testMethodMember.name" placeholder="{{'condition_or_property'|i18n}}" [disabled]="testAttributeOverrides != null" />
        <div *ngIf="isSubmitted && !memberName.valid" class="alert alert-danger">
          {{'condition_or_property'|i18n}} {{'is_required'|i18n}}
        </div>
      </div>
      <div class="pe-3 pt-2 text-nowrap">
        <p class="m-0">{{'test_method_expected_values'|i18n}}</p>
      </div>
      <div class="d-flex col px-0">
        <pdp-test-attribute-numeric [testAttribute]="getTestAttribute(testMethodMember, 0)" nameIndex="0" [testAttributeOverrides]="testAttributeOverrides"
                                    [isLotAverageConditionComponent]="isLotAverageConditionComponent"
                                    [allowedOperators]="[operatorTypeEnum.LessThanOrEqual, operatorTypeEnum.LessThan]"
                                    [numberFormat]="numberFormat"
                                    [isGrayScale]="isGrayScale" (attributeChange)="onAttributeChange()"
                                    [placeholder]="'minimum'" class="col px-0" [readonly]="readonly" [isSubmitted]="isSubmitted"></pdp-test-attribute-numeric>
        <div class="py-2 px-3">
          <p class="m-0">{{'and'|i18n}}</p>
        </div>
        <pdp-test-attribute-numeric [testAttribute]="getTestAttribute(testMethodMember, 1)" nameIndex="1" [testAttributeOverrides]="testAttributeOverrides"
                                    [isLotAverageConditionComponent]="isLotAverageConditionComponent"
                                    [allowedOperators]="[operatorTypeEnum.GreaterThanOrEqual, operatorTypeEnum.GreaterThan]"
                                    [numberFormat]="numberFormat"
                                    [isGrayScale]="isGrayScale" (attributeChange)="onAttributeChange()"
                                    [placeholder]="'maximum'" class="col px-0" [readonly]="readonly" [isSubmitted]="isSubmitted"></pdp-test-attribute-numeric>
      </div>
    </div>
  </ng-template>
  <ng-template [ngSwitchCase]="testTypeEnum['Equals']">
    <div class="d-flex mb-05  justify-content-between" ngModelGroup="member_{{index}}">
      <div class="pe-3 col" *ngIf="!isOnlyMember">
        <input type="text" class="form-control min-w-5" required name="memberName_{{index}}_0" #memberName="ngModel" (memberChange)="onMemberChange()"
               [(ngModel)]="testMethodMember.name" placeholder="{{'condition_or_property'|i18n}}" [disabled]="testAttributeOverrides != null" />
        <div *ngIf="isSubmitted && !memberName.valid" class="alert alert-danger">
          {{'condition_or_property'|i18n}} {{'is_required'|i18n}}
        </div>
      </div>
      <div class="pe-3 pt-2 text-nowrap">
        <p class="m-0">{{'test_method_expected_value'|i18n}}</p>
      </div>
      <div class="d-flex px-0 col">
        <pdp-test-attribute-numeric [testAttribute]="getTestAttribute(testMethodMember, 0)" nameIndex="0" [testAttributeOverrides]="testAttributeOverrides"
                                    [isLotAverageConditionComponent]="isLotAverageConditionComponent"
                                    [allowedOperators]="[operatorTypeEnum.Equals]"
                                    [numberFormat]="numberFormat"
                                    [isGrayScale]="isGrayScale" (attributeChange)="onAttributeChange()"
                                    [placeholder]="'equals'" class="flex-fill" [readonly]="readonly" [isSubmitted]="isSubmitted"></pdp-test-attribute-numeric>
      </div>
    </div>
  </ng-template>
  <ng-template [ngSwitchCase]="testTypeEnum['Minimum']">
    <div class="d-flex mb-05  justify-content-between" ngModelGroup="member_{{index}}">
      <div class="pe-3 col" *ngIf="!isOnlyMember">
        <input type="text" class="form-control min-w-5" required name="memberName_{{index}}_0" #memberName="ngModel" (change)="onMemberChange()"
               [(ngModel)]="testMethodMember.name" placeholder="{{'condition_or_property'|i18n}}" [disabled]="testAttributeOverrides != null" />
        <div *ngIf="isSubmitted && !memberName.valid" class="alert alert-danger">
          {{'condition_or_property'|i18n}} {{'is_required'|i18n}}
        </div>
      </div>
      <div class="pe-3 pt-2 text-nowrap">
        <p class="m-0">{{'test_method_expected_value'|i18n}}</p>
      </div>
      <div class="d-flex px-0 col">
        <pdp-test-attribute-numeric [testAttribute]="getTestAttribute(testMethodMember, 0)" nameIndex="0" [testAttributeOverrides]="testAttributeOverrides"
                                    [isLotAverageConditionComponent]="isLotAverageConditionComponent"
                                    [allowedOperators]="[operatorTypeEnum.GreaterThanOrEqual, operatorTypeEnum.GreaterThan]"
                                    [numberFormat]="numberFormat"
                                    [isGrayScale]="isGrayScale" (attributeChange)="onAttributeChange()"
                                    [placeholder]="'minimum'" class="flex-fill" [readonly]="readonly" [isSubmitted]="isSubmitted"></pdp-test-attribute-numeric>
      </div>
    </div>
  </ng-template>
  <ng-template [ngSwitchCase]="testTypeEnum['Maximum']">
    <div class="d-flex mb-05 justify-content-between" ngModelGroup="member_{{index}}">
      <div class="pe-3 col" *ngIf="!isOnlyMember">
        <input type="text" class="form-control min-w-5" required name="memberName_{{index}}_0" #memberName="ngModel" (change)="onMemberChange()"
               [(ngModel)]="testMethodMember.name" placeholder="{{'condition_or_property'|i18n}}" [disabled]="testAttributeOverrides != null" />
        <div *ngIf="isSubmitted && !memberName.valid" class="alert alert-danger">
          {{'condition_or_property'|i18n}} {{'is_required'|i18n}}
        </div>
      </div>
      <div class="pe-3 pt-2 text-nowrap">
        <p class="m-0">{{'test_method_expected_value'|i18n}}</p>
      </div>
      <div class="d-flex px-0 col">
        <pdp-test-attribute-numeric [testAttribute]="getTestAttribute(testMethodMember, 0)" nameIndex="0" [testAttributeOverrides]="testAttributeOverrides"
                                    [isLotAverageConditionComponent]="isLotAverageConditionComponent"
                                    [allowedOperators]="[operatorTypeEnum.LessThanOrEqual, operatorTypeEnum.LessThan]"
                                    [numberFormat]="numberFormat"
                                    [isGrayScale]="isGrayScale" (attributeChange)="onAttributeChange()"
                                    [placeholder]="'maximum'" class="flex-fill" [readonly]="readonly" [isSubmitted]="isSubmitted"></pdp-test-attribute-numeric>
      </div>
    </div>
  </ng-template>
  <ng-template ngSwitchDefault>
    <div class="d-flex mb-05 justify-content-between" ngModelGroup="member_{{index}}" [minMaxNames]="minMaxNames">
      <div class="pe-3" *ngIf="!isOnlyMember">
        <input type="text" class="form-control min-w-5" required name="memberName_{{index}}_0" #memberName="ngModel" (change)="onMemberChange()"
               [(ngModel)]="testMethodMember.name" placeholder="{{'condition_or_property'|i18n}}" [disabled]="testAttributeOverrides != null" />
        <div *ngIf="isSubmitted && !memberName.valid" class="alert alert-danger">
          {{'condition_or_property'|i18n}} {{'is_required'|i18n}}
        </div>
      </div>
      <div class="pe-3 pt-2 text-nowrap">
        <p class="m-0">{{'test_method_expected_values'|i18n}}</p>
      </div>
      <div class="d-flex col px-0" [minMaxNames]="minMaxNames">
        <pdp-test-attribute-numeric [testAttribute]="getTestAttribute(testMethodMember, 0)" nameIndex="0" [testAttributeOverrides]="testAttributeOverrides"
                                    [isLotAverageConditionComponent]="isLotAverageConditionComponent"
                                    [allowedOperators]="[operatorTypeEnum.GreaterThanOrEqual, operatorTypeEnum.GreaterThan]"
                                    [numberFormat]="numberFormat"
                                    [isGrayScale]="isGrayScale" (attributeChange)="onAttributeChange()"
                                    [placeholder]="'minimum'" class="col px-0" [readonly]="readonly" [isSubmitted]="isSubmitted"></pdp-test-attribute-numeric>
        <div class="py-2 px-3">
          <p class="m-0">{{'and'|i18n}}</p>
        </div>
        <pdp-test-attribute-numeric [testAttribute]="getTestAttribute(testMethodMember, 1)" nameIndex="1" [testAttributeOverrides]="testAttributeOverrides"
                                    [isLotAverageConditionComponent]="isLotAverageConditionComponent"
                                    [allowedOperators]="[operatorTypeEnum.LessThanOrEqual, operatorTypeEnum.LessThan]"
                                    [numberFormat]="numberFormat"
                                    [isGrayScale]="isGrayScale" (attributeChange)="onAttributeChange()"
                                    [placeholder]="'maximum'" class="col px-0" [readonly]="readonly" [isSubmitted]="isSubmitted"></pdp-test-attribute-numeric>

      </div>
    </div>
  </ng-template>
</div>
