<kendo-dialog-titlebar (close)="onClose($event)">
  <span>{{title}}</span>
</kendo-dialog-titlebar>

<div class="container">
  <form #methodGroupForm="ngForm">
    <div class="form-group row mb-2">
      <label class="col-sm-3 col-form-label" for="contractNumber">{{'test_method_group_name'|i18n}}</label>

      <div class="col-sm-9">
        <input type="text"
               class="form-control"
               id="methodGroupName"
               required
               name="methodGroupName"
               #name="ngModel"
               [disabled]="readonly"
               [(ngModel)]="testMethodGroup.name" />
        <div *ngIf="isNameInvalid(name)" class="alert alert-danger">{{'name'|i18n}} {{'is_required'|i18n}}</div>
      </div>
    </div>
    <hr />
    <div class="form-group row" *ngIf="!readonly">
      <div class="col pe-0">
        <kendo-combobox #testMethodComboBox
                        [data]="groupedData$ | async"
                        [textField]="'summary'"
                        [valueField]="'id'"
                        [ngModel]="selectedTestMethodVariant"
                        name="testMethodComboBox"
                        class="w-100"
                        placeholder="{{'searchForTestMethod'|i18n}}"
                        [filterable]="true"
                        [listHeight]="300"
                        [itemDisabled]="itemDisabled"
                        (filterChange)="filterTestMethodVariants($event)"
                        (valueChange)="testMethodVariantChanged($event)">
          <ng-template kendoComboBoxFixedGroupTemplate let-testMethodId>
            <ng-template *ngTemplateOutlet="groupHeader; context: {tm: getTestMethod(testMethodId)}"></ng-template>
          </ng-template>
          <ng-template kendoComboBoxGroupTemplate let-testMethodId>
            <ng-template *ngTemplateOutlet="groupHeader; context: {tm: getTestMethod(testMethodId)}"></ng-template>
          </ng-template>
          <ng-template kendoComboBoxItemTemplate let-dataItem>
            <div *ngIf="dataItem" class="test-method-item">
              <div class="test-method-selector-name test-method-variant ms-3">                
                <ng-template *ngTemplateOutlet="classificationSummary; context: {tmv: dataItem}"></ng-template>
              </div>
            </div>
          </ng-template>
          <ng-template kendoComboBoxNoDataTemplate>
            {{'noSearchResult' | i18n}}
          </ng-template>
          <ng-template kendoComboBoxFooterTemplate>
            <div *ngIf="resultInfo" class="test-method-selector-footer">{{resultInfo}}</div>
          </ng-template>
        </kendo-combobox>
      </div>
      <div class="col-auto ps-0">
        <button kendoButton class="ms-1" themeColor="secondary" (click)="addTestMethodGroupItem(testMethodComboBox)" [disabled]="selectedTestMethodVariant == null">
          <i class="fas fa-plus me-2"></i>{{'add_test_method_or_variant'|i18n}}
        </button>
      </div>
    </div>

    <div class="form-group row">
      <input type="hidden" [(ngModel)]="testMethodGroupItemsCheckValue" name="testMethodGroupItemsCheck" required />
      <div class="col">
        <n-grid *ngIf="!isItemsInvalid()"
                [data]="gridData"
                [columns]="gridColumns"
                [options]="gridOptions">
          <ng-template nGridCellTemplate forField="actions" let-rowData let-rowIndex="rowIndex">
            <div class="d-flex" *ngIf="!readonly">
              <div class="d-flex" *ngIf="testMethodGroup.testMethodGroupItems.length > 1">
                <button kendoButton
                        themeColor="secondary"
                        class="ms-1 h-38"
                        [disabled]="rowIndex <= 0"
                        (click)="moveSortableItemUp($event, testMethodGroup.testMethodGroupItems, rowData)"
                        title="{{'moveRequirementUp' | i18n}}">
                  <i class="fas fa-angle-double-up"></i>
                </button>
                <button kendoButton
                        themeColor="secondary"
                        class="ms-1 h-38"
                        [disabled]="rowIndex >= testMethodGroup.testMethodGroupItems.length-1"
                        (click)="moveSortableItemDown($event, testMethodGroup.testMethodGroupItems, rowData)"
                        title="{{'moveRequirementDown' | i18n}}">
                  <i class="fas fa-angle-double-down"></i>
                </button>
              </div>
              <div kendoTooltip tooltipClass="tooltip-wrapper" position="bottom">
                <button kendoButton
                        themeColor="error"
                        class="h-38 ms-1"
                        (click)="deleteTestMethodGroupItem($event, rowData)">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>            
          </ng-template>
        </n-grid>
        <div *ngIf="isItemsInvalid()" class="p-2 alert alert-danger text-center">{{'no_test_method_group_items_message'|i18n}}</div>
      </div>
    </div>
  </form>
</div>
<kendo-dialog-actions layout="normal">
  <button *ngIf="!readonly" kendoButton themeColor="primary" (click)="saveMethodGroup()" [disabled]="isFormInvalid()">{{actionName}}</button>
  <button *ngIf="readonly" kendoButton themeColor="primary" (click)="close()">{{'close' | i18n}}</button>
</kendo-dialog-actions>

<ng-template #groupHeader let-tm="tm">
  <div *ngIf="tm" class="test-method-item">
    <div class="test-method-selector-name">{{tm.summary}}</div>
    <div class="test-method-details">
      <div class="test-method-selector-comment">{{tm.comment}}</div>
      <div><label class="me-2">{{'unit_of_measure' | i18n}}:</label>{{tm.unitOfMeasure}}</div>
    </div>
  </div>
</ng-template>

<ng-template #classificationSummary let-tmv="tmv">
  <span *ngIf="isGroupMember(tmv)" class="member-indicator"><i class="fas fa-check-circle"></i></span>
  <span *ngIf="tmv.description" class="pe-2">[{{tmv.description}}]</span>
  <span *ngIf="tmv.specificationType">{{tmv.specificationType}}</span>
  <span *ngIf="tmv.specificationClass"> - {{tmv.specificationClass}}</span>
  <span *ngIf="tmv.specificationSubClass"> - {{tmv.specificationSubClass}}</span>
</ng-template>
