import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthenticatedResult } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, skipWhile } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const requiredRoles = route.data.roles || [];
    return this.checkUserRoles(requiredRoles);
  }

  private checkUserRoles(requiredRoles: string[]): Observable<boolean> {
    return this.authService.isAuthenticated$.pipe(
      skipWhile((authenticatedResult: AuthenticatedResult) => !authenticatedResult.isAuthenticated),
      switchMap((authenticatedResult) => {
        return this.authService.userData$.pipe(
          skipWhile(({ allUserData, userData }) => !userData),
          map(({ allUserData, userData }) => {
            if (requiredRoles.length === 0) {
              return true
            }

            if (!userData || !userData.role) {
              return false;
            }

            const roles = userData.role;
            return requiredRoles.some(r => roles.includes(r));
          }),
          catchError(error => {
            return of(false);
          }))
      }
      )
    );
  }
}
