import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from "../services/i18n.service";

@Pipe({
    name: 'i18n',
  pure: false
})


// Resolves resource by the specified key or returns key if there's no match
//
// If resolved resource text can contain html, use pipe with [innerHtml] property binding!
// Example: <div [innerHtml]="'translation_key' | i18n"></div>
//
// Replacment parameters can be specified in params object in case the transformed resource is a text template.
//
// Example: resource: { 'list_count': 'List has @count items.' }
// Call: transform('list_count', { '@count': list.length });

export class I18nPipe implements PipeTransform {
    constructor(private resources: I18nService) { }
  transform(key: any, params: any = null): any {
    return this.resources.transform(key, params) || key;
  }
}
