import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NGridPagedData } from '../components/n-grid/models/n-grid-paged-data.model';
import { NGridState } from '../components/n-grid/models/n-grid-state.model';
import { NGridService } from '../components/n-grid/n-grid.service';
import { TestMethodGroup } from '../models/test-method-groups/test-method-group.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class TestMethodGroupsService {

  private testMethodGroupsUrl = environment.apiBaseUrl + 'api/v1/TestMethodGroup';

  constructor(
    private http: HttpClient,
    private nGridService: NGridService) { }

  /** GET test method groups from the server */
  getTestMethodGroups(state: NGridState): Observable<NGridPagedData<TestMethodGroup>> {
    let httpParams = new HttpParams();
    httpParams = this.nGridService.stateToHttpParams(state, httpParams);
    return this.http.get<NGridPagedData<TestMethodGroup>>(this.testMethodGroupsUrl, { observe: 'body', params: httpParams });
  }

  /** POST: create a new test method group */
  addTestMethodGroup(testMethodGroup: TestMethodGroup): Observable<TestMethodGroup> {
    return this.http.post<TestMethodGroup>(this.testMethodGroupsUrl, testMethodGroup, httpOptions);
  }

  /** PUT: updates the test method on the server */
  updateTestMethodGroup(testMethodGroup: TestMethodGroup): Observable<TestMethodGroup> {
    return this.http.put<TestMethodGroup>(this.testMethodGroupsUrl, testMethodGroup, httpOptions);
  }

  /** DELETE: deletes the test method from the server */
  deleteTestMethodGroup(testMethodGroup: TestMethodGroup | number): Observable<any> {
    const id = typeof testMethodGroup === 'number' ? testMethodGroup : testMethodGroup.id;
    const url = `${this.testMethodGroupsUrl}/${id}`;
    return this.http.delete(url, httpOptions);
  }
}
