import { Injectable, Injector } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';

/** Add the auth headers and pass the request through to the next request handler. */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private authService: AuthService;

  constructor(private injector: Injector) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let requestToForward = req;

    const configRoute = ['api/v1/Configuration'].filter(s => req.url.includes(s)).length > 0;
    if (configRoute) {
      return next.handle(req);
    }

    if (this.authService === undefined) {
      this.authService = this.injector.get(AuthService);
    }

    return this.authService.getAccessToken().pipe(
      switchMap((tokenValue) => {
        requestToForward = req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + tokenValue
          }
        });

        return next.handle(requestToForward);
      }
      ));
  }
}
