<div class="flex-column-layout flex-fill m-05">
  <div>
    <h4>{{'test_methods'|i18n}}</h4>
    <button kendoButton themeColor="primary" (click)="openCreateTestMethodDialog()" class="mb-05 me-1"><i class="fas fa-plus pe-2"></i>{{'test_method_new'|i18n}}</button>
    <button kendoButton themeColor="primary" *featureToggle="Feature.FindSpecification" (click)="openFindSpecificationDialog()" class="mb-05"><i class="fas fa-search pe-2"></i>{{'find_specification'|i18n}}</button>
  </div>
  <kendo-tabstrip [scrollable]="true"
                  (tabSelect)="onTabSelect($event)"
                  class="grid-container flex-column-layout flex-fill">
    <kendo-tabstrip-tab *ngFor="let header of tabHeaders; let i = index;" [selected]="i==0">
      <ng-template kendoTabTitle>
        {{header.label}}
      </ng-template>
      <ng-template kendoTabContent>
        <div class="flex-column-layout flex-fill">
          <n-grid [data]="gridData$ | async"
                  class="mb-05 test-methods-grid"
                  [columns]="gridColumns"
                  [options]="gridOptions"
                  [state]="gridState"
                  [stateChangeDebounceTime]="1000"
                  [isLoading]="isLoading"
                  [noRecordsText]="noRecordsText()"
                  [columnFilterOptionText]="columnFilterOptionText"
                  [freeTextSearchFilterOptionText]="freeTextSearchFilterOptionText"
                  [searchTextboxPlaceholder]="searchTextboxPlaceholder"
                  (gridStateChange)="gridStateChange($event)">
            <ng-template nGridCellTemplate forField="actions" let-rowData>
              <div kendoTooltip tooltipClass="tooltip-wrapper" position="bottom">
                <button kendoButton themeColor="secondary" (click)="openTestMethodDetailsDialog(rowData)">{{'home_agenda_action_button_openDetails'|i18n}}</button>
                <span title="{{rowData.isReadOnly?  ('test_method_used' | i18n) : null}}">
                  <button [disabled]="!rowData.isDeletable" kendoButton themeColor="error" class="h-38 ms-1" (click)="deleteTestMethod(rowData)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </span>
              </div>
            </ng-template>
            <ng-template nGridCellTemplate forField="modified" let-rowData>
              {{rowData.modified | date: dateFormatString}}
            </ng-template>
            <ng-template nGridCellTemplate forField="archiveDate" let-rowData>
              <ng-container *ngIf="rowData.archiveDate">{{rowData.archiveDate | date: dateFormatString}}</ng-container>
            </ng-template>

            <ng-template nGridCellTemplate forField="testType" let-rowData>
              {{rowData.testType.toLowerFirstLetter()|i18n}}
            </ng-template>
            <ng-template nGridDetailTemplate let-rowData>
              <div class="pe-3 my-2 text-center">
                <div class="row">
                  <div class="col-sm-6">
                    <b>{{'description' | i18n}}</b>
                  </div>
                  <div class="col-sm-2">
                    <b>{{'type' | i18n}}</b>
                  </div>
                  <div class="col-sm-2">
                    <b>{{'class' | i18n}}</b>
                  </div>
                  <div class="col-sm-2">
                    <b>{{'sub_class' | i18n}}</b>
                  </div>
                </div>
                <div class="row mx-0" *ngFor="let testMethodVariant of rowData.testMethodVariants">
                  <hr class="w-100 mx-2 my-2">
                  <div class="col-sm-6">
                    {{testMethodVariant.description}}
                  </div>
                  <div class="col-sm-2">
                    {{testMethodVariant.specificationType}}
                  </div>
                  <div class="col-sm-2">
                    {{testMethodVariant.specificationClass}}
                  </div>
                  <div class="col-sm-2">
                    {{testMethodVariant.specificationSubClass}}
                  </div>
                </div>
              </div>
            </ng-template>
          </n-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
