import { Component, OnInit, ViewChild, ViewEncapsulation, ElementRef, OnDestroy, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { RequestService } from '../../shared/services/request.service';
import { Request } from '../../shared/models/requests/request.model';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestStatus } from '../../shared/enums/request-status.enum';
import { PanelBarItemComponent, PanelBarItemModel } from '@progress/kendo-angular-layout';
import { ContractsService } from '../../shared/services/contracts.service';
import { CompaniesService } from '../../shared/services/companies.service';
import { Company } from '../../shared/models/companies/company.model';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { I18nService } from '../../shared/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, Subject, Subscription } from 'rxjs';
import { SessionService } from '../../shared/services/session.service';
import { AuthorizationService } from '../../shared/services/authorization.service';
import { SortingService } from '../../shared/services/sorting.service';
import { MessageBoxService } from '../../shared/components/message-box/message-box.service';
import { MessageBoxType } from '../../shared/components/message-box/enums/message-box-type.enum';
import { MessageBoxPrimaryButton } from '../../shared/components/message-box/enums/message-box-primary-button.enum';
import { BlobDownloadService } from '../../shared/services/blob-download.service';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DatePipe } from '@angular/common';
import { LocalizationService } from '../../shared/services/localization.service';
import { CloningOptions } from '../../shared/models/cloning-options.model';
import { ComponentCanDeactivate } from '../../shared/services/component-can-deactivate';
import { AuditLogsComponent } from '../../audit-logs/audit-logs.component';
import { RequestContract } from '../../shared/models/requests/request-contract.model';
import { User } from '../../shared/models/users/user.model';
import { UsersService } from '../../shared/services/users.service';
import { PendingChangesService } from '../../shared/services/pending-changes.service';
import { debounceTime } from 'rxjs/operators';
import { DateService } from '../../shared/services/date.service';
import { TestDetailsComponent } from './test-details/test-details.component';
import { ErrorMessageService } from '../../shared/services/error-message.service';
import { ErrorCodes } from '../../shared/enums/error-codes.enum';
import { SaveType } from '../../shared/enums/save-type.enum';
import { TestRequirementsComponent } from './test-requirements/test-requirements.component';
import { ReasonForSubmittal } from '../../shared/enums/reason-for-submittal.enum';
import { CollapseInfoBuilder } from './collapse-info-builder';
import { Observable } from 'rxjs';
import { CloneRequestDialogComponent } from './clone-request-dialog/clone-request-dialog.component';
import { SpecifierObservationType } from '../../shared/enums/specifier-observation-type.enum';

@Component({
  selector: 'pdp-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.css'],
  host: { class: 'flex-column-layout flex-fill position-relative' },
  encapsulation: ViewEncapsulation.None
})
export class RequestDetailsComponent extends ComponentCanDeactivate implements OnInit, AfterViewInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private baseRequestKey = 'baseRequest';
  private cloningOptionsKey = 'cloningOptions';
  private isCloningInProgress = false;
  private invalidControlSelectorPattern = ':not(.ignore-ng-invalid):not([type="hidden"]).ng-invalid';

  request: Request;
  requestSubject: BehaviorSubject<Request> = new BehaviorSubject<Request>(null);
  viewRenderedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  reasonForSubmittalSubject: Subject<ReasonForSubmittal> = new Subject<ReasonForSubmittal>();
  reasonForSubmittalChanged$: Observable<ReasonForSubmittal>;

  private saveAttemptSubject: Subject<SaveType> = new Subject<SaveType>();
  private invalidControlDelayedScrollingSubject: Subject<HTMLElement> = new Subject<HTMLElement>();

  companies: Company[] = [];
  manufacturer: Company;
  laboratory: Company;
  filteredLaboratories: Company[];
  filteredManufacturers: Company[];

  endItems: string;
  specifierContractor: Company;
  primeContractor: Company;

  public defaultSpecialist = { fullName: this.i18nService.transform('select_specialist'), email: this.i18nService.transform('select_specialist'), value: null };
  specialists: User[] = [];
  isSystemOrPortalAdmin = false;
  isSsr = false;
  isLabManager = false;
  invalidControlHandlingPanelShouldShow = false;
  isSaveAndUpgradeInProgress = false;
  isDownloadInProgress = false;

  notificationMessage = '';
  yesAbbreviationText = this.i18nService.transform('yesAbbreviation');
  noAbbreviationText = this.i18nService.transform('noAbbreviation');

  // Variables on template
  currentStatus: RequestStatus = null;
  currentReasonForSubmittal: string = null;

  RequestStatus = RequestStatus;
  SaveType = SaveType;

  reasonsForSubmittal: { text: string, value: string }[];

  private workflows: { name: ReasonForSubmittal, steps: RequestStatus[] }[] = [
    {
      name: ReasonForSubmittal.SourceSampling,
      steps: [
        RequestStatus.New,
        RequestStatus.PendingSsrApproval,
        RequestStatus.InProgress,
        RequestStatus.PendingLabMgrApproval,
        RequestStatus.PendingSpecifierApproval,
        RequestStatus.ArchivedAndConfirmed
      ]
    },
    {
      name: ReasonForSubmittal.CorrelationTesting,
      steps: [
        RequestStatus.New,
        RequestStatus.PendingSsrApproval,
        RequestStatus.PendingFinalizeRequirements,
        RequestStatus.InProgress,
        RequestStatus.PendingLabMgrApproval,
        RequestStatus.PendingSpecifierApproval,
        RequestStatus.ArchivedAndConfirmed
      ]
    },
    {
      name: ReasonForSubmittal.VerificationTesting,
      steps: [
        RequestStatus.New,
        RequestStatus.PendingSsrApproval,
        RequestStatus.PendingFinalizeRequirements,
        RequestStatus.InProgress,
        RequestStatus.PendingLabMgrApproval,
        RequestStatus.PendingSpecifierApproval,
        RequestStatus.ArchivedAndConfirmed
      ]
    },
    {
      name: ReasonForSubmittal.ShadeEvaluation,
      steps: [
        RequestStatus.New,
        RequestStatus.PendingSsrApproval,
        RequestStatus.PendingFinalizeRequirements,
        RequestStatus.InProgress,
        RequestStatus.PendingLabMgrApproval,
        RequestStatus.PendingSpecifierApproval,
        RequestStatus.ArchivedAndConfirmed
      ]
    }
  ];

  private reasonsWithRegularWorkflow: string[] = this.workflows.filter(w => !w.steps.includes(RequestStatus.PendingFinalizeRequirements)).map(w => w.name);
  private reasonsWithExtendedWorkflow: string[] = this.workflows.filter(w => w.steps.includes(RequestStatus.PendingFinalizeRequirements)).map(w => w.name);

  dateFormatString: string;
  plannedTestingDateMinErrorMessage: string;

  requestDetailsId = 'requestDetails';
  testRequirementsId = 'testRequirements';
  sampleInformationId = 'sampleInformation';
  testDetailsId = 'testDetails';
  ptcResponseId = 'ptcResponse';
  ptcReviewId = 'ptcReview';

  isRequestDetailsExpanded: boolean;
  isTestRequirementsExpanded: boolean;
  isSampleInformationExpanded: boolean;
  isTestDetailsExpanded: boolean;
  isPtcResponseExpanded: boolean;
  isPtcReviewExpanded: boolean;

  isLoading: boolean;
  isSavingInProgress: boolean;
  isValidatingInProgress = false;
  plannedTestingDateValue: Date;
  dateOfManufactureValue: Date;
  invalidControlCount = 0;

  savingInProgressDialog: DialogRef;
  validatingInputDialog: DialogRef;
  inProgressDialog: DialogRef;

  @ViewChildren('requestForm')
  private formComponents: QueryList<NgForm>;
  private requestForm: NgForm;

  @ViewChild('requestDetailsPanel', { read: ElementRef })
  public requestDetailsPanelElement: ElementRef;
  @ViewChild('requestDetailsPanel', { read: PanelBarItemComponent })
  public requestDetailsPanel: PanelBarItemComponent;

  @ViewChild('testRequirementsPanel', { read: ElementRef })
  public testRequirementsPanelElement: ElementRef;
  @ViewChild('testRequirementsPanel', { read: PanelBarItemComponent })
  public testRequirementsPanel: PanelBarItemComponent;
  @ViewChild('testRequirementsComponent', { read: TestRequirementsComponent })
  public testRequirementsComponent: TestRequirementsComponent;

  @ViewChild('sampleInformationPanel', { read: ElementRef })
  public sampleInformationPanelElement: ElementRef;
  @ViewChild('sampleInformationPanel', { read: PanelBarItemComponent })
  public sampleInformationPanel: PanelBarItemComponent;

  @ViewChild('ptcResponsePanel', { read: ElementRef })
  public ptcResponsePanelElement: ElementRef;
  @ViewChild('ptcResponsePanel', { read: PanelBarItemComponent })
  public ptcResponsePanel: PanelBarItemComponent;

  @ViewChild('testDetailsPanel', { read: ElementRef })
  public testDetailsPanelElement: ElementRef;
  @ViewChild('testDetailsPanel', { read: PanelBarItemComponent })
  public testDetailsPanel: PanelBarItemComponent;
  @ViewChild('testDetailsComponent', { read: TestDetailsComponent })
  public testDetailsComponent: TestDetailsComponent;


  @ViewChild('ptcReviewPanel', { read: ElementRef })
  public ptcReviewPanelElement: ElementRef;
  @ViewChild('ptcReviewPanel', { read: PanelBarItemComponent })
  public ptcReviewPanel: PanelBarItemComponent;

  constructor(
    private rootElement: ElementRef,
    private usersService: UsersService,
    private requestService: RequestService,
    private contractService: ContractsService,
    private companiesService: CompaniesService,
    private sessionService: SessionService,
    private authorizationService: AuthorizationService,
    private messageBoxService: MessageBoxService,
    private dialogService: DialogService,
    private localizationService: LocalizationService,
    private toastr: ToastrService,
    private sortingService: SortingService,
    private datePipe: DatePipe,
    private dateService: DateService,
    private router: Router,
    private route: ActivatedRoute,
    private blobDownloadService: BlobDownloadService,
    private pendingChangesService: PendingChangesService,
    private errorMessageService: ErrorMessageService,
    public i18nService: I18nService,
    private ref: ChangeDetectorRef
  ) {
    super(i18nService);
  }

  /* Lifecycle hook methods */

  getReasonForSubmittalDataSource(includeCorrelationTesting: boolean = false) {
    let items = [
      { text: this.i18nService.transform('reasonForSubmittal_SourceSampling'), value: ReasonForSubmittal.SourceSampling },
      { text: this.i18nService.transform('reasonForSubmittal_VerificationTesting'), value: ReasonForSubmittal.VerificationTesting },
      { text: this.i18nService.transform('reasonForSubmittal_ShadeEvaluation'), value: ReasonForSubmittal.ShadeEvaluation }
    ]
    if (includeCorrelationTesting) {
      items.push({ text: this.i18nService.transform('reasonForSubmittal_CorrelationTesting'), value: ReasonForSubmittal.CorrelationTesting });
    }

    return items;
  }

  ngOnInit(): void {
    this.dateFormatString = this.localizationService.getDateFormatString();
    this.plannedTestingDateMinErrorMessage = this.errorMessageService.resolveError(ErrorCodes.PlannedTestingDateOutsideBoundaries);
    this.reasonForSubmittalChanged$ = this.reasonForSubmittalSubject.asObservable();

    this.subscriptions.add(this.sessionService.currentUser$.subscribe(user => {
      this.isSystemOrPortalAdmin = this.authorizationService.isSystemOrPortalAdmin(user);
      this.isSsr = this.authorizationService.isSourceSamplingRepresentative(user);
      this.isLabManager = this.authorizationService.isLabManager(user);
    }));

    this.subscriptions.add(this.invalidControlDelayedScrollingSubject
      .asObservable()
      .pipe(
        debounceTime(250)
      )
      .subscribe(element => {
        this.scrollElementIntoView(element, false);
        this.highlightInvalid(element);
        this.focusElement(element);
      }));

    this.route.params.subscribe(params => {
      if (params['id']) {
        this.notificationMessage = `<span class="k-icon k-i-loading"></span><span class="ps-2">${this.i18nService.transform('openingRequest')}</span>`;
        this.isLoading = true;
        this.isSavingInProgress = false;
        this.isValidatingInProgress = false;
        if (this.requestForm) {
          this.requestForm.form.markAsUntouched();
          this.requestForm.form.markAsPristine();
        }
        const requestId = parseInt(params['id']);
        this.requestService.getRequest(requestId).subscribe(response => {
          this.request = response.body;

          if (this.request.samples && this.request.samples.length > 1) {
            this.request.samples = this.request.samples.sort(this.sortingService.compareBySortIndex);
          }

          if (this.request.testRequirements && this.request.testRequirements.length > 1) {
            this.request.testRequirements = this.request.testRequirements.sort(this.sortingService.compareBySortIndex);
          }

          this.manufacturer = this.request.manufacturer || new Company();
          this.laboratory = this.request.laboratory || new Company();

          if (this.isSystemOrPortalAdmin) {
            this.usersService.getCompanyUsers(this.sessionService.currentUser.companyId).subscribe(users => {
              const currentUser = this.sessionService.currentUser;
              const isSystemAdmin = this.authorizationService.isSystemAdmin(currentUser);
              this.specialists = isSystemAdmin ? users : [currentUser];
            });
          }

          this.specialists = [this.sessionService.currentUser];

          this.reasonsForSubmittal = this.getReasonForSubmittalDataSource(this.request.reasonForSubmittal === ReasonForSubmittal.CorrelationTesting);

          const contractIds = this.request.requestContracts.map(rc => rc.contractId);
          this.initializeControls(contractIds);
        }, error => {
          this.isLoading = false;
          let errorMessage: string;
          switch (error.status) {
            case 403:
              errorMessage = this.i18nService.transform('request_access_error');
              break;
            case 404:
              errorMessage = this.i18nService.transform('request_notFound_error');
              break;
            default:
              errorMessage = error.message;
          }

          this.toastr.error(errorMessage);
          this.router.navigateByUrl('requests');
        });
      } else {
        if (params['contractIds']) {
          const regexPattern = /^(?:\d+&?)+$/gm;
          if (params['contractIds'].match(regexPattern)) {
            this.notificationMessage = `<span class="k-icon k-i-loading"></span><span class="ps-2">${this.i18nService.transform('initializingRequest')}</span>`;
            this.isLoading = true;
            const contractIds = params['contractIds'].split('&').map(id => parseInt(id));
            this.manufacturer = new Company();
            this.laboratory = new Company();

            const baseRequest = this.popStoredObject<Request>(this.baseRequestKey);

            const cloningOptions = this.popStoredObject<CloningOptions>(this.cloningOptionsKey);

            this.reasonsForSubmittal = this.getReasonForSubmittalDataSource(cloningOptions && cloningOptions.reasonForSubmittal === ReasonForSubmittal.CorrelationTesting);

            this.initializeRequest(contractIds, baseRequest, cloningOptions);

          } else {
            this.toastr.error('Invalid URL!');
          }
        }
      }
    });

    this.subscriptions.add(combineLatest(this.requestSubject.asObservable(), this.viewRenderedSubject.asObservable()).subscribe(([request, viewInitialized]) => {
      if (request && viewInitialized) {
        this.calculateScrollPosition();
      }
    }));

    /* Control mechanism for saving request */
    this.subscriptions.add(this.saveAttemptSubject.subscribe(saveType => {
      if (this.isValidatingInProgress) {
        this.ref.detectChanges();
        this.clearValidationInProgress();
        switch (saveType) {
          case SaveType.ConfirmRequest: this.confirmRequest(); break;
          case SaveType.RejectRequest: this.rejectRequest(); break;
          case SaveType.CancelRequest: this.cancelRequest(); break;
          case SaveType.ConfirmResults: this.confirmResults(); break;
          case SaveType.RejectResults: this.rejectResults(); break;
          case SaveType.SaveAndUpgradeStatus: this.saveAndUpgradeStatus(); break;
          case SaveType.SaveChanges:
          default: this.saveChanges(); break;
        }
      }
    }));
  }

  ngAfterViewInit(): void {
    this.formComponents.changes.subscribe((forms: QueryList<NgForm>) => {
      if (forms.first) {
        this.requestForm = forms.first;

        this.subscriptions.add(this.requestForm.form.statusChanges.subscribe(status => {
          if (status === 'VALID' && this.invalidControlHandlingPanelShouldShow) {
            this.invalidControlHandlingPanelShouldShow = false;
          }
        }));

        this.subscriptions.add(this.requestForm.form.valueChanges.subscribe(x => {
          this.getInvalidControlCount();
        }));
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.pendingChangesService.setComponentCanDeactivate(null);
  }

  /* Panelbar expansion control functions */

  requestDetailsPanelShouldExpand(): boolean {
    if (this.request) {
      return this.request.status < RequestStatus.PendingFinalizeRequirements ||
        this.request.status >= RequestStatus.PendingSpecifierApproval;
    }

    return false;
  }

  requirementsPanelShouldExpand(): boolean {
    if (this.request && this.currentStatus !== null) {
      return this.currentStatus <= RequestStatus.PendingSsrApproval ||
        (this.isSystemOrPortalAdmin && this.currentStatus === RequestStatus.PendingFinalizeRequirements) ||
        this.currentStatus >= RequestStatus.PendingSpecifierApproval;
    }

    return false;
  }

  samplesPanelShouldExpand(): boolean {
    if (this.request && this.currentStatus !== null) {
      return this.currentStatus === RequestStatus.New ||
        (this.isSsr && this.currentStatus === RequestStatus.PendingSsrApproval) ||
        (this.isSystemOrPortalAdmin && this.currentStatus === RequestStatus.PendingFinalizeRequirements) ||
        this.request.status >= RequestStatus.PendingSpecifierApproval;
    }

    return false;
  }

  testDetailsPanelShouldExpand(): boolean {
    if (this.request && this.currentStatus !== null) {
      return this.currentStatus >= RequestStatus.InProgress
        && (this.request.samples && this.request.samples.length > 0)
        && (this.request.testRequirements && this.request.testRequirements.length > 0);
    }

    return false;
  }

  ptcResponsePanelShouldExpand(): boolean {
    if (this.request && this.currentStatus !== null) {
      return this.currentStatus < RequestStatus.InProgress &&
        (this.request.willSpecifierObserve === null ||
          this.request.willSpecifierObserve === undefined ||
          this.request.correlationRequired === null ||
          this.request.correlationRequired === undefined);
    }

    return false;
  }

  ptcReviewPanelShouldExpand(): boolean {
    if (this.currentStatus !== null) {
      return this.currentStatus >= RequestStatus.PendingSpecifierApproval;
    }

    return false;
  }

  /* Readonly state controlling of panelbar & controls */

  requestDetailsPanelShouldBeReadOnly(): boolean {
    if (this.request) {
      return (this.reasonsWithRegularWorkflow.includes(this.request.reasonForSubmittal) && this.request.status > RequestStatus.PendingSpecifierApproval)
        || (this.reasonsWithExtendedWorkflow.includes(this.request.reasonForSubmittal)
          && this.request.status > RequestStatus.PendingSsrApproval
          && !this.isSystemOrPortalAdmin);
    }

    return false;
  }

  manufacturerShouldBeReadOnly(): boolean {
    if (this.request) {
      return this.request.status > RequestStatus.New;
    }

    return false;
  }

  ptcResponsePanelShouldBeReadOnly(): boolean {
    if (this.request) {
      const dateDiff = this.dateService.dateDiff(new Date(), new Date(this.request.plannedTestingDate));
      return dateDiff <= 0 || this.request.status > RequestStatus.InProgress;
    }

    return false;
  }

  testRequirementsPanelShouldBeReadOnly(): boolean {
    if (this.currentStatus !== null) {
      return this.currentStatus >= (this.isSystemOrPortalAdmin ? RequestStatus.InProgress : RequestStatus.PendingFinalizeRequirements);
    }

    return false;
  }

  samplesPanelShouldBeReadOnly(): boolean {
    if (this.request && this.currentStatus !== null && this.currentReasonForSubmittal !== null) {
      return !this.isSsr && this.currentStatus == RequestStatus.PendingSsrApproval ||
        this.currentStatus > (this.isSystemOrPortalAdmin ? RequestStatus.PendingFinalizeRequirements : RequestStatus.PendingSsrApproval);
    }

    return false;
  }

  testResultsPanelShouldBeReadOnly(): boolean {
    if (this.request) {
      return !((this.reasonsWithRegularWorkflow.includes(this.request.reasonForSubmittal) ||
        (this.reasonsWithExtendedWorkflow.includes(this.request.reasonForSubmittal) && this.isSystemOrPortalAdmin)) &&
        ((this.isLabManager && this.request.status <= RequestStatus.PendingLabMgrApproval)
          || (!this.isLabManager && this.request.status <= RequestStatus.InProgress)));
    }

    return false;
  }

  ptcReviewPanelShouldBeReadOnly(): boolean {
    if (this.request) {
      return this.request.status > RequestStatus.PendingSpecifierApproval;
    }

    return false;
  }

  /* Required state controlling of user controls */

  isInitiallyRequiredForUpgradeForRegularWorkflows(shouldEvaluate = true): boolean {
    if (this.currentStatus !== null && this.currentReasonForSubmittal !== null && shouldEvaluate) {
      return this.reasonsWithRegularWorkflow.includes(this.currentReasonForSubmittal) && this.currentStatus === RequestStatus.New;
    }
    return false;
  }

  isInitiallyRequiredForUpgrade(shouldEvaluate = true): boolean {
    if (this.currentStatus !== null && shouldEvaluate) {
      return this.currentStatus === RequestStatus.New;
    }
    return false;
  }

  requestDetailsPanelControlsShouldBeRequiredForSave(): boolean {
    if (this.currentStatus !== null) {
      return this.currentStatus <= RequestStatus.PendingSpecifierApproval;
    }

    return false;
  }

  requestDetailsPanelControlsShouldBeRequiredForUpgrade(shouldEvaluate = true): boolean {
    if (this.currentStatus !== null && shouldEvaluate) {
      return this.isSystemOrPortalAdmin && this.currentStatus === RequestStatus.PendingSpecifierApproval;
    }

    return false;
  }

  samplesPanelControlsShouldBeRequiredForUpgrade(shouldEvaluate = true): boolean {
    if (this.currentStatus !== null && shouldEvaluate) {
      return (this.isSsr && this.currentStatus === RequestStatus.PendingSsrApproval);
    }

    return false;
  }

  testRequirementsPanelControlsShouldBeRequiredForUpgrade(shouldEvaluate = true): boolean {
    if (this.currentStatus !== null && shouldEvaluate) {
      return (this.isSsr && this.currentStatus === RequestStatus.PendingSsrApproval);
    }

    return false;
  }

  testDetailsPanelControlsShouldBeRequiredForUpgrade(shouldEvaluate = true): boolean {

    if (this.currentStatus !== null && shouldEvaluate) {
      return (this.isLabManager && this.currentStatus === RequestStatus.PendingLabMgrApproval);
    }

    return false;
  }

  ptcReviewPanelControlsShouldBeRequiredForUpgrade(shouldEvaluate = true): boolean {

    if (this.currentStatus !== null && shouldEvaluate) {
      return this.isSystemOrPortalAdmin && this.currentStatus === RequestStatus.PendingSpecifierApproval;
    }

    return false;
  }

  /* Rendering control of panelbars & buttons */

  testRequirementsPanelShouldShow(): boolean {
    const workflows: string[] = [ReasonForSubmittal.CorrelationTesting, ReasonForSubmittal.VerificationTesting];
    if (this.currentReasonForSubmittal !== null && this.currentStatus !== null) {
      return this.isSystemOrPortalAdmin || !(workflows.includes(this.currentReasonForSubmittal) && this.currentStatus <= RequestStatus.PendingSsrApproval);
    }

    return false;
  }

  testDetailsPanelShouldShow(): boolean {
    if (this.currentStatus !== null) {
      return this.currentStatus >= RequestStatus.InProgress;
    }

    return false;
  }

  ptcResponsePanelShouldShow(): boolean {
    if (this.request && this.currentStatus !== null) {
      const dateDiff = this.request.plannedTestingDate ? this.dateService.dateDiff(new Date(), new Date(this.request.plannedTestingDate)) : 0;
      return this.isSystemOrPortalAdmin
        && this.reasonsWithRegularWorkflow.includes(this.request.reasonForSubmittal)
        && (this.currentStatus > RequestStatus.New && dateDiff > 0);
    }

    return false;
  }

  ptcReviewPanelShouldShow(): boolean {
    if (this.currentStatus !== null) {
      return this.isSystemOrPortalAdmin && this.currentStatus >= RequestStatus.PendingSpecifierApproval;
    }

    return false;
  }

  saveButtonShouldShow(): boolean {
    if (this.request && this.currentReasonForSubmittal !== null && this.currentStatus !== null) {
      if (this.reasonsWithExtendedWorkflow.includes(this.currentReasonForSubmittal) && !this.isSystemOrPortalAdmin) {
        return this.currentStatus <= RequestStatus.PendingSsrApproval;
      }

      return this.currentStatus <= RequestStatus.PendingSpecifierApproval;
    }

    return false;
  }

  saveAndUpgradeStatusButtonShouldShow(): boolean {
    const user = this.sessionService.currentUser;

    return (this.authorizationService.isSourceSamplingRepresentative(user) && this.currentStatus === RequestStatus.PendingSsrApproval) ||
      (this.currentStatus !== RequestStatus.PendingSsrApproval &&
        (this.reasonsWithExtendedWorkflow.includes(this.currentReasonForSubmittal) && !this.isSystemOrPortalAdmin ?
          this.currentStatus <= RequestStatus.PendingSsrApproval :
          this.currentStatus < RequestStatus.PendingLabMgrApproval));
  }

  confirmOrRejectTestResultButtonsShouldShow(): boolean {
    const user = this.sessionService.currentUser;
    return (this.authorizationService.isLabManager(user) && this.currentStatus === RequestStatus.PendingLabMgrApproval);
  }

  confirmOrRejectRequestButtonsShouldShow(): boolean {
    return this.isSystemOrPortalAdmin && this.currentStatus === RequestStatus.PendingSpecifierApproval;
  }

  cancelRequestButtonShouldShow(): boolean {
    if (this.request.id && this.currentReasonForSubmittal !== null && this.currentStatus != null) {
      if (this.reasonsWithExtendedWorkflow.includes(this.currentReasonForSubmittal) && !this.isSystemOrPortalAdmin) {
        return this.currentStatus <= RequestStatus.PendingSsrApproval;
      }
      
      return this.currentStatus < RequestStatus.PendingLabMgrApproval;
    }

    return false;
  }

  downloadDD1222SectionAButtonShouldShow() {
    if (this.currentStatus !== null && this.currentReasonForSubmittal !== null && this.request && this.request.id) {
      if (this.reasonsWithExtendedWorkflow.includes(this.currentReasonForSubmittal)) {
        if (this.isSystemOrPortalAdmin) {
          return this.currentStatus < RequestStatus.InProgress;
        } else {
          return this.currentStatus <= RequestStatus.InProgress;
        }
      } else {
        return this.currentStatus <= RequestStatus.PendingSsrApproval;
      }
    }

    return false;
  }

  downloadDD1222SectionAButtonShouldBeDisabled() {
    return !this.hasAllSampleDataEnteredAndSaved(this.request);
  }

  hasAllSampleDataEnteredAndSaved(request: Request) {
    return (this.requestForm && !this.requestForm.dirty && request.samples.length > 0 &&
      request.samples.every(s => s !== null && s.name !== null && s.name !== undefined && s.name.length > 0) &&
      request.rollNumber !== null && request.rollNumber !== undefined && request.rollNumber.length > 0);
  }

  getTooltipWhenDownloadDD1222SectionAButtonIsDisabled() {
    return this.downloadDD1222SectionAButtonShouldBeDisabled() ? this.i18nService.transform('downloadDD1222SectionADisabledTooltip') : '';
  }

  sampleReceivedDateShouldShow() {
    if (this.currentStatus !== null && this.currentReasonForSubmittal !== null) {
      return this.isSystemOrPortalAdmin
        && this.reasonsWithExtendedWorkflow.includes(this.currentReasonForSubmittal)
        && this.currentStatus >= RequestStatus.PendingFinalizeRequirements;
    }

    return false;
  }

  sampleReceivedDateShouldBeRequired() {
    return this.currentReasonForSubmittal !== null
      && this.currentStatus !== null
      && this.currentReasonForSubmittal === ReasonForSubmittal.CorrelationTesting
      && this.currentStatus === RequestStatus.PendingFinalizeRequirements;
  }

  isLotNumberShouldBeReadonly() {
    return this.currentStatus !== null
      && this.currentStatus > RequestStatus.New;
  }

  isPlannedTestingDateShouldBeReadonly(): boolean {
    return this.currentStatus !== null
      && this.currentStatus > RequestStatus.New;
  }

  getFirstAvailableTestingDate(): Date {
    if (this.request && this.requestForm) {
      if (this.requestForm.value.willSpecifierObserve === undefined ?
        this.request.willSpecifierObserve !== SpecifierObservationType.AttendanceNotNeeded : this.requestForm.value.willSpecifierObserve !== SpecifierObservationType.AttendanceNotNeeded) {
        let startDate = new Date();
        startDate = new Date(startDate.getUTCFullYear(), startDate.getUTCMonth(), startDate.getUTCDate());
        startDate.setDate(startDate.getDate() + 14); // 2 weeks
        return startDate;
      }
    }

    return new Date();
  }

  isRequestArchived(): boolean {
    return this.request ? this.request.status >= RequestStatus.ArchivedAndConfirmed : true;
  }

  isPlannedTestingDateMinError(): boolean {
    if (this.requestForm) {
      const plannedTestingDateControl = this.requestForm.form.get('plannedTestingDate');
      if (plannedTestingDateControl && plannedTestingDateControl.errors) {
        return plannedTestingDateControl.errors.minError && plannedTestingDateControl.touched;
      }
    }

    return false;
  }

  isReasonForSubmittalEditable(): boolean {
    if (this.request) {
      return this.request.status < RequestStatus.PendingSsrApproval;
    }
    return false;
  }

  shouldHighlightTestRequirementsPanel(): boolean {
    if (this.currentStatus !== null) {
      return this.currentStatus === RequestStatus.PendingFinalizeRequirements || (this.currentStatus <= RequestStatus.PendingSsrApproval
        && !(this.request.testRequirements && this.request.testRequirements.length > 0));
    }

    return false;
  }

  shouldHighlightSampleInformationPanel(): boolean {
    if (this.currentStatus !== null) {
      return this.currentStatus <= RequestStatus.PendingSsrApproval && !(this.request.samples && this.request.samples.length > 0);
    }

    return false;
  }

  shouldHighlightPlannedTestingDate(): boolean {
    if (this.currentStatus !== null) {
      return this.currentStatus <= RequestStatus.PendingSsrApproval && !this.request.plannedTestingDate;
    }

    return false;
  }

  shouldHighlightTestResultsPanel(): boolean {
    if (this.currentStatus !== null) {
      return this.currentStatus >= RequestStatus.InProgress && this.currentStatus <= RequestStatus.PendingLabMgrApproval;
    }

    return false;
  }

  resolveStatus(status: RequestStatus): string {
    return this.i18nService.transform(`requestStatus_${RequestStatus[status]}`);
  }

  getStatusValue(status: RequestStatus): number {
    return RequestStatus[status.toString()];
  }

  filterManufacturers(query: string): void {
    const predicate = (item) => item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    this.filteredManufacturers = this.companies.filter(predicate);
  }

  filterLaboratories(query: string): void {
    const predicate = (item) => item.name.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    this.filteredLaboratories = this.companies.filter(predicate);
  }

  manufacturerChanged(value: string) {
    if (this.request) {
      const selectedItem = this.companies.find(c => c.name === value);
      if (selectedItem || value === '') {
        this.manufacturer = selectedItem || new Company();
      }
    }
  }

  laboratoryChanged(value: string) {
    if (this.request) {
      const selectedItem = this.companies.find(c => c.name === value);
      if (selectedItem || value === '') {
        this.laboratory = selectedItem || new Company();
      }
    }
  }

  getSampleNumbers() {
    return this.request && this.request.samples ? this.request.samples.map(s => s.name).filter(s => s !== '').join(', ') : '';
  }

  getSpecifierObservationTypeText(value: SpecifierObservationType): string {
    if (value !== null && value !== undefined) {
      switch (value) {
        case SpecifierObservationType.FollowUpOffline:
          return this.i18nService.transform('followUpOffline');
        case SpecifierObservationType.RemoteInspection:
          return this.i18nService.transform('remoteInspection');
        case SpecifierObservationType.AttendanceNotNeeded:
        default:
          return this.i18nService.transform('attendanceNotNeeded');
      }      
    }

    return '-';
  }

  getYesNoUndefinedIndicatorText(value: boolean): string {
    return value !== null && value !== undefined ? this.i18nService.transform(value ? 'yes' : 'no') : '-';
  }

  openPopup(control: AutoCompleteComponent) {
    if (!control.isOpen) {
      control.toggle(true);
    }
  }

  onPanelBarStateChange(data: Array<PanelBarItemModel>) {
    const focusedItems = data.filter(item => item.focused);

    if (focusedItems && focusedItems.length > 0) {

      const touchedItem = focusedItems[0];

      switch (touchedItem.id) {
        case this.requestDetailsId: this.isRequestDetailsExpanded = touchedItem.expanded; break;
        case this.testRequirementsId: this.isTestRequirementsExpanded = touchedItem.expanded; break;
        case this.sampleInformationId: this.isSampleInformationExpanded = touchedItem.expanded; break;
        case this.testDetailsId: this.isTestDetailsExpanded = touchedItem.expanded; break;
        case this.ptcResponseId: this.isPtcResponseExpanded = touchedItem.expanded; break;
        case this.ptcReviewId: this.isPtcReviewExpanded = touchedItem.expanded; break;
      }
    }
  }

  getContractNumbers() {
    return this.request.requestContracts.map(rc => rc.contract.contractNumber).filter(s => s !== '').join(', ');
  }

  getRequestDetailsCollapseInfo() {
    const collapseInfoBuilder = new CollapseInfoBuilder(this.i18nService).initialize();
    collapseInfoBuilder.addCollapseInfoItem('request_label_reasonForSubmittal', this.request.reasonForSubmittal);

    if (this.request.plannedTestingDate) {
      collapseInfoBuilder.addCollapseInfoItem('request_label_plannedTestingDate', this.datePipe.transform(this.request.plannedTestingDate, this.dateFormatString));
    }

    if (this.request.materialToBeTested) {
      collapseInfoBuilder.addCollapseInfoItem('request_label_materialToBeTested', this.request.materialToBeTested);
    }

    return collapseInfoBuilder.build();
  }

  getSampleInformationCollapseInfo() {
    const collapseInfoBuilder = new CollapseInfoBuilder(this.i18nService).initialize();
    if (this.request.lotNumber) {
      collapseInfoBuilder.addCollapseInfoItem('lotNumber', this.request.lotNumber);
    }

    if (this.request.samples && this.request.samples.length > 0) {
      const sampleCount = this.request.samples.length;

      const sampleNumbers = this.request.samples.map(s => s.name).filter(s => s !== '');

      collapseInfoBuilder.addCollapseInfoItem('label_samples', `(${sampleCount}) ${sampleNumbers.join(', ')}`);
    }

    return collapseInfoBuilder.build();
  }

  getTestDetailsCollapseInfo() {
    const collapseInfoBuilder = new CollapseInfoBuilder(this.i18nService).initialize();
    if (this.request && this.request.testRequirements && this.request.testRequirements.length > 0) {
      let numberOfResults = 0;
      let filledResults = 0;

      this.request.testRequirements.forEach(requirement => {
        if (requirement.testResults && requirement.testResults.length > 0) {
          numberOfResults += requirement.testResults.length;
          filledResults += requirement.testResults.filter(result => result.value !== null).length;
        }
      });

      collapseInfoBuilder.addCollapseInfoItem('progressOfCompletion', `[ ${filledResults} / ${numberOfResults} ] ${numberOfResults > 0 ? '(' + (filledResults / numberOfResults * 100).toFixed() + '%)' : ''}`);
    }

    return collapseInfoBuilder.build();
  }

  getDateSampledAndSubmittedBy(): string {
    return this.request && this.request.ssrUser ? this.request.ssrUser.fullName : '' +
      this.request && this.request.ssrUser ? ', ' + this.request.ssrUser.companyName : '' +
        this.request && this.request.ssrSignedDate ? ', ' + this.datePipe.transform(this.request.ssrSignedDate, this.dateFormatString) : '';
  }

  resolveActionByStatus(): string {
    if (this.request) {
      return this.i18nService.transform(`requestAction_${RequestStatus[this.currentStatus]}`);
    }
    return '';
  }

  increaseStatus(): RequestStatus {
    let status = this.request.status;
    if (status < RequestStatus.ArchivedAndConfirmed) {
      const workflowSteps = this.getWorkFlowSteps(this.request.reasonForSubmittal as ReasonForSubmittal);
      if (workflowSteps !== null) {
        let indexOfStatus = workflowSteps.findIndex(ws => ws === status);
        if (indexOfStatus > -1 && indexOfStatus < workflowSteps.length - 1) {
          status = workflowSteps[indexOfStatus + 1];
        }
      }
    }
    return status;
  }

  private getWorkFlowSteps(name: ReasonForSubmittal): RequestStatus[] {
    const workflow = this.workflows.find(w => w.name === name);
    if (workflow) {
      return workflow.steps;
    }

    return null;
  }

  onDetailsShow() {
    this.viewRenderedSubject.next(true);
  }

  onSave(saveType: SaveType) {
    this.isSaveAndUpgradeInProgress = (saveType === SaveType.SaveAndUpgradeStatus || saveType === SaveType.ConfirmResults || saveType === SaveType.ConfirmRequest);
    this.setValidationInProgress();
    this.requestForm.form.updateValueAndValidity();

    this.saveAttemptSubject.next(saveType);
  }

  private confirmResults(): void {
    if (this.requestForm.valid) {

      let message = this.getStatusUpgradeConsequencesMessage();
      message = message.concat(`<div class="mb-2">${this.i18nService.transform('request_labManagerApproval_confirm_message')}</div>`);

      this.messageBoxService.open({
        title: this.i18nService.transform('message_box_title_confirm'),
        message: message,
        confirmButtonText: this.i18nService.transform('confirmResults'),
        cancelButtonText: this.i18nService.transform('back'),
        onConfirm: () => {
          this.saveRequest(true);
        }
      });
    } else {
      this.showInvalidControlHandlingPanel()
    }
  }

  private rejectResults(): void {
    this.messageBoxService.open({
      type: MessageBoxType.Warning,
      showIcon: true,
      primary: MessageBoxPrimaryButton.Deny,
      title: this.i18nService.transform('message_box_title_warning'),
      message: this.i18nService.transform('request_labManagerApproval_reject_message'),
      denyButtonText: this.i18nService.transform('rejectResults'),
      cancelButtonText: this.i18nService.transform('back'),
      onDeny: () => {
        this.request.status = RequestStatus.ArchivedAndRejected;
        this.saveRequest();
      }
    });
  }

  private confirmRequest(): void {
    if (this.requestForm.valid) {
      let message = this.getStatusUpgradeConsequencesMessage();
      message = message.concat(`<div class="mb-2">${this.i18nService.transform('request_specifierApproval_confirm_message')}</div>`);

      this.messageBoxService.open({
        title: this.i18nService.transform('message_box_title_confirm'),
        message: message,
        confirmButtonText: this.i18nService.transform('confirmRequest'),
        cancelButtonText: this.i18nService.transform('back'),
        onConfirm: () => {
          this.request.status = RequestStatus.ArchivedAndConfirmed;
          this.saveRequest();
        }
      });
    } else {
      this.showInvalidControlHandlingPanel();
    }
  }

  private rejectRequest(): void {
    this.messageBoxService.open({
      type: MessageBoxType.Warning,
      showIcon: true,
      primary: MessageBoxPrimaryButton.Deny,
      title: this.i18nService.transform('message_box_title_warning'),
      message: this.i18nService.transform('request_specifierApproval_reject_message'),
      denyButtonText: this.i18nService.transform('rejectRequest'),
      cancelButtonText: this.i18nService.transform('back'),
      onDeny: () => {
        this.request.status = RequestStatus.ArchivedAndRejected;
        this.saveRequest();
      }
    });
  }

  private cancelRequest(): void {
    this.messageBoxService.open({
      type: MessageBoxType.Warning,
      showIcon: true,
      title: this.i18nService.transform('message_box_title_warning'),
      message: this.i18nService.transform('request_cancel_message'),
      confirmButtonText: this.i18nService.transform('cancelRequest'),
      cancelButtonText: this.i18nService.transform('back'),
      onConfirm: () => {
        this.request.status = RequestStatus.ArchivedAndCancelled;
        this.saveRequest();
      },
    });
  }

  private saveChanges() {
    this.requestForm.form.updateValueAndValidity();
    if (this.requestForm.valid) {
      this.invalidControlCount = 0;
      this.saveRequest();
    } else {
      this.showInvalidControlHandlingPanel();
    }
  }

  private saveAndUpgradeStatus() {
    if (this.requestForm.valid) {
      this.invalidControlCount = 0;
      const confirmButtonText = `${this.i18nService.transform('saveChanges')} ${this.i18nService.transform('and')} ${this.resolveActionByStatus()}`;
      const message = this.getStatusUpgradeConsequencesMessage();

      if (message != '') {
        this.messageBoxService.open({
          type: MessageBoxType.Info,
          showIcon: true,
          title: this.i18nService.transform('message_box_title_warning'),
          message: message,
          confirmButtonText: confirmButtonText,
          cancelButtonText: this.i18nService.transform('back'),
          onConfirm: () => {
            this.saveRequest(true);
          }
        });
      } else {
        this.saveRequest(true);
      }
    } else {
      this.showInvalidControlHandlingPanel();
    }
  }

  private saveRequest(updateStatus: boolean = false): void {
    this.setSavingInProgress();

    this.updateRequestWithFormValues(this.requestForm);

    if (updateStatus && this.request.status < RequestStatus.ArchivedAndConfirmed) {
      this.request.status = this.increaseStatus();
    }

    if (this.request.reasonForSubmittal !== ReasonForSubmittal.CorrelationTesting) {
      delete this.request.linkedRequestId;
    }

    if (this.request.id != null) {
      this.requestService.updateRequest(this.request).subscribe(result => {
        this.requestService.getRequest(this.request.id).subscribe(response => {
          this.request = response.body;
          this.currentStatus = this.request.status;
          this.currentReasonForSubmittal = this.request.reasonForSubmittal;

          this.toastr.success(this.i18nService.transform('requestUpdated'));
          if (this.request.samples && this.request.samples.length > 1) {
            this.request.samples = this.request.samples.sort(this.sortingService.compareBySortIndex);
          }

          if (this.request.testRequirements && this.request.testRequirements.length > 1) {
            this.request.testRequirements = this.request.testRequirements.sort(this.sortingService.compareBySortIndex);
          }
          this.requestForm.form.markAsPristine();
          this.requestForm.form.markAsUntouched();
          this.invalidControlCount = 0;
          if (updateStatus) {
            this.setExpansionStateOfPanels();
          }
          this.clearSavingInProgress();
        }, error => {
          if (error.status === 403) {
            this.toastr.success(this.i18nService.transform('requestUpdated'));
            this.router.navigateByUrl('requests');
          }
          this.clearSavingInProgress();
          throw error;
        });
      }, error => {
        if (this.request.status > this.currentStatus) {
          this.request.status = this.currentStatus;
        }
        this.clearSavingInProgress();
        throw error;
      });
    } else {
      this.requestService.addRequest(this.request).subscribe(requestId => {
        this.toastr.success(this.i18nService.transform('requestCreated'));
        this.requestForm.form.markAsPristine();
        this.requestForm.form.markAsUntouched();
        this.clearSavingInProgress();
        this.isCloningInProgress = false;
        this.router.navigateByUrl(`request/${requestId}`);
      }, error => {
        if (this.request.status > this.currentStatus) {
          this.request.status = this.currentStatus;
        }
        this.clearSavingInProgress();
        throw error;
      });

    }
  }

  getStatusUpgradeConsequencesMessage(): string {
    let fields: string[] = [];
    let sections: string[] = [];
    let message = '';

    const nextStatus = this.resolveStatus(this.increaseStatus());

    if (this.currentStatus === RequestStatus.New) {
      fields.push('request_label_reasonForSubmittal');
      fields.push('request_label_manufacturer');
      if (this.reasonsWithRegularWorkflow.includes(this.currentReasonForSubmittal)) {
        fields.push('request_label_plannedTestingDate');
      }
      fields.push('lotNumber');
      let excludedFieldsList = this.i18nService.transform('lotNumber');
      message = this.i18nService.transform('updateStatus_readonly_warning_message', { '@status': nextStatus, '@fieldsAndSectionsListHtml': this.generateFieldsAndSectionsListHtml(fields, sections) });
      message = message.concat(`<div class="font-italic mb-2">(${this.i18nService.transform('ssr_editing_exception_message', { '@fieldsList': excludedFieldsList })})</div>`);
    }

    if ((this.reasonsWithRegularWorkflow.includes(this.currentReasonForSubmittal) || !this.isSystemOrPortalAdmin) && this.currentStatus === RequestStatus.PendingSsrApproval) {
      sections.push('sampleInformation');
      if (this.reasonsWithRegularWorkflow.includes(this.currentReasonForSubmittal)) {
        sections.push('testRequirements');
      }
      message = this.i18nService.transform('updateStatus_readonly_warning_message', { '@status': nextStatus, '@fieldsAndSectionsListHtml': this.generateFieldsAndSectionsListHtml([], sections) });
    }

    if (this.currentStatus === RequestStatus.PendingFinalizeRequirements && this.reasonsWithExtendedWorkflow.includes(this.currentReasonForSubmittal)) {
      sections.push('testRequirements');
      sections.push('sampleInformation');
      message = this.i18nService.transform('updateStatus_readonly_warning_message', { '@status': nextStatus, '@fieldsAndSectionsListHtml': this.generateFieldsAndSectionsListHtml([], sections) });
    }

    if (this.currentStatus === RequestStatus.InProgress) {
      sections.push('testDetails');
      message = this.i18nService.transform('updateStatus_readonly_warning_message', { '@status': nextStatus, '@fieldsAndSectionsListHtml': this.generateFieldsAndSectionsListHtml([], sections) });
      message = message.concat(`<div class="font-italic mb-2">(${this.i18nService.transform('labManager_editing_exception_message')})</div>`);
    }

    if (this.currentStatus === RequestStatus.PendingLabMgrApproval) {
      fields.push('labReports');
      message = this.i18nService.transform('updateStatus_readonly_warning_message', { '@status': nextStatus, '@fieldsAndSectionsListHtml': this.generateFieldsAndSectionsListHtml(fields, []) });
    }

    return message;
  }

  private showInvalidControlHandlingPanel() {
    // Paint invalid controls red
    this.requestForm.form.markAllAsTouched();

    this.getInvalidControlCount();
    this.invalidControlHandlingPanelShouldShow = true;

    setTimeout(() => {
      if (this.invalidControlCount > 0) {
        this.scrollToFirstInvalidControl();
      }
    }, 50);
  }

  private setValidationInProgress() {
    this.notificationMessage = `<span class="k-icon k-i-loading"></span><span class="ps-2">${this.i18nService.transform('validatingInput')}</span>`;
    this.isValidatingInProgress = true;
  }

  private clearValidationInProgress() {
    this.isValidatingInProgress = false;
  }

  private setSavingInProgress() {
    this.notificationMessage = `<span class="k-icon k-i-loading"></span><span class="ps-2">${this.i18nService.transform('savingRequest')}</span>`;
    this.isSavingInProgress = true;
  }

  private clearSavingInProgress() {
    this.isSavingInProgress = false;
  }

  private initializeRequest(contractIds: number[], baseRequest: Request, cloningOptions: CloningOptions) {
    const initialRequest = baseRequest || new Request();
    if (baseRequest !== null) {
      this.isCloningInProgress = true;

      if (cloningOptions.reasonForSubmittal === ReasonForSubmittal.CorrelationTesting) {
        initialRequest.linkedRequestId = initialRequest.id;
      }

      delete initialRequest.id;
      delete initialRequest.plannedTestingDate;
      delete initialRequest.lastStatusChangedDate;
      delete initialRequest.labManagerSignedDate;
      delete initialRequest.labManagerUser;
      delete initialRequest.specifierSignedDate;
      delete initialRequest.specifierUser;
      delete initialRequest.assignedSpecifierUser;
      delete initialRequest.assignedSpecifierUserExternalId;
      delete initialRequest.sspNumber;
      delete initialRequest.ssrSignedDate;
      delete initialRequest.ssrUser;
      delete initialRequest.specifierComment;
      delete initialRequest.stamps;
      delete initialRequest.submitDate;
    }
    if (cloningOptions !== null && cloningOptions.reasonForSubmittal) {
      initialRequest.reasonForSubmittal = cloningOptions.reasonForSubmittal
    } else {
      initialRequest.reasonForSubmittal = ReasonForSubmittal.SourceSampling;
    }

    if (cloningOptions === null || !cloningOptions.testRequirements) {
      initialRequest.testRequirements = [];
    } else {
      initialRequest.testRequirements.forEach(tr => {
        tr.testResults = [];
        tr.testResultComments = [];
      });
    }

    if (cloningOptions === null || !cloningOptions.sampleInformation) {
      initialRequest.samples = [];
      delete initialRequest.lotNumber;
      delete initialRequest.quantitySubmitted;
    }

    initialRequest.status = RequestStatus.New;
    initialRequest.attachments = [];
    initialRequest.willSpecifierObserve = null;
    initialRequest.correlationRequired = null;

    this.subscriptions.add(combineLatest(
      this.requestService.generateWorkOrderNumber(contractIds[0]),
      this.contractService.getContracts(contractIds))
      .subscribe(([workOrderNumber, contracts]) => {
        initialRequest.workOrderNumber = workOrderNumber;
        initialRequest.requestContracts = contracts.map(c => {
          const requestContract = new RequestContract();
          requestContract.contract = c;
          requestContract.contractId = c.id;

          return requestContract;
        });

        this.request = initialRequest;

        this.initializeControls(contractIds);
      },
        error => {
          if (error.status === 400) {
            error.error.errors = this.i18nService.transform('error_contracts_with_different_primes');
          }

          this.router.navigateByUrl('requests');

          throw error;
        }));
  }

  private popStoredObject<T>(key: string) {
    const objectLiteral = localStorage.getItem(key);
    if (objectLiteral) {
      localStorage.removeItem(key);
      return <T>JSON.parse(objectLiteral);
    }

    return null;
  }

  private initializeControls(contractIds: number[]) {
    this.deactivationWarningMessage = this.i18nService.transform(this.isCloningInProgress ? 'cloningInProgress_warning_message' : 'generic_unsavedChanges_message');
    this.pendingChangesService.setComponentCanDeactivate(this);

    this.companiesService.getCompaniesOfContracts(contractIds).subscribe(companies => {
      this.companies = companies;
      this.filteredManufacturers = this.companies;
      this.filteredLaboratories = this.companies;
    },
      error => {
        if (error.status === 400) {
          error.error.errors = this.i18nService.transform('error_suppliers_of_contracts_with_different_primes');
        }

        throw error;
      });

    this.endItems = this.request.requestContracts.length > 0 ? this.request.requestContracts.map(rc => rc.contract.endItemName).join(', ') : '';
    this.specifierContractor = this.request.requestContracts.length > 0 ? this.request.requestContracts[0].contract.specifierContractor : null;
    this.primeContractor = this.request.requestContracts.length > 0 ? this.request.requestContracts[0].contract.primeContractor : null;

    this.currentStatus = this.request.status;
    this.currentReasonForSubmittal = this.request.reasonForSubmittal;

    if (this.request.plannedTestingDate) {
      this.plannedTestingDateValue = new Date(this.request.plannedTestingDate);
    }

    if (this.request.dateOfManufacture) {
      this.dateOfManufactureValue = new Date(this.request.dateOfManufacture);
    }

    this.setExpansionStateOfPanels();
    this.requestSubject.next(this.request);
    this.isLoading = false;
  }

  private updateFieldWithFormValue(fieldName: string, request: Request, requestFormGroup: UntypedFormGroup) {
    const control = requestFormGroup.get(fieldName);
    if (control) {
      this.request[fieldName] = control.value;
    }
  }

  private updateRequestWithFormValues(requestForm: NgForm) {
    const formValues = requestForm.value;
    const formGroup = requestForm.form;
    const fieldListToUpdate = [
      'reasonForSubmittal',
      'quantityRepresented',
      'materialToBeTested',
      'governmentSpecificationNumber',
      'plannedTestingDate',
      'specifierAttention',
      'purchaseOrderNumber',
      'purchasedFromOrSource',
      'shipmentMethod',
      'nationalStockNumber',
      'partNumber',
      'dateOfManufacture',
      'sampleReceivedDate',
      'engineeringAuthority',
      'mqcssQslReview',
      'shade',
      'rollNumber',
      'specialInstructions',
      'additionalRecipients'
    ];

    fieldListToUpdate.forEach(fieldName => {
      this.updateFieldWithFormValue(fieldName, this.request, formGroup);
    });

    this.request.manufacturer = this.manufacturer && this.manufacturer.id > 0 ? this.manufacturer : null;
    this.request.laboratory = this.laboratory && this.laboratory.id > 0 ? this.laboratory : null;

    if (formValues.willSpecifierObserve !== null && formValues.willSpecifierObserve !== undefined) {
      this.request.willSpecifierObserve = formValues.willSpecifierObserve;
    }

    if (formValues.correlationRequired !== null && formValues.correlationRequired !== undefined) {
      this.request.correlationRequired = formValues.correlationRequired;
    }
  }

  calculateScrollPosition() {
    if (this.shouldHighlightTestRequirementsPanel()) {
      this.scrollElementIntoView(this.testRequirementsPanelElement.nativeElement);
    } else if (this.shouldHighlightSampleInformationPanel()) {
      this.scrollElementIntoView(this.sampleInformationPanelElement.nativeElement);
    } else if (this.shouldHighlightTestResultsPanel()) {
      this.scrollElementIntoView(this.testDetailsPanelElement.nativeElement);
    }
  }

  private scrollElementIntoView(nativeElement: HTMLElement, alignToTop: boolean = true) {
    const block = alignToTop ? 'start' : 'end';
    nativeElement.scrollIntoView({ behavior: 'smooth', block: block });
  }

  private setExpansionStateOfPanels() {
    this.isRequestDetailsExpanded = this.requestDetailsPanelShouldExpand();
    this.isTestRequirementsExpanded = this.requirementsPanelShouldExpand();
    this.isSampleInformationExpanded = this.samplesPanelShouldExpand();
    this.isTestDetailsExpanded = this.testDetailsPanelShouldExpand();
    this.isPtcResponseExpanded = this.ptcResponsePanelShouldExpand();
    this.isPtcReviewExpanded = this.ptcReviewPanelShouldExpand();
  }

  canDeactivate(): boolean {
    return (!this.isCloningInProgress && !this.requestForm.dirty);
  }

  downloadDD1222SectionA() {
    this.downloadDD1222(true);
  }

  downloadDD1222(isSectionAOnly: boolean = false) {
    this.notificationMessage = `<span class="k-icon k-i-loading"></span><span class="ps-2">${this.i18nService.transform('downloadInProgress')}</span>`;
    this.isDownloadInProgress = true;

    this.requestService.downloadDD1222(this.request.id, isSectionAOnly).subscribe(
      blob => {
        const identifier = this.request.sspNumber != null ? this.request.sspNumber : this.request.workOrderNumber;
        const suffix = (this.currentStatus === RequestStatus.PendingSpecifierApproval ?
          '_Preview' :
          (isSectionAOnly ?
            '_Section_A' :
            '')
        );
        const name = `${identifier}_DD1222${suffix}.pdf`;
        this.blobDownloadService.downloadBlob(blob, name);
        this.isDownloadInProgress = false;
      },
      error => {
        this.isDownloadInProgress = false;
      });
  }

  deleteRequest() {
    this.messageBoxService.open({
      title: this.i18nService.transform('message_box_title_confirm'),
      message: this.i18nService.transform('request_delete_confirm_message'),
      showIcon: true,
      type: MessageBoxType.Warning,
      primary: MessageBoxPrimaryButton.Cancel,
      confirmButtonText: this.i18nService.transform('yes'),
      cancelButtonText: this.i18nService.transform('no'),
      onConfirm: () => {
        this.requestService.deleteRequest(this.request.id).subscribe(response => {
          if (response.ok) {
            this.router.navigateByUrl('requests');
            this.toastr.success(this.i18nService.transform('requestDeleted'));
          }
        });
      }
    });
    
  }

  navigateToLinkedRequest() {
    if (this.request.linkedRequestId) {
      this.router.navigateByUrl(`request/${this.request.linkedRequestId}`);
    } else {
      this.toastr.error(this.i18nService.transform('error_noLinkedRequestError'), this.i18nService.transform('toast_error_title'));
    }
  }

  openCloneRequestDialog() {
    const dialogRef = this.dialogService.open({
      content: CloneRequestDialogComponent,
      title: this.i18nService.transform('clone_request'),
      maxWidth: 700,
    });

    const instance = dialogRef.content.instance;
    instance.request = this.request;

    dialogRef.result.subscribe(result => {
      if (!(result instanceof DialogCloseResult) && result.text === 'clone') {
        const baseRequest = <Request>dialogRef.content.instance.request;
        const contractIds = <number[]>dialogRef.content.instance.contractIds;
        const cloningOptions = <CloningOptions>dialogRef.content.instance.cloningOptions;
        localStorage.setItem(this.baseRequestKey, JSON.stringify(baseRequest));
        localStorage.setItem(this.cloningOptionsKey, JSON.stringify(cloningOptions));

        this.router.navigateByUrl(`request/create-for/${contractIds.join('&')}`);
      }
    });
  }

  openChangeLogDialog() {
    const dialogRef = this.dialogService.open({
      content: AuditLogsComponent,
      title: this.i18nService.transform('change_log'),
      actions: [
        { text: this.i18nService.transform('close'), value: 'cancel' },
      ],
      actionsLayout: 'normal'
    });

    const instance = dialogRef.content.instance;
    instance.requestId = this.request.id;
  }

  onSpecifierUserChange(specifierUser: User) {
    this.request.assignedSpecifierUserExternalId = specifierUser.externalId;
  }

  onReasonForSubmittalChange(reasonForSubmittal: string) {
    if ((this.currentReasonForSubmittal === ReasonForSubmittal.ShadeEvaluation || reasonForSubmittal === ReasonForSubmittal.ShadeEvaluation)
      && this.currentReasonForSubmittal !== reasonForSubmittal
      && this.request.testRequirements.length > 0) {
      this.messageBoxService.open({
        title: this.i18nService.transform('message_box_title_confirm'),
        message: this.i18nService.transform('request_reasonForSubmittal_change_confirm_message'),
        confirmButtonText: this.i18nService.transform('yes'),
        denyButtonText: this.i18nService.transform('no'),
        onConfirm: () => {
          this.currentReasonForSubmittal = reasonForSubmittal;
          this.request.testRequirements = [];
          this.reasonForSubmittalSubject.next(<ReasonForSubmittal>this.currentReasonForSubmittal);
        },
        onDeny: () => {
          if (this.requestForm) {
            const reasonForSubmittalControl = this.requestForm.form.get('reasonForSubmittal');
            if (reasonForSubmittalControl) {
              reasonForSubmittalControl.setValue(this.currentReasonForSubmittal);
            }
          }

        }
      });
    } else {
      this.currentReasonForSubmittal = reasonForSubmittal;
      this.reasonForSubmittalSubject.next(<ReasonForSubmittal>this.currentReasonForSubmittal);
    }
  }

  private highlightInvalid(element: HTMLElement) {
    const className = 'highlighted-invalid-element';
    const highlightedElements = this.rootElement.nativeElement.querySelectorAll(`.${className}`);

    highlightedElements.forEach(he => {
      if (he.classList.contains(className)) {
        he.classList.remove(className);
      }
    });
    setTimeout(() => { element.classList.add(className); }, 50); // Re-adding class immediately to the same element does not work.
  }

  private generateFieldsAndSectionsListHtml(fields: string[], sections: string[]): string {
    let result = '';
    if (fields.length > 0) {
      let fieldsList = '';
      fields.forEach(item => {
        fieldsList += `${this.i18nService.transform(item)}<br/>`;
      });

      result += `${this.i18nService.transform('fields')}<div class="ms-2 fw-bold">${fieldsList}</div>`
    }
    if (sections.length > 0) {
      let sectionsList = '';
      sections.forEach(item => {
        sectionsList += `${this.i18nService.transform(item)}<br/>`;
      });

      result += `${this.i18nService.transform('sections')}<div class="ms-2 fw-bold">${sectionsList}</div>`
    }

    return result;
  }

  isRequestDetailsInvalid(): boolean {
    return this.requestDetailsPanelElement ? this.hasInvalidControl(this.requestDetailsPanelElement, { shouldBeTouched: true }) : false;
  }

  isTestRequirementsInvalid(): boolean {
    return this.testRequirementsPanelElement ? this.hasInvalidControl(this.testRequirementsPanelElement, { shouldBeTouched: true }) : false;
  }

  isSampleInformationsInvalid(): boolean {
    return this.sampleInformationPanelElement ? this.hasInvalidControl(this.sampleInformationPanelElement, { shouldBeTouched: true }) : false;
  }

  isTestDetailsInvalid(): boolean {
    return this.testDetailsPanelElement ? this.hasInvalidControl(this.testDetailsPanelElement, { shouldBeTouched: true }) : false;
  }

  isPtcReviewInvalid(): boolean {
    return this.ptcReviewPanelElement ? this.hasInvalidControl(this.ptcReviewPanelElement, { shouldBeTouched: true }) : false;
  }

  private hasInvalidControl(panelElement: ElementRef, options?: { shouldBeTouched?: boolean }) {
    let selectorPattern = this.invalidControlSelectorPattern;

    if (options && options.shouldBeTouched) {
      selectorPattern += '.ng-touched';
    }

    const invalidControls = panelElement.nativeElement.querySelectorAll(selectorPattern);
    return invalidControls && invalidControls.length > 0;
  }

  private getPanelIfContainsInvalidControl(): PanelBarItemComponent {
    if (this.requestDetailsPanelElement && this.hasInvalidControl(this.requestDetailsPanelElement)) {
      return this.requestDetailsPanel;
    }

    if (this.testRequirementsPanelElement && this.hasInvalidControl(this.testRequirementsPanelElement)) {
      return this.testRequirementsPanel;
    }

    if (this.sampleInformationPanelElement && this.hasInvalidControl(this.sampleInformationPanelElement)) {
      return this.sampleInformationPanel;
    }

    if (this.ptcResponsePanelElement && this.hasInvalidControl(this.ptcResponsePanelElement)) {
      return this.ptcResponsePanel;
    }

    if (this.testDetailsPanelElement && this.hasInvalidControl(this.testDetailsPanelElement)) {
      return this.testDetailsPanel;
    }

    if (this.ptcReviewPanelElement && this.hasInvalidControl(this.ptcReviewPanelElement)) {
      return this.ptcReviewPanel;
    }

    return null;
  }

  private getInvalidControlCount() {
    const invalidControls = this.rootElement.nativeElement.querySelectorAll(this.invalidControlSelectorPattern);
    this.invalidControlCount = invalidControls ? invalidControls.length : 0;
  }

  scrollToFirstInvalidControl() {
    const panel = this.getPanelIfContainsInvalidControl();

    if (panel) {
      let isAlreadyExpanded = panel.expanded;

      switch (panel.id) {
        case this.requestDetailsId: this.isRequestDetailsExpanded = true; break;
        case this.testRequirementsId:
          this.isTestRequirementsExpanded = true;
          if (this.testRequirementsComponent) {
            const isAllRowsExpanded = this.testRequirementsComponent.isAllRowsExpanded();
            if (!isAllRowsExpanded) {
              this.testRequirementsComponent.expandAllRows();
            }

            isAlreadyExpanded = isAlreadyExpanded && isAllRowsExpanded;
          }
          break;
        case this.sampleInformationId: this.isSampleInformationExpanded = true; break;
        case this.testDetailsId:
          this.isTestDetailsExpanded = true;
          if (this.testDetailsComponent) {
            const isAllRowsExpanded = this.testDetailsComponent.isAllRowsExpanded();
            if (!isAllRowsExpanded) {
              this.testDetailsComponent.expandAll();
            }

            isAlreadyExpanded = isAlreadyExpanded && isAllRowsExpanded;
          }
          break;
        case this.ptcResponseId: this.isPtcResponseExpanded = true; break;
        case this.ptcReviewId: this.isPtcReviewExpanded = true; break;
      }

      const firstInvalidControl: HTMLElement = this.rootElement.nativeElement.querySelector(this.invalidControlSelectorPattern);

      if (isAlreadyExpanded) {
        this.scrollElementIntoView(firstInvalidControl, false);
        this.highlightInvalid(firstInvalidControl);
        this.focusElement(firstInvalidControl);
      } else {
        this.invalidControlDelayedScrollingSubject.next(firstInvalidControl);
      }
    }
  }

  private focusElement(element: HTMLElement) {
    const tagName = element.tagName.toLowerCase();
    switch (tagName) {
      case 'kendo-numerictextbox':
      case 'kendo-autocomplete':
      case 'kendo-datepicker':
        const inputElement = element.querySelector("input");
        inputElement.focus();
        break;
      default:
        element.focus();
        break;
    }
  }
}
