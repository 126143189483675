import { HostListener, Directive } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { I18nService } from './i18n.service';

@Directive()
export abstract class DialogComponentCanDeactivate extends DialogContentBase {

  abstract canDeactivate(): boolean;
  /***
   * Override the value of this field to display custom warning message 
   ***/
  public deactivationWarningMessage: string;

  public resetDeactivationWarningMessage() {
    this.deactivationWarningMessage = this.i18nService.transform('generic_unsavedChanges_message');
  }

  constructor(
    public dialog: DialogRef,
    public i18nService: I18nService) {
    super(dialog);
    this.resetDeactivationWarningMessage();
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = this.deactivationWarningMessage;
    }
  }

}
