<div class="k-dialog-wrapper">
  <div class="k-overlay"></div>
  <div class="k-window">
    <div class="k-window-content">
      <div  class="notification-area">
        <div class="notification-message" [innerHtml]="message"></div>
      </div>
    </div>
  </div>
</div>
