import { Injectable } from "@angular/core";
import { CanDeactivate } from "@angular/router";
import { Observable } from "rxjs";
import { ComponentCanDeactivate } from "./component-can-deactivate";
import { PendingChangesService } from "./pending-changes.service";

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(
    private pendingChangesNotificationService: PendingChangesService
  ) { }

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> | boolean {
    try {
      if (!component.isDeactivationBypassed() && !component.canDeactivate()) {
        return this.pendingChangesNotificationService.confirmRejectionOfPendingChanges(component.deactivationWarningMessage);
      }

      return true;
    }
    catch (error) {
      return true;
    }
  }
}
