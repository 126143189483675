<kendo-grid class="inner-grid"
            [data]="data"
            [loading]="isLoading"
            [sort]="state.sort"
            [pageSize]="state.take"
            [skip]="state.skip"
            [filter]="state.filter"
            [sortable]="{
              allowUnsort: true,
              mode: 'multiple'
            }"
            [pageable]="options.isPageable"
            [scrollable]="options.scrollMode"
            [selectable]="options.selectable"
            [navigable]="options.isNavigable"
            [height]="options.height"
            [rowHeight]="options.rowHeight"
            [filterable]="options.isFilterable && !isFreeTextSearchActive && anyColumnFilterSet()"
            [kendoGridSelectBy]="selectBy"
            [selectedKeys]="selectedKeys"
            [rowClass]="rowClass"
            [isDetailExpanded]="isDetailExpanded"
            (detailCollapse)="detailCollapseHandler($event)"
            (detailExpand)="detailExpandHandler($event)"
            (excelExport)="excelExportHandler($event)"
            (selectionChange)="selectionChangeHandler($event)"
            (cellClick)="cellClickHandler($event)"
            (dataStateChange)="dataStateChangeHandler($event)"
            (pageChange)="pageChangeHandler($event)"
            (remove)="removeHandler($event)">
  <ng-template kendoGridNoRecordsTemplate *ngIf="noRecordsText">
    {{noRecordsText}}
  </ng-template>
  <ng-template *ngIf="(options.isFilterable && options.isFreeTextSearchEnabled && anyColumnFilterSet()) || options.isExcelExportEnabled || options.isDataExportEnabled" kendoGridToolbarTemplate position="top">
    <div *ngIf="options.isFilterable && options.isFreeTextSearchEnabled && anyColumnFilterSet()">
      <span>
        <span class="filter-option-label column-filtering" [ngClass]="{ 'fw-bold': !isFreeTextSearchActive }">{{columnFilterOptionText}}</span>
        <kendo-switch class="filter-selector" size="small" [ngModel]="isFreeTextSearchActive" (ngModelChange)="onToggleSearch($event)"></kendo-switch>
        <span class="filter-option-label" [ngClass]="{ 'fw-bold': isFreeTextSearchActive }">{{freeTextSearchFilterOptionText}}</span>
      </span>
    </div>
    <div class="toolbar-buttons">
      <button *ngIf="options.isDataExportEnabled" kendoButton class="data-export" (click)="dataExportHandler()" icon="file-txt">{{exportButtonText}}</button>
      <button *ngIf="options.isExcelExportEnabled" type="button" class="excel-export" kendoGridExcelCommand icon="file-excel">{{excelExportButtonText}}</button>
    </div>
  </ng-template>
  <ng-container *ngIf="showCheckboxColumn">
    <kendo-grid-checkbox-column width="40" [showSelectAll]="showSelectAll"></kendo-grid-checkbox-column>
  </ng-container>
  <ng-container *ngFor="let gridColumn of columns">
    <kendo-grid-column *ngIf="isNGridColumn(gridColumn)"
                       [field]="gridColumn.field"
                       [title]="gridColumn.title"
                       [sortable]="gridColumn.sortable"
                       [filterable]="gridColumn.filterable"
                       [hidden]="gridColumn.hidden"
                       [filter]="gridColumn.filterType"
                       [width]="gridColumn.width"
                       [headerClass]="gridColumn.headerClass"
                       [class]="gridColumn.class">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <ng-container *ngIf="gridColumn.filterCellTemplate" [ngTemplateOutlet]="gridColumn.filterCellTemplate" [ngTemplateOutletContext]="{ $implicit: filter, column: column }">
        </ng-container>
        <ng-container *ngIf="!gridColumn.filterCellTemplate" [ngSwitch]="gridColumn.filterType">
          <kendo-grid-string-filter-cell *ngSwitchCase="NGridColumnFilterType.Text"
                                         [showOperators]="gridColumn.filterShowOperators"
                                         [column]="column"
                                         [filter]="filter">
          </kendo-grid-string-filter-cell>
          <kendo-grid-boolean-filter-cell *ngSwitchCase="NGridColumnFilterType.Boolean"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-boolean-filter-cell>
          <kendo-grid-numeric-filter-cell *ngSwitchCase="NGridColumnFilterType.Numeric"
                                          [showOperators]="gridColumn.filterShowOperators"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-numeric-filter-cell>
          <n-grid-date-range-filter *ngSwitchCase="NGridColumnFilterType.Date"
                                    [column]="column"
                                    [filter]="filter">
          </n-grid-date-range-filter>
          <n-grid-drop-down-filter *ngSwitchCase="NGridColumnFilterType.DropDown"
                                   [data]="gridColumn.filterData" [textField]="gridColumn.filterTextField" [valueField]="gridColumn.filterValueField"
                                   [defaultTextField]="gridColumn.filterDefaultTextField"
                                   [column]="column"
                                   [filter]="filter">
          </n-grid-drop-down-filter>
        </ng-container>
      </ng-template>
      <ng-template *ngIf="gridColumn.cellTemplate" kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <ng-container *ngTemplateOutlet="gridColumn.cellTemplate; context: { $implicit: dataItem, rowIndex: rowIndex }">
        </ng-container>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-command-column *ngIf="isNGridCommandColumn(gridColumn)"
                               [title]="gridColumn.title"
                               [hidden]="gridColumn.hidden"
                               [width]="gridColumn.width"
                               [headerClass]="gridColumn.headerClass"
                               [class]="gridColumn.class">
      <ng-template *ngIf="gridColumn.removeCommandCellTemplate" kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button kendoGridRemoveCommand class="button-danger">
          <ng-container *ngTemplateOutlet="gridColumn.removeCommandCellTemplate; context: { $implicit: dataItem, rowIndex: rowIndex }">
          </ng-container>
        </button>
      </ng-template>
    </kendo-grid-command-column>
  </ng-container>
  <ng-template *ngIf="detailRowTemplate" kendoGridDetailTemplate let-dataItem let-rowIndex="rowIndex">
    <ng-container *ngTemplateOutlet="detailRowTemplate; context: { $implicit: dataItem, rowIndex: rowIndex }">
    </ng-container>
  </ng-template>
  <kendo-grid-excel [fileName]="excelExportFilename" [fetchData]="exportToExcel"></kendo-grid-excel>
  <ng-template *ngIf="(options.isFreeTextSearchEnabled && isFreeTextSearchActive) || options.isPageable" kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <search-textbox *ngIf="options.isFreeTextSearchEnabled && isFreeTextSearchActive" [placeholder]="searchTextboxPlaceholder" [(value)]="state.freeTextSearch" (afterValueChanged)="freeTextSearchChange($event)"></search-textbox>
    <span class="pager-controls" *ngIf="options.isPageable">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="pagerSettings.buttonCount"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-page-sizes [pageSizes]="pagerSettings.pageSizes">
      </kendo-pager-page-sizes>
      <kendo-pager-info></kendo-pager-info>
    </span>
  </ng-template>
</kendo-grid>
