<div kendoDialogContainer></div>
<navbar></navbar>
<div class="wrapper">
  <nav-menu *ngIf="isAuthenticated$ | async"></nav-menu>
  <div class="page-container">
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
