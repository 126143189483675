<div (click)="onTileClick($event)">
  <div class="tile-icon">
    <i [className]="iconClass"></i>
  </div>
  <div class="tile-name">
    {{name}}
  </div>
  <div class="tile-description">
    {{description}}
  </div>
</div>
