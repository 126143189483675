import { OperatorType } from "../../enums/operator-type.enum";
import { TestAttribute } from "../test-methods/test-attribute.model";
import { TestRequirement } from "./test-requirement.model";

/**
 * View model for a test attribute override.
 */
export class TestAttributeOverride {
  id: number;
  testAttributeId: number;
  testRequirementId: number;
  expectedValue: number;
  operatorType: OperatorType;
  isLotAverageConditionComponent: boolean;
  testAttribute: TestAttribute;
  testRequirement: TestRequirement;
}
