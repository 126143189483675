import { Component, OnInit } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { SuggestionConflictResult } from '../../../shared/models/swiss/suggestions/suggestion-conflict-result.model';
import { Suggestion } from '../../../shared/models/swiss/suggestions/suggestion.model';
import { I18nService } from '../../../shared/services/i18n.service';

@Component({
  selector: 'pdp-suggestion-conflict-resolver',
  templateUrl: './suggestion-conflict-resolver.component.html',
  styleUrls: ['./suggestion-conflict-resolver.component.css']
})
export class SuggestionConflictResolverComponent implements OnInit {

  originalSuggestion: Suggestion;
  suggestion = new Suggestion();
  suggestionConflictResult: SuggestionConflictResult = null;
  requirementDescriptions: string[] = [];

  isTestMethodGroupConfictResolved = false;
  testMethodConflictActions: {
    isMethodConflictResolved: boolean,
    isVariantConflictResolved: boolean,
  }[] = [];

  constructor(
    public dialog: DialogRef,
    private i18nService: I18nService
  ) { }

  ngOnInit(): void {
    if (this.suggestionConflictResult && this.suggestionConflictResult.testMethodConflictResults) {
      this.suggestionConflictResult.testMethodConflictResults.forEach((testMethodConflictResult) => {
        this.testMethodConflictActions.push({
          isMethodConflictResolved: !testMethodConflictResult.hasDuplicateTestMethod,
          isVariantConflictResolved: !(testMethodConflictResult.hasDuplicateTestMethodVariant || testMethodConflictResult.hasDuplicateTestMethodGroupItem),
        });
      });
    }
  }

  hasBlockingConflictsResolved() {
    return this.suggestionConflictResult && (!this.suggestionConflictResult.hasDuplicateTestMethodGroup || this.isTestMethodGroupConfictResolved);
  }

  isAnyUnresolvedTestMethodConflicts() {
    return !this.testMethodConflictActions.every(a => a.isMethodConflictResolved && a.isVariantConflictResolved);
  }

  getDuplicateVariantOrGroupItemMessage(hasDuplicateTestMethodGroupItem: boolean) {
    const key = hasDuplicateTestMethodGroupItem ? 'duplicate_testMethodVariantAndGroupItem_message' : 'duplicate_testMethodVariant_message';
    return this.i18nService.transform(key);
  }

  setMethodGroupAction(value: boolean) {
    this.isTestMethodGroupConfictResolved = value;
    if (value) {
      this.suggestion.testMethodGroupId = this.suggestionConflictResult.duplicateTestMethodGroupId;
    } else {
      this.suggestion.testMethodGroupId = null;
    }

  }

  setMethodConflictAction(value: boolean, index: number) {
    this.testMethodConflictActions[index].isMethodConflictResolved = value;

    if (value) {
      this.suggestion.testMethodSuggestions[index].testMethodVariant.testMethodId = this.suggestionConflictResult.testMethodConflictResults[index].duplicateTestMethodId;
    } else {
      this.suggestion.testMethodSuggestions[index].testMethodVariant.testMethodId = null;
    }
  }

  setVariantConflictAction(value: boolean, index: number) {
    this.testMethodConflictActions[index].isVariantConflictResolved = value;

    if (value) {
      this.suggestion.testMethodSuggestions[index].testMethodVariant.id = this.suggestionConflictResult.testMethodConflictResults[index].duplicateTestMethodVariantId;
      this.suggestion.testMethodSuggestions[index].testMethodVariant.testMethodGroupItemId = this.suggestionConflictResult.testMethodConflictResults[index].duplicateTestMethodGroupItemId;
    } else {
      this.suggestion.testMethodSuggestions[index].testMethodVariant.id = null;
      this.suggestion.testMethodSuggestions[index].testMethodVariant.testMethodGroupItemId = null;
    }
  }

  onImport() {
    this.dialog.close('import');
  }

  onCancel() {
    this.dialog.close('cancel');
  }
}
