<div class="col-md-6 col-lg-8 pt-3 px-4">
  <div class="jumbotron d-flex align-items-center">
    <div class="container">
      <h5>
        <i class="fas fa-sign-in-alt"></i>&nbsp;
        {{'status_unauthorized_message' | i18n}}
      </h5>
      <div class="pt-3">
        <button type="button" class="btn btn-primary" (click)="login()">
          <img src="/assets/images/natificlogo-white.svg" height="12">&nbsp;
          {{'status_login_button_text' | i18n}}
        </button>
      </div>
    </div>
  </div>
</div>
