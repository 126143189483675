import { Component, Input, OnInit } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { NGridOptions } from '../shared/components/n-grid/models/n-grid-options.model';
import { NGridState } from '../shared/components/n-grid/models/n-grid-state.model';
import { INGridColumnOptionsBase } from '../shared/components/n-grid/interfaces/i-n-grid.column-options.interface';
import { NGridColumnOptions } from '../shared/components/n-grid/models/n-grid-column-options.model';
import { I18nService } from '../shared/services/i18n.service';
import { AuditLogsService } from '../shared/services/audit-logs.service';
import { LocalizationService } from '../shared/services/localization.service';
import '../shared/extensions/string.extension';
import { NGridService } from '../shared/components/n-grid/n-grid.service';
import * as jsondiffpatch from "jsondiffpatch";
import { DiffPatcher } from 'jsondiffpatch';
import { AuditLog } from '../shared/models/audit-log.model';
import { ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { NGridPagedData } from '../shared/components/n-grid/models/n-grid-paged-data.model';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'pdp-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['./audit-logs.component.css']
})
export class AuditLogsComponent implements OnInit {
  diffpatcher: DiffPatcher;
  dateTimeFormatString: string;
  isLoading = false;
  isError = false;
  @Input() public requestId: number;

  gridData$: Observable<GridDataResult>;
  private gridStateSubject: BehaviorSubject<NGridState>;

  gridColumns: INGridColumnOptionsBase[] = [
    new NGridColumnOptions({
      field: 'created',
      title: this.i18nService.transform('modified')
    }),
    new NGridColumnOptions({
      field: 'createdByEmail',
      title: this.i18nService.transform('modified_by')
    })
  ];

  enityGridColumns: INGridColumnOptionsBase[] = [
    new NGridColumnOptions({
      field: 'Action',
      title: this.i18nService.transform('action')
    }),
    new NGridColumnOptions({
      field: 'EntityName',
      title: this.i18nService.transform('level')
    })
  ];

  propertyGridColumns: INGridColumnOptionsBase[] = [];

  gridOptions: NGridOptions = new NGridOptions({
    isPageable: true,
    isSortable: true,
    initialLoading: true
  });

  gridState: NGridState = {
    filter: {
      logic: 'and',
      filters: []
    },
    take: 50,
    sort: [{
      field: 'created',
      dir: 'asc'
    }]
  };

  noRecordsText(): string {
    return !this.isLoading && this.isError ? this.i18nService.transform('message_no_record') : '. . .';
  };
  columnFilterOptionText = this.i18nService.transform('grid_columnFilterOption_text');
  freeTextSearchFilterOptionText = this.i18nService.transform('grid_freeTextSearchFilterOption_text');

  constructor(
    private nGridService: NGridService,
    private localizationService: LocalizationService,
    private auditLogsService: AuditLogsService,
    public i18nService: I18nService) { }

  ngOnInit() {
    this.diffpatcher = jsondiffpatch.create();

    this.dateTimeFormatString = this.localizationService.getDateTimeFormatString();

    if (this.requestId) {
      this.gridState = this.nGridService.applyFilter(this.gridState, 'topEntityName', 'request');
      this.gridState = this.nGridService.applyFilter(this.gridState, 'topEntityId', this.requestId.toString());
    }

    this.gridStateSubject = new BehaviorSubject<NGridState>(this.gridState);
    this.gridData$ = this.gridStateSubject.pipe(
      switchMap(state => this.getAuditLogs(state))
    );
  }

  private getAuditLogs(state: NGridState): Observable<NGridPagedData<AuditLog>> {
    this.isLoading = true;
    return this.auditLogsService.getAuditLogs(state).pipe(      
      map(item => {
        item.data.forEach(auditLog => {
          auditLog.dataChanged = JSON.parse(auditLog.dataChanged);
          auditLog.oldValues = JSON.parse(auditLog.oldValues);
          auditLog.newValues = JSON.parse(auditLog.newValues);
        });
        return item;
      }),
      tap(() => {
        this.isLoading = false;
      }),
      catchError((error, caught) => {
        this.isLoading = false;
        this.isError = true;

        return EMPTY;
      })
    );    
  }

  gridStateChange(state: NGridState): void {
    this.gridState = state;
    this.gridStateSubject.next(this.gridState);
  }

  getChangesAsHtml(row: AuditLog): string {
    const delta = this.diffpatcher.diff(row.oldValues, row.newValues);
    return jsondiffpatch.formatters.html.format(delta, null);
  }
}
