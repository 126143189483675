import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[isNumber][formControlName],[isNumber][formControl],[isNumber][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: IsNumberDirective, multi: true }]
})
export class IsNumberDirective implements Validator {
  @Input('isNumber') validatorEnabled: boolean|string = true;

  validate(c: UntypedFormControl): { [key: string]: any } {
    let isValid = c.value === null || c.value === undefined || c.value === '' || ((typeof c.value === 'number') && !isNaN(c.value));
    return (this.validatorEnabled === '' || this.validatorEnabled) && !isValid ? { 'isNumber': true } : null;
  }
} 
