import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";
import { I18nService } from "./i18n.service";
import { Router } from "@angular/router";
import { AuthService } from "../../auth/auth.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler{
  constructor(private injector: Injector) { }

  // Handling HTTP Errors using Toaster
  public handleError(err: HttpErrorResponse) {
    const toastr = this.injector.get(ToastrService);
    const i18nService = this.injector.get(I18nService);
    const router = this.injector.get(Router);
    const ngZone = this.injector.get(NgZone);
    const authService = this.injector.get(AuthService);
    let status = '';
    let errorMessage: string = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      status = i18nService.transform('client_error');
      errorMessage = err.error.message;
    } else {
      if (err.status) {
        // The backend returned an unsuccessful response code.
        switch (err.status) {
          case 400:
            status = i18nService.transform('status_400');            
            break;
          case 401:            
            status = i18nService.transform('status_401');

            ngZone.run(() => {
              authService.refreshSession().subscribe(() => {
                router.navigate(['/login'], { skipLocationChange: true });
              });
            });
            break;
          case 403:
            status = i18nService.transform('status_403');
            break;
          case 404:
            status = i18nService.transform('status_404');
            break;
          case 409:
            status = i18nService.transform('status_409');
            break;
          case 412:
            status = i18nService.transform('status_412');
            break;
          case 500:
            status = i18nService.transform('status_500');
            break;
          case 503:
            status = i18nService.transform('status_503');
            break;
          case 422:
            status = i18nService.transform('status_422');
            break;
          default:
            status = i18nService.transform('default_error_message');
        }

        if (err.error && err.error.errors && typeof (err.error.errors) == 'object') {
          for (const key in err.error.errors) {
            if (err.error.errors.hasOwnProperty(key)) {
              errorMessage = err.error.errors[key];
            }
          }
        } else if (err.error && err.error.errors && typeof (err.error.errors) == 'string') {
          errorMessage = err.error.errors;
        } else if (err.error && err.error.detail && typeof (err.error.detail) == 'string') {
          errorMessage = err.error.detail;
        }
      }
    }
    if (status) {
      toastr.error(
        errorMessage,
        status,
        {
          closeButton: true,
          disableTimeOut: true,
          tapToDismiss: true
        });
    }
  }
}
