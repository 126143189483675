import { ISortable } from "../../interfaces/i-sortable.interface";
import { TestMethodVariant } from "../test-methods/test-method-variant.model";
import { TestMethodGroup } from "./test-method-group.model";

export class TestMethodGroupItem implements ISortable {  
  id: number;
  testMethodGroupId: number;
  testMethodVariantId: number;
  testMethodGroup: TestMethodGroup;
  testMethodVariant: TestMethodVariant;
  sortIndex: number;
}
