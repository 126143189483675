import { Component } from '@angular/core';
import { Location } from '@angular/common';

/**
 * A Component which is used to display the not found (HTTP 404) message.
 */
@Component({
  selector: 'pdp-not-found',
  templateUrl: 'not-found.component.html'
})
export class NotFoundComponent {
  _location;
/**
  * Initializes a new instance of the `NotFoundComponent` class.
  * @param location Service to interact with the browser URL.
  */
  constructor(private location: Location) {
    this._location = location;
  }

  /**
   * Goes back to the previous page.
   */
  goBack() {
    this._location.back();
  }
}
