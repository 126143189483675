import { TestAttributeOverride } from "./test-attribute-override.model";
import { Supplier } from "../companies/supplier.model";
import { TestResult } from "../requests/test-result.model";
import { ISortable } from "../../interfaces/i-sortable.interface";
import { TestMethodVariant } from "../test-methods/test-method-variant.model";
import { AggregateFunction } from "../../enums/aggregate-function.enum";
import { AggregateConditionType } from "../../enums/aggregate-condition-type.enum";
import { AggregateTestResult } from "../requests/aggregate-test-result.model";
import { TestResultComment } from "../requests/test-result-comment.model";

/**
 * View model for a test requirement.
 */
export class TestRequirement implements ISortable {
  id: number;
  requestId: number;
  supplierId: number;
  testMethodVariantId: number;
  testMethodVariant: TestMethodVariant;
  description: string;
  determination: number;
  numberOfAllowedFailures: number;
  aggregateFunction: AggregateFunction;
  sortIndex: number;
  supplier: Supplier;
  testAttributeOverrides: TestAttributeOverride[];
  testResults: TestResult[];
  testResultComments: TestResultComment[];
  aggregateConditionType: AggregateConditionType;
  lotAverageConditionAttributeOverrides: TestAttributeOverride[];
  percentageOfRequiredPasses: number;
  precisionLevel: number;
  aggregatedTestResults: AggregateTestResult[];
  lotAverageTestResults: AggregateTestResult[];
}
