import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { GridDataResult, RowClassArgs, RowClassFn } from '@progress/kendo-angular-grid';
import { NGridOptions } from '../shared/components/n-grid/models/n-grid-options.model';
import { NGridState } from '../shared/components/n-grid/models/n-grid-state.model';
import { INGridColumnOptionsBase } from '../shared/components/n-grid/interfaces/i-n-grid.column-options.interface';
import { NGridColumnOptions } from '../shared/components/n-grid/models/n-grid-column-options.model';
import { I18nService } from '../shared/services/i18n.service';
import { RequestStatus } from '../shared/enums/request-status.enum';
import { TabHeader } from '../shared/models/tab-header.model';
import { User } from '../shared/models/users/user.model';
import { SessionService } from '../shared/services/session.service';
import { ToastrService } from "ngx-toastr";
import { NGridService } from '../shared/components/n-grid/n-grid.service';
import { RequestService } from '../shared/services/request.service';
import { Router } from '@angular/router';
import '../shared/extensions/string.extension';
import { AuthorizationService } from '../shared/services/authorization.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { catchError, switchMap, tap, distinctUntilChanged } from 'rxjs/operators';
import { NGridPagedData } from '../shared/components/n-grid/models/n-grid-paged-data.model';
import { AgendaGridViewModel } from '../shared/models/agenda-gridview.model';
import { EMPTY } from 'rxjs';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SpecifierObservationType } from '../shared/enums/specifier-observation-type.enum';

@Component({
  selector: 'pdp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  host: { class: 'flex-column-layout flex-fill' },
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit, OnDestroy {
  isTabsLoading: boolean = false;
  isGridContentLoading: boolean = false;
  isError: boolean = false;
  initialTabIndex = 0;

  gridData$: Observable<GridDataResult>;

  private gridStateSubject: BehaviorSubject<NGridState>;
  private tabHeaders: TabHeader[] = [];
  private currentUser: User;

  private subscriptions = new Subscription();

  private defaultSortCondition: SortDescriptor[] = [{
    field: 'daysInStatus',
    dir: "desc"
  }];


  gridOptions: NGridOptions = new NGridOptions({
    isPageable: true,
    isSortable: true,
    isFreeTextSearchEnabled: true,
    initialLoading: true
  });

  gridState: NGridState = {
    take: 15,
    sort: this.defaultSortCondition
  };

  statusWarningDeadline: number = 14;
  statusAlertDeadline: number = 21;
  instance: HomeComponent = this;

  columnFilterOptionText = this.i18nService.transform('home_agendaGrid_columnFilterOption_text');
  freeTextSearchFilterOptionText = this.i18nService.transform('home_agendaGrid_freeTextSearchFilterOption_text');
  searchTextboxPlaceholder = this.i18nService.transform('grid_searchTextboxPlaceholder_text');
  public isSystemOrPortalAdmin = false;

  RequestStatus = RequestStatus;
  status: RequestStatus = RequestStatus.All;

  gridColumns: INGridColumnOptionsBase[] = [
    new NGridColumnOptions({
      field: 'workOrderNumber',
      title: this.i18nService.transform('label_workOrderNumber'),
      hidden: () => this.status === RequestStatus.PendingSpecifierApproval,
      width: 166
    }),
    new NGridColumnOptions({
      field: 'sspNumber',
      title: this.i18nService.transform('label_requestId'),
      hidden: () => this.status !== RequestStatus.PendingSpecifierApproval,
      width: 100
    }),
    new NGridColumnOptions({
      field: 'contractNumbers',
      title: this.i18nService.transform('label_contractNumbers'),
      width: 180
    }),
    new NGridColumnOptions({
      field: 'lotNumber',
      title: this.i18nService.transform('lotNumber'),
      width: 104
    }),
    new NGridColumnOptions({
      field: 'primeContractor',
      title: this.i18nService.transform('primeContractor'),
      hidden: () => this.status !== RequestStatus.All
    }),
    new NGridColumnOptions({
      field: 'reasonForSubmittal',
      title: this.i18nService.transform('request_label_reasonForSubmittal'),
      hidden: () => this.status === RequestStatus.All,
      width: 170
    }),
    new NGridColumnOptions({
      field: 'daysInStatus',
      title: this.i18nService.transform('label_daysInStatus'),
      hidden: () => this.status === RequestStatus.All,
      width: 150
    }),
    new NGridColumnOptions({
      field: 'manufacturerName',
      title: this.i18nService.transform('request_label_manufacturer'),
      width: 210
    }),
    new NGridColumnOptions({
      field: 'endItems',
      title: this.i18nService.transform('label_endItems'),
      width: 170
    }),
    new NGridColumnOptions({
      field: 'materialToBeTested',
      title: this.i18nService.transform('label_materialToBeTested')
    }),
    new NGridColumnOptions({
      field: 'governmentSpecificationNumber',
      title: this.i18nService.transform('specification'),
      hidden: () => this.status === RequestStatus.All,
      width: 170
    }),
    new NGridColumnOptions({
      field: 'status',
      title: this.i18nService.transform('label_status'),
      hidden: () => this.status !== RequestStatus.All,
      width: 240
    }),
    new NGridColumnOptions({
      field: 'assignedSpecialistName',
      title: this.i18nService.transform('assigned_specialist'),
      hidden: () => this.status !== RequestStatus.PendingSpecifierApproval,
      width: 170
    })
  ];

  constructor(
    public i18nService: I18nService,
    private sessionService: SessionService,
    private toastr: ToastrService,
    private nGridService: NGridService,
    private requestService: RequestService,
    private authorizationService: AuthorizationService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.gridStateSubject = new BehaviorSubject<NGridState>(this.gridState);
    this.gridData$ = this.gridStateSubject.pipe(
      distinctUntilChanged(),
      switchMap(state => this.getAgendaWorkitems(state, this.currentUser))
    );

    this.subscriptions.add(this.sessionService.currentUser$.pipe(distinctUntilChanged()).subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.isSystemOrPortalAdmin = this.authorizationService.isSystemOrPortalAdmin(user);
        this.buildTabHeaders();
      }
    }));
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  noRecordsText(): string {
    return !this.isGridContentLoading && this.isError ? this.i18nService.transform('requests_noRecordMessage') : '. . .';
  };

  getVisibleTabHeaders() {
    return this.tabHeaders.filter(h => !h.hidden);
  }

  getSpecifierObservationTypeText(value: SpecifierObservationType): string {
    if (value !== null && value !== undefined) {
      switch (value) {
        case SpecifierObservationType.FollowUpOffline:
          return this.i18nService.transform('followUpOffline');
        case SpecifierObservationType.RemoteInspection:
          return this.i18nService.transform('remoteInspection');
        case SpecifierObservationType.AttendanceNotNeeded:
        default:
          return this.i18nService.transform('attendanceNotNeeded');
      }
    }

    return '-';
  }

  getYesNoUndefinedIndicatorText(value: boolean): string {
    return value !== null && value !== undefined ? this.i18nService.transform(value ? 'yes' : 'no') : '-';
  }

  private buildTabHeaders() {
    this.isTabsLoading = true;
    this.requestService.getStatusCounts(true).subscribe(response => {
      this.tabHeaders = response.body.map(s => {
        return <TabHeader>{
          key: s.status,
          label: this.resolveStatus(s.status),
          itemCount: s.count,
          hidden: s.count == 0 && s.status != RequestStatus.All
        }
      });
      this.initialTabIndex = this.getInitialTabIndex();
      this.isTabsLoading = false;

      if (this.initialTabIndex > 0) {
        this.status = this.getVisibleTabHeaders()[this.initialTabIndex].key;
        this.setStatusFilter(this.status);
      } else {
        this.gridStateSubject.next(this.gridState);
      }
    },
      error => {
        this.toastr.error(error.message);
      });
  }

  getAgendaWorkitems(state: NGridState, user: User): Observable<NGridPagedData<AgendaGridViewModel>> {
    if (user) {
      this.isGridContentLoading = true;

      return this.requestService.getAgenda(state).pipe(
        tap(() => {
          this.isGridContentLoading = false;
        }),
        catchError((error, caught) => {
          this.toastr.error(error.message);
          this.isGridContentLoading = false;
          this.isError = true;

          return EMPTY;
        })
      );
    }

    return EMPTY;
  }

  getInitialTabIndex(): number {
    let result = -1;
    const visibleTabs = this.getVisibleTabHeaders();
    const isSsr = this.authorizationService.isSourceSamplingRepresentative(this.currentUser);
    const isLabMgr = this.authorizationService.isLabManager(this.currentUser);

    result = visibleTabs.findIndex(t => t.key === RequestStatus.PendingSsrApproval);
    if (isSsr && result > -1) {
      return result;
    }

    result = visibleTabs.findIndex(t => t.key === RequestStatus.PendingLabMgrApproval);
    if (isLabMgr && result > -1) {
      return result;
    }

    result = visibleTabs.findIndex(t => t.key === RequestStatus.PendingSpecifierApproval);
    if (this.isSystemOrPortalAdmin && result > -1) {
      return result;
    }

    result = visibleTabs.findIndex(t => t.key === RequestStatus.New);
    if (!this.isSystemOrPortalAdmin && result > -1) {
      return result;
    }

    result = visibleTabs.findIndex(t => t.key === RequestStatus.InProgress);
    if (!this.isSystemOrPortalAdmin && result > -1) {
      return result;
    }

    if (visibleTabs.length > 0) {
      result = 0;
    }

    return result;
  }

  onTabSelect(e) {
    this.status = this.getVisibleTabHeaders()[e.index].key;
    this.gridState.skip = 0;
    this.gridState.sort = this.defaultSortCondition;
    this.setStatusFilter(this.status);
  }

  private setStatusFilter(status: RequestStatus) {
    if (status === RequestStatus.All) {
      this.gridState = this.nGridService.removeFilter(this.gridState, 'status');
    } else {
      this.gridState = this.nGridService.applyFilter(this.gridState, 'status', RequestStatus[status].toString().toLowerFirstLetter());
    }
  }

  gridStateChange(state: NGridState): void {
    this.gridState = state;
    this.gridStateSubject.next(state);
  }

  resolveStatus(status: RequestStatus): string {
    return this.i18nService.transform(`requestStatus_${RequestStatus[status]}`);
  }

  selectionChange(e): void {
    if (e.selectedRows && e.selectedRows.length > 0) {
      const requestId = e.selectedRows[0].dataItem.id;
      this.router.navigateByUrl(`request/${requestId}`);
    }
  }

  stylizeRowsByDaysInStatus: RowClassFn = (context: RowClassArgs) => {
    const statusWarningDeadline = this.statusWarningDeadline;
    const statusAlertDeadline = this.statusAlertDeadline;
    return {
      'status-warning': context.dataItem.status < RequestStatus.ArchivedAndConfirmed && context.dataItem.daysInStatus >= statusWarningDeadline,
      'status-alert': context.dataItem.status < RequestStatus.ArchivedAndConfirmed && context.dataItem.daysInStatus >= statusAlertDeadline,
      'clickable': true
    };
  }
}
