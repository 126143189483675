import { I18nService } from "../../shared/services/i18n.service";

export class CollapseInfoBuilder {
  private collapseInfoText: string;

  constructor(
    private i18nService: I18nService
  ) {}

  public initialize(): CollapseInfoBuilder {
    this.collapseInfoText = '';
    return this;
  }

  private addSeparationIfNecessary() {
    if (this.collapseInfoText !== '') {
      this.collapseInfoText += '; ';
    }
  }

  public addCollapseInfoItem(i18nLabel: string, value: string): CollapseInfoBuilder {
    this.addSeparationIfNecessary();

    this.collapseInfoText += `${this.i18nService.transform(i18nLabel)}: ${value}`;

    return this;
  }

  public addPlainText(text: string): CollapseInfoBuilder {
    this.addSeparationIfNecessary();

    this.collapseInfoText += text;

    return this;
  }

  public build(): string {
    return this.collapseInfoText;
  }
}
