<div *ngIf="suggestionConflictResult" class="conflict-resolving-panel">
  <div class="row mb-2">
    <div class="col-sm-3">
      <label class="header">{{'requirement'|i18n}}</label>
    </div>
    <div class="col-sm-9 ps-0">
      <div class="row">
        <div class="col-sm-3 d-flex">
          <label class="header">{{'target'|i18n}}</label>
        </div>
        <div class="col-sm-9 d-flex ps-0">
          <label class="header">{{'label_actions'|i18n}}</label>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="suggestionConflictResult.hasDuplicateTestMethodGroup" class="row mb-2">
    <div class="col-sm-3">
    </div>
    <div class="col-sm-9 ps-0">
      <div class="row">
        <div class="col-sm-3 d-flex">
          <div class="conflict-target info-card d-flex align-items-center">{{'suggestionConflictTarget_testMethodGroup'|i18n}}</div>
        </div>
        <div class="col-sm-9 d-flex ps-0">
          <div class="info-card p-0 d-flex" [ngClass]="{ 'danger': !isTestMethodGroupConfictResolved, 'resolved': isTestMethodGroupConfictResolved }">
            <div class="d-flex flex-1">
              <div class="conflict-icon d-flex align-items-center justify-content-center">
                <i class="fas" [ngClass]="{ 'fa-times-circle': !isTestMethodGroupConfictResolved, 'fa-check-circle': isTestMethodGroupConfictResolved }"></i>
              </div>
              <div class="card-content flex-1">
                <div class="mb-1">
                  {{'duplicate_testMethodGroup_message'|i18n}}
                </div>
                <div class="row">
                  <div class="col-sm-4 d-flex align-items-center">
                    <input kendoRadioButton
                           type="radio"
                           id="methodGroupCancel"
                           name="methodGroupCancel"
                           class="me-1"
                           [ngModel]="isTestMethodGroupConfictResolved"
                           (ngModelChange)="setMethodGroupAction($event)"
                           [value]="false" />
                    <label for="methodGroupCancel"
                           [ngClass]="{'selected-option': !isTestMethodGroupConfictResolved }">{{'resolution_cancelAndRename'|i18n}}</label>
                  </div>
                  <div class="col-sm-4 d-flex align-items-center">
                    <input kendoRadioButton
                           type="radio"
                           id="methodGroupUseExisting"
                           name="methodGroupUseExisting"
                           class="me-1"
                           [ngModel]="isTestMethodGroupConfictResolved"
                           (ngModelChange)="setMethodGroupAction($event)"
                           [value]="true" />
                    <label for="methodGroupUseExisting"
                           [ngClass]="{'selected-option': isTestMethodGroupConfictResolved }">{{'resolution_useExisting'|i18n}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="suggestionConflictResult.testMethodConflictResults && suggestionConflictResult.testMethodConflictResults.length > 0">
    <div *ngFor="let testMethodConflictResult of suggestionConflictResult.testMethodConflictResults; let i = index" class="row mb-2">
      <div class="col-sm-3 d-flex">
        <div class="info-card">
          {{requirementDescriptions[testMethodConflictResult.index]}}
        </div>
      </div>
      <div class="col-sm-9 ps-0">
        <div *ngIf="testMethodConflictResult.hasDuplicateTestMethod" class="row mb-2">
          <div class="col-sm-3 d-flex d-flex">
            <div class="conflict-target info-card d-flex align-items-center">{{'suggestionConflictTarget_testMethod'|i18n}}</div>
          </div>
          <div class="col-sm-9 d-flex ps-0">
            <div class="info-card p-0 d-flex" [ngClass]="{ 'warning': !testMethodConflictActions[i].isMethodConflictResolved, 'resolved': testMethodConflictActions[i].isMethodConflictResolved }">
              <div class="d-flex flex-1">
                <div class="conflict-icon d-flex align-items-center justify-content-center">
                  <i class="fas" [ngClass]="{ 'fa-exclamation-triangle': !testMethodConflictActions[i].isMethodConflictResolved, 'fa-check-circle': testMethodConflictActions[i].isMethodConflictResolved }"></i>
                </div>
                <div class="card-content flex-1">
                  <div class="mb-1">{{'duplicate_testMethod_message'|i18n}}</div>
                  <div class="row">
                    <div class="col-sm-4 d-flex align-items-center">
                      <input kendoRadioButton
                             type="radio"
                             id="methodDuplicate_{{i}}"
                             name="methodDuplicate_{{i}}"
                             class="me-1"
                             [ngModel]="testMethodConflictActions[i].isMethodConflictResolved"
                             (ngModelChange)="setMethodConflictAction($event, i)"
                             [value]="false" />
                      <label for="methodDuplicate_{{i}}"
                             [ngClass]="{'selected-option': !testMethodConflictActions[i].isMethodConflictResolved }">{{'resolution_createAnyway'|i18n}}</label>
                    </div>
                    <div class="col-sm-4 d-flex align-items-center">
                      <input kendoRadioButton
                             type="radio"
                             id="methodUseExisting_{{i}}"
                             name="methodUseExisting_{{i}}"
                             class="me-1"
                             [ngModel]="testMethodConflictActions[i].isMethodConflictResolved"
                             (ngModelChange)="setMethodConflictAction($event, i)"
                             [value]="true" />
                      <label for="methodUseExisting_{{i}}"
                             [ngClass]="{'selected-option': testMethodConflictActions[i].isMethodConflictResolved }">{{'resolution_useExisting'|i18n}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="testMethodConflictResult.hasDuplicateTestMethodVariant" class="row mb-2">
          <div class="col-sm-3 d-flex">
            <div class="conflict-target info-card d-flex align-items-center">
              {{'suggestionConflictTarget_testMethodVariant'|i18n}}
              <ng-container *ngIf="testMethodConflictResult.hasDuplicateTestMethodGroupItem">&amp;<br />{{'suggestionConflictTarget_testMethodGroupItem'|i18n}}</ng-container>
            </div>
          </div>
          <div class="col-sm-9 d-flex ps-0">
            <div class="info-card p-0 d-flex" [ngClass]="{ 'warning': !testMethodConflictActions[i].isVariantConflictResolved, 'resolved': testMethodConflictActions[i].isVariantConflictResolved }">
              <div class="d-flex flex-1">
                <div class="conflict-icon d-flex align-items-center justify-content-center">
                  <i class="fas" [ngClass]="{ 'fa-exclamation-triangle': !testMethodConflictActions[i].isVariantConflictResolved, 'fa-check-circle': testMethodConflictActions[i].isVariantConflictResolved }"></i>
                </div>
                <div class="card-content flex-1">
                  <div class="mb-1">
                    {{getDuplicateVariantOrGroupItemMessage(testMethodConflictResult.hasDuplicateTestMethodGroupItem, testMethodConflictResult.duplicateTestMethodGroupItemDescription)}}
                  </div>
                  <div class="row">
                    <div class="col-sm-4 d-flex align-items-center">
                      <input kendoRadioButton
                             type="radio"
                             id="methodVariantDuplicate_{{i}}"
                             name="methodVariantDuplicate_{{i}}"
                             class="me-1"
                             [ngModel]="testMethodConflictActions[i].isVariantConflictResolved"
                             (ngModelChange)="setVariantConflictAction($event, i)"
                             [value]="false" />
                      <label for="methodVariantDuplicate_{{i}}"
                             [ngClass]="{'selected-option': !testMethodConflictActions[i].isVariantConflictResolved }">{{'resolution_createAnyway'|i18n}}</label>
                    </div>
                    <div class="col-sm-4 d-flex align-items-center">
                      <input kendoRadioButton
                             type="radio"
                             id="methodVariantUseExisting_{{i}}"
                             name="methodVariantUseExisting_{{i}}"
                             class="me-1"
                             [ngModel]="testMethodConflictActions[i].isVariantConflictResolved"
                             (ngModelChange)="setVariantConflictAction($event, i)"
                             [value]="true" />
                      <label for="methodVariantUseExisting_{{i}}"
                             [ngClass]="{'selected-option': testMethodConflictActions[i].isVariantConflictResolved }">{{'resolution_useExisting'|i18n}}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="isAnyUnresolvedTestMethodConflicts()" class="row mt-2">
    <div class="col">
      <div class="alert alert-warning">
        {{'duplication_introduction_warning'|i18n}}
      </div>
    </div>
  </div>
</div>
<kendo-dialog-actions layout="normal" class="p-2">
  <button kendoButton
          themeColor="secondary"
          (click)="onCancel()">
    {{'cancel'|i18n}}
  </button>
  <button kendoButton
          themeColor="primary"
          (click)="onImport()"
          [disabled]="!hasBlockingConflictsResolved()">
    {{'import'|i18n}}
  </button>
</kendo-dialog-actions>
