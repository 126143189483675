import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, UntypedFormControl } from '@angular/forms';

@Directive({
  selector: '[isStampSelected][formControlName],[isStampSelected][formControl],[isStampSelected][ngModel]',
  providers: [{ provide: NG_VALIDATORS, useExisting: IsStampSelectedDirective, multi: true }]
})
export class IsStampSelectedDirective implements Validator {
  @Input('isStampSelected') validatorEnabled: boolean|string = true;

  validate(c: UntypedFormControl): { [key: string]: any } {
    let isStampSelected = c.value > 0;
    return (this.validatorEnabled === '' || this.validatorEnabled) && !isStampSelected ? { "isStampSelected": true } : null;
  }
} 
