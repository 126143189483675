import { Component } from '@angular/core';
import { Delimiter } from '../../../../shared/enums/delimiter.enum';

@Component({
  selector: 'pdp-add-samples',
  templateUrl: './add-samples.component.html',
  styleUrls: ['./add-samples.component.css']
})
export class AddSamplesComponent {  

  Delimiter = Delimiter;

  supplierRollNumbersText = '';
  supplierRollNumbers: string[] = [];
  delimiter: Delimiter = Delimiter.LineBreak;
  userDefinedDelimiterText = '';

  constructor() { }

  userDefinedDelimiterTextChange(value: string) {
    this.userDefinedDelimiterText = value;
    this.splitValues();
  }

  supplierRollNumbersTextChange(value: string) {
    this.supplierRollNumbersText = value;
    this.splitValues();
  }

  splitValues() {    
    let splittingRule = null;

    switch (this.delimiter) {      
      case Delimiter.Tabulator: splittingRule = /\t+/; break;
      case Delimiter.Space: splittingRule = / +/; break;
      case Delimiter.Comma: splittingRule = /, */; break;
      case Delimiter.Semicolon: splittingRule = /; */; break;
      case Delimiter.UserDefined: splittingRule = this.userDefinedDelimiterText; break;
      case Delimiter.LineBreak: splittingRule = /\n+/; break;
    }

    if (splittingRule !== null) {
      if (this.delimiter === Delimiter.LineBreak) {        
        this.supplierRollNumbers = this.supplierRollNumbersText.split(splittingRule);
      } else {
        this.supplierRollNumbers = this.supplierRollNumbersText.split(splittingRule).flatMap(v => v.split(/\n+/));
      }

      this.supplierRollNumbers = this.supplierRollNumbers.filter(item => item !== '');
    }
  }

}
