import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ContractsService } from '../../services/contracts.service';
import { ContractListItem } from '../../models/contracts/contract-list-item.model';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NGridState } from '../n-grid/models/n-grid-state.model';
import { NGridFilterMethod } from '../n-grid/enums/n-grid-filter-method.enum';
import { I18nService } from '../../services/i18n.service';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';

@Component({
  selector: 'pdp-contract-selector',
  templateUrl: './contract-selector.component.html',
  styleUrls: ['./contract-selector.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContractSelectorComponent implements OnInit, OnDestroy {
  
  public availableContracts: ContractListItem[] = [];    
  public selectedContract: ContractListItem;
  public selectedPrimeContractorName: string = '';  
  public resultCount: number;
  public resultInfo: string;
  public isLoading: boolean = false;

  private selectedPrimeId: number = null;
  private filteredContracts: ContractListItem[] = [];
  private resultLimit = 50;
  private contractSearchUpdated: Subject<string> = new Subject();
  private searchQuery = '';
  private contractSearchSubscription: Subscription;
  private isProgrammaticalFocus = false;

  @ViewChild('contractSelectorComboBox')
  public contractSelectorComboBox: ComboBoxComponent;

  @Input()
  public selectedContracts: ContractListItem[] = [];

  @Output()
  public selectedContractIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  constructor(
    private contractService: ContractsService,
    private i18nService: I18nService
  ) { }

  ngOnInit() {
    this.contractSearchSubscription = this.contractSearchUpdated.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe((value) => { this.searchQuery = value; this.getContractListItems(value); });

    if (this.selectedContracts && this.selectedContracts.length > 0) {
      this.selectedPrimeId = this.selectedContracts[0].primeId;
      this.selectedPrimeContractorName = this.selectedContracts[0].primeContractorName;
    }

    this.getContractListItems('', true);
  }

  ngOnDestroy(): void {
    this.contractSearchSubscription.unsubscribe();
  }

  openPopup() {
    if (!this.isProgrammaticalFocus && !this.contractSelectorComboBox.isOpen) {
      this.contractSelectorComboBox.toggle(true);
    }

    this.isProgrammaticalFocus = false;
  }

  filterContracts(query: string): void {
    this.contractSearchUpdated.next(query);
  }

  valueChanged(value: ContractListItem) {
    if (value || value === undefined) {
      this.selectedContract = value || null;
    }
  }

  private getContractListItems(freeTextSearch: string = '', isInitialization: boolean = false): void {
    const gridState: NGridState = {
      take: this.resultLimit,
      sort: [{
        field: 'contractNumber',
        dir: 'asc'
      }],
      filterMethod: NGridFilterMethod.FreeTextSearch,
      freeTextSearch: freeTextSearch
    };
    this.isLoading = true;
    this.contractService.getContractListItems(gridState, this.selectedPrimeId).subscribe(
      contracts => {
        this.filteredContracts = contracts;
        if (isInitialization) {
          const selectedContractIds = this.getSelectedContractIds();
          this.selectedContractIdsChange.emit(selectedContractIds);
        }
        this.availableContracts = this.getAvailableContracts();

        this.isLoading = false;

        this.resultInfo = this.availableContracts.length > 0
          ? this.i18nService.transform('showing_n_results_message', { '@count': this.availableContracts.length })
          : null;
      },
      error => {
        this.isLoading = false;
      });
  }

  public addContract(control: ComboBoxComponent): void {

    if (this.selectedContract && this.selectedContracts.findIndex(sc => sc.id === this.selectedContract.id) === -1) {
      if (this.selectedContracts.length === 0) {
        this.selectedPrimeId = this.selectedContract.primeId;
        this.selectedPrimeContractorName = this.selectedContract.primeContractorName;
      }
      this.selectedContracts.push(this.selectedContract);
      const selectedContractIds = this.getSelectedContractIds();
      this.selectedContractIdsChange.emit(selectedContractIds);
      this.selectedContract = null;
      this.searchQuery = '';
      this.getContractListItems();
    }

    this.selectedContract = null;

    if (!control.isFocused) {
      this.isProgrammaticalFocus = true;
      control.focus();
    }

  }

  public onRemoveContract(contractToRemove: ContractListItem) {    
    this.selectedContracts = this.selectedContracts.filter(sc => sc.id != contractToRemove.id);
    const selectedContractIds = this.getSelectedContractIds();
    this.selectedContractIdsChange.emit(selectedContractIds);
    if (this.selectedContracts.length === 0) {
      this.selectedPrimeId = null;
      this.selectedPrimeContractorName = null;
    }    
    this.getContractListItems(this.searchQuery);
  }

  private getSelectedContractIds(): number[] {
    return this.selectedContracts.map(sc => sc.id);
  }

  private getAvailableContracts(): ContractListItem[] {
    return this.filteredContracts.filter(c => this.selectedContracts.findIndex(sc => sc.id === c.id) === -1);
  }
}
