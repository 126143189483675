import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ErrorCodes } from '../../../shared/enums/error-codes.enum';
import { FormGroupNames } from '../../../shared/enums/form-group-names.enum';
import { Request } from '../../../shared/models/requests/request.model';
import { Sample } from '../../../shared/models/requests/sample.model';
import { formProvider } from '../../../shared/providers/form.provider';
import { ErrorMessageService } from '../../../shared/services/error-message.service';
import { I18nService } from '../../../shared/services/i18n.service';
import { SortingService } from '../../../shared/services/sorting.service';
import { AddSamplesComponent } from './add-samples/add-samples.component';

@Component({
  selector: 'pdp-sample-information',
  templateUrl: './sample-information.component.html',
  styleUrls: ['./sample-information.component.css'],
  viewProviders: [formProvider]
})
export class SampleInformationComponent implements OnInit {
  @Input() request: Request;
  @Input() readonly = false;
  @Input() indicatorsRequired = false;
  @Input() controlsRequired = false;
  @Input() showSampleReceivedDate = false;
  @Input() isSampleReceivedDateRequired = false;
  @Input() isLotNumberRequired = false;
  @Input() isLotNumberReadonly = false;
  @Input() dateFormatString = '';

  FormGroupNames = FormGroupNames;
  noSamplesErrorMessage: string;
  samplesCheckValue = '';

  sampleReceivedDateValue: Date;

  constructor(
    private dialogService: DialogService,
    private sortingService: SortingService,
    private i18nService: I18nService,
    private errorMessageService: ErrorMessageService,
    private requestForm: NgForm
  ) { }

  ngOnInit() {
    if (this.request && !this.request.samples) {
      this.request.samples = [];
    }

    if (this.request.sampleReceivedDate) {
      this.sampleReceivedDateValue = new Date(this.request.sampleReceivedDate);
    }

    this.updateCheckValue();
    this.noSamplesErrorMessage = this.errorMessageService.resolveError(ErrorCodes.NoSamplesAdded);
  }

  getMinimumSampleReceivedDateValue() {
    return new Date(this.request.ssrSignedDate ? this.request.ssrSignedDate : '1900-01-01');
  }

  isSamplesInvalid(): boolean {
    const samplesCheckControl = this.requestForm.form.get('samplesCheck');
    if (samplesCheckControl) {
      return samplesCheckControl.invalid && samplesCheckControl.touched;
    }

    return false;
  }

  updateCheckValue() {
    if (this.request && this.request.samples) {
      this.samplesCheckValue = this.request.samples.length > 0 ? this.request.samples.length.toString() : null;
    }
  }

  openAddSamplesDialog() {
    const dialogRef = this.dialogService.open({
      content: AddSamplesComponent,
      title: this.i18nService.transform('addSamples'),
      actions: [
        { text: this.i18nService.transform('close'), value: 'cancel', themeColor: "secondary" },
        { text: this.i18nService.transform('addSamples'), value: 'addSamples', themeColor: "primary" }
      ],
      actionsLayout: 'normal'
    });

    const instance = dialogRef.content.instance;
    dialogRef.result.subscribe(result => {
      const action = (result as object)['value'];
      if (action === 'addSamples') {
        const samplesForm = this.getSamplesForm();
        const supplierRollNumbers = instance.supplierRollNumbers as string[];

        supplierRollNumbers.forEach(srn => {
          const newSample = new Sample();
          newSample.requestId = this.request.id;
          newSample.name = srn;
          newSample.sortIndex = this.request.samples.length;
          this.request.samples.push(newSample);
        });

        samplesForm.markAsTouched({ onlySelf: true });
        this.updateCheckValue();
      }
    });
  }

  deleteSample(sample: Sample) {
    const samplesForm = this.getSamplesForm();
    const index: number = this.request.samples.indexOf(sample);
    if (index !== -1) {
      this.request.samples.splice(index, 1);
      this.sortingService.calculateSortIndex(this.request.samples, index);
      samplesForm.markAsTouched({ onlySelf: true });
      this.updateCheckValue();
    }
  }

  getIndicesOfRollNumberDuplicates(value: string, index: number) {
    var indices = [];
    for (let i = 0; i < this.request.samples.length; i++) {
      if (this.request.samples[i].name === value && value !== '' && i !== index)
        indices.push(i+1);
    }

    return indices;
  }

  getDuplicateIndicesMessage(indices: number[]) {
    let duplicateIndicesMessage = '';

    if (indices && indices.length > 0) {
      const indicesListText = indices.join(', ');
      duplicateIndicesMessage = this.i18nService.transform('duplicateIndicesMessage', { '@list': indicesListText });
    }

    return duplicateIndicesMessage;
  }

  trackBy(item: Sample, index: number) {
    return item.sortIndex;
  }

  private getSamplesForm(): UntypedFormGroup {
    return this.requestForm.form.get(FormGroupNames.Samples) as UntypedFormGroup;
  }
}
