<nav class="navbar">
  <div class="brand-container">
    <a class="navbar-brand" href="#">
      <img src="assets/images/ptc-logo.png" height="36">&nbsp;
      <span>{{'navbar_title_label_text'|i18n}}</span>&nbsp;
      <span>({{'navbar_build_text'|i18n}}&nbsp;{{buildNumber}})</span>
    </a>
  </div>
  <kendo-splitbutton *ngIf="isAuthenticated"
                     [data]="loginMenu"
                     [iconClass]="isUserLoading?'':'far fa-user-circle pe-025'"
                     fillMode="flat"
                     [popupSettings]="popupSettings">
    <span *ngIf="isUserLoading" class="k-icon k-i-loading"></span>
    <span kendoTooltip [tooltipTemplate]="tooltipTemplate" tooltipClass="tooltip-wrapper" filter="span.user-data">
      <span class="user-data" [title]="roles">{{userName}}<span *ngIf="companyName"> ({{companyName}})</span></span>
    </span>
  </kendo-splitbutton>
</nav>
<ng-template #tooltipTemplate let-anchor>
  <div *ngFor="let role of anchor.nativeElement.getAttribute('data-title').split('|')">
    {{role}}
  </div>
</ng-template>
