<div class="m-05">
  <n-grid [data]="gridData$ | async"
          [columns]="gridColumns"
          [options]="gridOptions"
          [state]="gridState"
          [isLoading]="isLoading"
          [noRecordsText]="noRecordsText()"
          [columnFilterOptionText]="columnFilterOptionText"
          [freeTextSearchFilterOptionText]="freeTextSearchFilterOptionText"
          [stateChangeDebounceTime]="1000"
          (gridStateChange)="gridStateChange($event)">
    <ng-template nGridCellTemplate forField="created" let-rowData>
      {{rowData.created | date: dateTimeFormatString}}
    </ng-template>
    <ng-template nGridDetailTemplate let-rowData>
      <div [innerHtml]="getChangesAsHtml(rowData)"></div>
    </ng-template>
  </n-grid>
</div>
