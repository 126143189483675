import { Component, OnInit } from '@angular/core';
import { DialogService } from '@progress/kendo-angular-dialog';

import { GridDataResult } from '@progress/kendo-angular-grid';
import { NGridOptions } from '../shared/components/n-grid/models/n-grid-options.model';
import { NGridState } from '../shared/components/n-grid/models/n-grid-state.model';
import { INGridColumnOptionsBase } from '../shared/components/n-grid/interfaces/i-n-grid.column-options.interface';
import { NGridColumnOptions } from '../shared/components/n-grid/models/n-grid-column-options.model';
import { I18nService } from '../shared/services/i18n.service';
import { ToastrService } from 'ngx-toastr';
import { TestMethodsService } from '../shared/services/test-methods.service';
import { TestMethodDetailsComponent } from './test-method-details/test-method-details.component';
import { TestMethod } from '../shared/models/test-methods/test-method.model';
import { TestMethodMember } from '../shared/models/test-methods/test-method-member.model';
import { TestAttribute } from '../shared/models/test-methods/test-attribute.model';
import '../shared/extensions/string.extension';
import { AggregateFunction } from '../shared/enums/aggregate-function.enum';
import { TestMethodVariant } from '../shared/models/test-methods/test-method-variant.model';
import { LocalizationService } from '../shared/services/localization.service';
import { NGridService } from '../shared/components/n-grid/n-grid.service';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { NGridColumnFilterType } from '../shared/components/n-grid/enums/n-grid-column-filter-type.enum';
import { TestType } from '../shared/enums/test-type.enum';
import { FindSpecificationComponent } from './find-specification/find-specification.component';
import { Globals } from '../shared/globals';
import { catchError, switchMap, distinctUntilChanged, tap } from 'rxjs/operators';
import { NGridPagedData } from '../shared/components/n-grid/models/n-grid-paged-data.model';
import { Feature } from '../core/feature-manager/feature.enum';
import { MessageBoxService } from '../shared/components/message-box/message-box.service';
import { MessageBoxType } from '../shared/components/message-box/enums/message-box-type.enum';

@Component({
  selector: 'pdp-test-methods',
  templateUrl: './test-methods.component.html',
  styleUrls: ['./test-methods.component.css'],
  host: { class: 'flex-column-layout w-100' }
})
export class TestMethodsComponent implements OnInit {

  public Feature = Feature;

  public tabHeaders = [];
  dateFormatString: string;
  isLoading = false;
  isError = false;

  gridData$: Observable<GridDataResult>;

  private gridStateSubject: BehaviorSubject<NGridState>;
  private bypassDistinction = false;

  gridColumns: INGridColumnOptionsBase[] = [
    new NGridColumnOptions({
      field: 'name',
      title: this.i18nService.transform('name'),
      width: 130
    }),
    new NGridColumnOptions({
      field: 'title',
      title: this.i18nService.transform('title'),
      width: 270
    }),
    new NGridColumnOptions({
      field: 'testType',
      title: this.i18nService.transform('test_type'),
      filterType: NGridColumnFilterType.DropDown,
      filterData: this.getTestTypes(),
      filterTextField: 'label',
      filterValueField: 'value',
      filterDefaultTextField: this.i18nService.transform('select_test_type'),
      width: 80
    }),
    new NGridColumnOptions({
      field: 'unitOfMeasure',
      title: this.i18nService.transform('unit_of_measure'),
      width: 80
    }),
    new NGridColumnOptions({
      field: 'modified',
      title: this.i18nService.transform('last_modified'),
      filterType: NGridColumnFilterType.Date,
      width: 70
    }),
    new NGridColumnOptions({
      field: 'archiveDate',
      title: this.i18nService.transform('archived_date'),
      filterType: NGridColumnFilterType.Date,
      width: 70
    }),
    new NGridColumnOptions({
      field: 'modifiedByName',
      title: this.i18nService.transform('modified_by'),
      sortable: false,
      width: 160
    }),
    new NGridColumnOptions({
      field: 'actions',
      title: this.i18nService.transform('label_actions'),
      sortable: false,
      filterable: false,
      width: 100
    })
  ];

  gridOptions: NGridOptions = new NGridOptions({
    isPageable: true,
    isSortable: true,
    isFilterable: true,
    isFreeTextSearchEnabled: true,
    initialLoading: true
  });

  gridState: NGridState = {
    take: 50,
    sort: [{
      field: 'name',
      dir: 'asc'
    }]
  };

  noRecordsText(): string {
    return !this.isLoading && this.isError ? this.i18nService.transform('message_no_record') : '. . .';
  }
  columnFilterOptionText = this.i18nService.transform('grid_columnFilterOption_text');
  freeTextSearchFilterOptionText = this.i18nService.transform('grid_freeTextSearchFilterOption_text');
  searchTextboxPlaceholder = this.i18nService.transform('grid_searchTextboxPlaceholder_text');

  constructor(
    private nGridService: NGridService,
    private localizationService: LocalizationService,
    private testMethodsService: TestMethodsService,
    private toastr: ToastrService,
    private dialogService: DialogService,
    private messageBoxService: MessageBoxService,
    public i18nService: I18nService) { }

  ngOnInit() {
    this.tabHeaders = [
      { label: this.i18nService.transform('testMethodStatus_active') },
      { label: this.i18nService.transform('testMethodStatus_archived') }
    ];
    this.dateFormatString = this.localizationService.getDateFormatString();

    this.gridStateSubject = new BehaviorSubject<NGridState>(this.gridState);
    this.gridData$ = this.gridStateSubject.pipe(
      distinctUntilChanged((oldValue: NGridState, newValue: NGridState) => this.bypassDistinction ? false : oldValue === newValue),
      switchMap(state => this.getTestMethods(state)),
      tap(() => this.bypassDistinction = false)
    );
  }

  getTestTypes(): any[] {
    let statuses = [];
    for (const testType in TestType) {
      const testTypeLabel = testType.charAt(0).toLowerCase() + testType.slice(1);
      const label = this.i18nService.transform(testTypeLabel);
      statuses.push({ value: (<any>TestType[testType]).toString(), label: label });
    }
    return statuses;
  }

  private getTestMethods(state: NGridState): Observable<NGridPagedData<TestMethod>> {
    this.isLoading = true;

    return this.testMethodsService.getTestMethods(state).pipe(
      tap(() => {
        this.isLoading = false;
      }),
      catchError((error, caught) => {
        this.toastr.error(error.message);
        this.isLoading = false;
        this.isError = true;

        return EMPTY;
      })
    );
  }

  gridStateChange(state: NGridState): void {
    this.gridState = state;
    this.gridStateSubject.next(state);
  }

  public openCreateTestMethodDialog(clone?: TestMethod) {
    const dialogRef = this.dialogService.open({
      content: TestMethodDetailsComponent
    });
    const instance = dialogRef.content.instance;

    const testMethod = this.initializeTestMethod(clone);
    instance.testMethod = testMethod;
    instance.actionName = this.i18nService.transform('create');
    instance.title = clone ? this.i18nService.transform('clone_test_method') : this.i18nService.transform('new_test_method');

    dialogRef.result.subscribe(result => {
      if (result === 'saved') {
        this.bypassDistinction = true;
        this.gridStateSubject.next(this.gridState);
        this.toastr.success(this.i18nService.transform('test_method_created'));
      }
      else if (result === 'clone') {
        this.openCreateTestMethodDialog(testMethod);
      }
    });
  }

  public openFindSpecificationDialog() {
    const dialogRef = this.dialogService.open({
      title: this.i18nService.transform('find_specification'),
      content: FindSpecificationComponent,
      width: '90%',
      minWidth: '70%'
    });

    dialogRef.result.subscribe(result => {
      if (result === 'imported') {
        this.bypassDistinction = true;
        this.gridStateSubject.next(this.gridState);
      }
    });
  }

  private initializeTestMethod(clone?: TestMethod): TestMethod {
    let testMethod: TestMethod;
    if (clone) {
      testMethod = Object.assign({}, clone);
      testMethod.id = null;
      testMethod.archiveDate = null;
      testMethod.isReadOnly = false;
      testMethod.name = this.i18nService.transform('clone_of') + testMethod.name;
      testMethod.testMethodVariants = [];
      clone.testMethodVariants.forEach(tmv => {
        testMethod.testMethodVariants.push(this.cloneVariant(tmv));
      });
    } else {
      testMethod = new TestMethod();
      testMethod.testMethodVariants = [new TestMethodVariant()];
      testMethod.testMethodVariants[0].specificationType = this.i18nService.transform('default');
      testMethod.testMethodVariants[0].testMethodMembers = [new TestMethodMember()];
      testMethod.testMethodVariants[0].testMethodMembers[0].testAttributes = [new TestAttribute(), new TestAttribute()];
      testMethod.testMethodVariants[0].testMethodMembers[0].testAttributes[0].index = 0;
      testMethod.testMethodVariants[0].testMethodMembers[0].testAttributes[1].index = 1;
      testMethod.testMethodVariants[0].determination = 1;
      testMethod.testMethodVariants[0].precisionLevel = Globals.DecimalPlaces;
    }

    return testMethod;
  }

  openTestMethodDetailsDialog(testMethod) {
    const dialogRef = this.dialogService.open({
      content: TestMethodDetailsComponent,
    });

    const instance = dialogRef.content.instance;
    instance.testMethod = JSON.parse(JSON.stringify(testMethod));
    instance.testRequirement = undefined;
    instance.actionName = this.i18nService.transform('update');
    instance.title = this.i18nService.transform('test_method_details');

    dialogRef.result.subscribe(result => {
      if (result === 'saved') {
        this.bypassDistinction = true;
        this.gridStateSubject.next(this.gridState);
        this.toastr.success(this.i18nService.transform('test_method_updated'));
      }
      else if (result === 'clone') {
        this.openCreateTestMethodDialog(testMethod);
      }
    });
  }

  private cloneVariant(testMethodVariant: TestMethodVariant): TestMethodVariant {
    const clone: TestMethodVariant = JSON.parse(JSON.stringify(testMethodVariant));
    clone.id = null;
    clone.specificationType = clone.specificationType;
    clone.isReadOnly = false;
    clone.testMethodMembers.forEach(tdm => {
      tdm.id = null;
      tdm.testAttributes.forEach(ta => {
        ta.id = null;
        ta.testAttributeOverrides = null;
      })
    });

    return clone;
  }

  public deleteTestMethod(testMethod): void {
    this.messageBoxService.open({
      title: this.i18nService.transform('message_box_title_confirm'),
      message: this.i18nService.transform('test_method_delete_confirm_message'),
      type: MessageBoxType.Warning,
      showIcon: true,
      confirmButtonText: this.i18nService.transform('yes'),
      denyButtonText: this.i18nService.transform('no'),
      onConfirm: () => {
        this.testMethodsService.deleteTestMethod(testMethod).subscribe(result => {
          this.bypassDistinction = true;
          this.gridStateSubject.next(this.gridState);
          this.toastr.success(this.i18nService.transform('test_method_deleted_message'));
        });
      }
    });
  }

  resolveAggregateFunction(aggregateFunction: AggregateFunction): string {
    if (aggregateFunction) {
      return this.i18nService.transform(`aggregateFunction_full_${aggregateFunction}`);
    } else {
      return '';
    }
  }

  onTabSelect(e) {
    const isArchived = e.index === 0 ? 'false' : 'true';
    this.gridState = this.nGridService.applyFilter(this.gridState, 'isArchived', isArchived);
    this.gridState.skip = 0;
    this.gridStateChange(this.gridState);
  }
}
