<div class="flex-column-layout flex-fill m-05">
  <div>
    <h4>{{'test_method_groups'|i18n}}</h4>
    <button kendoButton themeColor="primary" (click)="openCreateTestMethodGroupDialog()" class="mb-05">{{'test_method_group_button_new'|i18n}}</button>
    <div class="row mb-05">
      <div class="col">
        <search-textbox placeholder="{{'searchForTestMethodGroup'|i18n}}"
                        class="w-100"
                        [debounceTime]="1000"
                        (afterValueChanged)="freeTextSearchChange($event)">
        </search-textbox>
      </div>
    </div>
  </div>
  <div class="flex-column-layout flex-fill">
    <n-grid [data]="gridData$ | async"
            [columns]="masterGridColumns"
            [options]="masterGridOptions"
            [state]="gridState"
            class="test-method-groups-grid"
            [isLoading]="isLoading"
            [noRecordsText]="noRecordsText()"
            [rowClass]="stylizeRows"
            [stateChangeDebounceTime]="1000"
            (gridStateChange)="gridStateChange($event)"
            (cellClick)="cellClick($event)">
      <ng-template nGridCellTemplate forField="actions" let-rowData>
        <div kendoTooltip tooltipClass="tooltip-wrapper" position="bottom">
          <button kendoButton themeColor="error" class="h-38 ms-1" *ngIf="!isReadonly(rowData)" (click)="deleteTestMethodGroup(rowData)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </div>
      </ng-template>
      <ng-template nGridDetailTemplate let-rowData>
        <n-grid [data]="getDetailGridData(rowData)"
                [columns]="detailGridColumns"
                [options]="detailGridOptions">
        </n-grid>
      </ng-template>
    </n-grid>
  </div>
</div>
