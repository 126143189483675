export enum RequestStatus {  
  'New' = 0,
  'PendingSsrApproval' = 1,
  'PendingFinalizeRequirements' = 2,
  'InProgress' = 3,
  'PendingLabMgrApproval' = 4,
  'PendingSpecifierApproval' = 5,
  'ArchivedAndConfirmed' = 6,
  'ArchivedAndRejected' = 7,
  'ArchivedAndCancelled' = 8,
  'All' = 9 /* Helping status, just for listing purposes */
}
