import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'search-textbox',
  templateUrl: './search-textbox.component.html',
  styleUrls: ['./search-textbox.component.css']
})
export class SearchTextboxComponent implements OnDestroy {
  @Input() value: string;
  @Input() class: string | string[] = '';
  @Input() debounceTime: number = 0;
  @Output() valueChange = new EventEmitter<string>();
  @Output() afterValueChanged = new EventEmitter<string>();

  private searchUpdated: Subject<string> = new Subject();

  @Input() placeholder: string;

  get classList(): string[] {
    return ['form-control'].concat(this.class);
  }

  constructor() {
    this.searchUpdated.pipe(
      debounce(() => interval(this.debounceTime)),
      distinctUntilChanged())
      .subscribe((value) => this.afterValueChanged.emit(value));
  }

  clearValue() {
    this.value = "";
    this.onModelChange(this.value);
  }

  onModelChange(value) {
    this.valueChange.emit(value);
    this.searchUpdated.next(value);
  }

  ngOnDestroy(): void {
    this.searchUpdated.unsubscribe();
  }
}
