<div class="messagebox-content" [ngClass]="{
       'msg-info': type===MessageBoxType.Info,
       'msg-warning': type===MessageBoxType.Warning,
       'msg-alert': type===MessageBoxType.Alert }">
  <div class="icon-area" *ngIf="showIcon" [ngClass]="{
       'text-info': type===MessageBoxType.Info,
       'text-warning': type===MessageBoxType.Warning,
       'text-danger': type===MessageBoxType.Alert }">
    <i class="fas " [ngClass]="{
       'fa-info-circle': type===MessageBoxType.Info,
       'fa-exclamation-triangle': type===MessageBoxType.Warning,
       'fa-times-circle': type===MessageBoxType.Alert }"></i>
  </div>
  <div class="message-area" [ngClass]="{
       'msg-info': type===MessageBoxType.Info,
       'msg-warning': type===MessageBoxType.Warning,
       'msg-alert': type===MessageBoxType.Alert }" [innerHtml]="message">
  </div>
</div>
