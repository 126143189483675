import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'action-tile',
  templateUrl: './action-tile.component.html',
  styleUrls: ['./action-tile.component.css']
})
export class ActionTileComponent implements OnInit {

  @Input() iconClass: string;
  @Input() name: string;
  @Input() description: string;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  onTileClick(e: any) {
    this.action.emit(e);
  }
}
