<div class="flex-column-layout flex-fill m-05">  
  <div>
    <h4>{{'requestsOverview_title'|i18n}}</h4>
    <button kendoButton themeColor="primary" (click)="openContractSelectorDialog()" class="mb-05">{{'newRequest'|i18n}}</button>
    <div *ngIf="isTabsLoading" class="loading-indicator-container">
      <span class="k-icon k-i-loading"></span>
    </div>
  </div>    
  <kendo-tabstrip *ngIf="!isTabsLoading && !isError"
                  (tabSelect)="onTabSelect($event)"
                  [scrollable]="true"
                  class="grid-container flex-column-layout flex-fill">
    <kendo-tabstrip-tab *ngFor="let header of getVisibleTabHeaders(); let i = index;" [selected]="i==0">
      <ng-template kendoTabTitle>
        {{header.label}} ({{header.itemCount}})
      </ng-template>
      <ng-template kendoTabContent>
        <div class="flex-column-layout flex-fill">
          <div class="alert alert-info fst-italic mb-2">{{'attachmentsNote'|i18n}}</div>
          <n-grid [data]="gridData$ | async"
                  [columns]="gridColumns"
                  [options]="gridOptions"
                  [state]="gridState"
                  class="requests-grid"
                  [isLoading]="isGridContentLoading"
                  [noRecordsText]="noRecordsText()"
                  [columnFilterOptionText]="columnFilterOptionText"
                  [freeTextSearchFilterOptionText]="freeTextSearchFilterOptionText"
                  [searchTextboxPlaceholder]="searchTextboxPlaceholder"
                  [rowClass]="stylizeRowsByDaysInStatus"
                  [stateChangeDebounceTime]="1000"
                  (gridStateChange)="gridStateChange($event)"
                  (selectionChange)="selectionChange($event)">
            <ng-template nGridCellTemplate forField="status" let-rowData>
              {{resolveStatus(rowData.status)}}
            </ng-template>
            <ng-template nGridCellTemplate forField="contractNumbers" let-rowData>
              {{rowData.contractNumbers?.join(', ')}}
            </ng-template>
            <ng-template nGridCellTemplate forField="endItems" let-rowData>
              {{rowData.endItems?.join(', ')}}
            </ng-template>
            <ng-template nGridCellTemplate forField="daysInStatus" let-rowData>
              <div class="text-end">{{rowData.daysInStatus}}</div>
            </ng-template>            
            <ng-template nGridDetailTemplate let-rowData>
              <div class="my-1">
                <button *ngFor="let attachment of rowData.attachments" kendoButton (click)="downloadAttachment(rowData, attachment)" class="me-1">
                  <i class="fas fa-file-download me-1"></i>{{attachment.name}}
                </button>
                <div class="text-center" *ngIf="!rowData.attachments || rowData.attachments.length === 0">{{'noAttachments' | i18n}}</div>
              </div>
            </ng-template>
          </n-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
