import { Component, OnInit, Input, ViewEncapsulation, ElementRef, QueryList, ViewChildren, OnDestroy } from '@angular/core';
import { TestMethod } from '../../../shared/models/test-methods/test-method.model';
import { Request } from '../../../shared/models/requests/request.model';
import { TestMethodsService } from '../../../shared/services/test-methods.service';
import { I18nService } from '../../../shared/services/i18n.service';
import { TestRequirement } from '../../../shared/models/test-requirements/test-requirement.model';
import { SessionService } from '../../../shared/services/session.service';
import { SuppliersService } from '../../../shared/services/suppliers.service';
import { Supplier } from '../../../shared/models/companies/supplier.model';
import { AuthorizationService } from '../../../shared/services/authorization.service';
import { SortingService } from '../../../shared/services/sorting.service';
import { ComboBoxComponent } from '@progress/kendo-angular-dropdowns';
import { NGridFilterMethod } from '../../../shared/components/n-grid/enums/n-grid-filter-method.enum';
import { BehaviorSubject, EMPTY, of, Subscription, timer } from 'rxjs';
import { catchError, debounce, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { TestMethodVariant } from '../../../shared/models/test-methods/test-method-variant.model';
import { formProvider } from '../../../shared/providers/form.provider';
import { UntypedFormGroup, NgForm } from '@angular/forms';
import { PanelBarItemModel } from '@progress/kendo-angular-layout';
import { ErrorMessageService } from '../../../shared/services/error-message.service';
import { ErrorCodes } from '../../../shared/enums/error-codes.enum';
import { FormGroupNames } from '../../../shared/enums/form-group-names.enum';
import { TestMethodGroup } from '../../../shared/models/test-method-groups/test-method-group.model';
import { TestMethodGroupsService } from '../../../shared/services/test-method-groups.service';
import { TestMethodGroupItem } from '../../../shared/models/test-method-groups/test-method-group-item.model';
import { Observable } from 'rxjs';
import { ReasonForSubmittal } from '../../../shared/enums/reason-for-submittal.enum';
import { User } from '../../../shared/models/users/user.model';
import { ToastrService } from 'ngx-toastr';
import { ISortable } from '../../../shared/interfaces/i-sortable.interface';
import { NGridState } from '../../../shared/components/n-grid/models/n-grid-state.model';

@Component({
  selector: 'pdp-test-requirements',
  templateUrl: './test-requirements.component.html',
  styleUrls: ['./test-requirements.component.css'],
  viewProviders: [formProvider],
  encapsulation: ViewEncapsulation.None
})
export class TestRequirementsComponent implements OnInit, OnDestroy {
  public suppliers: Supplier[] = [];
  public selectedTestMethodVariant: TestMethodVariant;
  public selectedTestMethodGroup: TestMethodGroup;
  public resultCount: number;
  public resultInfo: string;
  public groupedData$: Observable<GroupResult[]>;
  public testMethodGroups$: Observable<TestMethodGroup[]>;
  public noEvaluationProcedureAvailable$: Observable<boolean> = null;
  public testRequirementsCheckValue: number = null;
  public noRequirementsErrorMessage: string;

  FormGroupNames = FormGroupNames;
  ReasonForSubmittal = ReasonForSubmittal;

  private testMethods: TestMethod[] = [];
  private defaultSupplier: Supplier;
  private _request: Request;
  private testMethodVariantSearchUpdated: BehaviorSubject<string> = new BehaviorSubject('');
  private testMethodGroupSearchUpdated: BehaviorSubject<string> = new BehaviorSubject('');
  private user: User;
  private isSystemOrPortalAdmin = false;
  public isTestMethodVariantsLoading = false;
  public isTestMethodGroupsLoading = false;

  private expandedRows = new Set();
  private resultLimit = 50;
  private variantSearchQuery = '';
  private reasonForSubmittal = null;
  private subscriptions: Subscription = new Subscription();
  private bypassRestrictions = false;

  @ViewChildren('testRequirementPanelBarItem', { read: ElementRef }) panelbarItemElementList: QueryList<ElementRef>;

  constructor(
    private testMethodsService: TestMethodsService,
    private testMethodGroupsService: TestMethodGroupsService,
    private authorizationService: AuthorizationService,
    private suppliersService: SuppliersService,
    private sessionService: SessionService,
    private sortingService: SortingService,
    public i18nService: I18nService,
    public errorMessageService: ErrorMessageService,
    private requestForm: NgForm,
    private toastr: ToastrService
  ) {
  }

  @Input() indicatorsRequired = false;
  @Input() controlsRequired = false;
  @Input() readonly = false;
  @Input() reasonForSubmittalChanged: Observable<ReasonForSubmittal>;

  @Input()
  set request(value: Request) {
    this._request = value;

    this.reasonForSubmittal = this.request.reasonForSubmittal;

    if (!this.request.testRequirements) {
      this.request.testRequirements = [];
    }

    this.updateCheckValue();

    if (this.request.requestContracts && this.request.requestContracts.length > 0) {
      const contractIds = this.request.requestContracts.map(rc => rc.contractId);
      this.suppliersService.getSuppliersOfContracts(contractIds).subscribe(suppliers => {
        this.suppliers = suppliers;

        this.request.testRequirements.forEach(tr => {
          tr.supplier = this.suppliers.find(s => s.id === tr.supplierId);
        });

        this.setDefaultSupplier();
      },
        error => {
          if (error.status === 400) {
            error.error.errors = this.i18nService.transform('error_suppliers_of_contracts_with_different_primes');
          }

          throw error;
        });
    }
  }

  get request(): Request {
    return this._request;
  }

  ngOnInit(): void {
    this.resultCount = this.resultLimit;
    this.resultInfo = this.i18nService.transform('showing_top_n_items_message', { '@count': this.resultCount });

    this.user = this.sessionService.currentUser;
    this.isSystemOrPortalAdmin = this.authorizationService.isSystemOrPortalAdmin(this.user);

    this.groupedData$ = this.testMethodVariantSearchUpdated.pipe(
      debounce(() => this.bypassRestrictions ? EMPTY : timer(1000)),
      distinctUntilChanged((oldValue: string, newValue: string) => this.bypassRestrictions ? false : oldValue === newValue),
      switchMap(value => {
        this.isTestMethodVariantsLoading = true;
        return this.getTestMethodVariants(value, this.isShadeEvaluationSet());
      }),
      tap((groupedVariants: GroupResult[]) => {
        this.bypassRestrictions = false;
        if (this.isShadeEvaluationSet()
          && this.request.testRequirements.length === 0
          && groupedVariants.length === 1 && groupedVariants[0].items.length === 1) {
          const testMethodVariant = <TestMethodVariant>groupedVariants[0].items[0];
          const testRequirement = this.createTestRequirement(testMethodVariant);
          this.request.testRequirements.push(testRequirement);
          const methodAutoAddedMessage = this.i18nService.transform('shadeEvaluation_requirement_autoAdded_message');
          this.toastr.success(methodAutoAddedMessage);
        }
      })
    );

    this.testMethodGroups$ = this.testMethodGroupSearchUpdated.pipe(
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(value => {
        this.isTestMethodGroupsLoading = true;
        return this.getTestMethodGroups(value)
      }
      ));

    if (this.reasonForSubmittalChanged) {
      this.subscriptions.add(this.reasonForSubmittalChanged.subscribe((reasonForSubmittal) => {
        this.reasonForSubmittal = reasonForSubmittal;
        this.bypassRestrictions = true;
        if (this.isShadeEvaluationSet()) {
          this.noEvaluationProcedureAvailable$ = this.getTestMethodVariants('', true).pipe(
            switchMap(value => {
              return of(value.length === 0);
            })
          );
        } else {
          this.noEvaluationProcedureAvailable$ = null;
        }
        this.testMethodVariantSearchUpdated.next(this.variantSearchQuery);
      }));
    }

    this.noRequirementsErrorMessage = this.errorMessageService.resolveError(ErrorCodes.NoTestRequirementsAdded);

    this.updateCheckValue();
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
  }

  isShadeEvaluationSet(): boolean {
    return this.reasonForSubmittal === ReasonForSubmittal.ShadeEvaluation;
  }

  getNoEvaluationProcedureAvailableMessage(): string {
    let key = 'noEvaluationProcedureAvailable_message';

    if (this.isSystemOrPortalAdmin) {
      key += '_admin';
    }

    return this.i18nService.transform(key);
  }

  private getTestRequirementsForm(): UntypedFormGroup {
    return this.requestForm.form.get(FormGroupNames.TestRequirements) as UntypedFormGroup;
  }

  isTestRequirementsInvalid(): boolean {
    if (this.requestForm.form) {
      const testRequirementsCheckControl = this.requestForm.form.get('testRequirementsCheck');
      if (testRequirementsCheckControl) {
        return testRequirementsCheckControl.invalid && testRequirementsCheckControl.touched;
      }
    }
    else {
      return false;
    }
  }

  private updateCheckValue() {
    if (this.request && this.request.testRequirements) {
      this.testRequirementsCheckValue = this.request.testRequirements.length > 0 ? this.request.testRequirements.length : null;
    }
  }

  private setDefaultSupplier() {
    this.defaultSupplier = null;
    const userCompanyId = this.sessionService.currentUser.companyId;
    const primeId = this.request.requestContracts[0].contract.primeContractor.externalId;
    if (this.suppliers && this.suppliers.length > 0) {

      if (this.isSystemOrPortalAdmin || this.authorizationService.isPrimeUserOf(this.user, this.request)) {
        this.defaultSupplier = this.suppliers.find(s => s.companyExternalId === primeId);
      } else {
        this.defaultSupplier = this.suppliers.find(s => s.companyExternalId === userCompanyId);
      }
    }
  }

  public getTestMethod(testMethodId): TestMethod {
    return this.testMethods.find(td => td.id === testMethodId);
  }

  private getTestMethodVariants(freeTextSearch: string = '', filterEvaluationProcedures: boolean = false): Observable<GroupResult[]> {
    let gridState: NGridState = {
      take: this.resultLimit,
      sort: [{
        field: 'description',
        dir: 'asc'
      },
      {
        field: 'specificationType',
        dir: 'asc'
      },
      {
        field: 'specificationClass',
        dir: 'asc'
      },
      {
        field: 'specificationSubClass',
        dir: 'asc'
      }],
      filterMethod: NGridFilterMethod.FreeTextSearch,
      freeTextSearch: freeTextSearch,
      filter: { logic: 'and', filters: [{ field: 'isArchived', operator: 'eq', value: 'false' }] }
    };

    if (filterEvaluationProcedures) {
      gridState.filter.filters.push({ field: 'isEvaluationProcedure', operator: 'eq', value: 'true' })
    }

    return this.testMethodsService.getTestMethodVariants(gridState).pipe(
      map(result => {
        const variants = result.data;
        return groupBy(variants, [{ field: 'testMethodId' }]) as GroupResult[];
      }),
      tap(variants => {
        this.testMethods = variants.map(s => (s.items[0] as TestMethodVariant).testMethod);

        this.resultCount = this.testMethods.length;
        if (freeTextSearch === '' || this.resultCount === 0) {
          this.resultInfo = (this.resultCount < this.resultLimit) ? null : this.i18nService.transform('showing_top_n_items_message', { '@count': this.resultCount });
        } else {
          this.resultInfo = this.i18nService.transform('showing_n_results_message', { '@count': this.resultCount });
        }

        this.isTestMethodVariantsLoading = false;
      })
    );
  }

  private getTestMethodGroups(freetextSearch: string = ''): Observable<TestMethodGroup[]> {
    const state: NGridState = {
      take: 50,
      filterMethod: NGridFilterMethod.FreeTextSearch,
      freeTextSearch: freetextSearch,
      sort: [{
        field: 'name',
        dir: 'asc'
      }]
    };

    return this.testMethodGroupsService.getTestMethodGroups(state).pipe(
      map(data => data.data),
      tap(() => {
        this.isTestMethodGroupsLoading = false
      }),
      catchError((error, caught) => { throw error; })
    );
  }

  getTestMethodVariantSummaryList(testMethodGroupItems: TestMethodGroupItem[]) {
    return testMethodGroupItems.map(tdgi => tdgi.testMethodVariant.summary).join(', ');
  }

  filterTestMethodVariants(query: string): void {
    this.variantSearchQuery = query;
    this.testMethodVariantSearchUpdated.next(query);
  }

  filterTestMethodGroups(query: string): void {
    this.testMethodGroupSearchUpdated.next(query);
  }

  testMethodVariantChanged(value: TestMethodVariant) {
    if (this.request) {
      if (value || value === undefined) {
        this.selectedTestMethodVariant = value || null;
      }
    }
  }

  testMethodGroupChanged(value: TestMethodGroup) {
    if (this.request) {
      if (value || value === undefined) {
        this.selectedTestMethodGroup = value || null;
      }
    }
  }

  isTestRequirementInvalid(testRequirementIndex: number): boolean {
    if (this.panelbarItemElementList) {
      const panelbarItemElement = this.panelbarItemElementList.toArray()[testRequirementIndex];
      return panelbarItemElement ? this.hasInvalidControl(panelbarItemElement, { shouldBeTouched: true }) : false;
    }
    return false;

  }

  moveSortableItemUp(event: any, list: Array<ISortable>, item: ISortable) {
    event.stopPropagation();
    this.sortingService.moveSortableItemUp(list, item);
  }

  moveSortableItemDown(event: any, list: Array<ISortable>, item: ISortable) {
    event.stopPropagation();
    this.sortingService.moveSortableItemDown(list, item);
  }

  private hasInvalidControl(panelElement: ElementRef, options?: { shouldBeTouched?: boolean }) {
    let selectorPattern = '.ng-invalid';

    if (options && options.shouldBeTouched) {
      selectorPattern += '.ng-touched';
    }

    const invalidControls = panelElement.nativeElement.querySelectorAll(selectorPattern);
    return invalidControls && invalidControls.length > 0;
  }

  isExpanded = function (testRequirementId: number): boolean {
    if (this.request && this.request.testRequirements) {
      return this.expandedRows.has(testRequirementId);
    }

    return false;
  }.bind(this);

  isAllRowsExpanded(): boolean {
    if (this._request && this._request.testRequirements) {
      return this._request.testRequirements.length === this.expandedRows.size;
    }

    return false;
  }

  expandAllRows() {
    if (this._request && this._request.testRequirements) {
      this.expandedRows.clear();
      this._request.testRequirements.forEach(testRequirement => {
        this.expandedRows.add(testRequirement.id);
      });
    }
  }

  public stateChange(data: Array<PanelBarItemModel>): boolean {
    const focusedEvent: PanelBarItemModel = data.filter(
      (item) => item.focused === true
    )[0];

    if (focusedEvent.id) {
      const testRequirementId = focusedEvent.id;

      if (focusedEvent.expanded) {
        this.expandedRows.add(testRequirementId);
      } else {
        this.expandedRows.delete(testRequirementId);
      }
    }

    return false;
  }

  deleteTestRequirement(event: any, testRequirement: TestRequirement) {
    event.stopPropagation();
    const testRequirementsForm = this.getTestRequirementsForm();
    const index: number = this.request.testRequirements.indexOf(testRequirement);
    if (index !== -1) {
      this.request.testRequirements.splice(index, 1);
      this.sortingService.calculateSortIndex(this.request.testRequirements, index);
      testRequirementsForm.markAsTouched({ onlySelf: true });
      this.updateCheckValue();
    }
  }

  private createTestRequirement(testMethodVariant: TestMethodVariant): TestRequirement {
    const testRequirement = new TestRequirement();
    testRequirement.requestId = this.request.id;
    testRequirement.testMethodVariantId = testMethodVariant.id;
    testRequirement.testMethodVariant = JSON.parse(JSON.stringify(testMethodVariant));
    testRequirement.precisionLevel = testRequirement.testMethodVariant.precisionLevel;
    testRequirement.determination = testRequirement.testMethodVariant.determination;
    testRequirement.numberOfAllowedFailures = testRequirement.testMethodVariant.numberOfAllowedFailures;
    testRequirement.aggregateConditionType = testRequirement.testMethodVariant.aggregateConditionType;
    testRequirement.percentageOfRequiredPasses = testRequirement.testMethodVariant.percentageOfRequiredPasses;
    testRequirement.aggregateFunction = testMethodVariant.aggregateFunction;
    testRequirement.id = 0;

    if (this.defaultSupplier) {
      testRequirement.supplier = this.defaultSupplier;
      testRequirement.supplierId = this.defaultSupplier.id;
    }

    testRequirement.sortIndex = this.request.testRequirements.length;
    return testRequirement;
  }

  public addTestRequirement(control: ComboBoxComponent): void {
    const testRequirementsForm = this.getTestRequirementsForm();
    const testRequirement = this.createTestRequirement(this.selectedTestMethodVariant);

    this.request.testRequirements.push(testRequirement);

    this.selectedTestMethodVariant = null;
    testRequirementsForm.markAsTouched({ onlySelf: true });

    testRequirementsForm.markAsTouched({ onlySelf: true });
    this.updateCheckValue();

    if (!control.isFocused) {
      control.focus();
    }
  }

  addTestRequirementsFromGroup(control: ComboBoxComponent): void {
    const testRequirementsForm = this.getTestRequirementsForm();
    this.selectedTestMethodGroup.testMethodGroupItems.forEach(tdgi => {
      const testRequirement = this.createTestRequirement(tdgi.testMethodVariant);
      this.request.testRequirements.push(testRequirement);
    });

    this.selectedTestMethodGroup = null;
    testRequirementsForm.markAsTouched({ onlySelf: true });

    testRequirementsForm.markAsTouched({ onlySelf: true });
    this.updateCheckValue();
    if (!control.isFocused) {
      control.focus();
    }
  }

  trackBy(item: TestRequirement, index: number) {
    return item;
  }
}
