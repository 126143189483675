import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { TestMethod } from '../models/test-methods/test-method.model';
import { environment } from '../../../environments/environment';
import { NGridState } from '../components/n-grid/models/n-grid-state.model';
import { NGridService } from '../components/n-grid/n-grid.service';
import { TestMethodVariant } from '../models/test-methods/test-method-variant.model';
import { Suggestion } from '../models/swiss/suggestions/suggestion.model';
import { NGridPagedData } from '../components/n-grid/models/n-grid-paged-data.model';
import { SuggestionConflictResult } from '../models/swiss/suggestions/suggestion-conflict-result.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class TestMethodsService {

  private testMethodsUrl = environment.apiBaseUrl + 'api/v1/TestMethod';

  constructor(
    private http: HttpClient,
    private nGridService: NGridService) { }

  /** GET test methods from the server */
  getTestMethods(state: NGridState): Observable<NGridPagedData<TestMethod>> {
    let httpParams = new HttpParams();
    httpParams = this.nGridService.stateToHttpParams(state, httpParams);
    return this.http.get<NGridPagedData<TestMethod>>(this.testMethodsUrl, { observe: 'body', params: httpParams });
  }

  /** GET test method variants from the server */
  getTestMethodVariants(state: NGridState): Observable<NGridPagedData<TestMethodVariant>> {
    const url = `${this.testMethodsUrl}/Variants`;
    let httpParams = new HttpParams();    
    httpParams = this.nGridService.stateToHttpParams(state, httpParams);
    return this.http.get<NGridPagedData<TestMethodVariant>> (url, { observe: 'body', params: httpParams });
  }

  /** GET test method by id. Will 404 if id not found */
  getTestMethod(id: string): Observable<TestMethod> {
    const url = `${this.testMethodsUrl}/${id}`;
    return this.http.get<TestMethod>(url);
  }

  /** POST: create a new test method */
  addTestMethod(testMethod: TestMethod): Observable<TestMethod> {
    return this.http.post<TestMethod>(this.testMethodsUrl, testMethod, httpOptions);
  }

  /** PUT: updates the test method on the server */
  updateTestMethod(testMethod: TestMethod): Observable<TestMethod> {
    return this.http.put<TestMethod>(this.testMethodsUrl, testMethod, httpOptions);
  }

  /** DELETE: deletes the test method from the server */
  deleteTestMethod(testMethod: TestMethod | string): Observable<any> {
    const id = typeof testMethod === 'string' ? testMethod : testMethod.id;
    const url = `${this.testMethodsUrl}/${id}`;
    return this.http.delete(url, httpOptions);
  }

  createSuggestion(suggestion: Suggestion) {
    var url = `${this.testMethodsUrl}/Suggestion`;
    return this.http.post<Suggestion>(url, suggestion, httpOptions);
  }

  /* POST: Collect information for possible duplicates before import */
  checkSuggestionForConflicts(suggestion: Suggestion) {
    var url = `${this.testMethodsUrl}/CheckSuggestionForConflicts`;
    return this.http.post<SuggestionConflictResult>(url, suggestion);
  }
  
}
