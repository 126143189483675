<div class="flex-column-layout flex-fill m-05">
  <div>
    <h4>{{'contracts_overview'|i18n}}</h4>
    <button kendoButton themeColor="primary" (click)="openCreateContractDialog()" class="mb-05">{{'new_contract'|i18n}}</button>
  </div>
  <kendo-tabstrip [scrollable]="true"
                  (tabSelect)="onTabSelect($event)"
                  class="grid-container flex-column-layout flex-fill">
    <kendo-tabstrip-tab *ngFor="let header of tabHeaders; let i = index;" [selected]="i==0">
      <ng-template kendoTabTitle>
        {{header.label}}
      </ng-template>
      <ng-template kendoTabContent>
        <div class="flex-column-layout flex-fill">
          <n-grid #contractsGrid
                  [data]="gridData$ | async"
                  [columns]="gridColumns"
                  [options]="gridOptions"
                  [state]="gridState"
                  class="contracts-grid"
                  [isLoading]="isLoading"
                  [noRecordsText]="noRecordsText"
                  [columnFilterOptionText]="columnFilterOptionText"
                  [freeTextSearchFilterOptionText]="freeTextSearchFilterOptionText"
                  [rowClass]="stylizeRows"
                  [stateChangeDebounceTime]="1000"
                  (gridStateChange)="gridStateChange($event)"
                  (cellClick)="cellClick($event)">
          </n-grid>
        </div>
      </ng-template>
    </kendo-tabstrip-tab>
  </kendo-tabstrip>
</div>
