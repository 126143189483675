import { Component } from '@angular/core';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'pdp-unauthorized',
  templateUrl: 'unauthorized.component.html'
})
export class UnauthorizedComponent {

  constructor(
    public authService: AuthService) {
  }

  /**
   * Initiates the login procedure.
   */
  login() {
    this.authService.authenticate();
  }
}
