import { Injectable } from "@angular/core";
import { Router, ResolveEnd, ActivatedRouteSnapshot } from "@angular/router";

import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppSettings } from '../../shared/models/app-settings/app-settings';
import { AppSettingsService } from '../../shared/services/app-settings.service';

@Injectable({
    providedIn: 'root'
})
export class AppInsightsService {

    private appInsights: ApplicationInsights;
    private settings: AppSettings;
    private routerSubscription: Subscription;

    constructor(private router: Router, private appSettingsService: AppSettingsService) {
        this.settings = this.appSettingsService.getSettings();
        this.loadAppInsights();
    }

    loadAppInsights(): void {
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: this.settings.appInsights.instrumentationKey,
                enableAutoRouteTracking: true
            }
        });

        this.appInsights.loadAppInsights();
        this.setAuthenticatedUserContext();

        // Log page views
        this.routerSubscription = this.router.events.pipe(filter(event => event instanceof ResolveEnd)).subscribe((event: ResolveEnd) => {
            const activatedComponent = this.getActivatedComponent(event.state.root);
            if (activatedComponent) {
                this.logPageView(`${activatedComponent.name} ${this.getRouteTemplate(event.state.root)}`, event.urlAfterRedirects);
            }
        });
    }


    /**
     * Sets the currently logged in user's id for the Application Insights trackings.
     */
    setAuthenticatedUserContext(): void {
        //    this.authService.userData.subscribe((userData: OidcUserInfo) => {
        //      if (userData && userData.sub) {

        // TODO add authenticated user to the context when authentication is added
        this.appInsights.setAuthenticatedUserContext('TestAccount');
        //      }
        //    });
    }

    logPageView(name: string, url: string
    ) {
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: any) {
        this.appInsights.trackEvent({
            name: name,
            properties: properties,
        });
    }

    logException(exception: Error) {
        this.appInsights.trackException({ exception: exception });
    }

    trace(message: string) {
        this.appInsights.trackTrace({
            message: message
        });
    }

    private getActivatedComponent(snapshot: ActivatedRouteSnapshot): any {
        if (snapshot.firstChild) {
            return this.getActivatedComponent(snapshot.firstChild);
        }

        return snapshot.component;
    }

    private getRouteTemplate(snapshot: ActivatedRouteSnapshot): string {
        let path = '';
        if (snapshot.routeConfig) {
            path += snapshot.routeConfig.path;
        }

        if (snapshot.firstChild) {
            return path + this.getRouteTemplate(snapshot.firstChild);
        }

        return path;
    }
}
