<div class="mb-2">
  {{'selectContract_message'|i18n}}
</div>
<form class="container">
  <div class="row">
    <div class="col pe-0">
      <kendo-combobox #contractSelectorComboBox
                      [data]="availableContracts"
                      textField="summary"
                      valueField="id"
                      class="w-100"
                      name="contract"
                      placeholder="{{'selectContracts'|i18n}}"
                      [filterable]="true"
                      [loading]="isLoading"
                      [ngModel]="selectedContract"
                      (focus)="openPopup()"
                      (filterChange)="filterContracts($event)"
                      (valueChange)="valueChanged($event)">
        <ng-template kendoComboBoxItemTemplate let-dataItem>
          <div class="contract-selector-item">
            <div class="contract-description mt-1 mb-0">{{dataItem.summary}}</div>
            <div class="prime-contractor mb-1">{{'primeContractor'|i18n}}: {{dataItem.primeContractorName}}</div>
          </div>
        </ng-template>
        <ng-template kendoComboBoxNoDataTemplate>
          {{'noSearchResult' | i18n}}
        </ng-template>
        <ng-template kendoComboBoxFooterTemplate>
          <div *ngIf="resultInfo" class="contract-selector-footer">{{resultInfo}}</div>
        </ng-template>
      </kendo-combobox>
    </div>
    <div class="col-auto ps-0">
      <button kendoButton themeColor="secondary" class="ms-1" (click)="addContract(contractSelectorComboBox)" [disabled]="selectedContract == null">{{'add_contract'|i18n}}</button>
    </div>
  </div>
</form>
<div class="form-group row" *ngIf="selectedPrimeContractorName">
  <label class="col-sm-4 col-form-label">{{'primeContractor'|i18n}}</label>
  <div class="col-sm-8 d-flex align-items-center">
    {{selectedPrimeContractorName}}
  </div>
</div>
<div class="form-group row" *ngIf="selectedContracts.length > 0">
  <label class="col-sm-4 col-form-label">{{'selectedContracts'|i18n}}</label>
  <div class="col-sm-8">
    <div *ngFor="let contract of selectedContracts; ">
      <kendo-chip [removable]="true"
                  (remove)="onRemoveContract(contract)">
        <b>{{contract.summary}}</b>
      </kendo-chip>
    </div>
  </div>
</div>
<div class="alert alert-info fst-italic mt-2">{{'multipleContractSelectionNote'|i18n}}</div>
