import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class I18nService {
  data: any = {};
  constructor(private http: HttpClient) { }
  use(lang: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      const langPath = `assets/i18n/${lang || 'en'}.json?${new Date().getTime()}`;
      this.http.get<{}>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          this.data = {};
          resolve(this.data);
        }
      );
    });
  };

  // Replacement parameters can be specified in params object in case the transformed resource is a text template.
  //
  // Example: resource: { 'list_count': 'List has @count items.' }
  // Call:    transform('list_count', { '@count': list.length });
  //
  transform(key: any, params: any = null): any {
    if (!this.data)
      this.use("");
    var value = this.data[key] || key;

    if (this.data[key] && params) {
      for (var paramKey in params)
        if (params.hasOwnProperty(paramKey)) {
          value = value.replace(paramKey, params[paramKey]);
        }
    }

    return value;
  };
}
