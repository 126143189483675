import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NGridState } from '../components/n-grid/models/n-grid-state.model';
import { NGridService } from '../components/n-grid/n-grid.service';
import { AuditLog } from '../models/audit-log.model';
import { NGridPagedData } from '../components/n-grid/models/n-grid-paged-data.model';

@Injectable({
  providedIn: 'root'
})
export class AuditLogsService {

  private auditLogsUrl = environment.apiBaseUrl + 'api/v1/AuditLogs';

  constructor(
    private http: HttpClient,
    private nGridService: NGridService) { }

  /** GET test methods from the server */
  getAuditLogs(state: NGridState): Observable<NGridPagedData<AuditLog>> {
    let httpParams = new HttpParams();
    httpParams = this.nGridService.stateToHttpParams(state, httpParams);
    return this.http.get<NGridPagedData<AuditLog>>(this.auditLogsUrl, { observe: 'body', params: httpParams });
  }
}
