<div class="flex-column-layout flex-fill m-05">
  <div class="tiles list-unstyled">
    <action-tile [routerLink]="['/contracts']"
                 class="tile"
                 iconClass="fas fa-file-contract"
                 name="{{'home_tile_name_contracts'|i18n}}"
                 description="{{'home_tile_description_contracts'|i18n}}">
    </action-tile>
    <action-tile [routerLink]="['/requests']"
                 class="tile"
                 iconClass="fas fa-tasks"
                 name="{{'home_tile_name_requests'|i18n}}"
                 description="{{'home_tile_description_requests'|i18n}}">
    </action-tile>
    <action-tile *ngIf="isSystemOrPortalAdmin"
                 [routerLink]="['/testMethods']"
                 class="tile"
                 iconClass="fas fa-flask"
                 name="{{'home_tile_name_testMethods'|i18n}}"
                 description="{{'home_tile_description_testMethods'|i18n}}">
    </action-tile>
  </div>
  <div class="requests-agenda flex-column-layout flex-fill">
    <h4>{{'home_requestsAgenda_title'|i18n}}</h4>
    <div *ngIf="isTabsLoading" class="loading-indicator-container">
      <span class="k-icon k-i-loading"></span>
    </div>
    <kendo-tabstrip *ngIf="!isTabsLoading && !isError"
                    (tabSelect)="onTabSelect($event)"
                    class="grid-container flex-column-layout flex-fill">
      <kendo-tabstrip-tab *ngFor="let header of getVisibleTabHeaders(); let i = index;" [selected]="i==initialTabIndex" cssClass="agendaTab">
        <ng-template kendoTabTitle>
          {{header.label}} ({{header.itemCount}})
        </ng-template>
        <ng-template kendoTabContent>
          <div class="flex-column-layout flex-fill">
            <div class="alert alert-info fst-italic mb-2">{{'detailsNote'|i18n}}</div>
            <n-grid [data]="gridData$ | async"
                    [columns]="gridColumns"
                    [options]="gridOptions"
                    [state]="gridState"
                    class="agenda-grid"
                    [isLoading]="isGridContentLoading"
                    [noRecordsText]="noRecordsText()"
                    [columnFilterOptionText]="columnFilterOptionText"
                    [freeTextSearchFilterOptionText]="freeTextSearchFilterOptionText"
                    [searchTextboxPlaceholder]="searchTextboxPlaceholder"
                    [rowClass]="stylizeRowsByDaysInStatus"
                    [stateChangeDebounceTime]="1000"
                    (gridStateChange)="gridStateChange($event)"
                    (selectionChange)="selectionChange($event)">
              <ng-template nGridCellTemplate forField="daysInStatus" let-rowData>
                <div class="text-end">{{rowData.daysInStatus}}</div>
              </ng-template>
              <ng-template nGridCellTemplate forField="status" let-rowData>
                {{resolveStatus(rowData.status)}}
              </ng-template>
              <ng-template nGridCellTemplate forField="contractNumbers" let-rowData>
                {{rowData.contractNumbers?.join(', ')}}
              </ng-template>
              <ng-template nGridCellTemplate forField="endItems" let-rowData>
                {{rowData.endItems?.join(', ')}}
              </ng-template>
              <ng-template nGridDetailTemplate let-rowData>
                <div class="my-2">
                  <div class="d-flex flex-wrap">
                    <div *ngIf="status === RequestStatus.PendingSpecifierApproval" class="min-w-25pct px-2 mb-2 request-details">
                      <div class="label">{{'label_workOrderNumber' | i18n}}:</div>{{rowData.workOrderNumber}}
                    </div>
                    <div *ngIf="status === RequestStatus.All" class="min-w-25pct px-2 mb-2 request-details">
                      <div class="label">{{'label_requestId' | i18n}}:</div>{{rowData.sspNumber ?? '-'}}
                    </div>
                    <div *ngIf="this.status === RequestStatus.All" class="min-w-25pct px-2 mb-2 request-details">
                      <div class="label">{{'request_label_reasonForSubmittal' | i18n}}:</div>{{rowData.reasonForSubmittal}}
                    </div>
                    <div *ngIf="status === RequestStatus.All" class="min-w-25pct px-2 mb-2 request-details">
                      <div class="label">{{'label_daysInStatus' | i18n}}:</div>{{rowData.daysInStatus}}
                    </div>
                    <div class="min-w-25pct px-2 mb-2 request-details">
                      <div class="label">{{'primeContractor' | i18n}}:</div>{{rowData.primeContractor}}
                    </div>
                    <div *ngIf="status < RequestStatus.PendingSpecifierApproval" class="min-w-25pct px-2 mb-2 request-details">
                      <div class="label">{{'assigned_specialist' | i18n}}:</div>{{rowData.assignedSpecialistName ?? '-'}}
                    </div>
                  </div>
                  <hr />
                  <div class="k-d-flex flex-wrap">
                    <div *ngIf="status === RequestStatus.All" class="min-w-25pct px-2 mb-2 request-details">
                      <div class="label">{{'specification' | i18n}}:</div>{{rowData.governmentSpecificationNumber ?? '-'}}
                    </div>
                    <div class="min-w-50pct px-2 request-details">
                      <div class="label">{{'request_label_sampleNo'| i18n}}:</div>{{rowData.sampleNumbers}}
                    </div>
                  </div>
                  <hr />
                  <div class="k-d-flex flex-wrap">
                    <div class="min-w-25pct px-2 mb-2 request-details">
                      <span class="label me-1">{{'ptcAttendance' | i18n}}:</span>{{getSpecifierObservationTypeText(rowData.willSpecifierObserve)}}
                    </div>
                    <div class="min-w-25pct px-2 mb-2 request-details">
                      <span class="label me-1">{{'correlationSamplesRequested' | i18n}}:</span>{{getYesNoUndefinedIndicatorText(rowData.correlationRequired)}}
                    </div>
                  </div>
                </div>
              </ng-template>
            </n-grid>
          </div>
        </ng-template>
      </kendo-tabstrip-tab>
    </kendo-tabstrip>
  </div>
</div>
