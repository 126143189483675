import { Component } from '@angular/core';
import { DialogAction, DialogRef } from '@progress/kendo-angular-dialog';
import { CloningOptions } from '../../../shared/models/cloning-options.model';
import { Request } from '../../../shared/models/requests/request.model';

@Component({
  selector: 'pdp-clone-request-dialog',
  templateUrl: './clone-request-dialog.component.html'
})
export class CloneRequestDialogComponent {

  constructor(
    public dialog: DialogRef
  ) { }

  public request: Request;
  public contractIds: number[] = [];
  public cloningOptions: CloningOptions;

  public contractIdsChanged(contractIds: number[]) {
    this.contractIds = contractIds;
  }

  public cloningOptionsChanged(cloningOptions: CloningOptions) {
    this.cloningOptions = cloningOptions;
  }

  onAction(action: string) {
    const dialogAction = new DialogAction();
    dialogAction.text = action;
    this.dialog.close(dialogAction);
  }
}
