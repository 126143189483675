import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DialogRef } from '@progress/kendo-angular-dialog';
import { Company } from '../../shared/models/companies/company.model';
import { ContractsService } from '../../shared/services/contracts.service';
import { Contract } from '../../shared/models/contracts/contract.model';
import { DialogComponentCanDeactivate } from '../../shared/services/dialog-component-can-deactivate';
import { I18nService } from '../../shared/services/i18n.service';
import { NgForm } from '@angular/forms';
import { PendingChangesService } from '../../shared/services/pending-changes.service';
import { ContractStatus } from '../../shared/enums/contract-status.enum';
import { MessageBoxService } from '../../shared/components/message-box/message-box.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'pdp-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.css']
})
export class ContractDetailsComponent extends DialogComponentCanDeactivate implements OnInit {
  @Input() public companies: Company[] = [];
  @Input() public contract: Contract;
  @Input() public actionName: string;
  @Input() public isPortalAdmin = false;
  @Input() public userCompany: Company;
  @Input() public title: string;

  public primeContractors: Company[] = [];
  public availableSuppliers: Company[] = [];
  public supplierContractorsInvolved: Company[] = [];
  public supplierContractorsNotInvolved: Company[] = [];
  public defaultSupplier: Company = null;
  private involvedSupplierIds: number[] = [];
  private results: string[] = [];

  @ViewChild('contractForm') contractForm: NgForm;

  constructor(
    public dialog: DialogRef,
    private contractsService: ContractsService,
    private messageBoxService: MessageBoxService,
    private toastr: ToastrService,
    public i18nService: I18nService,
    public pendingChangesService: PendingChangesService) {
    super(dialog, i18nService);
  }

  ngOnInit(): void {
    this.deactivationWarningMessage = this.i18nService.transform('generic_unsavedChanges_message');

    if (this.isPortalAdmin) {
      this.primeContractors = this.companies.filter(c => c.id != this.userCompany.id);
    } else {
      this.primeContractors = this.companies;
    }    

    if (this.contract.id) {
      this.contractsService.getInvolvedSupplierIds(this.contract.id).subscribe(ids => {
        this.involvedSupplierIds = ids;


        if (this.contract.primeContractor) {
          this.defaultSupplier = this.contract.primeContractor;
          this.supplierContractorsInvolved = this.companies.filter(c => c.id != this.contract.primeContractor.id && this.involvedSupplierIds.findIndex(sId => sId === c.id) >= 0);
          this.availableSuppliers = this.companies.filter(c => c.id != this.contract.primeContractor.id && this.involvedSupplierIds.findIndex(id => id === c.id) === -1);
        } else {
          this.supplierContractorsInvolved = this.companies.filter(is => this.involvedSupplierIds.findIndex(sId => sId === is.id) >= 0);
          this.availableSuppliers = this.companies.filter(c => this.involvedSupplierIds.findIndex(id => id === c.id) === -1);
        }

        if (this.isArchived()) {
          this.supplierContractorsNotInvolved = this.availableSuppliers.filter(as => this.contract.supplierContractorIds.findIndex(id => id === as.id) >= 0);
        }
      });
    } else {
      this.availableSuppliers = this.contract.primeContractor ? this.companies.filter(c => c.id != this.contract.primeContractor.id) : this.companies;
    }
  }

  public saveContract(): void {
    this.addResult('saved');
    if (this.contract.id != null) {
      this.contractsService.updateContract(this.contract).subscribe(result => {
        this.dialog.close(this.joinResults());
      });
    } else {
      this.contractsService.addContract(this.contract).subscribe(result => {
        this.dialog.close(this.joinResults());
      });
    }
  }

  onPrimeChange(primeId: number) {
    this.defaultSupplier = this.companies.find(c => c.id === primeId);
    if (this.contract.supplierContractorIds) {
      this.contract.supplierContractorIds = this.contract.supplierContractorIds.filter(id => id != primeId);
    }
    this.availableSuppliers = this.companies.filter(c => c.id != primeId);
  }

  isReadonly(): boolean {
    return this.contract && (this.contract.requestCount ? this.contract.requestCount > 0 : false);
  }

  isArchived(): boolean {
    return this.contract.status === ContractStatus.Archived;
  }

  canArchiveContract(): boolean {
    return this.contract &&
      this.contract.id != null &&
      !this.isArchived() &&
      this.contract.openRequestCount === 0 &&
      (this.isPortalAdmin || this.contract.primeId === this.userCompany.id);
  }

  canReactivateContract(): boolean {
    return this.isArchived() && (this.isPortalAdmin || this.contract.primeId === this.userCompany.id);
  }

  archiveContract() {
    this.messageBoxService.open({
      title: this.i18nService.transform('message_box_title_confirm'),
      message: this.i18nService.transform("archive_contract_confirm_message"),
      confirmButtonText: this.i18nService.transform('yes'),
      denyButtonText: this.i18nService.transform('no'),
      onConfirm: () => {
        this.addResult('stateChanged');
        this.contract.status = ContractStatus.Archived;
        this.contractsService.updateContract(this.contract).subscribe(result => {
          this.toastr.success(this.i18nService.transform('message_contract_archived'));
          this.supplierContractorsNotInvolved = this.availableSuppliers.filter(as => this.contract.supplierContractorIds.findIndex(id => id === as.id) >= 0);
        });
      }
    });
  }

  reactivateContract() {
    this.messageBoxService.open({
      title: this.i18nService.transform('message_box_title_confirm'),
      message: this.i18nService.transform("reactivate_contract_confirm_message"),
      confirmButtonText: this.i18nService.transform('yes'),
      denyButtonText: this.i18nService.transform('no'),
      onConfirm: () => {
        this.addResult('stateChanged');
        this.contract.status = ContractStatus.Open;
        this.contractsService.updateContract(this.contract).subscribe(result => {
          this.toastr.success(this.i18nService.transform('message_contract_reactivated'));
          this.supplierContractorsNotInvolved = null;
        });
      }
    });
  }

  private addResult(result: string) {
    if (this.results.findIndex(r => r === result) === -1) {
      this.results.push(result);
    }
  }

  private joinResults(): string {
    return this.results.join(' ');
  }

  canDeactivate(): boolean {
    return !this.contractForm.dirty;
  }

  onClose(ev): void {
    ev.preventDefault();
    if (this.contractForm.dirty) {
      const message = this.i18nService.transform('generic_unsavedChanges_message');
      this.pendingChangesService.confirmRejectionOfPendingChanges(message).subscribe(shouldBeClosed => {
        if (shouldBeClosed) {
          this.dialog.close(this.joinResults());
        }
      });
    } else {
      this.dialog.close(this.joinResults());
    }
  }
}
