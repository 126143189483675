import { EventEmitter, Component, Output, Input, OnInit, ViewChild, OnDestroy, AfterViewChecked } from '@angular/core';
import { TestType } from '../../shared/enums/test-type.enum';
import { TestAttribute } from '../../shared/models/test-methods/test-attribute.model';
import { TestMethodMember } from '../../shared/models/test-methods/test-method-member.model';
import { TestMethodVariant } from '../../shared/models/test-methods/test-method-variant.model';
import { NgForm, NgModelGroup } from '@angular/forms';
import { EvaluationMode } from '../../shared/enums/evaluation-mode.enum';
import { AggregateFunction } from '../../shared/enums/aggregate-function.enum';
import { I18nService } from '../../shared/services/i18n.service';
import { AggregateConditionType } from '../../shared/enums/aggregate-condition-type.enum';
import { ListItem } from '../../shared/models/list-item.model';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DataSourceService } from '../../shared/services/datasource.service';
import { formProvider } from '../../shared/providers/form.provider';
import { TestMethodVariantStatusChangeEventArgs } from '../../shared/models/test-methods/test-method-variant-status-change-event-args.model';
import { NumberEvaluatingFn } from '../../shared/interfaces/number-evaluating-function.interface';

@Component({
  selector: 'pdp-test-method-variant',
  templateUrl: './test-method-variant.component.html',
  styleUrls: ['./test-method-variant.component.css'],
  viewProviders: [formProvider]
})
export class TestMethodVariantComponent implements OnInit, OnDestroy, AfterViewChecked {
  @Input() public testMethodVariant: TestMethodVariant;
  @Input() public isSubmitted: boolean;
  @Input() public testType: TestType;
  @Input() public isGrayScale: boolean;
  @Input() public isArchived: boolean;
  @Input() public variantIndex: number;
  @Input()
  public set evaluationMode(value: EvaluationMode) {
    this._evaluationMode = value;
    this.isAggregateConditionEnabled = this._evaluationMode === EvaluationMode.Regular && this.testMethodVariant.aggregateConditionType != null;
  };

  public get evaluationMode(): EvaluationMode {
    return this._evaluationMode;
  }

  @Output() variantChange = new EventEmitter();
  @Output() memberChange = new EventEmitter();
  @Output() attributeChange = new EventEmitter();
  @Output() cloneVariant: EventEmitter<TestMethodVariant> = new EventEmitter();
  @Output() statusChange: EventEmitter<TestMethodVariantStatusChangeEventArgs> = new EventEmitter();
  public testTypeEnum = TestType;
  public aggregateFunctions: ListItem<string>[] = [];
  public testTypes: ListItem<string>[] = [];
  public aggregateConditionTypes: ListItem<string>[] = [];
  public isAggregateConditionEnabled: boolean;
  public EvaluationMode = EvaluationMode;
  public AggregateFunction = AggregateFunction;
  public AggregateConditionType = AggregateConditionType;
  public numberFormat$: Observable<NumberFormatOptions>;
  public precisionListItems: ListItem<number>[];
  private numberFormatSubject: BehaviorSubject<NumberFormatOptions>;
  private _evaluationMode: EvaluationMode;
  private subscription: Subscription;

  @ViewChild('variantModelGroup') variantModelGroup: NgModelGroup;

  constructor(
    public i18nService: I18nService,
    private dataSourceService: DataSourceService,
    private form: NgForm) {
  }

  ngOnInit(): void {
    this.getTestTypes();
    this.getAggregateFunctions();
    this.getAggregateConditionTypes();
    this.precisionListItems = this.dataSourceService.getPrecisionLevelDataSource();
    this.isAggregateConditionEnabled = this.evaluationMode === EvaluationMode.Regular && this.testMethodVariant.aggregateConditionType != null;
    this.numberFormatSubject = new BehaviorSubject<NumberFormatOptions>(this.setNumberFormat(this.testMethodVariant.precisionLevel));
    this.numberFormat$ = this.numberFormatSubject.asObservable();
  }

  getTestTypes() {
    for (const testType in TestType) {
      if (typeof TestType[testType] === 'string') {
        this.testTypes.push({ value: <string>TestType[testType], text: this.i18nService.transform(testType.toLowerFirstLetter()) });
      }
    }
  }

  public getVariantIndex: NumberEvaluatingFn = function (): number {
    return this.variantIndex;
  }.bind(this);

  ngAfterViewChecked(): void {
    if (this.variantModelGroup.control && this.variantModelGroup.control.statusChanges && !this.subscription) {
      this.subscription = this.variantModelGroup.control.statusChanges.subscribe(status => {
        this.statusChange.emit({
          status: status,
          getVariantIndex: this.getVariantIndex
        });
      });
      this.variantModelGroup.control.updateValueAndValidity();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getAggregateFunctions() {
    if (this.testType != TestType.Logical) {
      for (const aggregateFunction in AggregateFunction) {
        if (typeof AggregateFunction[aggregateFunction] === 'string') {
          this.aggregateFunctions.push({
            value: AggregateFunction[aggregateFunction], text: this.i18nService.transform(`aggregateFunction_full_${AggregateFunction[aggregateFunction]}`)
          });
        }
      }
    } else {
      let i18nKey = `aggregateFunction_full_logical_${AggregateFunction.Minimum}`;
      this.aggregateFunctions.push({
        value: AggregateFunction.Minimum, text: this.i18nService.transform(i18nKey)
      });

      i18nKey = `aggregateFunction_full_logical_${AggregateFunction.Maximum}`;
      this.aggregateFunctions.push({
        value: AggregateFunction.Maximum, text: this.i18nService.transform(i18nKey)
      });
    }
  }

  onAggregateFunctionChange(aggregateFunction: AggregateFunction) {
    if (aggregateFunction === AggregateFunction.LotAverage) {
      this.testMethodVariant.lotAverageConditionType = this.testType;
      this.initializeLotAverageConditionMember();
    } else {
      this.testMethodVariant.lotAverageConditionType = null;
      this.testMethodVariant.lotAverageConditionMember = null;
    }
  }

  onChangeLotAverageConditionType() {
    this.initializeLotAverageConditionMember();
  }

  private initializeLotAverageConditionMember() {
    this.testMethodVariant.lotAverageConditionMember = this.createTestMethodMember(this.testMethodVariant.lotAverageConditionType, 0, true);
    // this.testMethodVariant.lotAverageConditionMember.testMethodVariantId = this.testMethodVariant.id;
  }

  private createTestMethodMember(testType: TestType, index: number, isLotAverageConditionComponent: boolean = false): TestMethodMember {
    const testMethodMember = new TestMethodMember();
    testMethodMember.testAttributes = [new TestAttribute()];
    testMethodMember.testAttributes[0].index = 0;

    if (testType === TestType.InclusiveRange || testType === TestType.ExclusiveRange) {
      testMethodMember.testAttributes.push(new TestAttribute());
      testMethodMember.testAttributes[1].index = 1;
    }

    testMethodMember.index = index;
    testMethodMember.isLotAverageConditionComponent = isLotAverageConditionComponent;

    return testMethodMember;
  }

  public addTestMethodMember(): void {
    const testMethodMember = this.createTestMethodMember(this.testType, this.testMethodVariant.testMethodMembers.length);
    this.testMethodVariant.testMethodMembers.push(testMethodMember);
    this.memberChange.emit();
  }

  public removeTestMethodMember(testMethodMember): void {
    this.testMethodVariant.testMethodMembers = this.testMethodVariant.testMethodMembers.filter((t, i) => {
      return t !== testMethodMember;
    });

    if (this.testMethodVariant.testMethodMembers.length === 1) {
      this.testMethodVariant.testMethodMembers[0].name = null;
    }

    this.memberChange.emit();
  }

  aggregateConditionEnabledChange() {
    if (!this.isAggregateConditionEnabled) {
      this.testMethodVariant.aggregateConditionType = null;
      this.testMethodVariant.numberOfAllowedFailures = null;
      this.testMethodVariant.percentageOfRequiredPasses = null;
    } else {
      this.testMethodVariant.aggregateConditionType = AggregateConditionType.Amount;
      this.testMethodVariant.numberOfAllowedFailures = 0;
      this.testMethodVariant.percentageOfRequiredPasses = null;
    }
  }

  onVariantChange() {
    this.variantChange.emit();
  }

  onAttributeChange() {
    this.attributeChange.emit();
  }

  onMemberChange() {
    this.memberChange.emit();
  }

  onPrecisionLevelChange(precisionLevel: number) {
    this.testMethodVariant.precisionLevel = precisionLevel;
    const numberFormat = this.setNumberFormat(precisionLevel);
    this.numberFormatSubject.next(numberFormat);
  }

  onChangeSpecType(type) {
    this.testMethodVariant.specificationClass = type ? this.testMethodVariant.specificationClass : null;
    this.onChangeSpecClass(this.testMethodVariant.specificationClass);
  }

  onChangeSpecClass(specClass) {
    this.testMethodVariant.specificationSubClass = specClass ? this.testMethodVariant.specificationSubClass : null;
  }

  onChangeDetermination(determination) {
    if (determination < 2) {
      this.testMethodVariant.aggregateFunction = null;
      this.testMethodVariant.lotAverageConditionType = null;
      this.testMethodVariant.lotAverageConditionMember = null;
    } else if (this.testMethodVariant.determination < 2) {
      this.testMethodVariant.aggregateFunction = this.testType === TestType.Logical ? AggregateFunction.Minimum : AggregateFunction.Average;
    }

    this.testMethodVariant.determination = determination;
  }

  public aggregateFunctionShouldShow(): boolean {
    return this.testMethodVariant.determination > 1 &&
      this.evaluationMode === EvaluationMode.Regular;
  }

  public lotAverageConditionShouldShow(): boolean {
    return this.aggregateFunctionShouldShow() && this.testMethodVariant.aggregateFunction === AggregateFunction.LotAverage;
  }

  public cloneTestMethodVariant() {
    const clone: TestMethodVariant = JSON.parse(JSON.stringify(this.testMethodVariant));
    clone.id = null;
    clone.isReadOnly = false;
    clone.specificationType = this.i18nService.transform('clone_of') + clone.specificationType;
    clone.testMethodMembers.forEach(tdm => {
      tdm.id = null;
      tdm.testAttributes.forEach(ta => {
        ta.id = null;
        ta.testAttributeOverrides = null;
      })
    });
    this.cloneVariant.emit(clone);
  }

  getAggregateConditionTypes() {
    for (const aggregateConditionType in AggregateConditionType) {
      if (typeof AggregateConditionType[aggregateConditionType] === 'string') {
        this.aggregateConditionTypes.push({
          value: AggregateConditionType[aggregateConditionType], text: this.i18nService.transform(`aggregateConditionType_full_${AggregateConditionType[aggregateConditionType]}`)
        });
      }
    }
  }

  onChangeAggregateConditionType(aggregateConditionType) {
    this.testMethodVariant.aggregateConditionType = aggregateConditionType;

    this.testMethodVariant.numberOfAllowedFailures = aggregateConditionType === AggregateConditionType.Amount ? 0 : null;
    this.testMethodVariant.percentageOfRequiredPasses = aggregateConditionType === AggregateConditionType.Percentage ? 100 : null;

    if (this.form) {
      const aggregateConditionValueControl = this.form.form.get('aggregateConditionValue');
      if (aggregateConditionValueControl) {
        aggregateConditionValueControl.updateValueAndValidity();
      }
    }
  }

  private setNumberFormat(precisionLevel: number): NumberFormatOptions {
    return {
      minimumFractionDigits: precisionLevel,
      maximumFractionDigits: 8
    };
  }
}
