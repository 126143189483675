import { Component, Input, OnInit } from '@angular/core';
import { I18nService } from '../../shared/services/i18n.service';
import { OperatorType } from '../../shared/enums/operator-type.enum';
import { TestAttribute } from '../../shared/models/test-methods/test-attribute.model';
import { formProvider } from '../../shared/providers/form.provider';

@Component({
  selector: 'pdp-test-attribute-logical',
  templateUrl: './test-attribute-logical.component.html',
  styleUrls: ['./test-attribute-logical.component.css'],
  viewProviders: [formProvider]
})
export class TestAttributeLogicalComponent implements OnInit {
  @Input() public testAttribute: TestAttribute;
  @Input() public nameIndex: string;
  @Input() public readonly = false;

  public data: { text: string, value: number }[] = [
    { text: this.i18nService.transform('true'), value: 1 },
    { text: this.i18nService.transform('false'), value: 0 }
  ];

  constructor(
    public i18nService: I18nService) {
  }

  ngOnInit(): void {
    this.testAttribute.operatorType = OperatorType.Equals;
    if (this.testAttribute.expectedValue == null) {
      this.testAttribute.expectedValue = 1;
    }
  }
}
