import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReasonForSubmittal } from '../../../shared/enums/reason-for-submittal.enum';
import { CloningOptions } from '../../../shared/models/cloning-options.model';
import { ContractListItem } from '../../../shared/models/contracts/contract-list-item.model';
import { Request } from '../../../shared/models/requests/request.model';
import { AuthorizationService } from '../../../shared/services/authorization.service';
import { I18nService } from '../../../shared/services/i18n.service';
import { SessionService } from '../../../shared/services/session.service';

@Component({
  selector: 'pdp-clone-request',
  templateUrl: './clone-request.component.html',
  styleUrls: ['./clone-request.component.css']
})
export class CloneRequestComponent implements OnInit {

  public isSystemOrPortalAdmin: boolean;
  public selectAll: boolean;

  public reasonsForSubmittal: { text: string, value: string }[];

  public isReasonForSubmittalEnabled = true;

  public contractIds: number[] = [];
  public selectedContracts: ContractListItem[] = [];

  constructor(
    private sessionService: SessionService,
    private authorizationService: AuthorizationService,
    private i18nService: I18nService) { }

  @Input() request: Request;
  @Input() cloningOptions: CloningOptions;
  @Output() contractIdsChange: EventEmitter<number[]> = new EventEmitter<number[]>();
  @Output() cloningOptionsChange: EventEmitter<CloningOptions> = new EventEmitter<CloningOptions>();

  ngOnInit() {
    this.reasonsForSubmittal = [
      { text: this.i18nService.transform('reasonForSubmittal_SourceSampling'), value: ReasonForSubmittal.SourceSampling },
      { text: this.i18nService.transform('reasonForSubmittal_CorrelationTesting'), value: ReasonForSubmittal.CorrelationTesting },
      { text: this.i18nService.transform('reasonForSubmittal_VerificationTesting'), value: ReasonForSubmittal.VerificationTesting },
      { text: this.i18nService.transform('reasonForSubmittal_ShadeEvaluation'), value: ReasonForSubmittal.ShadeEvaluation }
    ];

    const user = this.sessionService.currentUser;
    this.isSystemOrPortalAdmin = this.authorizationService.isSystemOrPortalAdmin(user);

    if (this.request) {
      this.selectedContracts = this.getSelectedContracts();
      this.contractIds = this.selectedContracts.map(sc => sc.id);
    }

    this.cloningOptions = {
      testRequirements: true,
      sampleInformation: false,
      reasonForSubmittal: this.request.reasonForSubmittal
    };

    this.updateCloningOptions(this.cloningOptions.reasonForSubmittal)
  }

  getSelectedContracts(): ContractListItem[] {
    const contracts = this.request && this.request.requestContracts ? this.request.requestContracts.map(rc => rc.contract) : null;
    if (contracts && contracts.length > 0) {
      const selectedContracts = contracts.map(c => {
        const selectedContract: ContractListItem = {
          contractNumber: c.contractNumber,
          endItem: c.endItemName,
          id: c.id,
          primeId: c.primeId,
          primeContractorName: c.primeContractor.name,
          summary: `${c.contractNumber} - ${c.endItemName}`
        };

        return selectedContract;
      });

      return selectedContracts;
    }

    return [];
  }

  selectedContractsChanged(selectedContractIds: number[]) {
    this.contractIds = selectedContractIds;
    this.contractIdsChange.emit(this.contractIds);
  }

  updateCloningOptions(reasonForSubmittal: string) {
    this.cloningOptions.sampleInformation = (reasonForSubmittal === ReasonForSubmittal.CorrelationTesting);
    this.cloningOptions.reasonForSubmittal = reasonForSubmittal;
    this.cloningOptionsChange.emit(this.cloningOptions);
    this.checkIfAllSelected();
  }

  reasonForSubmittalChanged(value: string) {
    this.updateCloningOptions(value);
  }

  toggleSelectAll() {
    this.cloningOptions.testRequirements = this.selectAll;
    this.cloningOptions.sampleInformation = this.selectAll;
  }

  checkIfAllSelected() {
    this.selectAll = this.cloningOptions.testRequirements &&
      this.cloningOptions.sampleInformation;
  }
}
