<pdp-notification-overlay *ngIf="isLookingForConflicts || isImportInProgress" [message]="notificationMessage"></pdp-notification-overlay>
<div>
  <form #form class="row">
    <div class="col-sm-3 pe-0">
      <input type="text"
             autofocus
             class="form-control"
             id="documentLabel"
             name="documentLabel"
             [(ngModel)]="documentLabel"
             [disabled]="isFindInProgress"
             placeholder="{{'documentLabel'|i18n}}"
             required
             #documentLabelRef="ngModel" />
    </div>
    <div class="col-sm-1 pe-0">
      <input type="text"
             class="form-control"
             id="revision"
             name="revision"
             [(ngModel)]="revision"
             [disabled]="isFindInProgress"
             placeholder="{{'revision'|i18n}}"
             #revisionRef="ngModel" />
    </div>
    <div class="col-sm-8">
      <button kendoButton
              themeColor="primary"
              (click)="findSpecification()"
              [disabled]="isFindInProgress || !(searchFieldIsValid(documentLabelRef) && searchFieldIsValid(revisionRef))">
        {{'find_specification'|i18n}} <span *ngIf="isFindInProgress" class="inline-loading-indicator ms-1 k-icon k-i-loading"></span>
      </button>
      <span class="ps-2" *ngIf="isFindInProgress">{{'getting_specification_message'|i18n}}</span>
      <span class="alert alert-danger ms-2 p-1" *ngIf="findSpecificationErrorMessage">{{findSpecificationErrorMessage}}</span>
    </div>
  </form>
  <div *ngIf="requirementsDocument" class="row mt-3 mb-1 requirements-document-panel">
    <div class="col-sm-4 pe-0">
      <label>{{'requirementsFromDocument'|i18n}} {{documentIdentifier}}:</label>
      <div class="select-all-checkbox ps-2 mb-2">
        <input kendoCheckBox
               type="checkbox"
               name="selectAll"
               [(ngModel)]="isSelectAll"
               (change)="updateSelection()" />
        <label class="ps-2" for="selectAll">{{'selectAll'|i18n}}</label>
      </div>
      <n-grid [data]="gridData"
              [columns]="gridColumns"
              [options]="gridOptions"
              [noRecordsText]="noRecordsText()"
              class="requirements-grid"
              [rowClass]="stylizeRows"
              [selectedKeys]="selectedKeys"
              (selectionChange)="rowSelectionChange($event)">
        <ng-template nGridCellTemplate forField="isSelected" let-rowData>
          <div class="selection-checkbox">
            <input *ngIf="rowData.requirement.testMethodSuggestion"
                   type="checkbox"
                   kendoCheckBox
                   [(ngModel)]="rowData.isSelected"
                   (change)="suggestionSelectionChange()" />
          </div>
        </ng-template>
        <ng-template nGridCellTemplate forField="requirement" let-rowData>
          {{rowData?.description}}
        </ng-template>
      </n-grid>
      <div class="row">
        <div class="col">
          <div class="mt-2 info-card-light">
            <div class="select-all-checkbox my-2">
              <input type="checkbox"
                     kendoCheckBox
                     id="generateTestMethodGroup"
                     name="generateTestMethodGroup"
                     [ngModel]="isGenerateMethodGroup"
                     (ngModelChange)="generateTestMethodGroupChanged($event)" />
              <label class="ps-2" for="generateTestMethodGroup">{{'addRequirementsToTestMethodGroup'|i18n}}</label>
            </div>
            <fieldset [disabled]="!isGenerateMethodGroup" class="generate-test-method-group-panel">
              <div class="form-group row">
                <div class="col-sm-4 d-flex align-items-center">
                  <input type="radio"
                         kendoRadioButton
                         id="createNewGroup"
                         name="testMethodGroupAddingOption"
                         [value]="TestMethodGroupAddingOption.New"
                         [ngModel]="testMethodGroupAddingOption"
                         (ngModelChange)="testMethodGroupAddingOptionChanged($event)" />
                  <label for="createNewGroup" class="ps-2">{{'createNew'|i18n}}</label>
                </div>
                <div class="col-sm-6 d-flex align-items-center">
                  <input type="radio"
                         kendoRadioButton
                         id="useExistingGroup"
                         name="testMethodGroupAddingOption"
                         [value]="TestMethodGroupAddingOption.Existing"
                         [ngModel]="testMethodGroupAddingOption"
                         (ngModelChange)="testMethodGroupAddingOptionChanged($event)" />
                  <label for="useExistingGroup" class="ps-2">{{'addToExisting'|i18n}}</label>
                </div>
              </div>
              <hr />
              <div class="form-group row">
                <label class="col-sm-4" for="testMethodName">{{'test_method_group_name'|i18n}}</label>
                <ng-container [ngSwitch]="testMethodGroupAddingOption">
                  <div *ngSwitchCase="TestMethodGroupAddingOption.New" class="col-sm-8 ps-0">
                    <input type="text" class="form-control" name="testMethodGroupName"
                           required
                           [(ngModel)]="selectedTestMethodGroup.name"
                           placeholder="{{'test_method_group_name'|i18n}}"
                           #testMethodGroupNameControl="ngModel" />
                    <div *ngIf="isTestMethodGroupControlInvalid(testMethodGroupNameControl, TestMethodGroupAddingOption.New)"
                         class="alert alert-danger">
                      {{'test_method_group_name'|i18n}} {{'is_required'|i18n}}
                    </div>
                  </div>
                  <div *ngSwitchCase="TestMethodGroupAddingOption.Existing" class="col-sm-8 ps-0">
                    <kendo-combobox #testMethodGroupComboBox="ngModel"
                                    [data]="testMethodGroups"
                                    required
                                    textField="name"
                                    valueField="id"
                                    [ngModel]="selectedTestMethodGroup"
                                    name="testMethodGroupComboBox"
                                    class="w-100"
                                    placeholder="{{'searchForTestMethodGroup'|i18n}}"
                                    [popupSettings]="{ popupClass: 'test-method-group-popup'}"
                                    [listHeight]="300"
                                    [filterable]="true"
                                    [loading]="isTestMethodGroupsLoading$ | async"
                                    (filterChange)="filterTestMethodGroups($event)"
                                    (valueChange)="testMethodGroupChanged($event)">
                      <ng-template kendoComboBoxItemTemplate let-dataItem>
                        <div *ngIf="dataItem" class="test-method-item">
                          <div class="test-method-group">
                            <div class="test-method-group-name">{{dataItem.name}}</div>
                          </div>
                        </div>
                      </ng-template>
                      <ng-template kendoComboBoxNoDataTemplate>
                        {{'noSearchResult' | i18n}}
                      </ng-template>
                      <ng-template kendoComboBoxFooterTemplate>
                        <div *ngIf="resultInfo" class="test-method-selector-footer">{{resultInfo}}</div>
                      </ng-template>
                    </kendo-combobox>
                    <div *ngIf="isTestMethodGroupControlInvalid(testMethodGroupComboBox, TestMethodGroupAddingOption.Existing)"
                         class="alert alert-danger">
                      {{'test_method_group_name'|i18n}} {{'is_required'|i18n}}
                    </div>

                  </div>
                </ng-container>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-8">
      <kendo-panelbar>
        <kendo-panelbar-item title="{{'test_method_suggestion'|i18n}}" [expanded]="true">
          <ng-template kendoPanelBarContent>
            <form *ngIf="selectedSuggestion; else noSuggestionData" #suggestionForm="ngForm" class="p-3">
              <div class="row mb-2">
                <div class="col"><label>{{'test_method_suggestion'|i18n}}</label></div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4">Assigned test method</label>
                <div class="col-sm-8">
                  <kendo-dropdownlist [data]="testMethodListItems"
                                      textField="summary"
                                      valueField="id"
                                      [(ngModel)]="selectedSuggestion.testMethodVariant.testMethodId"
                                      name="testMethodDropdownList"
                                      class="w-100"
                                      [valuePrimitive]="true"
                                      [listHeight]="300">
                  </kendo-dropdownlist>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4" for="testMethodName">{{'name'|i18n}}</label>
                <div class="col-sm-8">
                  <input type="text"
                         class="form-control"
                         name="testMethodName"
                         [disabled]="!isNewTestMethodSelected()"
                         required
                         [(ngModel)]="selectedSuggestion.name"
                         placeholder="{{'name'|i18n}}"
                         #name="ngModel" />
                  <div *ngIf="isNewTestMethodSelected() && !name.valid" class="alert alert-danger">
                    {{'name'|i18n}} {{'is_required'|i18n}}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4" for="testMethodTitle">{{'title'|i18n}}</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="testMethodTitle"
                         [disabled]="!isNewTestMethodSelected()"
                         required
                         [(ngModel)]="selectedSuggestion.title"
                         placeholder="{{'title'|i18n}}"
                         #title="ngModel" />
                  <div *ngIf="isNewTestMethodSelected() && !title.valid" class="alert alert-danger">
                    {{'title'|i18n}} {{'is_required'|i18n}}
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4">{{'test_type'|i18n}}</label>
                <div class="col-sm-8">
                  {{resolveTestType(selectedSuggestion.testType)}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4">{{'test_evaluation_mode'|i18n}}</label>
                <div class="col-sm-8">
                  {{resolveEvaluationMode(selectedSuggestion.evaluationMode)}}
                </div>
              </div>
              <div class="form-group row">
                <label class="col-sm-4" for="unitOfMeasure">{{'unit_of_measure'|i18n}}</label>
                <div class="col-sm-8">
                  <input type="text" class="form-control" name="unitOfMeasure"
                         [disabled]="!isNewTestMethodSelected()"
                         required
                         [(ngModel)]="selectedSuggestion.unitOfMeasure"
                         placeholder="{{'unit_of_measure'|i18n}}"
                         #unitOfMeasure="ngModel" />
                  <div *ngIf="isNewTestMethodSelected() && !unitOfMeasure.valid" class="alert alert-danger">
                    {{'unit_of_measure'|i18n}} {{'is_required'|i18n}}
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <kendo-tabstrip #specificationsTabStrip
                                [scrollable]="true"
                                [animate]="false"
                                keepTabContent="true"
                                class="w-100">
                  <kendo-tabstrip-tab selected="true">
                    <ng-template kendoTabTitle>
                      <div style="height:1.9rem">
                        {{selectedSuggestion.testMethodVariant?.specificationType}}
                      </div>
                    </ng-template>
                    <ng-template kendoTabContent>
                      <div class="card p-2">
                        <div class="form-group row">
                          <label class="col-sm-4">{{'description'|i18n}}</label>
                          <div class="col-sm-8 ps-0">
                            <input type="text"
                                   class="form-control"
                                   name="description"
                                   required
                                   [(ngModel)]="selectedSuggestion.testMethodVariant.description"
                                   placeholder="{{'descriptionPlaceholder'|i18n}}"
                                   #description="ngModel" />
                            <div *ngIf="!description.valid" class="alert alert-danger">
                              {{'description'|i18n}} {{'is_required'|i18n}}
                            </div>
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4">{{'type'|i18n}}</label>
                          <div class="col-sm-8 ps-0">
                            <input type="text"
                                   class="form-control"
                                   name="type"
                                   [(ngModel)]="selectedSuggestion.testMethodVariant.specificationType"
                                   placeholder="{{'type'|i18n}}" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4" for="specClass">{{'class'|i18n}}</label>
                          <div class="col-sm-8 ps-0">
                            <input type="text"
                                   class="form-control"
                                   name="specClass"
                                   [(ngModel)]="selectedSuggestion.testMethodVariant.specificationClass"
                                   placeholder="{{'class'|i18n}}"
                                   #specClass="ngModel" />
                          </div>
                        </div>
                        <div class="form-group row">
                          <label class="col-sm-4" for="specSubClass">{{'sub_class'|i18n}}</label>
                          <div class="col-sm-8 ps-0">
                            <input type="text"
                                   class="form-control"
                                   name="specSubClass"
                                   [(ngModel)]="selectedSuggestion.testMethodVariant.specificationSubClass"
                                   placeholder="{{'sub_class'|i18n}}"
                                   #specSubClass="ngModel" />
                          </div>
                        </div>
                        <div class="overflow-auto">
                          <div class="d-flex" *ngFor="let testMethodMemberSuggestion of selectedSuggestion.testMethodVariant.testMethodMembers;index as memberIndex">
                            <pdp-test-method-member [testType]="selectedSuggestion.testType"
                                                    [testMethodMember]="testMethodMemberSuggestion"
                                                    [index]="'0' + memberIndex"
                                                    class="w-100"
                                                    [isGrayScale]="isGrayScale"
                                                    [isOnlyMember]="selectedSuggestion.testMethodVariant.testMethodMembers.length === 1"
                                                    [isSubmitted]="isSubmitted"
                                                    [readonly]="isReadonly">
                            </pdp-test-method-member>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </kendo-tabstrip-tab>
                </kendo-tabstrip>
              </div>
            </form>
            <ng-template #noSuggestionData>
              <div class="alert alert-info">{{'noSuggestionDataMessage'|i18n}}</div>
            </ng-template>
          </ng-template>
        </kendo-panelbar-item>
        <kendo-panelbar-item title="{{'jsonRepresentationMessage'|i18n:{'@idx': selectedRequirementIndex + 1} }}">
          <ng-template kendoPanelBarContent>
            <div class="row">
              <pre class="col mb-0 mx-3">{{selectedRequirementJson|json}}</pre>
            </div>
          </ng-template>
        </kendo-panelbar-item>
      </kendo-panelbar>
    </div>
  </div>
</div>
<kendo-dialog-actions layout="normal" class="p-2">
  <button kendoButton
          themeColor="secondary"
          (click)="onClose()">
    {{'close'|i18n}}
  </button>
  <button kendoButton
          themeColor="primary"
          (click)="onSave()"
          [disabled]="!hasAnyRequirementSelected() || isLookingForConflicts || isImportInProgress">
    {{'importSelected'|i18n}}
  </button>
</kendo-dialog-actions>
