<div class="mb-3">
  {{'cloning_description' | i18n}}
</div>
<div class="info-card-light mb-3">
  <h5>{{'base_request' | i18n}}</h5>
  <div class="row">
    <div class="col">
      <label>{{'label_workOrderNumber'|i18n}}</label>
      <div class="mb-1">{{request.workOrderNumber}}</div>
    </div>
    <div class="col">
      <ng-container *ngIf="request.sspNumber">
        <label>{{'label_requestId'|i18n}}</label>
        <div>{{request.sspNumber}}</div>
      </ng-container>
    </div>
  </div>
</div>
<div class="info-card-light mb-3">
  <h5>{{'for_contracts' | i18n}}</h5>
  <pdp-contract-selector [selectedContracts]="selectedContracts"
                         (selectedContractIdsChange)="selectedContractsChanged($event)">
  </pdp-contract-selector>
</div>
<div class="info-card-light">
  <div class="row mb-2">
    <div class="col">
      <label title="{{'request_label_reasonForSubmittal'|i18n}}">
        {{'request_label_reasonForSubmittal'|i18n}}
      </label>
      <div>
        <kendo-dropdownlist [data]="reasonsForSubmittal"
                            textField="text"
                            valueField="value"
                            [ngModel]="cloningOptions.reasonForSubmittal"
                            (ngModelChange)="reasonForSubmittalChanged($event)"
                            [valuePrimitive]="true"
                            [disabled]="!isReasonForSubmittalEnabled">
        </kendo-dropdownlist>
      </div>
    </div>
  </div>
  <div class="mb-1">
    <label>{{'cloning_options' | i18n}}</label>
  </div>
  <div class="ps-2 mb-1">
    <input type="checkbox" id="selectAll" kendoCheckBox [(ngModel)]="selectAll" (change)="toggleSelectAll()" />
    <label class="ms-1" for="selectAll">{{'selectAll' | i18n}}</label>
  </div>
  <div class="p-2 mb-2 info-card">
    <div>
      <input type="checkbox" kendoCheckBox checked disabled />
      <label class="ms-1">{{'requestDetails'|i18n}}</label>
    </div>
    <div>
      <input type="checkbox" id="testRequirements" kendoCheckBox [(ngModel)]="cloningOptions.testRequirements" (change)="checkIfAllSelected()" />
      <label class="ms-1" for="testRequirements">{{'testRequirements' | i18n}}</label>
    </div>
    <div>
      <input type="checkbox" id="sampleInformation" kendoCheckBox [(ngModel)]="cloningOptions.sampleInformation" (change)="checkIfAllSelected()" />
      <label class="ms-1" for="sampleInformation">{{'sampleInformation' | i18n}}</label>
    </div>
  </div>
  <div class="alert alert-info">
    {{'cloning_options_note' | i18n}}
  </div>
</div>
