import { OperatorType } from "../../enums/operator-type.enum";
import { TestAttributeOverride } from "../test-requirements/test-attribute-override.model";

/**
 * View model for a test attribute.
 */
export class TestAttribute {
  id: number;
  testMethodMemberId: number;
  expectedValue: number;
  operatorType: OperatorType;
  testAttributeOverrides: TestAttributeOverride[];
  index: number;
}
