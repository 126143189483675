import { Injectable } from '@angular/core';
import { ErrorCodes } from '../enums/error-codes.enum';
import { I18nService } from './i18n.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  constructor(
    private i18nService: I18nService
  ) { }

  public resolveError(errorCode: ErrorCodes): string {
    switch (errorCode) {
      case ErrorCodes.NoSamplesAdded:
      case ErrorCodes.NoStampsAdded:
      case ErrorCodes.NoTestRequirementsAdded: return this.i18nService.transform(errorCode);
      case ErrorCodes.PlannedTestingDateOutsideBoundaries:
        const fieldName = this.i18nService.transform('request_label_plannedTestingDate');
        const minimumDays = 14;
        return this.i18nService.transform(errorCode, { '@plannedTestingDate': fieldName, '@days': minimumDays });
      default: return this.i18nService.transform('default_error_message');
    }
  }
}
