import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'pdp-login-component',
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {

  constructor(public authService: AuthService
  ) {
  }

  ngOnInit() {
    this.authService.authenticate();
  }
}
