<ng-container *ngIf="testAttribute">
  <div class="d-flex">
    <div>
      <kendo-dropdownlist [data]="allowedOperators"
                          name="operator_{{nameIndex}}"
                          [ngModel]="operatorType"
                          (valueChange)="onChangeOperatorType($event)"
                          class="h-38"
                          [disabled]="readonly">
        <ng-template kendoDropDownListValueTemplate kendoDropDownListItemTemplate let-dataItem>
          <ng-container [ngSwitch]="dataItem">
            <ng-template [ngSwitchCase]="operatorTypeEnum.LessThan">
              <i class="fas fa-less-than"></i>
            </ng-template>
            <ng-template [ngSwitchCase]="operatorTypeEnum.LessThanOrEqual">
              <i class="fas fa-less-than-equal"></i>
            </ng-template>
            <ng-template [ngSwitchCase]="operatorTypeEnum.GreaterThan">
              <i class="fas fa-greater-than"></i>
            </ng-template>
            <ng-template [ngSwitchCase]="operatorTypeEnum.GreaterThanOrEqual">
              <i class="fas fa-greater-than-equal"></i>
            </ng-template>
            <ng-template ngSwitchDefault>
              <i class="fas fa-equals"></i>
            </ng-template>
          </ng-container>
        </ng-template>
      </kendo-dropdownlist>
    </div>
    <ng-container *ngIf="!isGrayScale">
      <div class="w-100 ms-1">
        <kendo-numerictextbox class="numeric-textbox required min-w-5"
                              name="expectedValue_{{nameIndex}}"
                              [spinners]="false"
                              [ngModel]="expectedValue"
                              (change)="onChangeExpectedValue($event.target.value)"
                              [format]="numberFormat"
                              placeholder="{{placeholder|i18n}}"
                              [disabled]="readonly"
                              required
                              #expectedValueRef="ngModel">
        </kendo-numerictextbox>
          <div *ngIf="isSubmitted && !expectedValueRef.valid" class="alert alert-danger">
            <ng-container *ngIf="expectedValue == null">{{ placeholder|i18n }} is required</ng-container>
            <ng-container *ngIf="expectedValue != null">{{'min_max_validation_message'|i18n}}</ng-container>
          </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isGrayScale">
      <div class="w-100 ms-1">
        <kendo-dropdownlist [data]="grayscaleValues"
                            textField="text"
                            valueField="value"
                            [ngModel]="expectedValue"
                            [valuePrimitive]="true"
                            (valueChange)="onChangeExpectedValue($event)"
                            name="expectedValue_{{nameIndex}}"
                            [disabled]="readonly"
                            #expectedValueRef="ngModel"
                            class="grayscale-selector required min-w-5"
                            required>
        </kendo-dropdownlist>
        <div *ngIf="isSubmitted && !expectedValueRef.valid" class="alert alert-danger">
          <ng-container *ngIf="expectedValue == null">{{ placeholder|i18n }} is required</ng-container>
          <ng-container *ngIf="expectedValue != null">{{'min_max_validation_message'|i18n}}</ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
