import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { IsStampSelectedDirective } from '../../../shared/directives/is-stamp-selected.directive';
import { ErrorCodes } from '../../../shared/enums/error-codes.enum';
import { Stamp } from '../../../shared/enums/stamp.enum';
import { Request } from '../../../shared/models/requests/request.model';
import { formProvider } from '../../../shared/providers/form.provider';
import { ErrorMessageService } from '../../../shared/services/error-message.service';

@Component({
  selector: 'pdp-ptc-review',
  templateUrl: './ptc-review.component.html',
  styleUrls: ['./ptc-review.component.css'],
  viewProviders: [formProvider]
})
export class PtcReviewComponent implements OnInit {
  @ViewChild('stampDirective', { read: IsStampSelectedDirective }) public stampDirective: IsStampSelectedDirective;

  @Input() public request: Request;
  @Input() indicatorsRequired = false;
  @Input()
  set controlsRequired(value: boolean) {
    this._controlsRequired = value;
    if (this.stampDirective) {
      let stampControl = this.getStampsControl();
      if (value) {
        stampControl.markAsTouched();
      }
      this.stampDirective.validatorEnabled = value;
      stampControl.setErrors(this.stampDirective.validate(stampControl));
    }
  }

  get controlsRequired(): boolean {
    return this._controlsRequired;
  } 

  @Input()
  set readonly(value: boolean) {
    this._readonly = value;
    this.prepareSelectedStamps();
  }

  get readonly(): boolean {
    return this._readonly;
  }  

  public _readonly = false;
  public _controlsRequired = false;

  public stamps: {
    stamp: Stamp,
    selectable: boolean,
    selected: boolean
  }[] = [];

  public noStampsSelectedErrorMessage: string;

  constructor(
    private errorMessageService: ErrorMessageService,
    private requestForm: NgForm
  ) { }

  ngOnInit(): void {
    this.initializeStamps();
    this.noStampsSelectedErrorMessage = this.errorMessageService.resolveError(ErrorCodes.NoStampsAdded);

    this.prepareSelectedStamps();
  }

  public get stampEnum(): typeof Stamp {
    return Stamp;
  }

  initializeStamps() {
    for (const s in Stamp) {
      if (typeof Stamp[s] === 'number') {
        this.stamps.push({ stamp: <any>Stamp[s], selectable: true, selected: false });
      }
    }
  }

  private prepareSelectedStamps() {
    /* tslint:disable:no-bitwise */
    if (!this.readonly) {
      this.stamps.forEach(s => {
        s.selected = (s.stamp & this.request.stamps) === s.stamp;
        if (s.selected) {
          this.dimNotSelectableStamps(s);
        }
      });
    } else {
      this.stamps = this.stamps.filter(s => (s.stamp & this.request.stamps) === s.stamp);
      this.stamps.forEach(s => s.selected = false);
    }
    /* tslint:enable:no-bitwise */
  }

  toggleSelection(stamp) {
    if (this.readonly) {
      return;
    }

    if (this.requestForm) {
      const stampsControl = this.getStampsControl();
      if (stampsControl && !stampsControl.touched) {
        stampsControl.markAsTouched();
      }
    }

    stamp.selected = !stamp.selected;
    this.dimNotSelectableStamps(stamp);

    this.request.stamps = 0;
    this.stamps.forEach(s => {
      if (s.selected) {
        this.request.stamps += s.stamp;
      }
    });
  }

  dimNotSelectableStamps(stamp: {
    stamp: Stamp,
    selectable: boolean,
    selected: boolean
  }) {
    /* tslint:disable:no-bitwise */
    this.stamps.forEach(s => {
      s.selectable = (this.hasFlag((Stamp.Failing | Stamp.PtcFailedTest | Stamp.LabFailedTest), stamp.stamp)
          && this.hasFlag((Stamp.Failing | Stamp.PtcFailedTest | Stamp.LabFailedTest), s.stamp)) ||
        (this.hasFlag((Stamp.Passing | Stamp.PtcPassedTest | Stamp.LabPassedTest), stamp.stamp)
          && this.hasFlag((Stamp.Passing | Stamp.PtcPassedTest | Stamp.LabPassedTest), s.stamp)) ||
        (this.hasFlag(Stamp.UnableToProcess, stamp.stamp) && this.hasFlag(Stamp.UnableToProcess, s.stamp));

      s.selected = !s.selectable ? false : s.selected;
    });
    /* tslint:enable:no-bitwise */
  }

  hasFlag(listToCheck: number, value: number, exclude?: boolean): boolean {
    /* tslint:disable:no-bitwise */
    if (exclude) {
      return (listToCheck & value) !== value;
    }

    return (listToCheck & value) === value;
    /* tslint:enable:no-bitwise */
  }

  isStampsInvalid(): boolean {
    const stampsControl = this.getStampsControl();
    return stampsControl ? stampsControl.invalid && stampsControl.touched : false;
  }

  private getStampsControl(): UntypedFormControl {
    return this.requestForm.form.get('stamps') as UntypedFormControl;
  }
}
