import { TestType } from "../../enums/test-type.enum";
import { TestRequirement } from "../test-requirements/test-requirement.model";
import { TestMethodVariant } from "./test-method-variant.model";
import { EvaluationMode } from "../../enums/evaluation-mode.enum";
import { TestMethodType } from "../../enums/test-method-type.enum";

/**
 * View model for a test method.
 */
export class TestMethod {
  id: number;
  name: string;
  title: string;
  summary: string;
  testMethodVariants: TestMethodVariant[] = [];
  testRequirements: TestRequirement[] = [];
  testType: TestType;
  unitOfMeasure: string;
  comment: string;
  modified: Date;
  modifiedBy: string;
  modifiedByEmail: string;
  isReadOnly: boolean;
  isDeletable: boolean;
  evaluationMode: EvaluationMode;
  specificationRequirementNotes: string;
  archiveDate?: Date;
  testMethodType: TestMethodType;
}
