import { Component, OnInit, Input, ViewEncapsulation, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { Request } from '../../../shared/models/requests/request.model';
import { I18nService } from '../../../shared/services/i18n.service';
import { FillOutDirection } from '../../../shared/enums/fill-out-direction.enum';
import { SortingService } from '../../../shared/services/sorting.service';
import { RequestService } from '../../../shared/services/request.service';
import { AttachmentCategory } from '../../../shared/enums/attachment-category.enum';
import { Attachment } from '../../../shared/models/requests/attachment.model';
import { ToastrService } from 'ngx-toastr';
import { BlobDownloadService } from '../../../shared/services/blob-download.service';
import { EvaluationMode } from '../../../shared/enums/evaluation-mode.enum';
import { formProvider } from '../../../shared/providers/form.provider';
import { NgForm } from '@angular/forms';
import { TestRequirement } from '../../../shared/models/test-requirements/test-requirement.model';
import { PanelBarItemModel } from '@progress/kendo-angular-layout';
import { FormGroupNames } from '../../../shared/enums/form-group-names.enum';
import { ISortable } from '../../../shared/interfaces/i-sortable.interface';
import { RequestStatus } from '../../../shared/enums/request-status.enum';
import { TestType } from '../../../shared/enums/test-type.enum';

@Component({
  selector: 'pdp-test-details',
  templateUrl: './test-details.component.html',
  styleUrls: ['./test-details.component.css'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [formProvider]
})
export class TestDetailsComponent implements OnInit {
  labReports: Attachment[] = [];
  uploadSaveUrl = '';

  EvaluationMode = EvaluationMode;
  FormGroupNames = FormGroupNames;

  private _request: Request;

  @Input() set request(value: Request) {
    this._request = value;
    if (this._request) {
      if (this._request.status === RequestStatus.InProgress) {
        this.expandUnfinished();
      } else if (this._request.status >= RequestStatus.ArchivedAndConfirmed && this._request.status !== RequestStatus.All) {
        this.collapseAll();
      } else {
        this.expandAll();
      }
    }
  }
  @Input() readonly = false;
  @Input() indicatorsRequired = false;
  @Input() controlsRequired = false;

  get request() { return this._request; }

  private expandedRows = new Set();

  FillOutDirection = FillOutDirection;
  fillOutDirection: FillOutDirection = FillOutDirection.Across;

  @ViewChildren('testResultsPanelBarItem', { read: ElementRef }) panelbarItemElementList: QueryList<ElementRef>;

  constructor(
    public i18nService: I18nService,
    public sortingService: SortingService,
    public requestForm: NgForm,
    private requestService: RequestService,
    private toastr: ToastrService,
    private blobDownloadService: BlobDownloadService
  ) { }

  ngOnInit() {
    this.labReports = this.request.attachments.filter(a => a.category === AttachmentCategory.LabReport);

    this.uploadSaveUrl = this.requestService.getUploadLabReportUrl(this.request.id);
  }

  onUploadComplete() {
    this.requestService.getRequest(this.request.id).subscribe(response => {
      this.labReports = response.body.attachments.filter(a => a.category === AttachmentCategory.LabReport);
    });
    this.toastr.success(this.i18nService.transform('labReportUploaded'));
  }

  isAllRowsExpanded(): boolean {
    if (this._request && this._request.testRequirements) {
      return this._request.testRequirements.length === this.expandedRows.size;
    }

    return false;
  }

  expandAll() {
    if (this._request && this._request.testRequirements) {
      this.expandedRows.clear();
      this._request.testRequirements.forEach(testRequirement => {
        this.expandedRows.add(testRequirement.id);
      });
    }
  }

  expandUnfinished() {
    if (this._request && this._request.testRequirements) {
      this.expandedRows.clear();
      this._request.testRequirements.forEach(testRequirement => {
        if (this.isUnfinishedFillout(testRequirement)) {
          this.expandedRows.add(testRequirement.id);
        }
      });
    }
  }

  isUnfinishedFillout(testRequirement: TestRequirement) {
    return testRequirement && testRequirement.testResults && (testRequirement.testResults.length === 0 || testRequirement.testResults.some(tr => tr.value === null || tr.value === undefined));
  }

  collapseAll() {
    this.expandedRows.clear();
  }

  deleteLabReport(labReport: Attachment) {
    this.requestService.deleteLabReport(this.request.id, labReport.id).subscribe(response => {
      if (response.ok) {
        this.labReports = this.labReports.filter(l => l.id !== labReport.id);
        this.toastr.success(this.i18nService.transform('labReportDeleted'));
      }
    });
  }

  onClickLabReport(labReport: Attachment) {
    this.requestService.downloadLabReport(this.request.id, labReport.id).subscribe(blob => {
      this.blobDownloadService.downloadBlob(blob, labReport.name);
    });
  }

  setFillOutDirection(direction: FillOutDirection) {
    this.fillOutDirection = direction;
  }

  calculateLastTabIndexFor(testRequirementIndex: number): number {
    let indexCount = 0;

    for (let i = 0; i < testRequirementIndex; i++) {
      const currentRequirement = this.request.testRequirements[i];
      const sampleCount = this.request.samples ? this.request.samples.length : 0;
      const determination = currentRequirement.determination;

      const testMethodMemberCount = currentRequirement.testMethodVariant.testMethodMembers
        ? currentRequirement.testMethodVariant.testMethodMembers.length : 0;

      indexCount += sampleCount * testMethodMemberCount * determination;
    }

    return indexCount;
  }

  public resolveAggregateFunctionName(aggregateFunction: string, testType: string, isFullLogical: boolean = false) {
    var prefix = testType === TestType.Logical ? `aggregateFunction_${isFullLogical ? 'full' : 'simple'}_logical` : 'aggregateFunction_full';
    return this.i18nService.transform(`${prefix}_${aggregateFunction}`);
  }

  isExpanded = function (testRequirementId: number): boolean {
    if (this.request && this.request.testRequirements) {
      return this.expandedRows.has(testRequirementId);
    }

    return false;
  }.bind(this);

  isTestResultsInvalid(testRequirementIndex: number): boolean {
    if (this.panelbarItemElementList) {
      const panelbarItemElement = this.panelbarItemElementList.toArray()[testRequirementIndex];
      return panelbarItemElement ? this.hasInvalidControl(panelbarItemElement, { shouldBeTouched: true }) : false;
    }
    return false;

  }

  private hasInvalidControl(panelElement: ElementRef, options?: { shouldBeTouched?: boolean }) {
    let selectorPattern = '.ng-invalid';

    if (options && options.shouldBeTouched) {
      selectorPattern += '.ng-touched';
    }

    const invalidControls = panelElement.nativeElement.querySelectorAll(selectorPattern);
    return invalidControls && invalidControls.length > 0;
  }

  public stateChange(data: Array<PanelBarItemModel>): boolean {
    const focusedEvent: PanelBarItemModel = data.filter(
      (item) => item.focused === true
    )[0];

    if (focusedEvent.id) {
      const testRequirementId = focusedEvent.id;

      if (focusedEvent.expanded) {
        this.expandedRows.add(testRequirementId);
      } else {
        this.expandedRows.delete(testRequirementId);
      }
    }

    return false;
  }

  moveSortableItemUp(event: any, list: Array<ISortable>, item: ISortable) {
    event.stopPropagation();
    this.sortingService.moveSortableItemUp(list, item);
  }

  moveSortableItemDown(event: any, list: Array<ISortable>, item: ISortable) {
    event.stopPropagation();
    this.sortingService.moveSortableItemDown(list, item);
  }

  trackBy(item: TestRequirement, index: number) {
    return item;
  }
}
