import { Component, OnDestroy, OnInit } from '@angular/core';
import { Company } from '../shared/models/companies/company.model';
import { CompaniesService } from '../shared/services/companies.service';
import { ContractStatus } from '../shared/enums/contract-status.enum';
import { Contract } from '../shared/models/contracts/contract.model';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ContractDetailsComponent } from './contract-details/contract-details.component';
import { CellClickEvent, GridDataResult, RowClassArgs, RowClassFn } from '@progress/kendo-angular-grid';
import { NGridOptions } from '../shared/components/n-grid/models/n-grid-options.model';
import { NGridState } from '../shared/components/n-grid/models/n-grid-state.model';
import { INGridColumnOptionsBase } from '../shared/components/n-grid/interfaces/i-n-grid.column-options.interface';
import { NGridColumnOptions } from '../shared/components/n-grid/models/n-grid-column-options.model';
import { I18nService } from '../shared/services/i18n.service';
import { ContractsService } from '../shared/services/contracts.service';
import { NGridService } from '../shared/components/n-grid/n-grid.service';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from '../shared/services/session.service';
import { AuthorizationService } from '../shared/services/authorization.service';
import { BehaviorSubject, EMPTY, Observable, Subscription } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { NGridPagedData } from '../shared/components/n-grid/models/n-grid-paged-data.model';

@Component({
  selector: 'pdp-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.css'],
  host: { class: 'flex-column-layout w-100' }
})
export class ContractsComponent implements OnInit, OnDestroy {
  isLoading = false;

  gridData$: Observable<GridDataResult>;
  private gridStateSubject: BehaviorSubject<NGridState>;

  public tabHeaders = [{ label: 'Open' }, { label: 'Archived' }];

  gridColumns: INGridColumnOptionsBase[] = [
    new NGridColumnOptions({
      field: 'contractNumber',
      title: this.i18nService.transform('contractNumber')
    }),
    new NGridColumnOptions({
      field: 'endItemName',
      title: this.i18nService.transform('endItemName')
    }),
    new NGridColumnOptions({
      field: 'primeContractor.name',
      title: this.i18nService.transform('primeContractor'),
      sortable: false
    })
  ];

  gridOptions: NGridOptions = new NGridOptions({
    isPageable: true,
    isSortable: true,
    initialLoading: true
  });

  gridState: NGridState = {
    take: 50,
    sort: [{
      field: 'contractNumber',
      dir: 'asc'
    }]
  };

  noRecordsText = this.i18nService.transform('message_no_record');
  columnFilterOptionText = this.i18nService.transform('home_agendaGrid_columnFilterOption_text');
  freeTextSearchFilterOptionText = this.i18nService.transform('home_agendaGrid_freeTextSearchFilterOption_text');

  public companies: Company[] = [];
  public isPortalAdmin = false;
  private userCompany: Company;
  private subscriptions = new Subscription();

  constructor(
    private sessionService: SessionService,
    private authorizationService: AuthorizationService,
    private toastr: ToastrService,
    private nGridService: NGridService,
    private contractsService: ContractsService,
    private companiesService: CompaniesService,
    private dialogService: DialogService,
    public i18nService: I18nService) { }    

  ngOnInit() {
    this.companiesService.allCompanies().subscribe(res => {
      res.forEach((itm) => {
        this.companies.push(itm as Company);
      });

      this.gridState = this.nGridService.applyFilter(this.gridState, 'status', ContractStatus.Open.toString());
      this.gridStateSubject = new BehaviorSubject<NGridState>(this.gridState);
      this.gridData$ = this.gridStateSubject.pipe(
        switchMap(state => this.getContracts(state))
      );      

      this.subscriptions.add(this.sessionService.currentUser$.subscribe(user => {
        if (user) {
          this.isPortalAdmin = this.authorizationService.isSystemOrPortalAdmin(user);
          this.userCompany = this.companies.find(c => c.externalId === user.companyId);
        }
      }));
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getContracts(state: NGridState): Observable<NGridPagedData<Contract>> {
    this.isLoading = true;
    return this.contractsService.getContractsByGridState(state).pipe(
      tap(() => { this.isLoading = false; }),
      catchError((error, caught) => {
        this.toastr.error(error.message);
        this.isLoading = false;

        return EMPTY;
      })
    );
  }

  onTabSelect(e) {
    const status = ContractStatus[ContractStatus[e.index]];
    this.gridState = this.nGridService.applyFilter(this.gridState, 'status', status);
    this.gridState.skip = 0;
    this.gridStateSubject.next(this.gridState);
  }

  gridStateChange(state: NGridState): void {
    this.gridState = state;
    this.gridStateSubject.next(this.gridState);
  }

  cellClick(e: CellClickEvent): void {
    const contract = e.dataItem;
    this.openContractDetailsDialog(contract);
  }

  stylizeRows: RowClassFn = (context: RowClassArgs) => {
    return 'clickable';
  };


  public openCreateContractDialog() {
    const dialogRef = this.dialogService.open({
      content: ContractDetailsComponent
    });

    const instance = dialogRef.content.instance;
    const contract = new Contract();

    instance.title = this.i18nService.transform('new_contract');
    instance.contract = contract;
    instance.companies = this.companies;
    instance.actionName = this.i18nService.transform('create');

    instance.userCompany = this.userCompany;
    if (!this.isPortalAdmin) {
      instance.contract.primeId = this.userCompany.id;
      instance.isPortalAdmin = false;
    } else {
      instance.isPortalAdmin = true;
    }

    this.subscriptions.add(dialogRef.result.subscribe((result: string) => {
      if (result.includes('saved')) {
        this.gridStateSubject.next(this.gridState);
        this.toastr.success(this.i18nService.transform('message_contract_created'));
      }
    }));
  }

  openContractDetailsDialog(contract: Contract) {
    const dialogRef = this.dialogService.open({
      content: ContractDetailsComponent
    });

    const instance = dialogRef.content.instance;

    instance.userCompany = this.userCompany;
    if (!this.isPortalAdmin) {
      instance.isPortalAdmin = false;
    } else {
      instance.isPortalAdmin = true;
    }

    instance.title = this.i18nService.transform('contract_details');
    instance.contract = JSON.parse(JSON.stringify(contract));
    instance.companies = this.companies;
    instance.actionName = this.i18nService.transform('update');

    this.subscriptions.add(dialogRef.result.subscribe((result: string) => {
      const isSaved = result.includes('saved');
      const isStateChanged = result.includes('stateChanged');
      if (isSaved || isStateChanged) {
        this.gridStateSubject.next(this.gridState);
      }

      if (isSaved) {
        this.toastr.success(this.i18nService.transform('message_contract_updated'));
      }
    }));
  }
}
