import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { I18nService } from '../../shared/services/i18n.service';
import { OperatorType } from '../../shared/enums/operator-type.enum';
import { TestAttribute } from '../../shared/models/test-methods/test-attribute.model';
import { TestAttributeOverride } from '../../shared/models/test-requirements/test-attribute-override.model';
import { DataSourceService } from '../../shared/services/datasource.service';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { formProvider } from '../../shared/providers/form.provider';

@Component({
  selector: 'pdp-test-attribute-numeric',
  templateUrl: './test-attribute-numeric.component.html',
  styleUrls: ['./test-attribute-numeric.component.css'],
  viewProviders: [formProvider],
  encapsulation: ViewEncapsulation.None
})
export class TestAttributeNumericComponent implements OnInit {
  @Output() attributeChange = new EventEmitter();
  @Input() public testAttribute: TestAttribute;
  @Input() public testAttributeOverrides: TestAttributeOverride[];
  @Input() public allowedOperators: OperatorType[] = [];
  @Input() public placeholder: string;
  @Input() public nameIndex: string;
  @Input() public isSubmitted: boolean;
  @Input() public numberFormat: NumberFormatOptions;
  @Input() public testRequirementId: number;
  @Input() public isLotAverageConditionComponent: boolean;
  @Input() public readonly = false;
  @Input()
  public set isGrayScale(value: boolean) {
    this._isGrayScale = value;

    if (this._isGrayScale && this.grayscaleValues) {
      this.clearExpectedValueIfNecessary();
    }
  }

  public get isGrayScale(): boolean {
    return this._isGrayScale;
  }

  private _isGrayScale = false;

  public testAttributeOverride: TestAttributeOverride;

  public operatorTypeEnum = OperatorType;

  public expectedValue: number;
  public operatorType: OperatorType;

  public grayscaleValues: { text: string, value: number }[];

  constructor(
    public i18nService: I18nService,
    private dataSourceService: DataSourceService) {
  }

  ngOnInit(): void {
    this.grayscaleValues = this.dataSourceService.getGrayScaleDataSource();

    if (this.testAttribute && this.testAttribute.operatorType == null) {
      this.testAttribute.operatorType = this.allowedOperators[0];
    }

    if (this.testAttributeOverrides != null) {
      this.testAttributeOverride = this.testAttributeOverrides.find(t => t.testAttributeId === this.testAttribute.id);
    }
    this.expectedValue = this.testAttributeOverride ? this.testAttributeOverride.expectedValue : this.testAttribute.expectedValue;
    this.operatorType = this.testAttributeOverride ? this.testAttributeOverride.operatorType : this.testAttribute.operatorType;
  }

  private clearExpectedValueIfNecessary() {
    const value = this.testAttributeOverride ? this.testAttributeOverride.expectedValue : this.testAttribute.expectedValue;

    const isValidGrayscaleValue = this.grayscaleValues.findIndex(gsv => gsv.value === value) >= 0;

    if (this.isGrayScale && !isValidGrayscaleValue) {
      this.onChangeExpectedValue('');
    }
  }

  onChangeExpectedValue(expectedValue) {
    if (expectedValue === '') {
      expectedValue = null;
    } else {
      expectedValue = +expectedValue;
    }

    this.onChange(expectedValue, this.operatorType);
  }

  onChangeOperatorType(operatorType) {
    this.onChange(this.expectedValue, operatorType);
  }

  onChange(expectedValue, operatorType) {
    this.attributeChange.emit();

    // If there is no test requirement (it is a test method)
    if (this.testAttributeOverrides == null) {
      this.testAttribute.operatorType = operatorType;
      this.operatorType = operatorType;
      this.testAttribute.expectedValue = expectedValue;
      this.expectedValue = expectedValue;
    } else {
      if (!this.testAttributeOverride) {
        this.testAttributeOverride = new TestAttributeOverride();
        this.testAttributeOverride.operatorType = operatorType;
        this.testAttributeOverride.expectedValue = expectedValue;
        this.testAttributeOverride.isLotAverageConditionComponent = this.isLotAverageConditionComponent;
        this.testAttributeOverride.testAttributeId = this.testAttribute.id;
        this.testAttributeOverride.testRequirementId = this.testRequirementId;
        this.testAttributeOverrides.push(this.testAttributeOverride);
      }

      if (operatorType === this.testAttribute.operatorType && expectedValue === this.testAttribute.expectedValue) {
        const index = this.testAttributeOverrides.findIndex(tao => tao === this.testAttributeOverride);
        this.testAttributeOverrides.splice(index, 1);
        this.testAttributeOverride = null;
      } else {
        this.testAttributeOverride.operatorType = operatorType;        
        this.testAttributeOverride.expectedValue = expectedValue;        
      }

      this.operatorType = operatorType;
      this.expectedValue = expectedValue;
    }
  }
}
