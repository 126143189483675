import { AggregateConditionType } from "../../enums/aggregate-condition-type.enum";
import { AggregateFunction } from "../../enums/aggregate-function.enum";
import { EvaluationMode } from "../../enums/evaluation-mode.enum";
import { TestType } from "../../enums/test-type.enum";
import { ISortable } from "../../interfaces/i-sortable.interface";
import { TestMethodMember } from "./test-method-member.model";
import { TestMethod } from "./test-method.model";

/**
 * View model for a test method variant.
 */
export class TestMethodVariant implements ISortable {
  id: number;
  testMethodMembers: TestMethodMember[] = [];
  summary: string;
  description: string;
  specificationType: string;
  specificationClass: string;
  specificationSubClass: string;
  testMethodId: number;
  testMethod: TestMethod;
  isReadOnly: boolean;
  determination: number;
  numberOfAllowedFailures: number;
  aggregateFunction: AggregateFunction;
  testType: TestType;
  evaluationMode: EvaluationMode;
  aggregateConditionType: AggregateConditionType;
  lotAverageConditionType: TestType;
  lotAverageConditionMember: TestMethodMember;
  percentageOfRequiredPasses: number;
  precisionLevel: number;
  sortIndex: number;
}
