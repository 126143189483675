import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TestMethodMember } from '../../shared/models/test-methods/test-method-member.model';
import { TestType } from '../../shared/enums/test-type.enum';
import { OperatorType } from '../../shared/enums/operator-type.enum';
import { TestAttribute } from '../../shared/models/test-methods/test-attribute.model';
import { NumberFormatOptions } from '@progress/kendo-angular-intl';
import { formProvider } from '../../shared/providers/form.provider';
import { MinMaxValidation } from '../../shared/models/validation/min-max-validation.model';
import { TestAttributeOverride } from '../../shared/models/test-requirements/test-attribute-override.model';

@Component({
  selector: 'pdp-test-method-member',
  templateUrl: './test-method-member.component.html',
  styleUrls: ['./test-method-member.component.css'],
  viewProviders: [formProvider]
})
export class TestMethodMemberComponent {
  @Input() public testMethodMember: TestMethodMember;
  @Input() public testType: TestType;
  @Input() public index: string;
  @Input() public isSubmitted: boolean;
  @Input() public isOnlyMember: boolean;
  @Input() public isGrayScale: boolean;
  @Input() public testAttributeOverrides: TestAttributeOverride[];
  @Input() public isLotAverageConditionComponent: boolean = false;
  @Input() public readonly: boolean = false;
  @Input() public numberFormat: NumberFormatOptions;
  @Output() attributeChange = new EventEmitter();
  @Output() memberChange = new EventEmitter();

  public testTypeEnum = TestType;
  public operatorTypeEnum = OperatorType;
  public minMaxNames: MinMaxValidation[] = [{ minimum: `expectedValue_0`, maximum: 'expectedValue_1' }];

  onAttributeChange() {
    this.attributeChange.emit();
  }

  onMemberChange() {
    this.memberChange.emit();
  }

  getTestAttribute(testMethodMember, index): TestAttribute {
    return testMethodMember.testAttributes.find(ta => ta.index === index);
  }
}
